import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {OrdersReport} from './orders-reports-table.component';
import {Button, ButtonGroup, Icon} from '@blueprintjs/core';
import {createColumnHelper} from '@tanstack/react-table';
import {capitalize} from 'lodash';
import {DateTime} from 'luxon';
import {downloadOrderReport} from '../../api/orders-report.api';
import {useAuth} from '../../contexts/auth.context';

const columnHelper = createColumnHelper<OrdersReport>() as any;

const renderDateCell = (cell, store) =>
  !!cell &&
  DateTime.fromISO(cell)
    .setZone(store?.timezone ?? 'America/Los_Angeles')
    .toFormat('MM/dd/yy h:mm a ZZZZ');

export const createTableColumns = (store: StoreDto, deleteFunction: (row: any) => void) => {
  const {user} = useAuth();
  return [
    columnHelper.accessor('createdAt', {
      header: () => <span>Created At</span>,
      cell: (info) => !!info.getValue() && DateTime.fromISO(info.getValue()).setZone(store?.timezone!).toRelative(),
    }),
    columnHelper.accessor('startDate', {
      header: () => 'From',
      cell: (info) => renderDateCell(info.getValue(), store),
    }),
    columnHelper.accessor('endDate', {
      header: 'To',
      cell: (info) => renderDateCell(info.getValue(), store),
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => capitalize(info.getValue()),
    }),
    columnHelper.accessor('fileUrl', {
      header: '',
      className: 'table__actions',
      cell: (info) => {
        return (
          <ButtonGroup>
            <Button
              disabled={info.row.original.status !== 'ready'}
              text="Download"
              icon="download"
              title="Download report"
              onClick={() => downloadOrderReport(user.token, info.row.original.id)}
            />
            <Button
              title="Delete report"
              icon={<Icon icon="trash" intent="danger" />}
              onClick={() => deleteFunction(info.row.original)}
            />
          </ButtonGroup>
        );
      },
    }),
  ] as any;
};
