import React from 'react';

import {Button, ButtonProps, MenuItem} from '@blueprintjs/core';
import {Select, SelectProps} from '@blueprintjs/select';
import classNames from 'classnames';

import {ADDITIONAL_CHARGE_TYPES} from '../../utils/constants';
import {AdditionalChargeType} from '../../utils/types';
import {capitalize} from 'lodash';

interface AdditionalChargeTypeSelectorProps extends Omit<SelectProps<AdditionalChargeType>, 'itemRenderer' | 'items'> {
  fill?: boolean;
  selectedItem?: AdditionalChargeType;
  buttonProps?: ButtonProps;
}

const AdditionalChargeTypeComponent = Select.ofType<AdditionalChargeType>();

export const AdditionalChargeTypeSelector: React.FC<AdditionalChargeTypeSelectorProps> = ({
  selectedItem,
  buttonProps,
  ...props
}) => {
  const items: AdditionalChargeType[] = [...ADDITIONAL_CHARGE_TYPES];

  return (
    <AdditionalChargeTypeComponent
      {...props}
      filterable={false}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      items={items}
      itemRenderer={(additionalChargeType, {modifiers, handleClick}) => (
        <MenuItem
          className="tw-capitalize"
          active={selectedItem === additionalChargeType}
          disabled={modifiers.disabled}
          key={additionalChargeType}
          onClick={handleClick}
          text={capitalize(additionalChargeType)}
        />
      )}
    >
      <Button
        {...buttonProps}
        text={selectedItem ? capitalize(selectedItem) : 'Type'}
        rightIcon="chevron-down"
        fill={props.fill}
      />
    </AdditionalChargeTypeComponent>
  );
};
