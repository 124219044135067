import React, {useState} from 'react';

import {Button, Callout, Spinner} from '@blueprintjs/core';
import {useQuery, useQueryClient} from 'react-query';
import {fetchStoreRequest} from '../../../api/store.api';
import {TimeframeRow} from '../../../components/timeframes/TimeframeRow';
import {TimeframeUpsertDialog} from '../../../components/timeframes/timeframe-upsert-dialog.component';
import {TimeframeDto} from '@kontactless/admin-api/timeframe/timeframe.dto';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../contexts/auth.context';

export function TimeframesPage() {
  const [isTimeframeDialogOpen, setIsTimeframeDialogOpen] = useState(false);
  const [timeframeEditing, setTimeframeEditing] = useState<TimeframeDto | undefined>();
  const {user} = useAuth();
  const queryClient = useQueryClient();
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);

  const storeQuery = useQuery({queryKey: ['stores', storeId], queryFn: () => fetchStoreRequest(user.token, storeId)});

  const timeframes = storeQuery.data?.timeframes ?? [];

  if (!storeQuery.data || storeQuery.isLoading || !timeframes) {
    return <Spinner />;
  }

  return (
    <div className="tw-w-full">
      <div className="tw-flex tw-justify-between tw-w-full tw-my-4 tw-px-6">
        <header className="card-header">
          <h3 className="tw-text-xl tw-font-bold">Timeframes</h3>
        </header>

        <Button text="+ Create timeframe" intent="primary" onClick={() => setIsTimeframeDialogOpen(true)} />
      </div>
      <div className="card-body tw-px-6 tw-pb-6">
        <ul className="tw-divide-solid tw-divide-y tw-divide-gray-200">
          {timeframes.map((timeframe) => (
            <TimeframeRow
              key={timeframe.id}
              timeframe={timeframe}
              onDelete={() => queryClient.invalidateQueries(['stores', storeId])}
              onEdit={(timeframe) => {
                setTimeframeEditing(timeframe);
                setIsTimeframeDialogOpen(true);
              }}
              onSwitch={() => queryClient.invalidateQueries(['stores', storeId])}
            />
          ))}
          {!timeframes.length && <Callout icon="info-sign">There are no timeframes set for this store</Callout>}
        </ul>
      </div>
      <TimeframeUpsertDialog
        storeId={storeId}
        isOpen={isTimeframeDialogOpen}
        timeframe={timeframeEditing}
        onResolve={() => queryClient.invalidateQueries(['stores', storeId])}
        onClose={() => {
          setIsTimeframeDialogOpen(false);
          setTimeframeEditing(undefined);
        }}
      />
    </div>
  );
}
