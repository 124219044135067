import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ErrorBoundary} from 'react-error-boundary';

import {AlertsProvider} from './contexts/alerts.context';
import {AuthProvider} from './contexts/auth.context';
import {CacheProvider} from './contexts/cache/cache.context';
import {ReactQueryProvider} from './contexts/react-query.context';
import {ToastsProvider} from './contexts/toasts.context';
import {FatalErrorScene} from './scenes/fatal-error/fatal-error.scene';
import {HOME_SCENE_PATH} from './utils/paths';

export const AppWrapper: React.FC = ({children}) => {
  const navigate = useNavigate();

  return (
    <ErrorBoundary FallbackComponent={FatalErrorScene} onReset={() => navigate(HOME_SCENE_PATH)}>
      <ReactQueryProvider>
        <CacheProvider>
          <AuthProvider>
            <ToastsProvider>
              <AlertsProvider>{children}</AlertsProvider>
            </ToastsProvider>
          </AuthProvider>
        </CacheProvider>
      </ReactQueryProvider>
    </ErrorBoundary>
  );
};
