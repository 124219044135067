import React, {useMemo} from 'react';

import {Button, Classes, Dialog, FormGroup, IDialogProps, InputGroup} from '@blueprintjs/core';
import {TimePicker} from '@blueprintjs/datetime';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';

import {useAuth} from '../../contexts/auth.context';
import {DayOfWeek, TimeframeType} from '../../utils/types';

import './timeframes.styles.scss';

import {yupResolver} from '@hookform/resolvers/yup';
import {DateTime} from 'luxon';
import {useMutation} from 'react-query';
import * as yup from 'yup';

import {createTimeframeRequest, updateTimeframeRequest} from '../../api/timeframe.api';
import {useToasts} from '../../contexts/toasts.context';

import {TimeframeCreateRequestDto, TimeframeDto, TimeframeUpdateRequestDto} from '@kontactless/admin-api/timeframe/timeframe.dto';
import {WeekListCheckbox} from './timeframe-week.component';
import {DAYS_OF_WEEK} from '../../utils/constants';

export interface TimeframeUpsertDialogProps extends IDialogProps {
  storeId: number;
  sectionId?: number;
  discountId?: number;
  timeframe?: TimeframeDto;
  title?: string;
  isInputNameEnabled?: Boolean;
  type?: TimeframeType | null;
  onResolve?: (timeframe: TimeframeDto) => void;
}

interface TimeframeForm {
  name: string;
  title: string;
  startTime: Date;
  endTime: Date;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  daysOfWeek: DayOfWeek[];
}

export function TimeframeUpsertDialog({
  storeId,
  sectionId,
  discountId,
  type,
  title,
  timeframe,
  isInputNameEnabled = true,
  onResolve,
  ...props
}: TimeframeUpsertDialogProps) {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const formValidator = yup
    .object()
    .shape({
      startDate: yup.date().nullable(true),
      endDate: yup.date().nullable(true),
      daysOfWeek: yup.array().required().min(1),
    })
    .test({
      name: 'startDate',
      test: function (values) {
        if (values.startDate && values.endDate) {
          if (DateTime.fromJSDate(values.startDate) > DateTime.fromJSDate(values.endDate)) {
            return this.createError({
              path: 'startDate',
              message: 'Start date must to be before start date',
            });
          }
        }
        return true;
      },
    });

  const {register, handleSubmit, control, errors} = useForm<TimeframeForm>({
    resolver: yupResolver(formValidator),
  });

  const upsertTimeframeMutation = useMutation<TimeframeDto, Error, TimeframeCreateRequestDto | TimeframeUpdateRequestDto>(
    (body) => {
      if (timeframe?.id) {
        return updateTimeframeRequest(user.token, body as TimeframeUpdateRequestDto, timeframe.id);
      } else {
        return createTimeframeRequest(user.token, body as TimeframeCreateRequestDto);
      }
    }
  );

  const defaultStartTime = useMemo(
    () =>
      timeframe?.startTime
        ? DateTime.fromFormat(timeframe.startTime, 'HH:mm:ss').toJSDate()
        : DateTime.local().startOf('day').toJSDate(),
    [timeframe]
  );

  const defaultEndTime = useMemo(
    () =>
      timeframe?.endTime
        ? DateTime.fromFormat(timeframe.endTime, 'HH:mm:ss').toJSDate()
        : DateTime.local().startOf('day').toJSDate(),
    [timeframe]
  );

  const submitForm = async (form: TimeframeForm) => {
    try {
      const upsertedTimeframe = await upsertTimeframeMutation.mutateAsync({
        daysOfWeek: form.daysOfWeek,
        startTime: DateTime.fromJSDate(form.startTime).toFormat('HH:mm:ss'),
        endTime: DateTime.fromJSDate(form.endTime).toFormat('HH:mm:ss'),
        startDate: form.startDate ? DateTime.fromJSDate(form.startDate).toFormat('yyyy-MM-dd') : undefined,
        endDate: form.endDate ? DateTime.fromJSDate(form.endDate).toFormat('yyyy-MM-dd') : undefined,
        name: isInputNameEnabled ? form.name.trim() : '',
        storeId,
        sectionId,
        discountId,
        type: type ?? null,
      });

      props.onClose?.(undefined as any);
      onResolve?.(upsertedTimeframe);

      toaster.show({intent: 'success', message: `Timeframe was ${timeframe?.id ? 'updated' : 'created'} successfully`});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred creating the timeframe'});
    }
  };

  return (
    <Dialog
      title={title ? title : timeframe ? 'Edit Timeframe' : 'New Timeframe'}
      className="kl-dialog timeframe-dialog"
      {...props}
    >
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          {isInputNameEnabled && (
            <FormGroup label="Name" intent={errors.name ? 'danger' : 'none'} helperText={errors.name ? 'Name is required' : ''}>
              <InputGroup
                className="control"
                name="name"
                intent={errors.name ? 'danger' : 'none'}
                defaultValue={timeframe?.name ?? ''}
                inputRef={register}
              />
            </FormGroup>
          )}
          {/* <div className="time-inputs">
            <FormGroup
              label="Start Date"
              intent={errors.startDate ? 'danger' : 'none'}
              helperText={errors.startDate ? 'Start date must to be before end date' : ''}
            >
              <Controller
                name="startDate"
                control={control}
                defaultValue={timeframe?.startDate ? DateTime.fromISO(timeframe.startDate.split('T')[0]).toJSDate() : undefined}
                render={(props) => (
                  <div className="tw-w-36">
                    <DatePickerButton value={props.value} onChange={props.onChange} />
                  </div>
                )}
              />
            </FormGroup>
            <FormGroup
              label="End Date"
              intent={errors.endDate ? 'danger' : 'none'}
              helperText={errors.endDate ? 'End date must to be after start date' : ''}
            >
              <Controller
                name="endDate"
                control={control}
                defaultValue={timeframe?.endDate ? DateTime.fromISO(timeframe.endDate.split('T')[0]).toJSDate() : undefined}
                render={(props) => (
                  <div className="tw-w-36">
                    <DatePickerButton value={props.value} onChange={props.onChange} />
                  </div>
                )}
              />
            </FormGroup>
          </div> */}
          <div className="tw-grid tw-grid-cols-2">
            <FormGroup
              label="Start time"
              intent={errors.startTime ? 'danger' : 'none'}
              helperText={errors.startTime ? 'Start time is required' : ''}
            >
              <Controller
                name="startTime"
                control={control}
                defaultValue={defaultStartTime}
                render={(props) => <TimePicker precision="minute" useAmPm value={props.value} onChange={props.onChange} />}
              />
            </FormGroup>
            <FormGroup
              label="End time"
              intent={errors.endTime ? 'danger' : 'none'}
              helperText={errors.endTime ? 'End time must be later than' : ''}
            >
              <Controller
                name="endTime"
                control={control}
                defaultValue={defaultEndTime}
                render={(props) => <TimePicker precision="minute" useAmPm value={props.value} onChange={props.onChange} />}
              />
            </FormGroup>
          </div>
          <FormGroup
            label="Days"
            intent={errors.daysOfWeek ? 'danger' : 'none'}
            helperText={errors.daysOfWeek ? 'Days of week is required' : ''}
          >
            <Controller
              name="daysOfWeek"
              control={control}
              defaultValue={timeframe?.daysOfWeek ?? DAYS_OF_WEEK}
              render={(props) => <WeekListCheckbox values={props.value} onChange={(newValues) => props.onChange(newValues)} />}
            />
          </FormGroup>
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button text="Cancel" disabled={upsertTimeframeMutation.isLoading} onClick={(ev) => props.onClose?.(ev)} />
        <Button
          text={timeframe ? 'Save changes' : 'Create'}
          intent="primary"
          loading={upsertTimeframeMutation.isLoading}
          disabled={upsertTimeframeMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
}
