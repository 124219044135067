import React, {useEffect, useState} from 'react';

import {Button, Callout, Collapse, Icon, Menu, MenuItem, Switch} from '@blueprintjs/core';
import uniqBy from 'lodash/uniqBy';
import {useMutation, useQueryClient} from 'react-query';

import {LocationQrDialog} from '../../../components/locations/location-qr-dialog.component';
import {LocationRow} from '../../../components/locations/location-row.component';
import {LocationUpsertDialog} from '../../../components/locations/location-upsert-dialog.component';

import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {SectionDto} from '@kontactless/admin-api/section/section.dto';
import {LocationDto} from '@kontactless/admin-api/location/location.dto';
import classNames from 'classnames';
import {Popover2} from '@blueprintjs/popover2';
import {LocationGroupUpsertDialog} from '../../../components/locations/location-group-upsert-dialog.component';
import {LocationGroupUpdateRequestDto, LocationGroupsDto} from '@kontactless/admin-api/location-groups/location-groups.dto';
import {deleteLocationGroupRequest, updateLocationGroupRequest} from '../../../api/location-groups.api';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';
import {useAlerts} from '../../../contexts/alerts.context';
import {StoreSettingsDto} from '@kontactless/admin-api/store-settings/store-settings.dto';
import {LocationTypeDto} from '@kontactless/admin-api/location-type/location-type.dto';
import {OrderingFlowType} from '@kontactless/utils/types';
import {getSectionsQRCode} from '../../../api/section.api';

interface LocationsProps {
  store: StoreDto;
  section: SectionDto;
}

export function Locations({store, section}: LocationsProps) {
  const {user} = useAuth();
  const [isLocationGroupsDialogOpen, setIsLocationGroupsDialogOpen] = useState(false);
  const [locations, setLocations] = useState<LocationDto[]>([]);
  const [isDownloadQRLoading, setIsDownloadQRLoading] = useState(false);
  const {
    state: {toaster},
  } = useToasts();

  const onDownloadAllSectionsQRCode = async () => {
    setIsDownloadQRLoading(true);

    toaster.show({message: 'Downloading...', intent: 'primary'});
    try {
      await getSectionsQRCode(user.token, section.id.toString());
    } catch (error) {
      console.error(error);
      toaster.show({message: 'Something was wrong.', intent: 'danger'});
    } finally {
      setIsDownloadQRLoading(false);
    }
  };

  useEffect(() => {
    setLocations(
      section.locationGroups
        ?.map((locationGroups) => locationGroups.locations)
        .flat()
        .filter(Boolean) as LocationDto[]
    );
  }, [section.locationGroups]);

  const isAddLocationButtonDisabled =
    section.orderingFlowType === 'single-location' &&
    uniqBy(section.locations ?? [], 'locationTypeId').length === section.store?.locationTypes?.length;

  return (
    <div>
      <div className="">
        <div className="tw-flex tw-justify-between tw-items-center tw-gap-4 tw-mb-2">
          <p className="tw-font-bold">
            Total: <span className="tw-font-medium">{locations.length}</span>
          </p>

          <div className="tw-ml-auto tw-space-x-2">
            <Button
              disabled={isDownloadQRLoading}
              text="Download QR Codes"
              intent="primary"
              minimal
              onClick={onDownloadAllSectionsQRCode}
            />
            <Button
              disabled={isAddLocationButtonDisabled}
              text="+ Create section"
              intent="primary"
              minimal
              onClick={() => setIsLocationGroupsDialogOpen(true)}
            />
          </div>
        </div>
        <div className="tw-space-y-4">
          {section.locationGroups?.length ? (
            section.locationGroups.map((locationGroups) => {
              return (
                <LocationGroup
                  key={locationGroups.id}
                  locationGroup={locationGroups}
                  sectionId={section.id}
                  storeId={store.id}
                  orderingFlowType={section.orderingFlowType}
                  omnivoreSettings={{
                    isPosConnected: section.isPosConnected,
                    omnivoreRevenueCenterId: section.omnivoreRevenueCenterId,
                    posId: store.settings?.posId ?? null,
                    omnivoreDineInOrderTypeId: store.settings?.omnivoreDineInOrderTypeId ?? null,
                    omnivoreEmployeeId: store.settings?.omnivoreEmployeeId ?? null,
                    omnivoreTakeawayOrderTypeId: store.settings?.omnivoreTakeawayOrderTypeId ?? null,
                  }}
                  isAddLocationButtonDisabled={isAddLocationButtonDisabled}
                  locationTypes={section.store?.locationTypes ?? []}
                />
              );
            })
          ) : (
            <Callout icon="info-sign">There are no sections in this ordering method</Callout>
          )}
        </div>
      </div>

      <LocationGroupUpsertDialog
        sectionId={section.id}
        storeId={store.id}
        isOpen={isLocationGroupsDialogOpen}
        onClose={() => setIsLocationGroupsDialogOpen(false)}
      />

      {/* <LocationsExportDialog
        isOpen={isLocationsExportDialogOpen}
        sectionId={section.id}
        locations={locations}
        onClose={() => setIsLocationsExportDialogOpen(false)}
      /> */}
    </div>
  );
}

type OmnivoreSettings = Pick<
  StoreSettingsDto,
  'posId' | 'omnivoreDineInOrderTypeId' | 'omnivoreEmployeeId' | 'omnivoreTakeawayOrderTypeId'
> &
  Pick<SectionDto, 'omnivoreRevenueCenterId' | 'isPosConnected'>;

interface LocationGroupProps {
  locationGroup: LocationGroupsDto;
  locationTypes: LocationTypeDto[];
  sectionId: number;
  storeId: number;
  omnivoreSettings: OmnivoreSettings;
  isAddLocationButtonDisabled: boolean;
  orderingFlowType: OrderingFlowType;
}

function LocationGroup({
  locationGroup,
  omnivoreSettings,
  orderingFlowType,
  locationTypes,
  isAddLocationButtonDisabled,
  sectionId,
  storeId,
}: LocationGroupProps) {
  const {user} = useAuth();
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState<boolean>(false);
  const [isUpsertLocationGroupDialogOpen, setIsUpsertLocationGroupDialogOpen] = useState<boolean>(false);
  const [isLocationQrDialogOpen, setIsLocationQrDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const {alertsDispatch} = useAlerts();
  const {
    state: {toaster},
  } = useToasts();

  const updateLocationGroupMutation = useMutation<LocationGroupsDto, Error, LocationGroupUpdateRequestDto>({
    mutationKey: ['update-location-group', locationGroup.id],
    mutationFn: (update) => updateLocationGroupRequest(user.token, update, update.id),
    onSuccess: () => {
      queryClient.invalidateQueries(['sections', sectionId]);
      toaster.show({intent: 'success', message: 'Section updated successfully'});
    },
    onError: () => {
      queryClient.invalidateQueries(['sections', sectionId]);
      toaster.show({intent: 'danger', message: 'An error ocurred updating the section'});
    },
  });

  const deleteLocationGroupMutation = useMutation<void, Error, number>({
    mutationKey: ['delete-location-group', locationGroup.id],
    mutationFn: (locationGroupId) => deleteLocationGroupRequest(user.token, locationGroupId),
    onSuccess: () => {
      queryClient.invalidateQueries(['sections', sectionId]);
    },
    onError: () => {
      queryClient.invalidateQueries(['sections', sectionId]);
    },
  });

  const onDelete = async (locationGroup: LocationGroupsDto) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Delete section "${locationGroup.name}"?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteLocationGroupMutation.mutateAsync(locationGroup.id);
            toaster.show({intent: 'success', message: 'Section was deleted successfully'});
            queryClient.invalidateQueries(['section', locationGroup.sectionId]);
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred while deleting the section'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  return (
    <>
      <div key={locationGroup.id}>
        <div
          className={classNames(
            'tw-flex tw-items-center tw-gap-2 tw-justify-between tw-border tw-border-solid tw-border-gray-300 tw-rounded-t-md tw-py-1 tw-px-2 tw-w-full tw-cursor-pointer',
            {
              'tw-rounded-b-md': !isCollapseOpen,
            }
          )}
          onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        >
          <span className="tw-align-middle"> {locationGroup.name}</span>
          <div className="tw-flex tw-gap-2 tw-items-center">
            <Button
              icon="new-grid-item"
              minimal
              intent="primary"
              onClick={(e) => {
                e.stopPropagation();
                setIsLocationQrDialogOpen(true);
              }}
            >
              View QR
            </Button>
            <Switch
              defaultChecked={locationGroup.isEnabled}
              disabled={updateLocationGroupMutation.isLoading}
              onChange={({currentTarget: {checked}}) =>
                updateLocationGroupMutation.mutate({id: locationGroup.id, isEnabled: checked})
              }
            />

            <Popover2
              onInteraction={(nextOpenState, e) => {
                e?.stopPropagation();
                setIsPopoverOpen(nextOpenState);
              }}
              isOpen={isPopoverOpen}
              content={
                <Menu>
                  <MenuItem text="Edit" icon="edit" onClick={() => setIsUpsertLocationGroupDialogOpen(true)} />
                  <MenuItem text="Delete" icon="trash" intent="danger" onClick={() => onDelete(locationGroup)} />
                </Menu>
              }
              position="bottom"
            >
              <Button icon="more" minimal />
            </Popover2>
            <Icon icon={isCollapseOpen ? 'chevron-up' : 'chevron-down'} className="tw-text-gray-500" />
          </div>
        </div>
        <Collapse isOpen={isCollapseOpen}>
          <div className="tw-space-y-2 tw-border tw-border-t-0 tw-border-solid tw-border-gray-300 tw-rounded-b-md tw-p-2 tw-w-full tw-cursor-pointer">
            {locationGroup.locations?.length ? (
              locationGroup.locations?.map((location) => (
                <LocationRow
                  key={location.id}
                  orderingFlowType={orderingFlowType}
                  sectionId={sectionId}
                  storeId={storeId}
                  omnivoreSettings={omnivoreSettings}
                  location={location}
                  locationGroup={locationGroup}
                />
              ))
            ) : (
              <Callout icon="info-sign">There are no locations in this section</Callout>
            )}

            <Button
              className="tw-self-start"
              disabled={isAddLocationButtonDisabled}
              text="+ Add location"
              intent="primary"
              minimal
              onClick={() => {
                setIsLocationDialogOpen(true);
              }}
            />
          </div>
        </Collapse>
      </div>

      <LocationGroupUpsertDialog
        sectionId={locationGroup.sectionId}
        storeId={storeId}
        locationGroup={locationGroup}
        isOpen={isUpsertLocationGroupDialogOpen}
        onClose={() => setIsUpsertLocationGroupDialogOpen(false)}
      />

      <LocationQrDialog
        isOpen={isLocationQrDialogOpen}
        location={locationGroup}
        onClose={() => setIsLocationQrDialogOpen(false)}
      />

      <LocationUpsertDialog
        storeId={storeId}
        sectionId={locationGroup.sectionId}
        orderingFlowType={orderingFlowType}
        isPosConnected={omnivoreSettings.isPosConnected}
        omnivoreRevenueCenterId={omnivoreSettings.omnivoreRevenueCenterId}
        isOpen={isLocationDialogOpen}
        onClose={() => setIsLocationDialogOpen(false)}
        locationGroup={locationGroup}
      />
    </>
  );
}
