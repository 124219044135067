import React, {ReactElement} from 'react';

import {CircularProgress} from '@material-ui/core';
import classNames from 'classnames';
import {isNil, sortBy, uniqBy} from 'lodash';
import {DateTime} from 'luxon';
import {Line} from 'react-chartjs-2';

import {getColorForIndex} from '../../../utils/helpers.utils';
import {DashboardWidget, DashboardWidgetProps} from './dashboard-widget.component';

export interface DashboardWidgetSalesHistoryProps extends DashboardWidgetProps {
  title: string;
  labelType: 'days' | 'hours';
  datasets: Array<{label: string; data: Array<{datetime: string; total: number}>}>;
  noDataComponent?: ReactElement;
}

export const DashboardWidgetSalesHistory: React.FC<DashboardWidgetSalesHistoryProps> = ({
  title,
  labelType,
  datasets,
  noDataComponent,
  isLoading,
  errorMessage,
  ...itemProps
}) => {
  let component: ReactElement | null = null;

  if (isLoading) {
    component = <CircularProgress className="widget-spinner" />;
  } else if (!isNil(errorMessage)) {
    component = <p className="widget-error">{errorMessage}</p>;
  } else if (datasets.length === 0) {
    component = noDataComponent ?? null;
  } else {
    component = (
      <div className="chart-container">
        <Line
          data={{
            datasets: datasets.map((dataset, idx) => ({
              data: dataset.data.map(({total}) => Math.round(total)),
              borderColor: getColorForIndex(idx),
              borderWidth: 2,
              backgroundColor: getColorForIndex(idx, 10),
              label: dataset.label,
            })),
            labels: sortBy(
              uniqBy(
                datasets
                  .flatMap(({data}) => data)
                  .map(({datetime}) => {
                    const dateTimeObj = DateTime.fromISO(datetime);

                    return {
                      key: labelType === 'hours' ? dateTimeObj.toFormat('yyyy-MM-dd HH') : dateTimeObj.toISODate(),
                      label: labelType === 'hours' ? dateTimeObj.toFormat('h a') : dateTimeObj.toFormat('d. MMM'),
                    };
                  }),
                ({key}) => key
              ),
              ({key}) => key
            ).map(({label}) => label),
          }}
          options={{
            tooltips: {mode: 'nearest'},
            hover: {intersect: false},
            legend: {display: false},
            responsive: true,
            elements: {
              point: {
                radius: 1,
              },
            },
            scales: {
              xAxes: [{gridLines: {display: false}}],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: (value) => '$' + value,
                  },
                },
              ],
            },
          }}
        />
      </div>
    );
  }

  return (
    <DashboardWidget {...itemProps} className={classNames('widget-sales-history', itemProps.className)}>
      <h4 className="widget-title">{title}</h4>
      {component}
    </DashboardWidget>
  );
};
