import {Spinner} from '@blueprintjs/core';
import {PercentageChip} from './PercentageChip';

interface BestPerformingStoreCardProps {
  stores: {
    amount: string;
    salesDiffPercentage: string;
    storeName: string;
  }[];
  isLoading: boolean;
  isError: boolean;
  showPercentage: boolean;
}

export default ({stores, isLoading, isError, showPercentage}: BestPerformingStoreCardProps) => {
  const topFour = stores.slice(0, 4);
  if (isError) {
    return (
      <div className="tw-flex tw-flex-col tw-rounded tw-bg-white tw-p-3 tw-shadow">
        <p className="tw-mt-3 tw-text-red-700">Error</p>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-rounded tw-bg-white tw-p-3 tw-shadow">
      <p className="tw-mb-3 tw-text-xs tw-text-gray-500">Best Performing Stores</p>
      {isLoading ? (
        <Spinner />
      ) : (
        <ol className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          <>
            {!topFour.length ? (
              <p className="tw-mt-3 tw-text-gray-500">There are no stores in the selected range</p>
            ) : (
              <>
                {topFour.map((store, i) => (
                  <li className="tw-flex tw-justify-between tw-items-center tw-gap-3" key={store.storeName}>
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <span className="tw-text-gray-500">{i + 1}</span>
                      <p className="tw-text-xs tw-text-gray-700">{store.storeName}</p>
                    </div>
                    <div className="tw-flex tw-gap-2">
                      <p className="tw-text-sm tw-font-bold">{store.amount}</p>
                      {showPercentage && <PercentageChip diffPercentage={store.salesDiffPercentage} />}
                    </div>
                  </li>
                ))}
              </>
            )}
          </>
        </ol>
      )}
    </div>
  );
};
