import {TimeframeCreateRequestDto, TimeframeDto, TimeframeUpdateRequestDto} from '@kontactless/admin-api/timeframe/timeframe.dto';
import {callApi} from './api';

export const createTimeframeRequest = async (token: string, timeframe: TimeframeCreateRequestDto): Promise<TimeframeDto> => {
  return callApi<TimeframeDto>(`/timeframes`, {token, method: 'POST', body: JSON.stringify(timeframe)}).then(({data}) => data);
};

export const updateTimeframeRequest = async (
  token: string,
  timeframe: TimeframeUpdateRequestDto,
  timeframeId: number
): Promise<TimeframeDto> => {
  return callApi<TimeframeDto>(`/timeframes/${timeframeId}`, {token, method: 'PATCH', body: JSON.stringify(timeframe)}).then(
    ({data}) => data
  );
};

export const deleteTimeframeRequest = async (token: string, timeframeId: number): Promise<void> => {
  return callApi<void>(`/timeframes/${timeframeId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
