import {Button, Classes, Dialog, Switch} from '@blueprintjs/core';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';

import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';

import {updateCurrentUserRequest} from '../../api/user.api';
import {UserDto, UserUpdateRequestDto} from '@kontactless/admin-api/user/user.dto';

interface EmailNotificationsForm {
  receiveOrderCreatedEmail: boolean;
  receiveOrderCancelledEmail: boolean;
  receiveFeedbackEmails: boolean;
}

export const EmailNotificationsDialog = ({...props}) => {
  const {user} = useAuth();
  const queryClient = useQueryClient();
  const {
    state: {toaster},
  } = useToasts();
  const {handleSubmit, control} = useForm<EmailNotificationsForm>();

  const updateCurrentUserMutation = useMutation<UserDto, Error, UserUpdateRequestDto>((body: UserUpdateRequestDto) => {
    return updateCurrentUserRequest(user.token, body);
  });

  const onSubmit = async (form: EmailNotificationsForm) => {
    updateCurrentUserMutation.mutate(
      {...form},
      {
        onSuccess: () => {
          toaster.show({intent: 'success', message: 'Email notifications changed successfully'});
          props.onClose?.({} as any);
          queryClient.invalidateQueries(['current-user', user.token]);
        },
        onError: (error) => {
          console.log(error);
          toaster.show({intent: 'danger', message: 'Email notifications could not be changed'});
        },
      }
    );
  };

  return (
    <Dialog title="Email Notifications" className="kl-dialog" {...props}>
      <div className={Classes.DIALOG_BODY}>
        <div className="tw-overflow-hidden tw-min-h-content tw-w-full">
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-flex tw-justify-between">
              <p>Receive an email when a new order is created</p>
              <Controller
                name="receiveOrderCreatedEmail"
                control={control}
                defaultValue={user.receiveOrderCreatedEmail}
                render={(props) => (
                  <Switch
                    name="receiveOrderCreatedEmail"
                    checked={props.value}
                    disabled={updateCurrentUserMutation.isLoading}
                    onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                  />
                )}
              />
            </div>
            <div className="tw-flex tw-justify-between">
              <p>Receive an email when a new order is cancelled</p>
              <Controller
                name="receiveOrderCancelledEmail"
                control={control}
                defaultValue={user.receiveOrderCancelledEmail}
                render={(props) => (
                  <Switch
                    name="receiveOrderCancelledEmail"
                    checked={props.value}
                    disabled={updateCurrentUserMutation.isLoading}
                    onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                  />
                )}
              />
            </div>
            <div className="tw-flex tw-justify-between">
              <p>Receive feedback emails</p>
              <Controller
                name="receiveFeedbackEmails"
                control={control}
                defaultValue={user.receiveFeedbackEmails}
                render={(props) => (
                  <Switch
                    name="receiveFeedbackEmails"
                    checked={props.value}
                    disabled={updateCurrentUserMutation.isLoading}
                    onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={updateCurrentUserMutation.isLoading}
          onClick={(e) => props.onClose?.(e)}
        />
        <Button
          text="Confirm"
          intent="primary"
          loading={updateCurrentUserMutation.isLoading}
          disabled={updateCurrentUserMutation.isLoading}
          onClick={handleSubmit(onSubmit)}
        />
      </footer>
    </Dialog>
  );
};
