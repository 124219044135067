import React, {createRef, useMemo} from 'react';

import {Button, ButtonProps, InputGroup, MenuItem} from '@blueprintjs/core';
import {Suggest, SuggestProps} from '@blueprintjs/select';
import classNames from 'classnames';
import {OmnivoreCategoryDto} from '@kontactless/admin-api/omnivore-category/omnivore-category.dto';

import './selectors.styles.scss';

import {sortBy} from 'lodash';
import {getScreenNumberFromOmnivoreId} from '../../utils/helpers.utils';

interface OmnivoreCategorySelectorProps
  extends Omit<SuggestProps<OmnivoreCategoryDto>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  storeId: number;
  fill?: boolean;
  selectedItemId?: string;
  buttonProps?: ButtonProps;
  autoSelect?: boolean;
  screenNumber?: number;
  onItemSelected: (item: OmnivoreCategoryDto | undefined) => void;
  storeOmnivoreCategories?: OmnivoreCategoryDto[];
  isLoading: boolean;
  isError: boolean;
}

const OmnivoreCategorySelectorComponent = Suggest.ofType<OmnivoreCategoryDto>();

export const OmnivoreCategorySelector: React.FC<OmnivoreCategorySelectorProps> = ({
  storeId,
  selectedItemId,
  onItemSelected,
  buttonProps,
  autoSelect,
  screenNumber,
  storeOmnivoreCategories,
  isLoading,
  isError,
  ...props
}) => {
  const inputRef = createRef<any>();

  const sortedCategories = useMemo(
    () =>
      sortBy(
        storeOmnivoreCategories?.filter(
          ({menuItems}) =>
            !!menuItems?.filter(({menuItemId}) => !screenNumber || +getScreenNumberFromOmnivoreId(menuItemId)! === screenNumber)
              .length
        ) ?? [],
        ({name}) => name
      ),
    [storeOmnivoreCategories, screenNumber]
  );

  const selectedItem = useMemo(() => sortedCategories.find(({categoryId}) => categoryId === selectedItemId), [
    sortedCategories,
    selectedItemId,
  ]);

  const getCategoryLabel = (category: OmnivoreCategoryDto): string => {
    return category.name;
  };

  if (isError) {
    return <InputGroup {...(props.inputProps ?? {})} intent="danger" value="Error fetching omnivore categories" readOnly />;
  }

  return (
    <OmnivoreCategorySelectorComponent
      {...props}
      inputValueRenderer={(employee) => (isLoading ? '' : getCategoryLabel(employee))}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      items={sortedCategories}
      itemRenderer={(employee, {modifiers, handleClick}) => (
        <MenuItem
          key={employee.categoryId}
          active={modifiers.active}
          disabled={modifiers.disabled}
          onClick={handleClick}
          text={getCategoryLabel(employee)}
        />
      )}
      onItemSelect={(value) => onItemSelected(value)}
      itemsEqual={(e1, e2) => e1.categoryId === e2.categoryId}
      selectedItem={selectedItem ?? null}
      itemPredicate={(query, employee) =>
        !!employee.categoryId.toLocaleLowerCase().includes(query.toLowerCase()) ||
        !!employee.name?.toLocaleLowerCase().includes(query.toLowerCase())
      }
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      inputProps={{
        ...(props.inputProps ?? {}),
        leftElement: isLoading ? <Button loading minimal /> : undefined,
        placeholder: isLoading ? 'Loading categories...' : 'Choose one...',
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
      }}
    />
  );
};
