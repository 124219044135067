import React, {useEffect} from 'react';

import {Button, Callout, Checkbox, Classes, Dialog, DialogProps, FormGroup, InputGroup} from '@blueprintjs/core';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import {useMutation} from 'react-query';

import {duplicateMenuRequest} from '../../../api/menu.api';
import {StoreSelector} from '../../../components/selectors/store-selector.component';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';

import {StoreDto} from '@kontactless/admin-api/store/store.dto';

import '../../styles/menus-page.styles.scss';
import {MenuDto, MenuDuplicateRequestDto} from '@kontactless/admin-api/menu/menu.dto';

export interface DuplicateDialogProps extends DialogProps {
  store: StoreDto;
  menu?: MenuDto;
  onResolve?: (menu: MenuDto) => void;
}

interface MenuForm {
  name: string;
  storeId: number;
  duplicateProducts: boolean;
}

export function DuplicateDialog({store, menu, onResolve, ...props}: DuplicateDialogProps) {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {register, handleSubmit, control, errors, reset, watch} = useForm<MenuForm>({
    defaultValues: {
      duplicateProducts: false,
    },
  });

  const duplicateMenuMutation = useMutation<MenuDto, Error, {id: number} & MenuDuplicateRequestDto>((body) =>
    duplicateMenuRequest(user.token, body.id, body)
  );

  const submitForm = async (form: MenuForm) => {
    try {
      if (menu?.id) {
        const updatedMenu = await duplicateMenuMutation.mutateAsync({
          ...form,
          id: menu.id,
        });

        props.onClose?.(undefined as any);
        onResolve?.(updatedMenu);

        toaster.show({
          intent: 'success',
          message: 'Menu duplicated successfully',
        });
      }
    } catch (error) {
      toaster.show({
        intent: 'danger',
        message: 'An error ocurred duplicating the menu',
      });
    }
  };

  useEffect(() => {
    reset({name: getDuplicateMenuName()}, {dirtyFields: false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  const getDuplicateMenuName = () => (menu?.name ? `Copy of ${menu.name}` : '');

  return (
    <Dialog title="Duplicate menu" className="kl-dialog menu-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          <FormGroup
            label="Destination store"
            intent={errors.storeId ? 'danger' : 'none'}
            helperText={errors.storeId ? 'Store is required' : ''}
          >
            <Controller
              name="storeId"
              control={control}
              rules={{required: true}}
              defaultValue={store.id}
              render={(props) => (
                <StoreSelector
                  selectedItemId={props.value}
                  companyId={store.companyId}
                  onItemSelected={(store) => {
                    props.onChange(store?.id);
                  }}
                  inputProps={{intent: errors.storeId ? 'danger' : 'none'}}
                />
              )}
            />
          </FormGroup>

          <FormGroup
            label="New menu name"
            intent={errors.name ? 'danger' : 'none'}
            helperText={errors.name ? 'Name is required' : ''}
          >
            <InputGroup
              name="name"
              defaultValue={getDuplicateMenuName()}
              intent={errors.name ? 'danger' : 'none'}
              inputRef={register({required: true})}
            />
          </FormGroup>

          {store.id === watch('storeId', store.id) && (
            <FormGroup intent={errors.duplicateProducts ? 'danger' : 'none'}>
              <Checkbox name="duplicateProducts" label="Unlink products" inputRef={register} />
              {watch('duplicateProducts') && (
                <Callout intent="warning" icon="warning-sign">
                  By unlinking the products, you are able to make changes to the duplicated menu without affecting the original
                  menu items.
                </Callout>
              )}
            </FormGroup>
          )}
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={duplicateMenuMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text="Duplicate menu"
          intent="primary"
          loading={duplicateMenuMutation.isLoading}
          disabled={duplicateMenuMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
}
