import React, {createRef} from 'react';

import {Button, MenuItem} from '@blueprintjs/core';
import {MultiSelect, MultiSelectProps} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';
import {useAuth} from '../../contexts/auth.context';
import {fetchAllEventsRequest} from '../../api/event.api';
import {EventDto} from '@kontactless/admin-api/event/event.dto';
import {DateTime} from 'luxon';
import {capitalize} from 'lodash';

interface MultiSelectorProps extends Omit<MultiSelectProps<EventDto>, 'itemRenderer' | 'tagRenderer' | 'items'> {
  selectedValues: EventDto[];
  onItemSelect: (value: EventDto) => void;
  onRemove: (value: EventDto) => void;
  storeId?: number;
  companyId?: number;
  section?: number;
}

const MultiSelectorComponent = MultiSelect.ofType<EventDto>();

export const EventsMultiSelector: React.FC<MultiSelectorProps> = ({
  selectedValues,
  onItemSelect,
  onRemove,
  storeId,
  ...props
}) => {
  const {user} = useAuth();

  const eventsQuery = useQuery({
    queryKey: ['stores', storeId, 'events'],
    queryFn: () => fetchAllEventsRequest(user.token, {storeId}),
    enabled: !!storeId,
  });

  let inputRef: any = createRef<any>();
  const items = eventsQuery.data?.filter((event) => event.ordersCount > 0) ?? [];
  const selectedItems = items.filter((event) => selectedValues?.includes(event)) ?? undefined;

  return (
    <MultiSelectorComponent
      {...props}
      resetOnSelect
      items={items}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      itemRenderer={(event, {modifiers, handleClick}) => (
        <MenuItem
          disabled={modifiers.disabled}
          key={event.id}
          onClick={handleClick}
          icon={selectedValues.includes(event) ? 'tick' : 'blank'}
          text={
            <div className="tw-flex tw-items-center tw-gap-2">
              {DateTime.fromISO(event.startDatetime).toFormat('LL/dd t')}
              <p className="tw-text-right tw-font-bold">{capitalize(event.name)}</p>
            </div>
          }
          labelElement={`${event.ordersCount} orders`}
        />
      )}
      selectedItems={selectedItems}
      onItemSelect={onItemSelect}
      onRemove={onRemove}
      tagRenderer={(value) => (
        <div className="tw-flex tw-gap-2">
          <p>{DateTime.fromISO(value.startDatetime).toFormat('LL/dd t')}</p>
          <p className="tw-text-right tw-font-bold">{capitalize(value.name)}</p>
        </div>
      )}
      itemsEqual={(e1, e2) => e1 === e2}
      itemPredicate={(query, value) => value.name.toLocaleLowerCase().includes(query.toLowerCase())}
      ref={inputRef}
      tagInputProps={{
        placeholder: 'Select events',
        ...(props.tagInputProps ?? null),
        inputRef: (input) => (inputRef = input),
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
        tagProps: {minimal: true},
      }}
    />
  );
};
