import React, {useEffect, useState} from 'react';

import {capitalize} from 'lodash';
import {DateTime} from 'luxon';

import {isTimeOverdue} from '../../utils/helpers.utils';

interface CountdownProps {
  initialDateTime: DateTime;
  overdueTimeMinutes?: number;
  onOverdue?: () => void;
}

export const Countdown: React.FC<CountdownProps> = ({initialDateTime, overdueTimeMinutes, onOverdue}) => {
  const [dateTime, setDateTime] = useState(initialDateTime);
  const [countdownInterval, setCountdownInterval] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    setDateTime(initialDateTime);
    setCountdownInterval(
      setInterval(() => {
        setDateTime(DateTime.fromJSDate(dateTime.toJSDate()));
        if (overdueTimeMinutes && onOverdue && isTimeOverdue(dateTime, overdueTimeMinutes)) {
          onOverdue();
        }
      }, 1000)
    );

    return () => clearInterval(countdownInterval as any);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDateTime]);

  return <>{capitalize(dateTime.toRelative() ?? '')}</>;
};
