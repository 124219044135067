import {capitalize} from 'lodash';
import {AsteriskIcon} from 'lucide-react';
import {CardBrandIcon} from './card-brand-icon.component';
import {cn} from '../../common/utils';

interface PaymentMethodItemProps {
  cardBrand: string;
  lastFourDigits?: string;
  cardHolderName: string;
  className?: string;
  onClick?: () => void;
}

export function PaymentMethodItem({cardBrand, lastFourDigits, cardHolderName, onClick, className}: PaymentMethodItemProps) {
  return (
    <div onClick={onClick} className={cn('tw-flex tw-items-center tw-gap-3 tw-text-gray-900', className)}>
      <CardBrandIcon className="tw-w-14" cardBrand={cardBrand} />
      <div className="tw-text-left">
        {['apple-pay', 'google-pay'].includes(cardBrand) ? (
          <>
            <p className="tw-flex tw-items-center tw-gap-1 tw-text-sm">{cardBrand.split('-').map(capitalize).join(' ')}</p>
            <p className="tw-text-xs tw-text-gray-500">{cardHolderName}</p>
          </>
        ) : (
          <>
            <div className="tw-flex tw-items-center tw-gap-1 tw-text-sm">
              <span className="tw-flex">
                {[0, 1, 2, 3].map(() => (
                  <AsteriskIcon className="tw-w-3" />
                ))}
              </span>
              <span className="tw-text-sm tw-tracking-widest">{lastFourDigits}</span>
            </div>
            <p className="tw-text-xs tw-text-gray-500">{cardHolderName}</p>
          </>
        )}
      </div>
    </div>
  );
}
