import {callApi} from './api';
import {RevenueGroupDto, RevenueGroupUpsertRequestDto} from '@kontactless/admin-api/revenue-group/revenue-group.dto';

export const fetchAllRevenueGroupsRequest = async (token: string, storeId?: number): Promise<RevenueGroupDto[]> => {
  return callApi<RevenueGroupDto[]>('/revenue-groups', {token, params: {storeId}}).then(({data}) => data);
};

export const createRevenueGroupRequest = async (token: string, body: RevenueGroupUpsertRequestDto): Promise<RevenueGroupDto> => {
  return callApi<RevenueGroupDto>('/revenue-groups', {token, method: 'POST', body: JSON.stringify(body)}).then(({data}) => data);
};

export const updateRevenueGroupRequest = async (
  token: string,
  revenueGroup: RevenueGroupUpsertRequestDto,
  revenueGroupId: number
): Promise<RevenueGroupDto> => {
  return callApi<RevenueGroupDto>(`/revenue-groups/${revenueGroupId}`, {
    token,
    method: 'PATCH',
    body: JSON.stringify(revenueGroup),
  }).then(({data}) => data);
};

export const deleteRevenueGroupRequest = async (token: string, revenueGroupId: number): Promise<{}> => {
  return callApi<{}>(`/revenue-groups/${revenueGroupId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
