import {callApi} from './api';
import {
  LocationGroupCreateRequestDto,
  LocationGroupUpdateRequestDto,
  LocationGroupsDto,
} from '@kontactless/admin-api/location-groups/location-groups.dto';

export const createLocationGroupsRequest = async (
  token: string,
  locationGroups: LocationGroupCreateRequestDto
): Promise<LocationGroupsDto> => {
  return callApi<LocationGroupsDto>(`/location-groups`, {token, method: 'POST', body: JSON.stringify(locationGroups)}).then(
    ({data}) => data
  );
};

export const updateLocationGroupRequest = async (
  token: string,
  locationGroups: LocationGroupUpdateRequestDto,
  locationGroupId: number
): Promise<LocationGroupsDto> => {
  return callApi<LocationGroupsDto>(`/location-groups/${locationGroupId}`, {
    token,
    method: 'PATCH',
    body: JSON.stringify(locationGroups),
  }).then(({data}) => data);
};

export const deleteLocationGroupRequest = async (token: string, locationGroupId: number): Promise<void> => {
  return callApi<void>(`/location-groups/${locationGroupId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
