import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {Button, Classes, Dialog, DialogProps, FormGroup, InputGroup} from '@blueprintjs/core';
import classNames from 'classnames';
import {DateTime} from 'luxon';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';

import {createStoreRequest} from '../../api/store.api';
import {ImageUploader} from '../../components/images/image-uploader.component';

import {useAuth} from '../../contexts/auth.context';
import {StoreCreateRequestDto, StoreDto} from '@kontactless/admin-api/store/store.dto';
import {Timezone} from '../../utils/types';

export interface OperationCreateDialogProps extends DialogProps {
  companyId: number;
}

interface StoreForm {
  name: string;
  description: string;
  timezone: Timezone;
  imageUrl: string[];
}

export const OperationCreateDialog: React.FC<OperationCreateDialogProps> = ({companyId, ...props}) => {
  const {user} = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const {register, handleSubmit, control, errors} = useForm();
  const createStoreMutation = useMutation<StoreDto, Error, StoreCreateRequestDto>((store) =>
    createStoreRequest(user.token, store)
  );

  const submitForm = async (form: StoreForm) => {
    try {
      const store = await createStoreMutation.mutateAsync({
        companyId,
        name: form.name.trim(),
        description: form.description.trim(),
        timezone: DateTime.local().toFormat('z') as Timezone,
        imageUrl: form.imageUrl?.length ? form.imageUrl[0] : null,
      });

      queryClient.invalidateQueries(['current-user', user.token]);

      props.onClose?.(undefined as any);

      navigate(`${location.pathname}/${store.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog title="New Store" className="kl-dialog store-create-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          <FormGroup
            label="Store name"
            intent={errors.name ? 'danger' : 'none'}
            helperText={errors.name ? 'Name is required' : ''}
          >
            <InputGroup
              className="control"
              name="name"
              intent={errors.name ? 'danger' : 'none'}
              defaultValue=""
              inputRef={register({
                validate: {
                  required: (value: string) => value.trim() !== '',
                },
              })}
            />
          </FormGroup>
          <FormGroup
            label="Description"
            intent={errors.description ? 'danger' : 'none'}
            helperText={errors.description ? 'Description is required' : ''}
          >
            <InputGroup
              className="control"
              name="description"
              intent={errors.description ? 'danger' : 'none'}
              defaultValue=""
              inputRef={register({
                validate: {
                  required: (value: string) => value.trim() !== '',
                },
              })}
            />
          </FormGroup>
          <Controller
            name="imageUrl"
            control={control}
            defaultValue={[]}
            render={(props) => <ImageUploader value={props.value} onChange={props.onChange} maxNumber={1} />}
          />
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={createStoreMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text="Create"
          intent="primary"
          loading={createStoreMutation.isLoading}
          disabled={createStoreMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
};
