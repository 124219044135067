import {callApi} from './api';
import {PosStatusDto} from '@kontactless/admin-api/pos-status/pos-status.dto';

export const fetchCurrentPosStatusRequest = async (
  token: string,
  posId: string | null | undefined,
  storeId: number
): Promise<PosStatusDto> => {
  return callApi<PosStatusDto>(`/pos-statuses/${posId}`, {token, method: 'GET', params: {storeId}}).then(({data}) => data);
};
