import {QueryClient} from 'react-query';

import {UserDto} from '@kontactless/admin-api/user/user.dto';

export type CacheUserAction =
  | {type: 'add-user'; user: UserDto}
  | {type: 'remove-user'; userId: number}
  | {type: 'update-user'; user: UserDto};

export const dispatchUserAction = (queryClient: QueryClient, action: CacheUserAction) => {
  switch (action.type) {
    case 'add-user':
      addUser(queryClient, action.user);
      break;
    case 'remove-user':
      removeUser(queryClient, action.userId);
      break;
    case 'update-user':
      updateUser(queryClient, action.user);
      break;
  }
};

const addUser = (queryClient: QueryClient, user: UserDto) => {
  queryClient.invalidateQueries('users');
};

const removeUser = (queryClient: QueryClient, userId: number) => {
  queryClient.invalidateQueries('users');
};

const updateUser = (queryClient: QueryClient, user: UserDto) => {
  queryClient.invalidateQueries('users');
};
