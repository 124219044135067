import {callApi} from './api';
import {ProductDto, ProductStateUpdateRequestDto, ProductUpsertRequestDto} from '@kontactless/admin-api/product/product.dto';

export const upsertProductRequest = async (token: string, product: ProductUpsertRequestDto): Promise<ProductDto> => {
  let url = '/products',
    method = 'POST';
  if (product.id) {
    url = `${url}/${product.id}`;
    method = 'PATCH';
  }
  return callApi<ProductDto>(url, {token, method, body: JSON.stringify(product)}).then(({data}) => data);
};

export const updateProductStateRequest = async (
  token: string,
  productId: number,
  product: ProductStateUpdateRequestDto
): Promise<ProductDto> => {
  return callApi<ProductDto>(`/products/${productId}/state`, {token, method: 'PATCH', body: JSON.stringify(product)}).then(
    ({data}) => data
  );
};
