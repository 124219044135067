import {sortBy} from 'lodash';
import {QueryClient} from 'react-query';

import {StoreDto} from '@kontactless/admin-api/store/store.dto';

import {RevenueGroupDto} from '@kontactless/admin-api/revenue-group/revenue-group.dto';

export type CacheRevenueGroupAction =
  | {type: 'add-revenue-group'; revenueGroup: RevenueGroupDto}
  | {type: 'remove-revenue-group'; revenueGroup: {id: number; storeId: number}}
  | {type: 'update-revenue-group'; revenueGroup: RevenueGroupDto};

export const dispatchRevenueGroupAction = (queryClient: QueryClient, action: CacheRevenueGroupAction) => {
  switch (action.type) {
    case 'add-revenue-group':
      addRevenueGroup(queryClient, action.revenueGroup);
      break;
    case 'remove-revenue-group':
      removeRevenueGroup(queryClient, action.revenueGroup.id, action.revenueGroup.storeId);
      break;
    case 'update-revenue-group':
      updateRevenueGroup(queryClient, action.revenueGroup);
      break;
  }
};

const addRevenueGroup = (queryClient: QueryClient, revenueGroup: RevenueGroupDto) => {
  queryClient.refetchQueries(['revenue-groups', revenueGroup.storeId]);

  queryClient.setQueryData<StoreDto | undefined>(['stores', revenueGroup.storeId], () => {
    const cachedStore = queryClient.getQueryData<StoreDto>(['stores', revenueGroup.storeId]);

    if (cachedStore) {
      const revenueGroups = sortBy(
        (cachedStore.revenueGroups ?? []).filter(({id}) => id !== revenueGroup.id).concat([revenueGroup]),
        ({id}) => id
      );

      return {...cachedStore, revenueGroups};
    }
  });
};

const removeRevenueGroup = (queryClient: QueryClient, revenueGroupId: number, storeId: number) => {
  queryClient.refetchQueries(['revenue-groups', storeId]);

  queryClient.setQueryData<StoreDto | undefined>(['stores', storeId], () => {
    const cachedStore = queryClient.getQueryData<StoreDto>(['stores', storeId]);

    if (cachedStore) {
      const revenueGroups = (cachedStore.revenueGroups ?? []).filter(({id}) => id !== revenueGroupId);
      return {...cachedStore, revenueGroups};
    }
  });
};

const updateRevenueGroup = (queryClient: QueryClient, revenueGroup: RevenueGroupDto) => {
  queryClient.refetchQueries(['revenue-groups', revenueGroup.storeId]);
  queryClient.refetchQueries('menus');

  queryClient.setQueryData<StoreDto | undefined>(['stores', revenueGroup.storeId], () => {
    const cachedStore = queryClient.getQueryData<StoreDto>(['stores', revenueGroup.storeId]);

    if (cachedStore) {
      const revenueGroups = sortBy(
        (cachedStore.revenueGroups ?? []).filter(({id}) => id !== revenueGroup.id).concat([revenueGroup]),
        ({id}) => id
      );

      return {...cachedStore, revenueGroups};
    }
  });
};
