import React, {useState} from 'react';

import {Button, Icon, Menu, MenuItem, Spinner, Tag} from '@blueprintjs/core';
import {Popover2} from '@blueprintjs/popover2';
import classNames from 'classnames';
import {DateTime} from 'luxon';

import {Countdown} from '../../components/ui/countdown.component';
import {useAlerts} from '../../contexts/alerts.context';
import {getOrderStatusIcon, getOrderStatusLabel, isTimeOverdue} from '../../utils/helpers.utils';
import {OrderStatus} from '../../utils/types';
import {AgeVerificationComponent} from '../../components/age-verification/age-verification.component';
import {OrderDto} from '@kontactless/admin-api/order/order.dto';

export interface OrderCardProps {
  order: OrderDto;
  sendTo?: OrderStatus;
  enabledNextStatuses?: OrderStatus[];
  disableOverdue?: boolean;
  confirmOrderStatusUpdate?: boolean;
  onSeeDetailsClick?: (order: OrderDto) => void;
  updateOrderStatus: ({id, status}: {id: number; status: string}) => void;
  isLoading: boolean;
}

export const OrderCard: React.FC<OrderCardProps> = ({
  order,
  sendTo,
  enabledNextStatuses,
  disableOverdue,
  confirmOrderStatusUpdate,
  onSeeDetailsClick,
  updateOrderStatus,
  isLoading,
}) => {
  const {alertsDispatch} = useAlerts();

  const [isOverdue, setIsOverdue] = useState(
    order.store?.settings?.overdueTimeMinutes &&
      isTimeOverdue(DateTime.fromISO(order.scheduleDate ?? order.createdAt), order.store?.settings?.overdueTimeMinutes)
  );

  const onSendToStatusClick = (status: OrderStatus) => {
    if (status === 'preparing' && (confirmOrderStatusUpdate || order.roomNumber)) {
      alertsDispatch({
        type: 'set-alert',
        alert: {
          intent: 'warning',
          children: order.roomNumber ? (
            <>
              <p>
                Change order #{order.number} to {getOrderStatusLabel(status)}?
              </p>
              <p>Room number is {order.roomNumber}.</p>
              <p>Room guest last name is {order.roomGuestLastName}.</p>
            </>
          ) : (
            `Change order #${order.number} to ${getOrderStatusLabel(status)}?`
          ),
          onConfirm: async (setAlert, removeAlert) => {
            updateOrderStatus({id: order.id, status});
            removeAlert();
          },
        },
      });
    } else {
      updateOrderStatus({id: order.id, status});
    }
  };

  const onCancelClick = async () => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        intent: 'danger',
        children: `Cancel order #${order.number}?`,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Close',
        onConfirm: async (setAlert, removeAlert) => {
          updateOrderStatus({id: order.id, status: 'cancelled-by-store'});
          removeAlert();
        },
      },
    });
  };

  const getCustomerInfo = (order: OrderDto) => {
    let subtitle = '';
    if (order.pickupName) {
      subtitle = order.pickupName;
    } else if (order.customer?.fullName) {
      subtitle = order.customer?.fullName;
    }

    return `${subtitle} ${order.roomNumber && `- Room ${order.roomNumber}, ${order.roomGuestLastName}`}`;
  };

  return (
    <div className="order-card">
      <header className="order-header">
        <h1 className="order-title">
          #{order.number} – {order.location?.name || order.locationName || order.event?.name}
        </h1>
        {isOverdue && (
          <Tag intent="danger" className="order-overdue-tag">
            <Countdown
              initialDateTime={DateTime.fromISO(order.scheduleDate || order.createdAt)}
              overdueTimeMinutes={disableOverdue || isOverdue ? undefined : order.store?.settings?.overdueTimeMinutes}
              onOverdue={() => {
                if (order.store?.settings?.overdueTimeMinutes ?? 0 > 0) {
                  setIsOverdue(true);
                }
              }}
            />
          </Tag>
        )}
        <Popover2
          className="more-actions"
          children={<Button icon="more" minimal />}
          content={
            <Menu>
              {enabledNextStatuses?.length && (
                <MenuItem text="Move to" icon="flow-end">
                  {enabledNextStatuses.map((status) => (
                    <MenuItem
                      key={status}
                      className={classNames('order-status', `is-${status}`)}
                      text={getOrderStatusLabel(status)}
                      icon={getOrderStatusIcon(status)}
                      onClick={() => onSendToStatusClick(status)}
                    />
                  ))}
                </MenuItem>
              )}
              <MenuItem text="View Details" icon="properties" onClick={() => onSeeDetailsClick?.(order)} />
              {!['cancelled-by-store', 'cancelled-by-customer'].includes(order.status) && (
                <MenuItem text="Cancel" icon="trash" intent="danger" onClick={() => onCancelClick()} />
              )}
            </Menu>
          }
        />
      </header>
      {(getCustomerInfo(order) || order.pickupInfo) && (
        <div className="order-additional-info">
          <h2 className="customer-info">{getCustomerInfo(order)}</h2>
          {order.pickupInfo && <p className="pickup-info">{order.pickupInfo}</p>}
        </div>
      )}
      {order.items
        ?.filter(({type}) => type === 'product')
        .map(({id, quantity, title, productVersion, customerNotes}) => (
          <div key={id} className="order-product-line">
            <div className="item-quantity">{quantity}x</div>
            <div className="item-description">
              <h1>{title}</h1>
              {productVersion?.modifierGroups?.map((modifierGroup) => (
                <div key={modifierGroup.id} className={classNames('modifier-group-section', `is-${order.status}`)}>
                  <h4>{modifierGroup.name}:</h4>
                  {modifierGroup.modifiers.map((modifier) => (
                    <h4 key={modifier.id + modifier.name}>{modifier.name}</h4>
                  ))}
                </div>
              )) ?? []}
              {customerNotes ? <p>{customerNotes}</p> : null}
            </div>
          </div>
        )) ?? null}
      {sendTo ? (
        <div className="order-cta">
          {order.items?.some(({productVersion}) => productVersion?.needsAgeVerification) && <AgeVerificationComponent />}

          <Button
            className={`order-status is-${order.status}`}
            text={`Send to ${getSendToLabel(order, sendTo)}`}
            outlined
            rightIcon={isLoading ? <Spinner size={15} /> : <Icon className="rotate-90" icon="upload" />}
            disabled={isLoading}
            onClick={() => onSendToStatusClick(sendTo)}
          />
        </div>
      ) : null}
    </div>
  );
};

const getSendToLabel = (order: OrderDto, sendTo: OrderStatus) => {
  switch (sendTo) {
    case 'ready':
      return order.type === 'takeaway' ? 'Pick Up' : 'Ready';
    case 'delivered':
      return order.type === 'takeaway' ? 'Picked Up' : 'Delivered';
    default:
      return getOrderStatusLabel(sendTo);
  }
};
