import React, {useState} from 'react';
import {Button, Callout, Collapse, FormGroup, Switch, Tag} from '@blueprintjs/core';
import {DateTime} from 'luxon';
import {useMutation, useQueryClient} from 'react-query';
import {ApiError} from '../../../api/api';
import {deleteDiscountRequest, updateDiscountRequest} from '../../../api/discount.api';
import {TimeframeRow} from '../../../components/timeframes/TimeframeRow';
import {useAlerts} from '../../../contexts/alerts.context';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';
import {TimeframeDto} from '@kontactless/admin-api/timeframe/timeframe.dto';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {DATETIME_FORMAT} from '../../../utils/constants';
import {getDiscountTypeLabel} from '../../../utils/helpers.utils';
import {PromotionsProductsTable} from './PromotionsProductsTable';
import {DiscountDto, DiscountUpdateRequestDto} from '@kontactless/admin-api/discount/discount.dto';
import {cn} from '../../../common/utils';

interface PromotionsCardProps {
  store: StoreDto;
  discount: DiscountDto;
  onEditClick?: () => void;
  onAddProductsClick?: () => void;
  onAddTimeframeClick?: () => void;
  onEditTimeframeClick?: (timeframe: TimeframeDto) => void;
}

export function PromotionsCard({
  store,
  discount,
  onEditClick,
  onAddProductsClick,
  onAddTimeframeClick,
  onEditTimeframeClick,
}: PromotionsCardProps) {
  const {user} = useAuth();
  const {alertsDispatch} = useAlerts();
  const {
    state: {toaster},
  } = useToasts();
  const [areDetailsShown, setAreDetailsShown] = useState(false);
  const queryClient = useQueryClient();

  const discountSetEnabledMutation = useMutation<DiscountDto, ApiError, DiscountUpdateRequestDto>({
    mutationKey: 'update-discount',
    mutationFn: (update) => updateDiscountRequest(user.token, discount.id, update),
    onSuccess: (updatedDiscount) => {
      queryClient.invalidateQueries(['stores', store.id, 'discounts']);
      toaster.show({
        intent: 'success',
        message: `Promotion was ${updatedDiscount.isEnabled ? 'enabled' : 'disabled'} successfully`,
      });
    },
    onError: (error, {isEnabled}) => {
      queryClient.invalidateQueries(['stores', store.id, 'discounts']);
      toaster.show({
        intent: 'danger',
        message: `An error ocurred ${isEnabled ? 'enabling' : 'disabling'} the promotion`,
      });
    },
  });
  const discountDeleteMutation = useMutation<{}, ApiError, number>((discountId) => deleteDiscountRequest(user.token, discountId));

  const onDeleteClick = async () => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Delete discount "${discount.name}"?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await discountDeleteMutation.mutateAsync(discount.id);
            queryClient.invalidateQueries(['stores', store.id, 'discounts']);
            toaster.show({intent: 'success', message: 'Promotion was deleted successfully'});
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred deleting the promotion'});
            setAlert({loading: false});
          }
          queryClient.invalidateQueries(['discounts', {storeId: store.id}]);
        },
      },
    });
  };

  const disableControls = discountSetEnabledMutation.isLoading || discountDeleteMutation.isLoading;

  return (
    <div key={discount.id} className={cn('tw-rounded tw-bg-white tw-p-4 tw-shadow', !discount.isEnabled && 'tw-opacity-50')}>
      <h3 className="tw-text-gray-900 tw-text-sm tw-font-bold">{discount.name}</h3>
      <div className="card-body">
        <div className="tw-mt-2 tw-flex tw-justify-between">
          <div className="tw-flex tw-gap-6">
            <div className="tw-flex tw-flex-col">
              <p className="tw-text-gray-500">Type</p>
              <FormGroup>{getDiscountTypeLabel(discount.type)}</FormGroup>
            </div>
            {!!discount.code && (
              <div className="tw-flex tw-flex-col">
                <p className="tw-text-gray-500">Code</p>
                <FormGroup>{discount.code}</FormGroup>
              </div>
            )}
            <DiscountAmountFormField discount={discount} />
            <DiscountMinimumAmountFormField discount={discount} />
            <DiscountExpirationDateFormField discount={discount} />
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <Button minimal disabled={disableControls} onClick={onEditClick}>
              <p className="tw-text-blue-600">Edit</p>
            </Button>
            <Switch
              checked={discount.isEnabled}
              onChange={({currentTarget: {checked}}) => {
                discountSetEnabledMutation.mutate({isEnabled: checked});
              }}
              disabled={disableControls}
              className="switch"
            />
            <Button
              minimal
              intent="danger"
              icon="trash"
              disabled={disableControls}
              onClick={onDeleteClick}
              loading={discountDeleteMutation.isLoading}
            />
          </div>
        </div>
        <Button
          text={areDetailsShown ? 'Hide details' : 'See details'}
          rightIcon={areDetailsShown ? 'chevron-up' : 'chevron-down'}
          onClick={() => setAreDetailsShown(!areDetailsShown)}
          outlined
          small
        />
        <Collapse isOpen={areDetailsShown} keepChildrenMounted>
          {/* Products associates with the promotion */}
          {discount.type.startsWith('promotion-') && (
            <div className="tw-border-gray-300 tw-my-4 tw-border-t tw-border-solid">
              <header className="tw-flex tw-items-center tw-justify-between">
                <h3 className="tw-mt-4 tw-mb-2 tw-font-bold">Products</h3>
                <Button intent="primary" text="Add Products" icon="add" minimal onClick={onAddProductsClick} />
              </header>
              {!!discount.menuCategoryProducts?.length && <PromotionsProductsTable discount={discount} />}
              {discount.menuCategoryProducts?.length === 0 && (
                <Callout icon="info-sign" children="No products are set for this discount" />
              )}
            </div>
          )}

          {/* Timeframes of the promotion */}
          <div className="tw-border-gray-300 tw-my-4 tw-border-t tw-border-solid">
            <header className="tw-flex tw-items-center tw-justify-between">
              <h3 className="tw-mt-4 tw-mb-2 tw-font-bold">Timeframes</h3>
              <Button intent="primary" text="Add Timeframes" icon="add" minimal onClick={onAddTimeframeClick} />
            </header>
            {discount.timeframes?.map((timeframe) => (
              <TimeframeRow
                key={timeframe.id}
                timeframe={timeframe}
                onEdit={onEditTimeframeClick}
                onDelete={() => {
                  queryClient.refetchQueries(['discounts', {storeId: store.id}]);
                }}
              />
            )) ?? null}
            {discount.timeframes?.length === 0 && <Callout icon="info-sign" children="No timeframes are set for this discount" />}
          </div>
        </Collapse>
      </div>
    </div>
  );
}

function DiscountAmountFormField({discount}: {discount: DiscountDto}) {
  if (discount.type === 'coupon-flat') {
    return (
      <div className="tw-flex tw-flex-col">
        <p className="tw-text-gray-500">Discount</p>
        <FormGroup>${discount.discount.toFixed(2)}</FormGroup>
      </div>
    );
  }

  if (discount.type === 'coupon-percentage') {
    return (
      <div className="tw-flex tw-flex-col">
        <p className="tw-text-gray-500">Discount</p>
        <FormGroup>{discount.discount.toFixed(2)}%</FormGroup>
      </div>
    );
  }

  if (discount.type === 'promotion-flat-price') {
    return (
      <div className="tw-flex tw-flex-col">
        <p className="tw-text-gray-500">Flat Price</p>
        <FormGroup>${discount.discount.toFixed(2)}</FormGroup>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col">
      <p className="tw-text-gray-500">Discount</p>
      <FormGroup>{discount.discount}</FormGroup>
    </div>
  );
}

const DiscountExpirationDateFormField: React.FC<{discount: DiscountDto}> = ({discount}) => {
  if (!discount.expirationDate) {
    return null;
  }

  const formattedDate = DateTime.fromISO(discount.expirationDate).toFormat(DATETIME_FORMAT);

  const isExpired = DateTime.fromISO(discount.expirationDate) < DateTime.local();

  if (discount.type.startsWith('coupon-')) {
    return (
      <div className="tw-flex tw-flex-col">
        <p className="tw-text-gray-500">Expires At</p>
        <div className="tw-flex tw-items-center tw-gap-2">
          <p>{formattedDate}</p>
          {isExpired && (
            <Tag minimal intent="warning">
              Expired
            </Tag>
          )}
        </div>
      </div>
    );
  }

  if (discount.type.startsWith('promotion-')) {
    return (
      <div className="tw-flex tw-flex-col">
        <p className="tw-text-gray-500">Finishes At</p>
        <FormGroup>{formattedDate}</FormGroup>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col">
      <p className="tw-text-gray-500"></p>
      <FormGroup>{discount.expirationDate}</FormGroup>
    </div>
  );
};

const DiscountMinimumAmountFormField: React.FC<{discount: DiscountDto}> = ({discount}) => {
  if (discount.type.startsWith('promotion-')) {
    return null;
  }

  return (
    <div className="tw-flex tw-flex-col">
      <p className="tw-text-gray-500">Minimum Amount</p>
      <FormGroup>${discount.minimumAmount.toFixed(2)}</FormGroup>
    </div>
  );
};
