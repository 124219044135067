import {callApi} from './api';
import {
  GetPrintersFiltersRequestDto,
  PrinterCreateRequestDto,
  PrinterDto,
  PrinterUpdateRequestDto,
} from '@kontactless/admin-api/printer/printer.dto';

export const fetchAllPrinters = async (
  token: string,
  storeId: number,
  filters?: GetPrintersFiltersRequestDto
): Promise<PrinterDto[]> => {
  return callApi<PrinterDto[]>(`/printers`, {token, method: 'GET', params: {storeId, ...filters}}).then(({data}) => data);
};

export const createPrinterRequest = async (token: string, printer: PrinterCreateRequestDto): Promise<PrinterDto> => {
  return callApi<PrinterDto>(`/printers`, {token, method: 'POST', body: JSON.stringify(printer)}).then(({data}) => data);
};

export const updatePrinterRequest = async (
  token: string,
  printer: PrinterUpdateRequestDto,
  printerId: number
): Promise<PrinterDto> => {
  return callApi<PrinterDto>(`/printers/${printerId}`, {token, method: 'PATCH', body: JSON.stringify(printer)}).then(
    ({data}) => data
  );
};

export const deletePrinterRequest = async (token: string, printerId: number): Promise<PrinterDto> => {
  return callApi<PrinterDto>(`/printers/${printerId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
