import {Spinner} from '@blueprintjs/core';
import {orderBy} from 'lodash';
import {Doughnut} from 'react-chartjs-2';
import {PercentageChip} from './PercentageChip';

interface TopOrdersByOperationCardProps {
  stores: {
    amount: number;
    ordersDiffPercentage: string;
    storeName: string;
  }[];
  isLoading: boolean;
  isError: boolean;
  showPercentage: boolean;
}

export default ({stores, isLoading, isError, showPercentage}: TopOrdersByOperationCardProps) => {
  const topFour = stores.slice(0, 4);
  const colors = ['#2C54E9', '#EC3E6F', '#A0F1EF', '#CAFADA'];

  if (isError) {
    return (
      <div className="tw-flex tw-flex-col tw-rounded tw-bg-white tw-p-3 tw-shadow">
        <p className="tw-mt-3 tw-text-red-700">Error</p>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-rounded tw-bg-white tw-p-3 tw-shadow">
      <p className="tw-mb-3 tw-text-xs tw-text-gray-500">Top Orders By Store</p>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="tw-flex tw-items-center tw-justify-between">
          {!topFour.length || !topFour.filter((store) => store.amount !== 0).length ? (
            <p className="tw-mt-3 tw-flex tw-w-full tw-text-gray-500">There are no stores in the selected range</p>
          ) : (
            <>
              <Doughnut
                data={{
                  datasets: [
                    {
                      label: 'Top Orders By Store',
                      data: topFour.map(({amount}) => amount),
                      backgroundColor: orderBy(topFour, 'amount', 'desc').map((_, idx) => colors[idx]),
                      borderWidth: 1,
                    },
                  ],
                  labels: topFour.map(({storeName}) => storeName),
                }}
                width={90}
                height={90}
                options={{legend: {display: false}, responsive: false, maintainAspectRatio: false, cutoutPercentage: 50}}
              />

              <ol className="tw-space-y-1 tw-flex-1 tw-ml-4">
                {orderBy(topFour, 'amount', 'desc').map((store, i) => (
                  <li className="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-text-xs" key={store.storeName}>
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <span className="tw-h-3 tw-w-3 tw-rounded-sm" style={{backgroundColor: `${colors[i]}`}}></span>
                      <span className="tw-text-gray-500">{`${i + 1}.`}</span>
                      <p className="tw-flex tw-text-zinc-700">{store.storeName}</p>
                    </div>
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <p className="tw-text-sm tw-font-bold">{store.amount}</p>
                      {showPercentage && <PercentageChip diffPercentage={store.ordersDiffPercentage} />}
                    </div>
                  </li>
                ))}
              </ol>
            </>
          )}
        </div>
      )}
    </div>
  );
};
