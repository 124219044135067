import {ApiResponse, callApi} from './api';
import {
  OrderDto,
  OrderRefundRequestDto,
  OrderStatusUpdateRequestDto,
  OrdersCancelledRequestDto,
  OrdersRequestDto,
  OrdersScheduledRequestDto,
} from '@kontactless/admin-api/order/order.dto';

export const fetchAllOrdersRequest = async (
  token: string,
  {storeId, eventId, page, pageSize, dateStart, dateEnd}: OrdersRequestDto
): Promise<ApiResponse<OrderDto[]>> => {
  return callApi<OrderDto[]>(`/orders`, {token, params: {storeId, eventId, page, pageSize, dateStart, dateEnd}});
};

export const fetchOrderRequest = async (token: string, orderId: number): Promise<OrderDto> => {
  return callApi<OrderDto>(`/orders/${orderId}`, {token}).then(({data}) => data);
};

export const fetchAllOrdersPdfRequest = async (
  token: string,
  {storeId, dateStart, dateEnd, eventId}: OrdersRequestDto
): Promise<ApiResponse<OrderDto[]>> => {
  return callApi<OrderDto[]>(`/orders/report/pdf`, {token, params: {storeId, dateStart, dateEnd, eventId}});
};

export const fetchCurrentOrdersRequest = async (token: string, storeId: number, eventIds?: number[]): Promise<OrderDto[]> => {
  return callApi<OrderDto[]>(`/orders/current`, {token, params: {storeId, eventIds}}).then(({data}) => data);
};

export const fetchOrdersCancelledRequest = async (
  token: string,
  {storeId, dateStart, dateEnd}: OrdersCancelledRequestDto
): Promise<OrderDto[]> => {
  return callApi<OrderDto[]>(`/orders/cancelled`, {token, params: {storeId, dateStart, dateEnd}}).then(({data}) => data);
};

export const fetchOrdersScheduledRequest = async (token: string, {storeId}: OrdersScheduledRequestDto): Promise<OrderDto[]> => {
  return callApi<OrderDto[]>(`/orders/scheduled`, {token, params: {storeId}}).then(({data}) => data);
};

export const downloadAllOrdersRequest = async (
  token: string,
  {storeId, page, pageSize, dateStart, dateEnd, eventId}: OrdersRequestDto
): Promise<null> => {
  return callApi<null>(`/orders/download`, {
    token,
    params: {storeId, page, pageSize, dateStart, dateEnd, eventId},
  }).then(({data}) => data);
};

export const cancelOrderRequest = async (token: string, orderId: number): Promise<OrderDto> => {
  return callApi<OrderDto>(`/orders/${orderId}`, {
    token,
    method: 'PATCH',
    body: JSON.stringify({status: 'cancelled-by-store'}),
  }).then(({data}) => data);
};

export const refundOrderRequest = async (
  token: string,
  orderId: number,
  {itemsToRefund}: OrderRefundRequestDto
): Promise<OrderDto> => {
  return callApi<OrderDto>(`/orders/${orderId}`, {
    token,
    method: 'PATCH',
    body: JSON.stringify({status: 'partially-refunded', itemsToRefund}),
  }).then(({data}) => data);
};

export const updateOrderStatusRequest = async (
  token: string,
  orderId: number,
  {status}: OrderStatusUpdateRequestDto
): Promise<OrderDto> => {
  return callApi<OrderDto>(`/orders/${orderId}`, {
    token,
    method: 'PATCH',
    body: JSON.stringify({status}),
  }).then(({data}) => data);
};
