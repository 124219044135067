import {QueryClient} from 'react-query';

import {DiscountDto} from '@kontactless/admin-api/discount/discount.dto';

export type CacheDiscountAction =
  | {type: 'add-discount'; discount: DiscountDto}
  | {type: 'remove-discount'; discount: {id: number; storeId: number}}
  | {type: 'update-discount'; discount: DiscountDto};

export const dispatchDiscountAction = (queryClient: QueryClient, action: CacheDiscountAction) => {
  switch (action.type) {
    case 'add-discount':
      addDiscount(queryClient, action.discount);
      break;
    case 'remove-discount':
      removeDiscount(queryClient, action.discount);
      break;
    case 'update-discount':
      updateDiscount(queryClient, action.discount);
      break;
  }
};

const addDiscount = (queryClient: QueryClient, discount: DiscountDto) => {
  queryClient.setQueryData<DiscountDto[] | undefined>(['discounts', {storeId: discount.storeId}], () => {
    const cachedDiscounts = queryClient.getQueryData<DiscountDto[] | undefined>(['discounts', {storeId: discount.storeId}]);
    return cachedDiscounts?.concat(discount);
  });
};

const removeDiscount = (queryClient: QueryClient, discount: {id: number; storeId: number}) => {
  queryClient.setQueryData<DiscountDto[] | undefined>(['discounts', {storeId: discount.storeId}], () => {
    const cachedDiscounts = queryClient.getQueryData<DiscountDto[] | undefined>(['discounts', {storeId: discount.storeId}]);
    return cachedDiscounts?.filter(({id}) => id !== discount.id);
  });
};

const updateDiscount = (queryClient: QueryClient, discount: DiscountDto) => {
  queryClient.setQueryData<DiscountDto[] | undefined>(['discounts', {storeId: discount.storeId}], () => {
    const cachedDiscounts = queryClient.getQueryData<DiscountDto[] | undefined>(['discounts', {storeId: discount.storeId}]);
    const cachedDiscountIdx = cachedDiscounts?.findIndex(({id}) => id === discount.id) ?? -1;

    if (cachedDiscountIdx >= 0) {
      cachedDiscounts![cachedDiscountIdx] = {...cachedDiscounts![cachedDiscountIdx], ...discount};
    }

    return cachedDiscounts;
  });
};
