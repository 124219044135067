import {ReportTotalData, StoresTotalSalesData, TopOrderedCategoriesReport, TopSellingProductsReport} from '../../api/report.api';
import {getDiffPercentageString, getPriceText} from '../../utils/helpers.utils';
import BestPerformingStoresCard from './BestPerformingStoresCard';
import PopularCategoriesCard from './PopularCategoriesCard';
import SummaryCard from './SummaryCard';
import TopOrdersByOperation from './TopOrdersByOperation';
import TopProductsCard from './TopProductsCard';

interface SummarySectionProps {
  salesData?: ReportTotalData;
  isSalesDataLoading: boolean;
  isSalesDataError: boolean;
  ordersData?: ReportTotalData;
  isOrdersDataLoading: boolean;
  isOrdersDataError: boolean;
  newCustomersData?: ReportTotalData;
  isNewCustomersLoading: boolean;
  isNewCustomersError: boolean;
  averageOrderTotalData?: ReportTotalData;
  isAverageOrderTotalLoading: boolean;
  isAverageOrderTotalError: boolean;
  bestPerformingStoresData?: StoresTotalSalesData[];
  isBestPerformingStoresLoading: boolean;
  isBestPerformingStoresError: boolean;
  topOrderedCategoriesData?: TopOrderedCategoriesReport;
  isTopOrderedCategoriesLoading: boolean;
  isTopOrderedCategoriesError: boolean;
  topSellingProductsData?: TopSellingProductsReport;
  isTopSellingProductsLoading: boolean;
  isTopSellingProductsError: boolean;
  showPercentage: boolean;
}

export default ({
  salesData,
  isSalesDataError,
  isSalesDataLoading,
  ordersData,
  isOrdersDataLoading,
  isOrdersDataError,
  newCustomersData,
  isNewCustomersLoading,
  isNewCustomersError,
  averageOrderTotalData,
  isAverageOrderTotalLoading,
  isAverageOrderTotalError,
  bestPerformingStoresData,
  isBestPerformingStoresLoading,
  isBestPerformingStoresError,
  topOrderedCategoriesData,
  isTopOrderedCategoriesLoading,
  isTopOrderedCategoriesError,
  topSellingProductsData,
  isTopSellingProductsLoading,
  isTopSellingProductsError,
  showPercentage,
}: SummarySectionProps) => {
  return (
    <div className="tw-grid tw-w-full tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4">
      <div className="tw-grid tw-gap-4 tw-grid-cols-1">
        <div className="tw-grid tw-gap-4 tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-4">
          <SummaryCard
            title="Sales"
            amount={getPriceText(salesData?.total ?? 0)}
            diffPercentage={getDiffPercentageString(salesData?.diffPercentage!)}
            isError={isSalesDataError}
            isLoading={isSalesDataLoading}
            showPercentage={showPercentage}
          />
          <SummaryCard
            title="Orders"
            amount={Math.round(ordersData?.total ?? 0).toString()}
            diffPercentage={getDiffPercentageString(ordersData?.diffPercentage!)}
            isError={isOrdersDataError}
            isLoading={isOrdersDataLoading}
            showPercentage={showPercentage}
          />
          <SummaryCard
            title="New Customers"
            amount={(newCustomersData?.total ?? 0).toFixed(0)}
            diffPercentage={getDiffPercentageString(newCustomersData?.diffPercentage!)}
            isError={isNewCustomersError}
            isLoading={isNewCustomersLoading}
            showPercentage={showPercentage}
          />
          <SummaryCard
            title="Average Spend Per Order"
            amount={getPriceText(averageOrderTotalData?.total ?? 0)}
            diffPercentage={getDiffPercentageString(averageOrderTotalData?.diffPercentage!)}
            isError={isAverageOrderTotalError}
            isLoading={isAverageOrderTotalLoading}
            showPercentage={showPercentage}
          />
        </div>

        <div className="tw-grid tw-grid-cols-1 tw-gap-4">
          <BestPerformingStoresCard
            stores={
              bestPerformingStoresData?.map((store) => {
                return {
                  storeName: store.name,
                  amount: getPriceText(store.totalSales),
                  salesDiffPercentage: getDiffPercentageString(store.salesDiffPercentage),
                };
              }) ?? []
            }
            isLoading={isBestPerformingStoresLoading}
            isError={isBestPerformingStoresError}
            showPercentage={showPercentage}
          />
          <TopOrdersByOperation
            stores={
              bestPerformingStoresData?.map((store) => {
                return {
                  storeName: store.name,
                  amount: store.totalOrders,
                  ordersDiffPercentage: getDiffPercentageString(store.ordersDiffPercentage),
                };
              }) ?? []
            }
            isLoading={isBestPerformingStoresLoading}
            isError={isBestPerformingStoresError}
            showPercentage={showPercentage}
          />
        </div>
      </div>
      <div className="tw-gap-4 tw-grid tw-grid-cols-1 xl:tw-grid-cols-2">
        <PopularCategoriesCard
          categories={
            topOrderedCategoriesData?.map((category) => {
              return {
                name: category.categoryName,
                amount: category.total.toString(),
                total: category.total,
                compareTo: category.compareTo,
                diffPercentage: getDiffPercentageString(+category.diffPercentage),
              };
            }) ?? []
          }
          isLoading={isTopOrderedCategoriesLoading}
          isError={isTopOrderedCategoriesError}
          showPercentage={showPercentage}
        />
        <TopProductsCard
          products={
            topSellingProductsData?.map((product) => {
              return {
                name: product.name,
                amount: product.total.toString(),
                diffPercentage: getDiffPercentageString(product.diffPercentage),
                total: product.total,
                store: product.storeName,
                imageUrl: product.imageUrl,
              };
            }) ?? []
          }
          isLoading={isTopSellingProductsLoading}
          isError={isTopSellingProductsError}
          showPercentage={showPercentage}
        />
      </div>
    </div>
  );
};
