import React, {createRef, useMemo} from 'react';

import {Button, InputGroup, MenuItem} from '@blueprintjs/core';
import {ISuggestProps, Suggest} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';

import {fetchAllOmnivoreTablesRequest} from '../../api/omnivore.api';
import {useAuth} from '../../contexts/auth.context';
import {OmnivoreTableDto} from '@kontactless/admin-api/omnivore-table/omnivore-table.dto';

import './selectors.styles.scss';

interface OmnivoreTableSelectorProps
  extends Omit<ISuggestProps<OmnivoreTableDto>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  storeId: number;
  revenueCenterId?: string | null;
  fill?: boolean;
  selectedItemId?: string;
  onItemSelected: (item: OmnivoreTableDto | undefined) => void;
}

const OmnivoreTableSelectorComponent = Suggest.ofType<OmnivoreTableDto>();

export const OmnivoreTableSelector: React.FC<OmnivoreTableSelectorProps> = ({
  storeId,
  revenueCenterId,
  selectedItemId,
  onItemSelected,
  ...props
}) => {
  const {user} = useAuth();
  const tablesQuery = useQuery(['omnivore-tables', storeId], () => fetchAllOmnivoreTablesRequest(user.token, storeId));
  const inputRef = createRef<any>();

  const selectedItem = useMemo(() => {
    return tablesQuery.data?.find(({tableId}) => tableId === selectedItemId);
  }, [tablesQuery.data, selectedItemId]);

  const getTableLabel = (table: OmnivoreTableDto): string => {
    return `(${table.posId ?? ''}) - ${table.name ?? 'no table name'}`;
  };

  if (tablesQuery.isError) {
    return <InputGroup {...(props.inputProps ?? {})} intent="danger" value="Error fetching omnivore tables" readOnly />;
  }

  return (
    <OmnivoreTableSelectorComponent
      {...props}
      inputValueRenderer={(table) => (tablesQuery.isFetching ? '' : getTableLabel(table))}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      items={(tablesQuery.data ?? []).filter(({revenueCenterId: rcId}) => (revenueCenterId ? rcId === revenueCenterId : true))}
      itemRenderer={(table, {modifiers, handleClick}) => (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={table.tableId}
          onClick={handleClick}
          text={getTableLabel(table)}
        />
      )}
      onItemSelect={(value) => onItemSelected(value)}
      itemsEqual={(e1, e2) => e1.tableId === e2.tableId}
      selectedItem={selectedItem ?? null}
      itemPredicate={(query, table) =>
        !!table.tableId.toLocaleLowerCase().includes(query.toLowerCase()) ||
        !!table.name?.toLocaleLowerCase().includes(query.toLowerCase())
      }
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      inputProps={{
        ...(props.inputProps ?? {}),
        leftElement: tablesQuery.isFetching ? <Button loading minimal /> : undefined,
        placeholder: tablesQuery.isFetching ? 'Loading tables...' : 'Choose one...',
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
      }}
    />
  );
};
