import React, {useMemo} from 'react';

import {Button, IButtonProps, MenuItem} from '@blueprintjs/core';
import {ISelectProps, Select} from '@blueprintjs/select';
import classNames from 'classnames';

import {getSecurityLevelLabel} from '../../utils/helpers.utils';
import {SecurityLevel} from '../../utils/types';

interface SecurityLevelSelectorProps extends Omit<ISelectProps<SecurityLevel>, 'itemRenderer' | 'items'> {
  fill?: boolean;
  selectedItem?: SecurityLevel;
  buttonProps?: IButtonProps;
  currentSecurityLevel?: SecurityLevel;
}

const SecurityLevelSelectorComponent = Select.ofType<SecurityLevel>();

export const SecurityLevelSelector: React.FC<SecurityLevelSelectorProps> = ({
  selectedItem,
  buttonProps,
  currentSecurityLevel,
  ...props
}) => {
  const items: SecurityLevel[] = useMemo(() => {
    switch (currentSecurityLevel) {
      case 'superadmin':
      case 'company-admin':
        return ['company-admin', 'supervisor', 'server'];
      case 'supervisor':
        return ['supervisor', 'server'];
      case 'server':
        return [];
      default:
        return [];
    }
  }, [currentSecurityLevel]);

  return (
    <SecurityLevelSelectorComponent
      {...props}
      filterable={false}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      items={items}
      itemRenderer={(securityLevel, {modifiers, handleClick}) => (
        <MenuItem
          active={selectedItem === securityLevel}
          disabled={modifiers.disabled}
          key={securityLevel}
          onClick={handleClick}
          text={getSecurityLevelLabel(securityLevel)}
        />
      )}
    >
      <Button {...buttonProps} text={getSecurityLevelLabel(selectedItem!) ?? 'Role'} rightIcon="chevron-down" fill={props.fill} />
    </SecurityLevelSelectorComponent>
  );
};
