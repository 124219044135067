import React, {ReactElement} from 'react';

import {CircularProgress} from '@material-ui/core';
import {Rating, RatingProps} from '@material-ui/lab';
import classNames from 'classnames';
import {isNil} from 'lodash';

import {DashboardWidget, DashboardWidgetProps} from './dashboard-widget.component';

export interface DashboardWidgetRatingProps extends DashboardWidgetProps {
  ratingProps: RatingProps & {max: number};
  errorMessage?: string;
  noRatingComponent?: ReactElement;
}

export const DashboardWidgetRating: React.FC<DashboardWidgetRatingProps> = ({
  title,
  ratingProps,
  noRatingComponent,
  isLoading,
  errorMessage,
  ...itemProps
}) => {
  let component: ReactElement | null = null;

  if (isLoading) {
    component = <CircularProgress className="widget-spinner" />;
  } else if (!isNil(errorMessage)) {
    component = <p className="widget-error">{errorMessage}</p>;
  } else if (isNil(ratingProps.value)) {
    component = noRatingComponent ?? null;
  } else if (!component) {
    component = (
      <div className="rating-container">
        <Rating size="small" readOnly={true} {...ratingProps} />
        <span className="rating-score">
          {+ratingProps.value!.toFixed(2)}/{ratingProps.max}
        </span>
      </div>
    );
  }

  return (
    <DashboardWidget {...itemProps} className={classNames('widget-rating', itemProps.className)}>
      <h4 className="widget-title">{title}</h4>
      {component}
    </DashboardWidget>
  );
};
