import React from 'react';

import {Button, Classes, Dialog, FormGroup, IDialogProps, InputGroup} from '@blueprintjs/core';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';

import {createSectionRequest} from '../../../api/section.api';
import {useAuth} from '../../../contexts/auth.context';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {SectionDto, SectionUpsertRequestDto} from '@kontactless/admin-api/section/section.dto';
import {useNavigate} from 'react-router-dom';
import {useToasts} from '../../../contexts/toasts.context';

interface CreateOrderingMethodDialogProps extends IDialogProps {
  store: StoreDto;
}

interface SectionCreateForm {
  name: string;
  imageUrl?: string[];
}

export function CreateOrderingMethodDialog({store, ...props}: CreateOrderingMethodDialogProps) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    state: {toaster},
  } = useToasts();
  const createSectionMutation = useMutation<SectionDto, Error, SectionUpsertRequestDto>({
    mutationFn: (store) => createSectionRequest(user.token, store),
    onSuccess: (section) => {
      queryClient.invalidateQueries(['stores', store.id, 'ordering-methods']);
      toaster.show({intent: 'success', message: `Ordering method "${section.name}" created successfully.`});
      props.onClose?.(undefined as any);
      navigate(section.id.toString());
    },
  });
  const {register, handleSubmit, errors} = useForm<SectionCreateForm>();

  const submitForm = async (form: SectionCreateForm) =>
    createSectionMutation.mutate({
      ...defaultCreateSectionRequest,
      storeId: store.id,
      name: form.name.trim(),
      imageUrl: form.imageUrl?.length ? form.imageUrl[0] : null,
    });

  const defaultCreateSectionRequest: Omit<SectionUpsertRequestDto, 'storeId'> = {
    name: '',
    areOptionalTipsEnabled: false,
    areAdditionalChargesEnabled: false,
    arePromotionsEnabled: false,
    isPosConnected: false,
    freeItemsLimit: 0,
    imageUrl: null,
    orderingFlowType: 'delivery-to-location',
    isViewOnly: false,
    areSchedulableOrdersAllowed: false,
    minutesToShowScheduledOrders: 20,
    menus: [],
    openingHours: [],
    omnivoreRevenueCenterId: null,
  };

  return (
    <Dialog title="New ordering method" className="kl-dialog section-create-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          <FormGroup label="Name" intent={errors.name ? 'danger' : 'none'} helperText={errors.name ? 'Name is required' : ''}>
            <InputGroup
              className="control"
              name="name"
              intent={errors.name ? 'danger' : 'none'}
              defaultValue=""
              inputRef={register({validate: {required: (value: string) => value.trim() !== ''}})}
            />
          </FormGroup>
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={createSectionMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text="Create"
          intent="primary"
          disabled={createSectionMutation.isLoading}
          loading={createSectionMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
}
