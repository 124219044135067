import React, {useEffect, useState} from 'react';

import {Button, Callout, FormGroup, InputGroup, Spinner} from '@blueprintjs/core';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {startOmnivorePullRequest} from '../../../api/omnivore.api';
import {fetchCurrentPosStatusRequest} from '../../../api/pos-status.api';
import {fetchStoreRequest, updateStoreRequest} from '../../../api/store.api';
import {OmnivoreIcon} from '../../../components/icons/omnivore.icon';
import {PosStatusComponent} from '../../../components/omnivore/pos-status.component';
import {OmnivoreCategoriesSelector} from '../../../components/selectors/omnivore-categories-selector.component';
import {OmnivoreEmployeeSelector} from '../../../components/selectors/omnivore-employee-selector.component';
import {OmnivoreOrderTypeSelector} from '../../../components/selectors/omnivore-order-type-selector.component';
import {OmnivoreTenderTypeSelector} from '../../../components/selectors/omnivore-tender-type-selector.component';
import {useAlerts} from '../../../contexts/alerts.context';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';
import {StoreDto, StoreUpdateRequestDto} from '@kontactless/admin-api/store/store.dto';
import {PosFetchStatus} from '../../../utils/types';
import {ProductDto} from '@kontactless/admin-api/product/product.dto';
import {OmnivoreCategoryDto, OmnivoreStartPullRequestDto} from '@kontactless/admin-api/omnivore-category/omnivore-category.dto';
import {useParams} from 'react-router-dom';

interface StoreUpdateForm {
  posId?: string | null;
  posFetchStatus?: PosFetchStatus | null;
  omnivoreEmployeeId?: string | null;
  omnivoreDineInOrderTypeId?: string | null;
  omnivoreTakeawayOrderTypeId?: string | null;
  omnivoreTenderTypeId?: string | null;
  categoriesToSync: string[] | null;
}

export function OmnivoreIntegrationSettingsPage() {
  const {user} = useAuth();
  const {alertsDispatch} = useAlerts();
  const {
    state: {toaster},
  } = useToasts();
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);
  const storeQuery = useQuery({queryKey: ['stores', storeId], queryFn: () => fetchStoreRequest(user.token, storeId)});

  const storeSettings = storeQuery.data?.settings;
  const [isFetchPosStatusQueryEnabled, setIsFetchPosStatusQueryEnabled] = useState(true);
  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    watch,
    setValue,
    setError,
    formState: {isDirty},
  } = useForm<StoreUpdateForm>({defaultValues: defaultForm});
  const queryClient = useQueryClient();
  const storeUpdateMutation = useMutation<StoreDto, Error, StoreUpdateRequestDto>((update) =>
    updateStoreRequest(user.token, update, storeId)
  );
  const omnivorePullMutation = useMutation<{}, Error, OmnivoreStartPullRequestDto>((request) =>
    startOmnivorePullRequest(user.token, storeId, request)
  );

  const posStatusQuery = useQuery(
    ['posStatuses', storeId],
    () => fetchCurrentPosStatusRequest(user.token, storeQuery.data?.settings?.posId, storeId),
    {
      refetchInterval: 1000 * 10,
      enabled: isFetchPosStatusQueryEnabled && !!watch('posId'),
    }
  );

  const submitForm = async (form: StoreUpdateForm) => {
    try {
      const oldPosId = storeSettings?.posId;
      const updatedStore = await storeUpdateMutation.mutateAsync({
        settings: {
          ...form,
          posId: form.posId === '' ? null : form.posId,
          omnivoreCategories: form.categoriesToSync?.length === 0 ? null : form.categoriesToSync,
        },
      });

      queryClient.setQueryData<StoreDto>(['stores', storeId], () => ({...storeQuery.data, ...updatedStore}));

      if (updatedStore.settings!.posId !== oldPosId) {
        setValue('posFetchStatus', 'never-fetched', {shouldDirty: false});

        queryClient.invalidateQueries([
          ['omnivore-employees', storeId],
          ['omnivore-order-types', storeId],
          ['omnivore-tender-types', storeId],
          ['omnivore-revenue-centers', storeId],
          ['omnivore-tables', storeId],
        ]);
      }

      toaster.show({intent: 'success', message: 'Stores settings were updated successfully'});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred updating the store settings'});
    }
  };

  const onPullMenuClick = async (categoriesToSync: string[]) => {
    try {
      posStatusQuery.remove();
      setIsFetchPosStatusQueryEnabled(false);
      const omnivoreCategories = queryClient.getQueryData<OmnivoreCategoryDto[] | undefined>(['omnivore-categories']);
      if (omnivoreCategories?.length && !categoriesToSync.length) {
        setError('categoriesToSync', {type: 'required', message: ''});
        return;
      }

      setValue('posFetchStatus', 'fetching', {shouldDirty: false});

      await omnivorePullMutation.mutateAsync({categoriesToSync});
      setIsFetchPosStatusQueryEnabled(true);
      posStatusQuery.refetch();

      toaster.show({intent: 'success', message: 'Data pull process started successfully'});
    } catch (error) {
      setValue('posFetchStatus', 'error', {shouldDirty: false});
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred when starting the data pull process'});
    }
  };

  useEffect(() => {
    if (storeSettings) {
      reset({
        posId: storeSettings.posId,
        posFetchStatus: storeSettings.posFetchStatus,
        omnivoreDineInOrderTypeId: storeSettings.omnivoreDineInOrderTypeId,
        omnivoreEmployeeId: storeSettings.omnivoreEmployeeId,
        omnivoreTakeawayOrderTypeId: storeSettings.omnivoreTakeawayOrderTypeId,
        omnivoreTenderTypeId: storeSettings.omnivoreTenderTypeId,
        categoriesToSync: storeSettings.omnivoreCategories,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeSettings]);

  useEffect(() => {
    if (!posStatusQuery.data || posStatusQuery.data.log.every((step) => step.status === 'completed')) {
      setIsFetchPosStatusQueryEnabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posStatusQuery.data]);

  const getPossibleRemovedProducts = (category: OmnivoreCategoryDto): ProductDto[] => {
    const omnivoreMenuItemsInCategory = category.menuItems?.map(({menuItemId}) => menuItemId);
    return (
      storeQuery.data
        ?.menus!.filter(({isPosMenu}) => isPosMenu)
        .flatMap(({menuCategories}) => menuCategories ?? [])
        .flatMap(({menuCategoryProducts}) => menuCategoryProducts ?? [])
        .flatMap(({product}) => product ?? [])
        .filter(({omnivoreMenuItemId}) => omnivoreMenuItemsInCategory?.includes(omnivoreMenuItemId ?? '')) ?? []
    );
  };

  const buildRemoveCategoryAlertMessage = (productsToRemove: ProductDto[]) => {
    return (
      <>
        <p>Are you sure to remove this category?</p>
        <br />
        <p>The following products will be removed from your menus after Pull data: </p>
        {productsToRemove.map(({id, name}) => (
          <p key={id}> - {name}</p>
        ))}
      </>
    );
  };
  const onRemoveCategory = (category, props) => {
    const possibleRemovedProducts = getPossibleRemovedProducts(category);
    if (possibleRemovedProducts.length) {
      const messageInAlert = buildRemoveCategoryAlertMessage(possibleRemovedProducts);
      alertsDispatch({
        type: 'set-alert',
        alert: {
          children: messageInAlert,
          intent: 'danger',
          icon: 'trash',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Close',
          onConfirm: async (setAlert, removeAlert) => {
            try {
              setAlert({loading: true});
              props.onChange((props.value as string[]).filter((categoryId) => categoryId !== category.categoryId));
              toaster.show({intent: 'success', message: 'Order was cancelled successfully'});
              removeAlert();
            } catch (error) {
              console.error(error);
              toaster.show({intent: 'danger', message: 'An error ocurred cancelling the order'});
              setAlert({loading: false});
            }
          },
        },
      });
    } else {
      props.onChange((props.value as string[]).filter((categoryId) => categoryId !== category.categoryId));
    }
  };

  if (!storeSettings) {
    return <Spinner />;
  }

  return (
    <div className="tw-item-center tw-flex tw-flex-col tw-justify-between tw-px-6">
      <h3 className="tw-my-4 tw-text-xl tw-font-bold">Integrations</h3>
      <div className="settings-form">
        <header className="card-header tw-flex tw-mb-2 tw-items-center">
          <OmnivoreIcon className="tw-w-8 tw-h-auto" />
          <h3 className="card-title tw-ml-1">Omnivore</h3>
        </header>
        <div className="card-body form-body">
          {isDirty && (
            <div className="form-actions">
              <Button text="Cancel" minimal disabled={storeUpdateMutation.isLoading} onClick={() => reset()} />
              <Button
                text="Save"
                minimal
                disabled={storeUpdateMutation.isLoading}
                loading={storeUpdateMutation.isLoading}
                onClick={handleSubmit(submitForm)}
              />
            </div>
          )}

          <FormGroup label="POS ID" intent={errors.posId ? 'danger' : 'none'} helperText={errors.posId ? 'Invalid POS ID' : ''}>
            <InputGroup
              name="posId"
              intent={errors.posId ? 'danger' : 'none'}
              rightElement={<Button icon="cross" minimal onClick={() => setValue('posId', '')} />}
              inputRef={register}
            />
          </FormGroup>

          {storeSettings?.posId && storeSettings?.posId === watch('posId') && (
            <>
              <FormGroup
                label="Open tickets on behalf of..."
                intent={errors.omnivoreEmployeeId ? 'danger' : 'none'}
                helperText={errors.omnivoreEmployeeId ? 'Invalid omnivore employee' : ''}
              >
                <Controller
                  name="omnivoreEmployeeId"
                  control={control}
                  render={(props) => (
                    <OmnivoreEmployeeSelector
                      storeId={storeId}
                      selectedItemId={props.value}
                      onItemSelected={(employee) => props.onChange(employee?.employeeId)}
                      inputProps={{}}
                      fill
                    />
                  )}
                />
              </FormGroup>
              <FormGroup
                label="For Takeaway orders use order type..."
                intent={errors.posId ? 'danger' : 'none'}
                helperText={errors.posId ? 'Invalid order type' : ''}
              >
                <Controller
                  name="omnivoreTakeawayOrderTypeId"
                  control={control}
                  render={(props) => (
                    <OmnivoreOrderTypeSelector
                      storeId={storeId}
                      selectedItemId={props.value}
                      onItemSelected={(orderType) => props.onChange(orderType?.orderTypeId)}
                      inputProps={{}}
                      fill
                    />
                  )}
                />
              </FormGroup>
              <FormGroup
                label="For Dine In orders use order type..."
                intent={errors.posId ? 'danger' : 'none'}
                helperText={errors.posId ? 'Invalid order type' : ''}
              >
                <Controller
                  name="omnivoreDineInOrderTypeId"
                  control={control}
                  render={(props) => (
                    <OmnivoreOrderTypeSelector
                      storeId={storeId}
                      selectedItemId={props.value}
                      onItemSelected={(orderType) => props.onChange(orderType?.orderTypeId)}
                      inputProps={{}}
                      fill
                    />
                  )}
                />
              </FormGroup>
              <FormGroup
                label="Track payments using tender type..."
                intent={errors.posId ? 'danger' : 'none'}
                helperText={errors.posId ? 'Invalid order type' : ''}
              >
                <Controller
                  name="omnivoreTenderTypeId"
                  control={control}
                  render={(props) => (
                    <OmnivoreTenderTypeSelector
                      storeId={storeId}
                      selectedItemId={props.value}
                      onItemSelected={(tenderType) => props.onChange(tenderType?.tenderTypeId)}
                      inputProps={{}}
                      fill
                    />
                  )}
                />
              </FormGroup>

              <FormGroup
                label="Omnivore categories to sync..."
                intent={errors.categoriesToSync ? 'danger' : 'none'}
                helperText={errors.categoriesToSync ? 'Categories to sync must be selected' : ''}
              >
                <Controller
                  name="categoriesToSync"
                  control={control}
                  defaultValue={storeQuery.data?.settings?.omnivoreCategories}
                  render={(props) => (
                    <OmnivoreCategoriesSelector
                      storeId={storeId}
                      selectedCategoriesIds={props.value}
                      onItemSelect={(category) => {
                        if ((props.value as string[]).includes(category.categoryId)) {
                          props.onChange((props.value as string[]).filter((categoryId) => categoryId !== category.categoryId));
                        } else {
                          props.onChange(
                            props.value.filter((categoryId) => categoryId !== category.categoryId).concat(category.categoryId)
                          );
                        }
                      }}
                      onRemove={(category) => {
                        onRemoveCategory(category, props);
                      }}
                      fill
                      tagInputProps={{intent: errors.categoriesToSync ? 'danger' : 'none'}}
                    />
                  )}
                />
              </FormGroup>

              <div className="center">
                <Button
                  text={isFetchPosStatusQueryEnabled || omnivorePullMutation.isLoading ? 'Data is being pulled' : 'Pull data'}
                  intent="primary"
                  rightIcon="download"
                  disabled={isFetchPosStatusQueryEnabled || omnivorePullMutation.isLoading}
                  onClick={() => {
                    if (isDirty) {
                      handleSubmit(submitForm)();
                    }
                    onPullMenuClick(watch('categoriesToSync') || []);
                  }}
                />
                {watch('posFetchStatus') === 'error' && (
                  <Callout className="margin-top-10" intent="danger">
                    An error ocurred pulling the menu
                  </Callout>
                )}
              </div>
            </>
          )}
        </div>
        <header className="card-header tw-mt-4">
          <h3 className="card-title">Pull logs</h3>
          {posStatusQuery.data && <p>(Last run: {new Date(posStatusQuery.data?.createdAt!).toLocaleString()})</p>}
        </header>
        <div className="card-body">
          <PosStatusComponent posStatusLog={posStatusQuery.data?.log} />
        </div>
      </div>
    </div>
  );
}

const defaultForm: StoreUpdateForm = {
  categoriesToSync: [],
  posId: '',
  posFetchStatus: 'never-fetched',
};
