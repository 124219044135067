import React, {createRef, useMemo} from 'react';

import {Button, ButtonProps, MenuItem} from '@blueprintjs/core';
import {Select, SuggestProps} from '@blueprintjs/select';
import {Suggest} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';

import {fetchAllEventsRequest} from '../../api/event.api';
import {useAuth} from '../../contexts/auth.context';
import {SelectorType} from '../../utils/types';

import {EventDto} from '@kontactless/admin-api/event/event.dto';

interface EventSelectorProps
  extends Omit<SuggestProps<EventDto>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  fill?: boolean;
  selectedItemId?: number;
  storeId?: number;
  section?: number;
  buttonProps?: ButtonProps;
  includes?: Array<'sections' | 'locationTypes' | 'settings' | 'revenue-groups'>;
  onItemSelect: (item: EventDto | undefined) => void;
  componentType?: SelectorType;
}

const EventSelectorComponent = Select.ofType<EventDto>();
const EventSuggestComponent = Suggest.ofType<EventDto>();

export const EventSelector: React.FC<EventSelectorProps> = ({
  selectedItemId,
  includes,
  buttonProps,
  storeId,
  onItemSelect,
  componentType = 'select',
  ...props
}) => {
  const {user} = useAuth();

  const eventsQuery = useQuery({
    queryKey: ['stores', storeId, 'events'],
    queryFn: () => fetchAllEventsRequest(user.token, {storeId}),
    enabled: !!storeId,
  });

  const inputRef = createRef<any>();

  const selectedItem = useMemo(() => {
    if (eventsQuery?.data) {
      return eventsQuery.data?.find(({id}) => id === selectedItemId);
    }
    return null;
  }, [eventsQuery?.data, storeId]);

  if (componentType === 'suggest') {
    return (
      <EventSuggestComponent
        {...props}
        className={classNames(props.className, {'bp3-fill': props.fill})}
        defaultSelectedItem={[] as any}
        items={eventsQuery?.data ?? []}
        ref={inputRef}
        inputValueRenderer={(section) => (selectedItemId && section.storeId === storeId ? section.name : '')}
        popoverProps={{minimal: true}}
        onItemSelect={onItemSelect}
        itemPredicate={(query, event) => event.name.toLocaleLowerCase().includes(query.toLowerCase())}
        inputProps={{
          ...(props.inputProps ?? {}),
          leftElement: eventsQuery.isLoading ? <Button minimal loading /> : undefined,
          placeholder: 'Choose one...',
          rightElement: selectedItemId ? (
            <Button icon="cross" minimal onClick={() => onItemSelect(undefined)} />
          ) : (
            <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />
          ),
        }}
        noResults={<MenuItem disabled={true} text="No results." />}
        itemRenderer={({id, name}, {modifiers, handleClick}) => (
          <MenuItem active={selectedItem?.id! === id} disabled={modifiers.disabled} key={id} onClick={handleClick} text={name} />
        )}
        itemsEqual={(t1, t2) => t1 === t2}
      >
        <Button
          {...buttonProps}
          text={selectedItem ? selectedItem.name : 'Event'}
          rightIcon="chevron-down"
          fill={props.fill}
          onClick={() => inputRef.current?.inputElement?.focus()}
        />
      </EventSuggestComponent>
    );
  }

  return (
    <EventSelectorComponent
      {...props}
      onItemSelect={onItemSelect}
      filterable={false}
      items={eventsQuery?.data ?? []}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      itemRenderer={({id, name}, {modifiers, handleClick}) => (
        <MenuItem active={selectedItem?.id === id} disabled={modifiers.disabled} key={id} onClick={handleClick} text={name} />
      )}
      itemsEqual={(t1, t2) => t1 === t2}
    >
      <Button {...buttonProps} text={selectedItem ? selectedItem.name : 'Event'} rightIcon="chevron-down" fill={props.fill} />
    </EventSelectorComponent>
  );
};
