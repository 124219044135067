import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, InputGroup, NonIdealState, Spinner} from '@blueprintjs/core';
import {Container} from '../../common/components/layout/Container';
import {Header} from '../../common/components/layout/Header';
import {OrdersFooter} from '../../common/components/layout/OrdersFooter';
import {useAuth} from '../../contexts/auth.context';
import {useQuery} from 'react-query';

import {fetchAllEventsRequest} from '../../api/event.api';
import {DateTime} from 'luxon';

import {EventGrid} from '../components/EventGrid';
import {orderBy} from 'lodash';
import {fetchStoreRequest} from '../../api/store.api';

export function PastEventsPage() {
  const {user} = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);

  const storeQuery = useQuery({
    queryKey: ['stores', storeId],
    queryFn: () => fetchStoreRequest(user.token, storeId),
  });

  const eventsQuery = useQuery({
    queryKey: ['stores', storeId, 'events', {dateEnd: DateTime.utc().toFormat('yyyy-MM-dd')}],
    queryFn: () =>
      fetchAllEventsRequest(user.token, {
        storeId: storeId,
        dateEnd: DateTime.utc().toFormat('yyyy-MM-dd'),
      }),
    enabled: !!storeId,
  });

  const filteredEvents =
    eventsQuery.data?.filter((event) => (search ? event.name.toLowerCase().includes(search.toLowerCase()) : event)) ?? [];

  if (!eventsQuery.data || !storeQuery.data) {
    return <Spinner />;
  }

  return (
    <>
      <>
        <Header>
          <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
            <div className="tw-flex tw-gap-4 tw-items-center">
              <Button
                text="Back"
                icon="chevron-left"
                minimal
                small
                className="!tw-bg-blue-100 !tw-rounded-lg"
                onClick={() => navigate('../')}
              />
              <h1 className="tw-text-base tw-font-bold tw-whitespace-nowrap">Past events</h1>
            </div>
          </div>
        </Header>
        <Container>
          <div className="tw-flex tw-items-center tw-justify-between">
            <InputGroup
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Events..."
              value={search}
              leftIcon="search"
            />
          </div>

          {/* Loading state */}
          {eventsQuery.isLoading && <Spinner />}

          {/* Empty state */}
          {!eventsQuery.isLoading && !eventsQuery.data.length && (
            <div className="tw-pt-10">
              <NonIdealState
                icon="settings"
                description={
                  search.length
                    ? `Looks like there are no past events matching your search.`
                    : `Looks like there are no past events yet.`
                }
                action={<Button intent="primary" text="+Add new event" onClick={() => navigate('new')} />}
              />
            </div>
          )}

          <EventGrid timezone={storeQuery.data.timezone} events={orderBy(filteredEvents, ['startDatetime'], ['desc'])} />
        </Container>
        <OrdersFooter />
      </>
    </>
  );
}
