import {ChevronRightIcon} from '@heroicons/react/20/solid';
import {EventDto} from '@kontactless/admin-api/event/event.dto';
import {DateTime} from 'luxon';
import {Link} from 'react-router-dom';

interface EventCardProps {
  event: EventDto;
  timezone: string;
}

export function EventCard({event, timezone}: EventCardProps) {
  const fallbackImage = 'https://via.placeholder.com/440x160/F2F2F2/969696?text=+';
  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-border tw-border-solid tw-border-gray-200 tw-bg-white tw-rounded-lg tw-shadow-sm hover:tw-shadow">
      <div className="tw-flex tw-flex-col tw-gap-2">
        <img
          className="tw-aspect-[11/5] tw-w-full tw-rounded-t-lg tw-object-cover"
          src={event.coverImageUrl ?? fallbackImage}
          alt={event.name}
        />
        <div className="tw-px-4">
          <h3 className="tw-text-base tw-font-bold tw-text-gray-900 tw-line-clamp-2">{event.name}</h3>
          <p className="tw-text-xs tw-text-gray-600">
            {DateTime.fromISO(event.startDatetime).setZone(timezone).toFormat('EEE LLL d, hh:mm a')}
          </p>
        </div>
      </div>
      <div className="tw-py-2">
        <hr className="tw-border-t tw-my-2 tw-border-solid tw-border-gray-200" />
        <Link to={event.id.toString()} className="tw-text-blue-500 tw-flex tw-items-center tw-justify-between tw-px-4">
          Event configuration
          <ChevronRightIcon className="tw-h-4 tw-w-4 tw-ml-1 tw-text-blue-500 hover:tw-text-blue-500" />
        </Link>
      </div>
    </div>
  );
}
