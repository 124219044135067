import {useNavigate, useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Button} from '@blueprintjs/core';
import {DateTime} from 'luxon';
import {fetchStoreRequest} from '../../../api/store.api';
import {fetchAllOrdersRequest} from '../../../api/order.api';
import {getReport} from '../../../api/report.api';
import {useAuth} from '../../../contexts/auth.context';
import {getPriceText} from '../../../utils/helpers.utils';

export function OrdersFooter() {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {companyId, storeId} = useParams<{companyId: string; storeId: string}>();
  const storeQuery = useQuery(['stores', storeId], () => fetchStoreRequest(user.token, +storeId!));
  const ordersQuery = useQuery({
    queryKey: [
      'orders',
      {
        storeId,
        dateStart: DateTime.local().startOf('day'),
        dateEnd: DateTime.local().endOf('day'),
      },
    ],
    queryFn: () =>
      fetchAllOrdersRequest(user.token, {
        storeId: +storeId!,
        page: 1,
        pageSize: 9999,
        dateStart: DateTime.local().minus({days: 1}).toString(),
        dateEnd: DateTime.local().toString(),
      }),
    refetchInterval: 1000 * 60,
  });

  const totalTipsQuery = useQuery({
    queryKey: ['total-tips-report', companyId, storeId],
    queryFn: () =>
      getReport('total-tips', {
        token: user.token,
        compareGroups: {
          compareGroupFrom: {
            start: DateTime.local().minus({day: 1}).toString(),
            end: DateTime.local().toString(),
          },
          compareGroupTo: {
            start: DateTime.local().endOf('day').minus({day: 1}).toString(),
            end: DateTime.local().endOf('day').minus({day: 1}).toString(),
          },
        },
        companyId: +companyId!,
        storeId: +storeId!,
      }),
    refetchInterval: 1000 * 60,
  });

  const totalSales =
    ordersQuery.data?.data
      .filter((order) => ['received', 'preparing', 'ready', 'delivered', 'partially-refunded'].includes(order.status))
      .reduce((acc, order) => acc + order.total, 0) ?? 0;

  const totalTips = totalTipsQuery.data?.total ?? 0;

  const totalNewOrders =
    ordersQuery.data?.data.filter((order) => ['received', 'preparing', 'ready'].includes(order.status)).length ?? 0;

  const totalCompletedOrders =
    ordersQuery.data?.data.filter((order) => ['delivered', 'partially-refunded'].includes(order.status)).length ?? 0;

  return (
    <div className="tw-fixed tw-bottom-0 tw-bg-white tw-border-t tw-border-solid tw-border-gray-200 tw-w-full">
      <div className="tw-w-full tw-max-w-screen-xl tw-mx-auto tw-px-6 xl:tw-px-0">
        <footer className="tw-flex tw-items-center tw-justify-between tw-p-2">
          <div className="tw-flex tw-items-center tw-gap-6">
            <div className="tw-flex tw-items-center tw-gap-2">
              {storeQuery.data?.imageUrl && (
                <img
                  src={storeQuery.data.imageUrl}
                  className="tw-h-6 tw-w-6 tw-rounded-full tw-object-cover"
                  alt={`${storeQuery.data?.name} logo`}
                />
              )}
              <span className="tw-font-bold tw-truncate"> {storeQuery.data?.name}</span>
            </div>
            <div className="tw-flex tw-gap-2 tw-items-center tw-overflow-hidden">
              {/* Orders */}
              <span className="tw-truncate">Last 24 hrs. orders:</span>
              <div className="tw-flex tw-gap-4 tw-border tw-border-solid tw-border-gray-100 tw-rounded-md tw-p-2 tw-font-bold">
                <div className="tw-flex tw-items-center tw-gap-2">
                  <span>New</span>
                  <span className="tw-rounded-xl tw-px-1.5 tw-bg-[#2A4874] tw-text-white tw-text-xs">{totalNewOrders}</span>
                </div>
                <div className="tw-flex tw-items-center tw-gap-2">
                  <span>Completed</span>
                  <span className="tw-rounded-xl tw-px-1.5 tw-bg-[#0FAD85] tw-text-white tw-text-xs">{totalCompletedOrders}</span>
                </div>
              </div>
            </div>
            {/* Sales */}
            <div className="tw-flex tw-gap-2 tw-items-center tw-overflow-hidden">
              <span className="tw-truncate">Sales:</span>
              <div className="tw-border tw-border-solid tw-border-gray-100 tw-rounded-md tw-p-2 tw-font-bold">
                {getPriceText(totalSales)}
              </div>
            </div>
            {/* Tips */}
            <div className="tw-flex tw-gap-2 tw-items-center tw-overflow-hidden">
              <span className="tw-truncate">Tips:</span>
              <div className="tw-border tw-border-solid tw-border-gray-100 tw-rounded-md tw-p-2 tw-font-bold">
                {getPriceText(totalTips)}
              </div>
            </div>
          </div>
          <Button
            minimal
            intent="primary"
            onClick={() => navigate(`/companies/${companyId}/stores/${storeId}/orders`)}
            icon="shopping-cart"
            rightIcon="chevron-right"
          >
            Manage Orders
          </Button>
        </footer>
      </div>
    </div>
  );
}
