import {Spinner} from '@blueprintjs/core';

import {PercentageChip} from './PercentageChip';

interface PopularCategoriesProps {
  categories: {
    amount: string;
    total: number;
    compareTo: number;
    diffPercentage: string;
    name: string;
  }[];
  isError: boolean;
  isLoading: boolean;
  showPercentage: boolean;
}

export default ({categories, isError, isLoading, showPercentage}: PopularCategoriesProps) => {
  if (isError) {
    return (
      <div className="tw-flex tw-w-full tw-flex-col tw-gap-3 tw-rounded tw-bg-white tw-p-3 tw-shadow">
        <p className="tw-mt-3 tw-text-red-700">Error</p>
      </div>
    );
  }

  const filteredCategories = categories.filter((category) => category.total > 0);

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-3 tw-rounded tw-bg-white tw-p-3 tw-shadow">
      <p className="tw-mb-1 tw-text-xs tw-text-gray-500">Popular Categories</p>
      {isLoading ? (
        <div className="tw-mt-3 tw-flex tw-content-center tw-justify-center">
          <Spinner />
        </div>
      ) : (
        <ol className="tw-flex tw-flex-col tw-gap-2">
          {!filteredCategories.length ? (
            <p className="tw-mt-3 tw-text-gray-500">There are no categories in the selected range</p>
          ) : (
            filteredCategories.slice(0, 10).map((category, i) => (
              <li className="tw-flex tw-items-center tw-justify-between tw-gap-2" key={category.name}>
                <div className="tw-flex tw-justify-between tw-gap-3 tw-text-xs tw-overflow-hidden tw-max-w-[140px]">
                  <span className="tw-w-3 tw-text-gray-500">{`${i + 1}.`}</span>
                  <p className="tw-font-bold tw-text-zinc-700 tw-truncate">{category.name}</p>
                </div>
                <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
                  <p className="tw-text-xs tw-text-zinc-700">{`${category.total} units`}</p>
                  {showPercentage && <PercentageChip diffPercentage={category.diffPercentage.toString()} />}
                </div>
              </li>
            ))
          )}
        </ol>
      )}
    </div>
  );
};
