import React, {useState} from 'react';
import {NavLink, Navigate, Outlet, useNavigate, useParams} from 'react-router-dom';

import {first, sortBy} from 'lodash';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {deleteMenuRequest, fetchMenusRequest} from '../../api/menu.api';
import {useAlerts} from '../../contexts/alerts.context';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';

import {DuplicateDialog} from '../components/menus/DuplicateDialog';
import {UpsertDialog} from '../components/menus/UpsertDialog';

import '../styles/menus-page.styles.scss';
import {MenuDto} from '@kontactless/admin-api/menu/menu.dto';
import classNames from 'classnames';
import {Menu as BpMenu, Button, MenuItem, NonIdealState, Spinner} from '@blueprintjs/core';
import {Popover2} from '@blueprintjs/popover2';
import {fetchStoreRequest} from '../../api/store.api';
import {OmnivoreIcon} from '../../components/icons/omnivore.icon';

export function MenusPageLayout() {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {alertsDispatch} = useAlerts();
  const params = useParams<{companyId: string; storeId: string; menuId?: string; page: string}>();
  const storeId = Number(params.storeId);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isMenuUpsertDialogOpen, setIsMenuUpsertDialogOpen] = useState(false);
  const [isMenuDuplicateDialogOpen, setIsMenuDuplicateDialogOpen] = useState(false);
  const [menuEditing, setMenuEditing] = useState<MenuDto | undefined>(undefined);

  const storeQuery = useQuery({
    queryKey: ['stores', storeId],
    queryFn: () => fetchStoreRequest(user.token, storeId),
  });
  const menusQuery = useQuery({
    queryKey: ['stores', storeId, 'menus'],
    queryFn: () => fetchMenusRequest(user.token, storeId),
  });

  const deleteMenuMutation = useMutation<void, Error, number>((menuId) => deleteMenuRequest(user.token, menuId));

  const onMenuUpserted = (upsertedMenu: MenuDto) => {
    queryClient.invalidateQueries(['stores', storeId, 'menus']);
    queryClient.invalidateQueries(['menus', upsertedMenu.id]);
    navigate(upsertedMenu.id);
  };

  const onDuplicateMenu = async (menu: MenuDto) => {
    queryClient.invalidateQueries(['stores', storeId, 'menus']);
    navigate(menu.id);
  };

  const onDeleteMenu = async (menu: MenuDto) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Delete menu "${menu.name}"?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteMenuMutation.mutateAsync(menu.id);
            toaster.show({intent: 'success', message: `Menu "${menu.name}" successfully deleted`});

            queryClient.removeQueries(['menus', menu.id]);
            queryClient.invalidateQueries(['stores', storeId, 'menus']);
            navigate(first(sortBy(menusQuery.data, 'id'))?.id.toString() || '');
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred deleting the menu'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  if (storeQuery.isLoading || menusQuery.isLoading) {
    return <Spinner />;
  }

  if (!storeQuery.data || storeQuery.isError) {
    return (
      <NonIdealState
        icon="error"
        title="There was an error loading the store"
        action={<Button intent="primary" text="Try again" onClick={() => storeQuery.refetch()} />}
      />
    );
  }

  if (!menusQuery.data?.length && !menusQuery.isLoading) {
    return (
      <>
        <NonIdealState
          icon="settings"
          title="Looks like you have no menus yet"
          action={
            <Button
              intent="primary"
              text="Click here to create your first menu"
              onClick={() => setIsMenuUpsertDialogOpen(true)}
            />
          }
        />
        <UpsertDialog
          isOpen={isMenuUpsertDialogOpen}
          store={storeQuery.data}
          menu={menuEditing}
          onResolve={onMenuUpserted}
          onClose={() => {
            setIsMenuUpsertDialogOpen(false);
            setMenuEditing(undefined);
          }}
        />
      </>
    );
  }

  if (!params.menuId && menusQuery.data?.length) {
    return <Navigate to={menusQuery.data[0].id.toString()} replace />;
  }

  return (
    <>
      {!!menusQuery.data?.length && (
        <div className="tw-grid tw-grid-cols-12 tw-gap-8">
          <div className="tw-col-span-3">
            <nav
              aria-label="Sidebar"
              className="tw-sticky tw-top-40 tw-divide-y tw-divide-gray-300 tw-bg-white tw-shadow tw-rounded tw-p-4"
            >
              {/* Header */}
              <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
                <h1 className="tw-text-lg tw-font-bold">Menus</h1>
                <Button intent="primary" onClick={() => setIsMenuUpsertDialogOpen(true)}>
                  + Create menu
                </Button>
              </div>

              {/* Menu list */}
              <ul className="tw-space-y-2">
                {menusQuery.data?.map((menu) => (
                  <li key={menu.id} className="tw-flex tw-items-center tw-justify-between">
                    <NavLink
                      className={({isActive}) =>
                        classNames('tw-block  tw-rounded hover:tw-no-underline  hover:tw-text-blue-600', {
                          'tw-text-blue-600 ': isActive,
                          'tw-text-black tw-bg-white tw-border-white': !isActive,
                        })
                      }
                      to={menu.id.toString()}
                    >
                      <div className="tw-flex tw-items-center">
                        {menu.name}
                        {menu.isPosMenu && <OmnivoreIcon className="tw-w-4 tw-h-auto tw-object-contain tw-ml-2" />}
                      </div>
                    </NavLink>
                    <Popover2
                      content={
                        <BpMenu>
                          <MenuItem
                            text="Edit"
                            icon="edit"
                            onClick={() => {
                              setMenuEditing(menu);
                              setIsMenuUpsertDialogOpen(true);
                            }}
                          />

                          {!menu.isPosMenu && (
                            <MenuItem
                              text="Duplicate"
                              icon="duplicate"
                              disabled={menu.isPosMenu}
                              onClick={() => {
                                setMenuEditing(menu);
                                setIsMenuDuplicateDialogOpen(true);
                              }}
                            />
                          )}
                          <MenuItem text="Remove" icon="trash" intent="danger" onClick={() => onDeleteMenu(menu)} />
                        </BpMenu>
                      }
                      position="bottom-left"
                    >
                      <Button icon="more" small minimal />
                    </Popover2>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <main className="tw-col-span-9">
            <Outlet />
          </main>
        </div>
      )}
      <UpsertDialog
        isOpen={isMenuUpsertDialogOpen}
        store={storeQuery.data}
        menu={menuEditing}
        onResolve={onMenuUpserted}
        onClose={() => {
          setIsMenuUpsertDialogOpen(false);
          setMenuEditing(undefined);
        }}
      />
      <DuplicateDialog
        isOpen={isMenuDuplicateDialogOpen}
        store={storeQuery.data}
        menu={menuEditing}
        onResolve={onDuplicateMenu}
        onClose={() => {
          setIsMenuDuplicateDialogOpen(false);
          setMenuEditing(undefined);
        }}
      />
    </>
  );
}
