import React, {useState} from 'react';

import {Button, Callout, Classes, Spinner} from '@blueprintjs/core';
import classNames from 'classnames';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {ApiError} from '../../api/api';
import {createDiscountMenuCategoryProductRequest} from '../../api/discount.api';
import {fetchMenuSimpleRequest, fetchMenusRequest} from '../../api/menu.api';
import {MenuSelector} from '../../components/selectors/menu-selector.component';
import {Drawer, DrawerProps} from '../../components/ui/drawer.component';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {MenuDto} from '@kontactless/admin-api/menu/menu.dto';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {getPriceText} from '../../utils/helpers.utils';
import {MenuCategoryProductDto} from '@kontactless/admin-api/menu-category-product/menu-category-product.dto';

interface ProductSelectorDrawerProps extends Omit<DrawerProps, 'title'> {
  store: StoreDto;
  selectedMenuCategoryProductsIds: number[];
  discountId: number;
}

export function ProductSelectorDrawer({
  store,
  selectedMenuCategoryProductsIds,
  discountId,
  ...drawerProps
}: ProductSelectorDrawerProps) {
  const {user} = useAuth();
  const [selectedMenu, setSelectedMenu] = useState<MenuDto | undefined>(undefined);

  const menuQuery = useQuery({
    queryKey: ['menu-simple', {id: selectedMenu?.id}],
    queryFn: () => fetchMenuSimpleRequest(user.token, selectedMenu!.id),
    enabled: !!selectedMenu,
  });

  const menusQuery = useQuery({
    queryKey: ['stores', store.id, 'menus'],
    queryFn: () => fetchMenusRequest(user.token, store.id),
    enabled: !!store,
  });

  let component: React.ReactElement | null = null;

  if (menuQuery.isLoading) {
    component = <Spinner intent="primary" className="menu-spinner" />;
  } else if (menuQuery.isError) {
    component = <Callout intent="danger" className="scene-error" children="An error ocurred loading the menu" />;
  } else if (menuQuery.data) {
    component = (
      <>
        {menuQuery.data.menuCategories?.map((menuCategory) => (
          <section key={menuCategory.id} className="menu-category-section card">
            <header className="card-header">
              <h3 className="card-title">{menuCategory.name}</h3>
            </header>
            <div className="card-body">
              {menuCategory.menuCategoryProducts?.map((menuCategoryProduct) => (
                <ProductRow
                  storeId={store.id}
                  key={menuCategoryProduct.id}
                  menuCategoryProduct={menuCategoryProduct}
                  isSelected={selectedMenuCategoryProductsIds.includes(menuCategoryProduct.id)}
                  discountId={discountId}
                />
              )) ?? null}
              {menuCategory.menuCategoryProducts?.length === 0 && (
                <Callout icon="info-sign" children="This category has no products" />
              )}
            </div>
          </section>
        )) ?? null}
      </>
    );
  }

  return (
    <Drawer
      {...drawerProps}
      className={classNames(drawerProps.className, 'product-selector-drawer')}
      title="Products"
      size="600px"
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <MenuSelector items={menusQuery.data ?? []} onItemSelect={setSelectedMenu} selectedItemId={selectedMenu?.id} fill />
          {component}
        </div>
      </div>
    </Drawer>
  );
}

interface ProductRowProps {
  menuCategoryProduct: MenuCategoryProductDto;
  isSelected: boolean;
  discountId: number;
  storeId: number;
}

function ProductRow({menuCategoryProduct, isSelected, discountId, storeId}: ProductRowProps) {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const queryClient = useQueryClient();
  const product = menuCategoryProduct.product;

  const createDiscountMenuCategoryProductMutation = useMutation<{}, ApiError, void>(
    () => createDiscountMenuCategoryProductRequest(user.token, {menuCategoryProductId: menuCategoryProduct.id}, discountId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['stores', storeId, 'discounts']);
        toaster.show({intent: 'success', message: `"${product?.name}" added to discount`});
      },
      onError: () => {
        toaster.show({intent: 'danger', message: `An error ocurred adding "${product?.name}" to discount`});
      },
    }
  );

  if (!product) {
    return null;
  }

  return (
    <div className="product-row">
      <Button
        intent="primary"
        text="Add"
        icon="circle-arrow-left"
        loading={createDiscountMenuCategoryProductMutation.isLoading}
        disabled={isSelected || createDiscountMenuCategoryProductMutation.isLoading}
        onClick={() => createDiscountMenuCategoryProductMutation.mutate()}
        outlined
      />
      <p>{product.name}</p>
      <p>{getPriceText(product.price)}</p>
    </div>
  );
}
