import React from 'react';

import {Button, ButtonGroup, Toast} from '@blueprintjs/core';
import {useMutation, useQueryClient} from 'react-query';

import {deleteMenuCategoryProductsRequest} from '../../../api/menu-category-product.api';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';
import {
  MenuCategoryProductDto,
  MenuCategoryProductsDeleteRequestDto,
} from '@kontactless/admin-api/menu-category-product/menu-category-product.dto';

interface CategoryProductsSelectedToastProps {
  menuId: number;
  menuCategoryProducts: MenuCategoryProductDto[];
  onDismiss?: () => void;
  onMenuCategoryProductsDeleted?: (menuId: number, menuCategoryProducts: MenuCategoryProductDto[]) => void;
}

export const CategoryProductsSelectedToast: React.FC<CategoryProductsSelectedToastProps> = ({
  menuId,
  menuCategoryProducts,
  onDismiss,
  onMenuCategoryProductsDeleted,
}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();

  const queryClient = useQueryClient();

  const deleteMenuCategoryProductsMutation = useMutation<void, Error, MenuCategoryProductsDeleteRequestDto>({
    mutationKey: 'delete-menu-category-products',
    mutationFn: (body) => {
      return deleteMenuCategoryProductsRequest(user.token, body);
    },
    onSuccess: () => {
      toaster.show({intent: 'success', message: `Products successfully removed`});

      onMenuCategoryProductsDeleted?.(menuId, menuCategoryProducts);
      queryClient.invalidateQueries(['menus', menuId]);
    },
    onError: (error) => {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred removing the products'});
    },
  });

  return (
    <Toast
      className="items-selected-toast"
      intent="primary"
      timeout={0}
      message={
        <>
          <div className="selected-count">{menuCategoryProducts.length}</div>
          <h3>Selected products</h3>
          <ButtonGroup minimal>
            <Button
              className="action-button"
              icon="trash"
              text="Remove"
              disabled={deleteMenuCategoryProductsMutation.isLoading}
              loading={deleteMenuCategoryProductsMutation.isLoading}
              onClick={() =>
                deleteMenuCategoryProductsMutation.mutate({
                  menuId,
                  menuCategoryProducts: menuCategoryProducts.map(({id}) => ({id})),
                })
              }
            />
          </ButtonGroup>
        </>
      }
      onDismiss={onDismiss}
    />
  );
};
