import React from 'react';

import classNames from 'classnames';
import NumberFormat from 'react-number-format';

interface PriceInputProps {
  disabled?: boolean;
  fill?: boolean;
  onChange: (value?: number) => void;
  value: string | number | null | undefined;
}

export const PriceInput: React.FC<PriceInputProps> = ({disabled = false, fill = false, onChange, value}) => {
  return (
    <NumberFormat
      className={classNames('bp3-input', {
        'bp3-disabled': disabled,
        'bp3-fill': fill,
      })}
      disabled={disabled}
      value={value}
      prefix="$ "
      decimalScale={2}
      fixedDecimalScale
      allowEmptyFormatting
      thousandSeparator
      onFocus={(event) => event.target.select()}
      min={0}
      onValueChange={({floatValue}) => floatValue !== value && onChange(floatValue)}
    />
  );
};
