import React from 'react';
import {OrderRow} from '../components/OrderRow';
import {OrderDto} from '@kontactless/admin-api/order/order.dto';
import {Pagination} from '../../components/ui/Pagination';
import {PaginationResponse} from '../../api/api';
import {Timezone} from '../../utils/types';

interface OrderHistoryTableProps {
  orders: OrderDto[];
  pagination: PaginationResponse;
  onPageChange: (page: number) => void;
  currentPage: number;
  timezone: Timezone;
  eventsFeatureEnabled: boolean;
}

export function OrderHistoryTable({
  orders,
  pagination,
  onPageChange,
  currentPage,
  timezone,
  eventsFeatureEnabled,
}: OrderHistoryTableProps) {
  return (
    <>
      <table className="tw-min-w-full tw-divide-y tw-divide-solid tw-divide-gray-200">
        <thead>
          <tr>
            <th scope="col" className="tw-py-3.5 tw-pr-2 tw-text-left tw-text-sm tw-font-bold tw-text-gray-900">
              Order
            </th>
            <th
              scope="col"
              className="tw-py-3.5 tw-px-2 tw-text-left tw-text-sm tw-font-bold tw-text-gray-900 tw-whitespace-nowrap"
            >
              Location
            </th>
            {eventsFeatureEnabled && (
              <th
                scope="col"
                className="tw-py-3.5 tw-px-2 tw-text-left tw-text-sm tw-font-bold tw-text-gray-900 tw-whitespace-nowrap"
              >
                Event
              </th>
            )}
            <th
              scope="col"
              className="tw-py-3.5 tw-px-2 tw-text-left tw-text-sm tw-font-bold tw-text-gray-900 tw-whitespace-nowrap"
            >
              Items count
            </th>
            {/* <th
              scope="col"
              className="tw-py-3.5 tw-px-2 tw-text-left tw-text-sm tw-font-bold tw-text-gray-900 tw-whitespace-nowrap"
            >
              Gross total
            </th> */}
            <th
              scope="col"
              className="tw-py-3.5 tw-px-2 tw-text-left tw-text-sm tw-font-bold tw-text-gray-900 tw-whitespace-nowrap"
            >
              Total
            </th>
            <th
              scope="col"
              className="tw-py-3.5 tw-px-2 tw-text-left tw-text-sm tw-font-bold tw-text-gray-900 tw-whitespace-nowrap"
            >
              Payment method
            </th>
            <th
              scope="col"
              className="tw-py-3.5 tw-px-2 tw-text-left tw-text-sm tw-font-bold tw-text-gray-900 tw-whitespace-nowrap"
            >
              Status
            </th>
            <th
              scope="col"
              className="tw-py-3.5 tw-px-2 tw-text-left tw-text-sm tw-font-bold tw-text-gray-900 tw-whitespace-nowrap"
            >
              Rating
            </th>
            <th scope="col" className="tw-relative tw-py-3.5 tw-px-2 sm:tw-pr-0">
              <span className="tw-sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <OrderRow key={order.id} order={order} timezone={timezone} eventsFeatureEnabled={eventsFeatureEnabled} />
          ))}
        </tbody>
      </table>

      {pagination && (
        <div className="tw-w-full tw-pt-3.5 tw-border-t tw-border-solid tw-border-gray-200">
          <Pagination {...pagination} page={currentPage} onPageSelected={onPageChange} />
        </div>
      )}
    </>
  );
}
