import React from 'react';

import {Button, MenuItem} from '@blueprintjs/core';
import {IMultiSelectProps, MultiSelect} from '@blueprintjs/select';
import classNames from 'classnames';

import {TimeframeDto} from '@kontactless/admin-api/timeframe/timeframe.dto';

interface TimeframesSelectorProps extends Omit<IMultiSelectProps<TimeframeDto>, 'itemRenderer' | 'tagRenderer'> {
  selectedTimeframesIds: number[];
}

const TimeframesSelectorComponent = MultiSelect.ofType<TimeframeDto>();

export const TimeframesSelector: React.FC<TimeframesSelectorProps> = ({selectedTimeframesIds, ...props}) => {
  let inputRef: any = {current: undefined};

  return (
    <TimeframesSelectorComponent
      {...props}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      itemRenderer={(timeframe, {modifiers, handleClick}) => (
        <MenuItem
          active={false}
          key={timeframe.id}
          onClick={handleClick}
          text={timeframe.name}
          icon={selectedTimeframesIds.includes(timeframe.id) ? 'tick' : 'blank'}
        />
      )}
      scrollToActiveItem={false}
      selectedItems={props.items.filter(({id}) => selectedTimeframesIds.includes(id))}
      tagRenderer={({name}) => name}
      itemsEqual={(m1, m2) => m1.id === m2.id}
      itemPredicate={(query, timeframe) => timeframe.name.toLocaleLowerCase().includes(query.toLowerCase())}
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      tagInputProps={{
        inputRef: (input) => (inputRef = input),
        placeholder: 'Choose many...',
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
        tagProps: {minimal: true},
      }}
    />
  );
};
