import {MenuCategoryReorderResponseDto} from '@kontactless/admin-api/menu-category/menu-category.dto';
import {callApi} from './api';
import {
  MenuCategoryCreateRequestDto,
  MenuCategoryDto,
  MenuCategoryReorderRequestDto,
  MenuCategoryUpdateRequestDto,
} from '@kontactless/admin-api/menu-category/menu-category.dto';

export const createMenuCategoryRequest = async (
  token: string,
  category: MenuCategoryCreateRequestDto
): Promise<MenuCategoryDto> => {
  return callApi<MenuCategoryDto>(`/menu-categories`, {token, method: 'POST', body: JSON.stringify(category)}).then(
    ({data}) => data
  );
};

export const updateMenuCategoryRequest = async (
  token: string,
  categoryId: number,
  category: MenuCategoryUpdateRequestDto
): Promise<MenuCategoryDto> => {
  return callApi<MenuCategoryDto>(`/menu-categories/${categoryId}`, {
    token,
    method: 'PATCH',
    body: JSON.stringify(category),
  }).then(({data}) => data);
};

export const deleteMenuCategoryRequest = async (token: string, categoryId: number): Promise<void> => {
  return callApi<void>(`/menu-categories/${categoryId}`, {token, method: 'DELETE'}).then(({data}) => data);
};

export const reorderMenuCategoryRequest = async (
  token: string,
  categoryId: number,
  category: MenuCategoryReorderRequestDto
): Promise<MenuCategoryReorderResponseDto> => {
  return callApi<MenuCategoryReorderResponseDto>(`/menu-categories/${categoryId}/reorder`, {
    token,
    method: 'PATCH',
    body: JSON.stringify(category),
  }).then(({data}) => data);
};
