interface HeaderProps {
  children?: React.ReactNode;
}

export function Header({children}: HeaderProps) {
  return (
    <header className="tw-bg-white tw-w-full xl:tw-px-0 tw-border-b tw-border-solid tw-border-gray-200 tw-sticky tw-top-16 tw-z-10">
      <div className="tw-flex tw-w-full tw-px-6 xl:tw-px-0 tw-max-w-screen-xl tw-mx-auto tw-h-16">{children}</div>
    </header>
  );
}
