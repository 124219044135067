import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Button, Callout, Collapse, FormGroup, Icon, InputGroup, Intent, Switch, Tag, TagInput, TextArea} from '@blueprintjs/core';
import {Classes} from '@blueprintjs/core';
import {Tooltip2} from '@blueprintjs/popover2';
import {yupResolver} from '@hookform/resolvers/yup';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import * as yup from 'yup';

import {upsertProductRequest} from '../../api/product.api';
import {ImageUploader} from '../../components/images/image-uploader.component';
import {AttributesSelector} from '../../components/selectors/attributes-selector.component';
import {TaxSelector} from '../../components/selectors/tax-selector.component';
import {Drawer} from '../../components/ui/drawer.component';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {RevenueGroupSelector} from '../selectors/revenue-group-selector.component';
import {PriceInput} from '../ui/price-input.component';
import {ModifierGroupFieldArray} from './modifier-group-field-array.component';
import {
  ModifierFieldset,
  ProductForm,
  ProductUpsertDrawerProps,
  createProductUpsertRequest,
  mapProductToProductForm,
} from './product-upsert-drawer.model';
import {ProductDto, ProductUpsertRequestDto} from '@kontactless/admin-api/product/product.dto';
import {Badge} from '../../common/components/Badge';

const MAX_UPSELLING_ITEMS = 6;
const formValidator = yup.object().shape({
  id: yup.number(),
  name: yup.string().required(),
  description: yup.string().max(255),
  price: yup
    .number()
    .min(0)
    .transform((value) => (isNaN(value) ? null : value))
    .required(),
  taxId: yup.number().nullable(),
  revenueGroupId: yup.number().nullable(),
  calories: yup
    .number()
    .positive()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  needsAgeVerification: yup.boolean().default(false),
  stockCount: yup
    .number()
    .positive()
    .min(0)
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  modifierGroups: yup.array(
    yup.object().shape({
      name: yup.string().required(),
      taxId: yup.number().nullable(),
      minimumRequired: yup
        .number()
        .min(0)
        .transform((value) => (isNaN(value) ? null : value))
        .test({
          message:
            'This value should be less than the count of enabled modifiers in the group and, if defined, less than the maxium allowed',
          test: (value, context) => {
            const {modifiers, maximumAllowed} = context.parent as {modifiers: ModifierFieldset[]; maximumAllowed: number};
            if (!modifiers?.length) {
              // won't validate
              return true;
            }
            if (typeof value === undefined || isNaN(value!)) {
              // can't validate
              return false;
            }
            return value! <= modifiers.filter(({isEnabled}) => isEnabled).length && (!maximumAllowed || value! <= maximumAllowed);
          },
        })
        .required(),
      maximumAllowed: yup
        .number()
        .positive()
        .min(yup.ref('minimumRequired'))
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .test({
          message: 'This value should be at most the count of enabled modifiers in the group',
          test: (value, context) => {
            const {modifiers} = context.parent as {modifiers: ModifierFieldset[]};
            if (!value || isNaN(value) || !modifiers?.length) {
              // won't validate
              return true;
            }
            return value <= modifiers.filter(({isEnabled}) => isEnabled).length;
          },
        }),
      modifiers: yup
        .array(
          yup.object().shape({
            name: yup.string().required(),
            isEnabled: yup.boolean().required(),
            price: yup
              .number()
              .min(0)
              .transform((value) => (isNaN(value) ? null : value))
              .required(),
          })
        )
        .min(1)
        .required(),
    })
  ),
  images: yup
    .array(
      yup.object().shape({
        id: yup.number(),
        url: yup.string().required(),
      })
    )
    .max(3),
  attributes: yup.array(
    yup.object().shape({
      id: yup.number().required(),
    })
  ),
  tags: yup.array(yup.string()),
});

export function ProductUpsertDrawer({
  product,
  menuCategoryId,
  title = 'Create new product',
  submitButtonText = 'Save product',
  onClose: closeDrawer,
  isOpen = false,
  pricesIncludeTax,
  menuUpsellingItemsCount,
}: ProductUpsertDrawerProps) {
  const {user} = useAuth();
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);
  const queryClient = useQueryClient();
  const {
    state: {toaster},
  } = useToasts();
  const [advancedSectionIsExpanded, setAdvancedSectionIsExpanded] = useState(false);

  const {formState, register, handleSubmit, errors, control, reset, clearErrors, setError, watch} = useForm<ProductForm>({
    mode: 'onBlur',
    resolver: yupResolver(formValidator),
  });

  const formMode = product ? 'edit' : 'add';

  useEffect(() => {
    if (product) {
      reset(mapProductToProductForm(product, pricesIncludeTax));
    }
  }, [product, pricesIncludeTax, reset]);

  const upsertProduct = (productUpsertRequest: ProductUpsertRequestDto) => upsertProductRequest(user.token, productUpsertRequest);
  const showToast = (intent: Intent, message: string) => toaster.show({intent, message});

  const productUpsertMutation = useMutation<ProductDto, Error, ProductUpsertRequestDto>(upsertProduct, {
    onSuccess: () => {
      showToast('success', `Product ${formMode === 'add' ? 'created' : 'updated'} succesfully`);
      closeDrawer();
      queryClient.invalidateQueries('menus');
    },
    onError: (error) => {
      console.error(error);
      showToast('danger', `An error ocurred while ${formMode === 'add' ? 'creating' : 'updating'} the product`);
    },
  });

  const submitForm = async (productFormFields: ProductForm) => {
    productUpsertMutation.mutateAsync({
      ...createProductUpsertRequest(
        productFormFields,
        product?.id,
        Number(storeId),
        menuCategoryId,
        product?.isEnabled,
        product?.omnivoreMenuItemId
      ),
    });
  };

  const isFormLoading = productUpsertMutation.isLoading;
  const isOmnivoreProduct = !!product?.omnivoreMenuItemId;

  let productPrice = product?.price;
  let labelPrice = 'Price';
  if (pricesIncludeTax) {
    productPrice = (product?.price || 0) + (product?.taxValue || 0);
    labelPrice = 'Price (tax incl.)';
  }

  const renderGeneralSection = () => (
    <section className="general">
      {product?.isLinked && (
        <Callout intent="primary" className="product-linked" icon={<Icon icon="link" iconSize={12} />}>
          This product is used in multiple menus
        </Callout>
      )}
      <h5>General</h5>
      <FormGroup label="Name" intent={errors.name ? 'danger' : 'none'} helperText={errors.name ? 'Name is required' : ''}>
        <InputGroup
          disabled={isFormLoading}
          name="name"
          defaultValue={product?.name}
          inputRef={register({required: true})}
          intent={errors.name ? 'danger' : 'none'}
        />
      </FormGroup>

      <div className="price-tax-container">
        <FormGroup
          label={labelPrice}
          intent={errors.price ? 'danger' : 'none'}
          helperText={errors.price ? 'Price is required' : ''}
        >
          <Controller
            name="price"
            control={control}
            defaultValue={productPrice?.toString() || '0'}
            render={(props) => (
              <Tooltip2 targetTagName="div" disabled={!isOmnivoreProduct} content="Price is read-only for Omnivore products">
                <PriceInput
                  fill
                  value={props.value}
                  disabled={isFormLoading || isOmnivoreProduct}
                  onChange={(value) => props.onChange(value)}
                />
              </Tooltip2>
            )}
          />
        </FormGroup>

        <FormGroup label="Tax" intent={errors.taxId ? 'danger' : 'none'} helperText={errors.taxId ? 'Tax is required' : ''}>
          <Controller
            name="taxId"
            control={control}
            defaultValue={product?.taxId}
            render={(props) => (
              <TaxSelector
                fill
                storeId={+storeId}
                disabled={isFormLoading}
                selectedItemId={props.value}
                popoverProps={{usePortal: false}}
                onItemSelected={(tax) => props.onChange(tax?.id ?? 0)}
                inputProps={{intent: errors.taxId ? 'danger' : 'none', disabled: isOmnivoreProduct}}
              />
            )}
          />
        </FormGroup>
      </div>

      <FormGroup
        label="Description"
        intent={errors.description ? 'danger' : 'none'}
        helperText={errors.description && 'Description must be no longer than 255 characters'}
      >
        <TextArea
          fill
          name="description"
          disabled={isFormLoading}
          defaultValue={product?.description}
          inputRef={register({required: true})}
          intent={errors.description ? 'danger' : 'none'}
        />
      </FormGroup>

      <FormGroup label="Images">
        <Controller
          name="images"
          control={control}
          defaultValue={product?.images || []}
          render={(props) => (
            <ImageUploader
              multiple
              maxNumber={3}
              disabled={isFormLoading}
              value={(props.value as Array<{url: string}>).map(({url}) => url)}
              onChange={(imagesUrl) => props.onChange(imagesUrl.map((imageUrl) => ({url: imageUrl})))}
            />
          )}
        />
      </FormGroup>
    </section>
  );

  const renderModifiersSection = () => (
    <section>
      <h5>Modifiers</h5>
      <ModifierGroupFieldArray
        isOmnivoreProduct={isOmnivoreProduct}
        errors={errors}
        clearErrors={clearErrors}
        setError={setError}
        control={control}
        register={register}
        isFormLoading={isFormLoading}
        watch={watch}
        storeId={+storeId}
      />
    </section>
  );

  function getCurrentUpsellingCount() {
    const isUpselling = watch('isUpselling');
    const isProductUpselling = product?.isUpselling;
    const delta = isUpselling && !isProductUpselling ? 1 : !isUpselling && isProductUpselling ? -1 : 0;
    return Math.max(0, menuUpsellingItemsCount + delta);
  }

  const renderAdvancedSection = () => (
    <section className={classNames('advanced', {expanded: advancedSectionIsExpanded})}>
      <header onClick={() => setAdvancedSectionIsExpanded(!advancedSectionIsExpanded)}>
        <h5>Advanced</h5>
        <Icon iconSize={24} icon="chevron-down" />
      </header>

      <Collapse isOpen={advancedSectionIsExpanded} keepChildrenMounted>
        <FormGroup label="Calories">
          <InputGroup
            type="number"
            fill
            min={0}
            disabled={isFormLoading}
            defaultValue={product?.calories?.toString()}
            name="calories"
            rightElement={<Tag minimal>cal.</Tag>}
            inputRef={register({valueAsNumber: true})}
          />
        </FormGroup>

        <FormGroup label="Attributes">
          <Controller
            name="attributes"
            control={control}
            defaultValue={product?.attributes ?? []}
            render={(props) => (
              <AttributesSelector
                disabled={isFormLoading}
                selectedAttributesIds={props.value?.map(({id}) => id) ?? []}
                onItemSelect={(attribute) => {
                  if (props.value.map(({id}) => id).includes(attribute.id)) {
                    props.onChange([...props.value.filter(({id}) => id !== attribute.id)]);
                  } else {
                    props.onChange([...props.value, attribute]);
                  }
                }}
                onRemove={(attribute) => {
                  props.onChange([...props.value.filter(({id}) => id !== attribute.id)]);
                }}
              />
            )}
          />
        </FormGroup>

        <FormGroup>
          <div className="tw-flex tw-justify-between tw-items-center">
            <Controller
              name="isUpselling"
              control={control}
              defaultValue={product?.isUpselling}
              render={(props) => (
                <Switch
                  inline
                  disabled={isFormLoading || (menuUpsellingItemsCount === MAX_UPSELLING_ITEMS && !product?.isUpselling)}
                  label="Show this product in upsell offers?"
                  alignIndicator="right"
                  value={props.value}
                  defaultChecked={product?.isUpselling}
                  onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                />
              )}
            />
            <p>
              <span className="tw-mr-1">Currently upselling items</span>
              <Badge className="tw-font-bold" text={`${getCurrentUpsellingCount()}/${MAX_UPSELLING_ITEMS}`} />
            </p>
          </div>
        </FormGroup>
        <FormGroup>
          <Controller
            name="needsAgeVerification"
            control={control}
            defaultValue={product?.needsAgeVerification}
            render={(props) => (
              <Switch
                inline
                disabled={isFormLoading}
                label="Age verification required?"
                alignIndicator="right"
                value={props.value}
                defaultChecked={product?.needsAgeVerification}
                onChange={({currentTarget: {checked}}) => props.onChange(checked)}
              />
            )}
          />
        </FormGroup>

        <FormGroup label="Stock">
          <InputGroup
            type="number"
            min={0}
            fill
            disabled={isFormLoading}
            defaultValue={product?.stockCount?.toString()}
            name="stockCount"
            inputRef={register({valueAsNumber: true})}
            intent={errors.stockCount ? 'danger' : 'none'}
          />
        </FormGroup>

        <FormGroup label="Tags">
          <Controller
            name="tags"
            control={control}
            defaultValue={product?.tags || []}
            render={(props) => (
              <TagInput
                disabled={isFormLoading}
                onChange={props.onChange}
                placeholder="Enter some tags..."
                tagProps={{minimal: true}}
                values={props.value}
              />
            )}
          />
        </FormGroup>

        <FormGroup label="Revenue Group">
          <Controller
            name="revenueGroupId"
            control={control}
            defaultValue={product?.revenueGroupId}
            render={(props) => (
              <RevenueGroupSelector
                storeId={+storeId}
                selectedItemId={props.value}
                disabled={isFormLoading}
                onItemSelected={(revenueGroup) => props.onChange(revenueGroup?.id ?? null)}
              />
            )}
          />
        </FormGroup>
      </Collapse>
    </section>
  );

  return (
    <Drawer
      className="product-upsert-drawer"
      title={title}
      close={closeDrawer}
      isOpen={isOpen}
      confirmClose={formState.isDirty}
      size="600px"
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          {renderGeneralSection()}
          {renderModifiersSection()}
          {renderAdvancedSection()}
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <Button loading={isFormLoading} onClick={handleSubmit(submitForm)} intent="primary" fill large>
          {submitButtonText}
        </Button>
      </div>
    </Drawer>
  );
}
