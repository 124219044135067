import {TaxCreateRequestDto, TaxDto, TaxUpdateRequestDto} from '@kontactless/admin-api/tax/tax.dto';
import {callApi} from './api';

export const fetchAllTaxesRequest = async (token: string, storeId?: number): Promise<TaxDto[]> => {
  return callApi<TaxDto[]>('/taxes', {token, params: {storeId}}).then(({data}) => data);
};

export const createTaxRequest = async (token: string, body: TaxCreateRequestDto): Promise<TaxDto> => {
  return callApi<TaxDto>('/taxes', {token, method: 'POST', body: JSON.stringify(body)}).then(({data}) => data);
};

export const updateTaxRequest = async (token: string, taxId: number, update: TaxUpdateRequestDto): Promise<TaxDto> => {
  return callApi<TaxDto>(`/taxes/${taxId}`, {token, method: 'PATCH', body: JSON.stringify(update)}).then(({data}) => data);
};

export const deleteTaxRequest = async (token: string, taxId: number): Promise<{}> => {
  return callApi<{}>(`/taxes/${taxId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
