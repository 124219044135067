// From Root
export const HOME_SCENE_PATH = '/' as const;
export const LOGIN_SCENE_PATH = '/login' as const;
export const TERMS_OF_USE_SCENE_PATH = '/terms-of-use' as const;
export const PRIVACY_POLICY_SCENE_PATH = '/privacy-policy' as const;
export const CHANGELOG_SCENE_PATH = '/changelog' as const;
export const SUPPORT_SCENE_PATH = '/support' as const;
export const QR_GENERATOR_SCENE_PATH = '/qr' as const;
export const QR_SCANNER_SCENE_PATH = '/qr/scanner' as const;

// From Companies
export const COMPANIES_SCENE_PATH = '/companies' as const;
export const OPERATIONS_PATH = '/companies/:companyId/stores' as const;
export const DASHBOARD_SCENE_PATH = '/companies/:companyId/dashboard' as const;
export const ORDERS_SCENE_PATH = '/companies/:companyId/orders' as const;
export const USERS_SCENE_PATH = '/companies/:companyId/users' as const;
export const HOME_PATH = '/companies/:companyId/home' as const;

// From Store details
export const STORE_DETAILS_SCENE_PATH = '/companies/:companyId/stores/:storeId/:page?' as const;
export const STORE_MENUS_PAGE_PATH = '/companies/:companyId/stores/:storeId/menus' as const;
export const STORE_MENU_PAGE_PATH = '/companies/:companyId/stores/:storeId/menus/:menuId' as const;
export const STORE_SECTIONS_PAGE_PATH = '/companies/:companyId/stores/:storeId/sections' as const;
export const STORE_EVENTS_PAGE_PATH = '/companies/:companyId/stores/:storeId/events' as const;
export const STORE_SECTION_PAGE_PATH = '/companies/:companyId/stores/:storeId/sections/:sectionId' as const;
export const STORE_EVENT_PAGE_PATH = '/companies/:companyId/stores/:storeId/events/:eventId' as const;
export const STORE_SETTINGS_PAGE_PATH = '/companies/:companyId/stores/:storeId/settings' as const;
export const STORE_SETTING_PAGE_PATH = '/companies/:companyId/stores/:storeId/settings/:settingId' as const;
export const STORE_ORDERS_HISTORY_SCENE_PATH = '/companies/:companyId/stores/:storeId/orders/history' as const;
export const STORE_ORDERS_HISTORY_PRINT_VERSION_SCENE_PATH = '/companies/:companyId/stores/:storeId/orders/print-version' as const;
export const STORE_PAYMENT_OPTIONS_PATH = '/companies/:companyId/stores/:storeId/payment-options' as const;
export const STORE_EVENTS_LIST_PAGE_PATH = '/companies/:companyId/stores/:storeId/events-list' as const;
export const ORDERING_METHODS_PATH = '/companies/:companyId/stores/:storeId/ordering-methods' as const;
export const ORDERING_METHODS_DETAILS_PATH = '/companies/:companyId/stores/:storeId/ordering-methods/:orderingMethodId' as const;
export const STORE_ORDERS_PATH = '/companies/:companyId/stores/:storeId/orders' as const;
