import React, {createRef, useMemo} from 'react';

import {Button, InputGroup, MenuItem} from '@blueprintjs/core';
import {Suggest, SuggestProps} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';

import {fetchAllOmnivoreServiceChargesRequest} from '../../api/omnivore.api';
import {useAuth} from '../../contexts/auth.context';
import {OmnivoreServiceChargeDto} from '@kontactless/admin-api/omnivore-service-charge/omnivore-service-charge.dto';

import './selectors.styles.scss';

interface OmnivoreServiceChargeSelectorProps
  extends Omit<SuggestProps<OmnivoreServiceChargeDto>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  storeId: number;
  revenueCenterId?: string | null;
  fill?: boolean;
  selectedItemId?: string;
  onItemSelected: (item: OmnivoreServiceChargeDto | undefined) => void;
}

const OmnivoreServiceChargeSelectorComponent = Suggest.ofType<OmnivoreServiceChargeDto>();

export const OmnivoreServiceChargeSelector: React.FC<OmnivoreServiceChargeSelectorProps> = ({
  storeId,
  revenueCenterId,
  selectedItemId,
  onItemSelected,
  ...props
}) => {
  const {user} = useAuth();
  const serviceChargesQuery = useQuery(['omnivore-service-charges', storeId], () =>
    fetchAllOmnivoreServiceChargesRequest(user.token, storeId)
  );
  const inputRef = createRef<any>();

  const selectedItem = useMemo(() => {
    return serviceChargesQuery.data?.find(({serviceChargeId}) => serviceChargeId === selectedItemId);
  }, [serviceChargesQuery.data, selectedItemId]);

  const getTableLabel = (serviceCharge: OmnivoreServiceChargeDto): string => {
    return `(${serviceCharge.serviceChargeId ?? ''}) - ${serviceCharge.name ?? 'no service charge name'}`;
  };

  if (serviceChargesQuery.isError) {
    return <InputGroup {...(props.inputProps ?? {})} intent="danger" value="Error fetching omnivore tables" readOnly />;
  }

  return (
    <OmnivoreServiceChargeSelectorComponent
      {...props}
      inputValueRenderer={(serviceCharge) => (serviceChargesQuery.isFetching ? '' : getTableLabel(serviceCharge))}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      items={serviceChargesQuery.data ?? []}
      itemRenderer={(serviceCharge, {modifiers, handleClick}) => (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={serviceCharge.serviceChargeId}
          onClick={handleClick}
          text={getTableLabel(serviceCharge)}
        />
      )}
      onItemSelect={(value) => onItemSelected(value)}
      itemsEqual={(e1, e2) => e1.serviceChargeId === e2.serviceChargeId}
      selectedItem={selectedItem ?? null}
      itemPredicate={(query, serviceCharge) =>
        !!serviceCharge.serviceChargeId.toLocaleLowerCase().includes(query.toLowerCase()) ||
        !!serviceCharge.name?.toLocaleLowerCase().includes(query.toLowerCase())
      }
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      inputProps={{
        ...(props.inputProps ?? {}),
        leftElement: serviceChargesQuery.isFetching ? <Button loading minimal /> : undefined,
        placeholder: serviceChargesQuery.isFetching ? 'Loading tables...' : 'Choose one...',
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
      }}
    />
  );
};
