import React from 'react';

import {Button, ButtonProps, MenuItem} from '@blueprintjs/core';
import {Select, SelectProps} from '@blueprintjs/select';
import classNames from 'classnames';

import {LocationTypeDto} from '@kontactless/admin-api/location-type/location-type.dto';
import {useQuery} from 'react-query';
import {useAuth} from '../../contexts/auth.context';
import {fetchLocationTypesRequest} from '../../api/location-type.api';

interface LocationTypeSelectorProps extends Omit<SelectProps<LocationTypeDto>, 'itemRenderer' | 'items'> {
  storeId: number;
  fill?: boolean;
  selectedItemId?: number;
  buttonProps?: ButtonProps;
}

const LocationTypeSelectorComponent = Select.ofType<LocationTypeDto>();

export function LocationTypeSelector({selectedItemId, storeId, buttonProps, ...props}: LocationTypeSelectorProps) {
  const {user} = useAuth();

  const locationTypesQuery = useQuery({
    queryKey: ['stores', storeId, 'location-types'],
    queryFn: () => fetchLocationTypesRequest(user.token, storeId),
    enabled: Boolean(storeId),
  });

  const selectedLocationType = locationTypesQuery.data?.find((locationType) => locationType.id === selectedItemId);

  return (
    <LocationTypeSelectorComponent
      {...props}
      items={locationTypesQuery.data ?? []}
      filterable={false}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      itemRenderer={({id, name}, {modifiers, handleClick}) => (
        <MenuItem
          active={selectedLocationType?.id === id}
          disabled={modifiers.disabled}
          key={id}
          onClick={handleClick}
          text={name}
        />
      )}
      itemsEqual={(t1, t2) => t1 === t2}
    >
      <Button
        {...buttonProps}
        loading={locationTypesQuery.isLoading}
        text={selectedLocationType ? selectedLocationType.name : 'Select a location type'}
        rightIcon="chevron-down"
        fill={props.fill}
      />
    </LocationTypeSelectorComponent>
  );
}
