import React from 'react';

import {Button, ButtonProps, MenuItem} from '@blueprintjs/core';
import {ItemRenderer, Select, SelectProps} from '@blueprintjs/select';
import classNames from 'classnames';

interface SelectorProps extends Omit<SelectProps<any>, 'itemRenderer'> {
  fill?: boolean;
  selectedItem?: any;
  buttonProps?: ButtonProps;
  itemRenderer?: ItemRenderer<any>;
}

const SelectorComponent = Select.ofType<any>();

export const Selector: React.FC<SelectorProps> = ({selectedItem, buttonProps, ...props}) => {
  return (
    <SelectorComponent
      filterable={false}
      itemsEqual={(t1, t2) => t1 === t2}
      itemRenderer={(value, {modifiers, handleClick}) => (
        <MenuItem active={selectedItem === value} disabled={modifiers.disabled} key={value} onClick={handleClick} text={value} />
      )}
      {...props}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{className: 'selector-popover', minimal: true}}
    >
      {props.children ?? (
        <Button text={selectedItem} rightIcon="chevron-down" fill={props.fill} {...buttonProps} minimal outlined />
      )}
    </SelectorComponent>
  );
};
