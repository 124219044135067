import React, {useMemo, useState} from 'react';

import {Button, Menu, MenuItem, Switch, Tag} from '@blueprintjs/core';
import {Popover2, Tooltip2} from '@blueprintjs/popover2';
import {useMutation, useQueryClient} from 'react-query';
import {deleteLocationRequest, updateLocationStateRequest} from '../../api/location.api';
import {useAlerts} from '../../contexts/alerts.context';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {LocationDto, LocationUpdateStateRequestDto} from '@kontactless/admin-api/location/location.dto';
import {StoreSettingsDto} from '@kontactless/admin-api/store-settings/store-settings.dto';
import {SectionDto} from '@kontactless/admin-api/section/section.dto';
import {LocationQrDialog} from './location-qr-dialog.component';
import {LocationUpsertDialog} from './location-upsert-dialog.component';
import {OrderingFlowType} from '@kontactless/utils/types';

import {LocationGroupsDto} from '@kontactless/admin-api/location-groups/location-groups.dto';

interface LocationRowProps {
  sectionId: number;
  storeId: number;
  omnivoreSettings: Pick<
    StoreSettingsDto,
    'omnivoreDineInOrderTypeId' | 'omnivoreEmployeeId' | 'omnivoreTakeawayOrderTypeId' | 'posId'
  > &
    Pick<SectionDto, 'isPosConnected' | 'omnivoreRevenueCenterId'>;
  location: LocationDto;
  locationGroup: LocationGroupsDto;
  orderingFlowType: OrderingFlowType;
  onSwitch?: (location: LocationDto) => void;
  onDelete?: (location: LocationDto) => void;
}

export function LocationRow({
  sectionId,
  storeId,
  omnivoreSettings,
  orderingFlowType,
  location,
  locationGroup,
  onDelete,
  onSwitch,
}: LocationRowProps) {
  const {user} = useAuth();
  const [isLocationQrDialogOpen, setIsLocationQrDialogOpen] = useState(false);
  const {
    state: {toaster},
  } = useToasts();
  const {alertsDispatch} = useAlerts();
  const [isEnabled, setIsEnabled] = useState(location.isEnabled);
  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);

  const deleteLocationMutation = useMutation<void, Error, number>({
    mutationKey: ['delete-location', location.id],
    mutationFn: (location) => deleteLocationRequest(user.token, location),
  });

  const updateLocationStateMutation = useMutation<LocationDto, Error, LocationUpdateStateRequestDto>({
    mutationKey: ['update-location', location.id],
    mutationFn: (locationUpdate) => {
      return updateLocationStateRequest(user.token, locationUpdate, location.id);
    },
  });

  const queryClient = useQueryClient();

  const locationLabel = useMemo(
    () =>
      location.name
        ? location.name
        : location.attributes?.map(
            (attribute) => `${location.locationType!.name} ${attribute.locationTypeAttribute?.name ?? 'type'}: ${attribute.value}`
          ) ?? '',
    [location]
  );

  const omnivoreValidation = useMemo(() => {
    const result: string[] = [];

    if (omnivoreSettings?.posId && omnivoreSettings.isPosConnected) {
      if (!omnivoreSettings.omnivoreEmployeeId) {
        result.push('No employee was selected for the store.');
      }
      if (!omnivoreSettings.omnivoreDineInOrderTypeId) {
        result.push("No 'dine-in' order type was selected for the store.");
      }
      if (!omnivoreSettings.omnivoreTakeawayOrderTypeId) {
        result.push("No 'takeaway' order type was selected for the store.");
      }
      if (!omnivoreSettings.omnivoreRevenueCenterId) {
        result.push('No revenue center was selected for the section.');
      }
      if (!location.omnivoreTableId && !location.omnivoreMenuItemId) {
        result.push('No POS table or menu item was selected for the location.');
      }
    }

    return result;
  }, [location, omnivoreSettings]);

  const onSwitchClick = async (isEnabled: boolean) => {
    try {
      setIsEnabled(isEnabled);
      const updatedLocation = await updateLocationStateMutation.mutateAsync({isEnabled});
      onSwitch?.(updatedLocation);

      toaster.show({intent: 'success', message: 'Location state was updated successfully'});
    } catch (error) {
      console.error(error);
      setIsEnabled(!isEnabled);
      toaster.show({intent: 'danger', message: 'An error ocurred updating the location state'});
    }
  };

  const onDeleteClick = async () => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Delete location "${locationLabel}"?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteLocationMutation.mutateAsync(location.id);
            onDelete?.(location);
            toaster.show({intent: 'success', message: 'Location was deleted successfully'});
            queryClient.invalidateQueries(['section', sectionId]);
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred deleting the location'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between">
        <p>{locationLabel}</p>
        <div className="qr-status-col">
          {omnivoreSettings.isPosConnected && omnivoreValidation.length > 0 && (
            <Tooltip2
              placement="bottom"
              content={
                <>
                  <h1>Omnivore location not properly configured</h1>
                  <ul>
                    {omnivoreValidation.map((error) => (
                      <li key={error}>- {error}</li>
                    ))}
                  </ul>
                </>
              }
            >
              <Tag
                rightIcon={omnivoreValidation.length === 0 ? 'tick' : 'cross'}
                intent={omnivoreValidation.length === 0 ? 'success' : 'danger'}
              >
                Omnivore config
              </Tag>
            </Tooltip2>
          )}
        </div>
        <div className="tw-flex tw-gap-2 tw-items-center">
          <Button
            text="View QR"
            intent="primary"
            icon="new-grid-item"
            minimal
            small
            onClick={() => setIsLocationQrDialogOpen(true)}
          />
          <Switch
            defaultChecked={isEnabled}
            disabled={deleteLocationMutation.isLoading || updateLocationStateMutation.isLoading}
            onChange={({currentTarget: {checked}}) => onSwitchClick(checked)}
          />
          <Popover2
            content={
              <Menu>
                <MenuItem text="Edit" icon="edit" onClick={() => setIsLocationDialogOpen(true)} />
                <MenuItem text="Delete" icon="trash" intent="danger" onClick={onDeleteClick} />
              </Menu>
            }
            position="bottom"
          >
            <Button icon="more" minimal />
          </Popover2>
        </div>
      </div>
      <LocationQrDialog isOpen={isLocationQrDialogOpen} location={location} onClose={() => setIsLocationQrDialogOpen(false)} />
      <LocationUpsertDialog
        sectionId={sectionId}
        storeId={storeId}
        isPosConnected={omnivoreSettings.isPosConnected}
        omnivoreRevenueCenterId={omnivoreSettings.omnivoreRevenueCenterId}
        orderingFlowType={orderingFlowType}
        isOpen={isLocationDialogOpen}
        locationId={location.id}
        onClose={() => setIsLocationDialogOpen(false)}
        locationGroup={locationGroup}
      />
    </>
  );
}
