import React from 'react';

import {HTMLSelect, HTMLSelectProps} from '@blueprintjs/core';

import {UserClaims} from '../../contexts/auth.context';
import {ORDERING_FLOW_TYPES} from '../../utils/constants';
import {getOrderingFlowTypeLabel} from '../../utils/helpers.utils';
import {OrderingFlowType} from '../../utils/types';

interface OrderingFlowTypeSelectorProps extends HTMLSelectProps {
  fill?: boolean;
  selectedItemId?: number;
  user: UserClaims;
  onOrderingFlowTypeSelected: (orderingFlowType: OrderingFlowType) => void;
}

export const OrderingFlowTypeSelector: React.FC<OrderingFlowTypeSelectorProps> = ({
  onOrderingFlowTypeSelected,
  user,
  ...props
}) => {
  const filterOrderingFlowTypes = (orderingFlowType: OrderingFlowType) => {
    if (['single-location'].includes(orderingFlowType) && !['superadmin'].includes(user.securityLevel)) {
      return false;
    }

    return true;
  };

  return (
    <HTMLSelect
      options={ORDERING_FLOW_TYPES.filter(filterOrderingFlowTypes).map((status) => ({
        label: getOrderingFlowTypeLabel(status),
        value: status,
      }))}
      onChange={(event) => onOrderingFlowTypeSelected(event.target.value as OrderingFlowType)}
      {...props}
    />
  );
};
