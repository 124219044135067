import React, {useState} from 'react';

import {Button, Callout, NonIdealState, Spinner} from '@blueprintjs/core';
import {useQuery} from 'react-query';

import {fetchAllUsersRequest} from '../../api/user.api';
import {useAuth} from '../../contexts/auth.context';

import {UserRow} from './UserRow';
import {UserUpsertDialog} from './UserUpsertDialog';

import {UserDto} from '@kontactless/admin-api/user/user.dto';

import {Header} from '../../common/components/layout/Header';
import {useNavigate, useParams} from 'react-router-dom';
import {Container} from '../../common/components/layout/Container';

export function UsersPage() {
  const {user} = useAuth();
  const navigate = useNavigate();
  const params = useParams<{companyId: string}>();
  const companyId = Number(params.companyId);
  const [isUserUpsertDialogOpen, setIsUserUpsertDialogOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<UserDto | undefined>(undefined);

  const usersQuery = useQuery<UserDto[]>({
    queryKey: ['companies', companyId, 'users'],
    queryFn: () => fetchAllUsersRequest(user.token, companyId),
  });

  if (usersQuery.isLoading) {
    return <Spinner />;
  }

  if (usersQuery.isError) {
    return (
      <Callout intent="danger" className="scene-error">
        An error ocurred fetching the users
      </Callout>
    );
  }

  return (
    <>
      <Header>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-gap-4 tw-items-center">
            <Button
              text="Back"
              icon="chevron-left"
              minimal
              small
              className="!tw-bg-blue-100 !tw-rounded-lg"
              onClick={() => navigate('../ordering-methods')}
            />
            <h1 className="tw-text-base tw-font-bold">Users</h1>
          </div>

          <div className="tw-flex tw-gap-4 tw-items-center">
            <Button text="Create user" intent="primary" onClick={() => setIsUserUpsertDialogOpen(true)} />
          </div>
        </div>
      </Header>

      <Container>
        {usersQuery.data?.length === 0 ? (
          <NonIdealState
            icon="settings"
            title="Looks like there are no users yet"
            action={
              <Button
                text="Click here to create your first user"
                intent="primary"
                onClick={() => setIsUserUpsertDialogOpen(true)}
              />
            }
          />
        ) : (
          <div className="tw-bg-white tw-px-6 tw-rounded-lg tw-shadow">
            <table className="tw-min-w-full tw-divide-y tw-divide-solid tw-divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0"
                  >
                    User
                  </th>
                  <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                    Role
                  </th>
                  <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="tw-py-3.5">
                    <span className="tw-sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="tw-divide-y tw-divide-solid tw-divide-gray-200 tw-bg-white">
                {usersQuery.data?.map((user) => (
                  <UserRow
                    companyId={companyId}
                    key={user.id}
                    user={user}
                    onEditClick={(user) => {
                      setUserToEdit(user);
                      setIsUserUpsertDialogOpen(true);
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Container>

      <UserUpsertDialog
        companyId={companyId}
        isOpen={isUserUpsertDialogOpen}
        onClose={() => {
          setIsUserUpsertDialogOpen(false);
          setUserToEdit(undefined);
        }}
        user={userToEdit}
      />
    </>
  );
}
