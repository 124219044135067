import {useEffect} from 'react';
import {Button, Dialog, DialogProps, FormGroup, InputGroup, Radio, Switch, Tag} from '@blueprintjs/core';
import {TipsConfigurationDto, TipsConfigurationUpsertDto} from '@kontactless/admin-api/tips-configuration/tips-configuration.dto';
import {cn} from '../../common/utils';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import {createTipsConfigurationRequest, updateTipsConfigurationRequest} from '../tip-configurations.api';
import {useAuth} from '../../contexts/auth.context';

export interface TipsForm {
  tip1: number;
  tip2: number;
  tip3: number;
  defaultTipOption: 1 | 2 | 3;
  isDefaultTipOptionFixed: boolean;
  name: string;
}

interface UpsertTipDialogProps extends DialogProps {
  storeId: number;
  tipConfiguration?: TipsConfigurationDto;
  onTipConfigurationUpserted?: (tipConfiguration: TipsConfigurationDto) => void;
}

export function UpsertTipConfigurationDialog({
  storeId,
  tipConfiguration,
  onTipConfigurationUpserted,
  ...dialogProps
}: UpsertTipDialogProps) {
  const {user} = useAuth();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: {errors},
  } = useForm<TipsForm>({
    defaultValues: {
      name: 'New tip',
      tip1: 10,
      tip2: 20,
      tip3: 30,
      defaultTipOption: 1,
      isDefaultTipOptionFixed: false,
    },
  });

  const formMode = tipConfiguration ? 'edit' : 'create';

  useEffect(() => {
    if (formMode === 'edit') {
      reset({
        name: tipConfiguration?.name,
        tip1: (tipConfiguration?.tip1 ?? 0) * 100,
        tip2: (tipConfiguration?.tip2 ?? 0) * 100,
        tip3: (tipConfiguration?.tip3 ?? 0) * 100,
        defaultTipOption: tipConfiguration?.defaultTipOption ?? 1,
        isDefaultTipOptionFixed: tipConfiguration?.isDefaultTipOptionFixed,
      });
    }
  }, [tipConfiguration, reset, formMode]);

  const onSuccess = (tipConfiguration: TipsConfigurationDto) => {
    dialogProps.onClose?.(undefined as any);
    queryClient.invalidateQueries(['stores', storeId, 'tips-configurations']);
    onTipConfigurationUpserted?.(tipConfiguration);
  };

  const createTipConfigurationMutation = useMutation<TipsConfigurationDto, Error, TipsConfigurationUpsertDto>({
    mutationKey: ['create-tip-configuration'],
    mutationFn: (payload) => createTipsConfigurationRequest(user.token, payload),
    onSuccess,
  });

  const updateTipConfigurationMutation = useMutation<TipsConfigurationDto, Error, TipsConfigurationUpsertDto>({
    mutationKey: ['update-tip-configuration'],
    mutationFn: (payload) => updateTipsConfigurationRequest(user.token, tipConfiguration!.id, payload),
    onSuccess,
  });

  const submit = (form: TipsForm) => {
    const payload = {
      ...form,
      tip1: Number((form.tip1 / 100).toFixed(2)),
      tip2: Number((form.tip2 / 100).toFixed(2)),
      tip3: Number((form.tip3 / 100).toFixed(2)),
      storeId: storeId,
    };

    if (formMode === 'create') {
      createTipConfigurationMutation.mutate(payload);
    } else {
      updateTipConfigurationMutation.mutate(payload);
    }
  };

  const isUpsertingTipConfiguration = createTipConfigurationMutation.isLoading || updateTipConfigurationMutation.isLoading;

  const isDefaultTipOptionFixed = watch('isDefaultTipOptionFixed');
  const defaultTipOption = watch('defaultTipOption');
  return (
    <Dialog {...dialogProps} title={formMode === 'create' ? 'Create tip' : 'Edit tip'} className="!tw-pb-0">
      <div className="tw-w-full tw-p-6">
        <FormGroup label="Name" helperText={errors.name && 'Name is required'} intent={errors.name ? 'danger' : 'none'}>
          <InputGroup
            type="text"
            inputRef={register({required: true})}
            readOnly={!formMode}
            name="name"
            required
            intent={errors.name ? 'danger' : 'none'}
            fill
          />
        </FormGroup>
        <Controller
          name="defaultTipOption"
          control={control}
          render={(props) => (
            <div className="tw-flex tw-flex-col tw-gap-4">
              <div
                className={cn(
                  'tw-flex tw-items-center tw-gap-2',
                  isDefaultTipOptionFixed && defaultTipOption !== 1 && 'tw-hidden'
                )}
              >
                <Radio
                  checked={props.value === 1}
                  onChange={(e) => props.onChange(1)}
                  name={props.name}
                  className="tw-text-gray-500 !tw-mb-0 tw-text-xs"
                  value={1}
                  label="Tip #1"
                />
                <InputGroup
                  className="tw-w-20"
                  name="tip1"
                  type="number"
                  rightElement={<Tag minimal>%</Tag>}
                  inputRef={register({required: true, validate: (value) => value > 0})}
                  intent={errors.tip1 ? 'danger' : 'none'}
                />
              </div>
              <div
                className={cn(
                  'tw-flex tw-items-center tw-gap-2',
                  isDefaultTipOptionFixed && defaultTipOption !== 2 && 'tw-hidden'
                )}
              >
                <Radio
                  checked={props.value === 2}
                  onChange={(e) => props.onChange(2)}
                  name={props.name}
                  className="tw-text-gray-500 !tw-mb-0  tw-text-xs"
                  value={2}
                  label="Tip #2"
                />
                <InputGroup
                  className="tw-w-20"
                  name="tip2"
                  type="number"
                  rightElement={<Tag minimal>%</Tag>}
                  inputRef={register({required: true, validate: (value) => value > 0})}
                  intent={errors.tip2 ? 'danger' : 'none'}
                />
              </div>
              <div
                className={cn(
                  'tw-flex tw-items-center tw-gap-2',
                  isDefaultTipOptionFixed && defaultTipOption !== 3 && 'tw-hidden'
                )}
              >
                <Radio
                  checked={props.value === 3}
                  onChange={(e) => props.onChange(3)}
                  name={props.name}
                  className="tw-text-gray-500 !tw-mb-0  tw-text-xs"
                  value={3}
                  label="Tip #3"
                />
                <InputGroup
                  className="tw-w-20"
                  name="tip3"
                  type="number"
                  rightElement={<Tag minimal>%</Tag>}
                  inputRef={register({required: true, validate: (value) => value > 0})}
                  intent={errors.tip3 ? 'danger' : 'none'}
                />
              </div>
            </div>
          )}
        />
        <div className="tw-my-4 tw-flex tw-items-center tw-gap-2">
          <Controller
            name="isDefaultTipOptionFixed"
            control={control}
            render={(props) => (
              <Switch
                label="Fix tip value"
                checked={props.value}
                onChange={(e) => props.onChange((e.target as HTMLInputElement).checked)}
                name="isDefaultTipOptionFixed"
              />
            )}
          />
          <p className="tw-text-gray-500 tw-text-xs">
            {watch('isDefaultTipOptionFixed') ? `Customers won't be able to change it` : `Customer is able to choose the tip`}
          </p>
        </div>
        <div className="tw-flex tw-justify-end tw-gap-4">
          <Button text="Cancel" minimal disabled={isUpsertingTipConfiguration} onClick={dialogProps.onClose} />
          <Button
            text={formMode === 'create' ? '+Create' : 'Edit'}
            type="submit"
            intent="primary"
            disabled={isUpsertingTipConfiguration}
            onClick={handleSubmit(submit)}
          />
        </div>
      </div>
    </Dialog>
  );
}
