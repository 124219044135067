import React from 'react';

import {Button, Callout, Classes, HTMLTable, Menu, MenuItem, Popover, Spinner} from '@blueprintjs/core';
import {capitalize} from 'lodash';
import {DateTime} from 'luxon';
import {useMutation, useQuery} from 'react-query';

import {deletePrintJobRequest, fetchAllPrintJobs, updatePrintJobRequest} from '../../api/print-job.api';
import {useAlerts} from '../../contexts/alerts.context';
import {useAuth} from '../../contexts/auth.context';
import {useCache} from '../../contexts/cache/cache.context';
import {useToasts} from '../../contexts/toasts.context';

import {PrintJobDto, PrintJobUpdateRequestDto} from '@kontactless/admin-api/print-job/print-job.dto';

type PrintJobListColumns = 'createdAt' | 'orderId' | 'status' | 'printerName' | 'error' | 'actions';

interface PrintJobListProps {
  storeId: number;
  columns?: PrintJobListColumns[];
  onPrintJobUpdated?: (printJob: PrintJobDto) => void;
  onPrintJobDeleted?: (printJob: PrintJobDto) => void;
}

export const PrintJobList: React.FC<PrintJobListProps> = ({
  storeId,
  columns = ['createdAt', 'orderId', 'status', 'printerName', 'error', 'actions'],
  ...props
}) => {
  const {user} = useAuth();
  const {data: printJobs, isFetching: isFetchingPrintJobs} = useQuery(
    ['print-jobs', storeId],
    () => fetchAllPrintJobs(user.token, storeId),
    {refetchInterval: 1000 * 30}
  );

  const {
    state: {toaster},
  } = useToasts();
  const {alertsDispatch} = useAlerts();

  const {dispatchCacheAction} = useCache();

  const updatePrintJobMutation = useMutation<PrintJobDto, Error, {update: PrintJobUpdateRequestDto; printJobId: number}>(
    ({update, printJobId}) => updatePrintJobRequest(user.token, update, printJobId)
  );

  const deletePrintJobMutation = useMutation<{}, Error, number>((printJobId) => deletePrintJobRequest(user.token, printJobId));

  const onRetryPrintJobActionClick = async (printJob: PrintJobDto) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Do you want to retry the execution of this print job?`,
        intent: 'warning',
        icon: 'warning-sign',
        confirmButtonText: 'Yes, I do',
        cancelButtonText: `No, I don't`,
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            const updatedPrintJob = await updatePrintJobMutation.mutateAsync({
              update: {...printJob, status: 'pending'},
              printJobId: printJob.id,
            });
            props.onPrintJobUpdated?.(printJob);
            dispatchCacheAction({type: 'update-print-job', printJob: updatedPrintJob});

            toaster.show({intent: 'success', message: 'Print job was set to "Pending" and will be executed again.'});
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred while retrying the print job'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  const onDeletePrintJobActionClick = async (printJob: PrintJobDto) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Do you want to delete the print job for order #${printJob.order?.number}?. This action can't be undone.`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Yes, delete it',
        cancelButtonText: `No, I don't`,
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deletePrintJobMutation.mutateAsync(printJob.id);
            props.onPrintJobDeleted?.(printJob);
            dispatchCacheAction({type: 'remove-print-job', printJob});

            toaster.show({intent: 'success', message: 'Print job was deleted successfully'});
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error while deleting the print job'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  const getRelativeDate = (printJob: PrintJobDto) => DateTime.fromISO(printJob.createdAt!).toRelative();

  return (
    <>
      {printJobs?.length ? (
        <HTMLTable className="print-jobs-table">
          <thead>
            <tr>
              {columns.includes('createdAt') && (
                <th title="Created">
                  <span>Created</span>
                </th>
              )}
              {columns.includes('orderId') && (
                <th title="Order">
                  <span>Order</span>
                </th>
              )}
              {columns.includes('status') && (
                <th title="Status">
                  <span>Status</span>
                </th>
              )}
              {columns.includes('printerName') && (
                <th title="Printer">
                  <span>Printer</span>
                </th>
              )}
              {columns.includes('error') && (
                <th title="Error">
                  <span>Error</span>
                </th>
              )}
              {columns.includes('actions') && <th className="actions">{isFetchingPrintJobs && <Spinner size={15} />}</th>}
            </tr>
          </thead>
          <tbody>
            {printJobs?.map((printJob) => (
              <tr key={printJob.id}>
                {columns.includes('createdAt') && (
                  <td title={getRelativeDate(printJob)!}>
                    <span>{getRelativeDate(printJob)}</span>
                  </td>
                )}
                {columns.includes('orderId') && (
                  <td>
                    <span>#{printJob.order?.number}</span>
                  </td>
                )}
                {columns.includes('status') && (
                  <td>
                    <span>{capitalize(printJob.status)}</span>
                  </td>
                )}
                {columns.includes('printerName') && (
                  <td title={printJob.printer?.name}>
                    <span>{printJob.printer?.name}</span>
                  </td>
                )}
                {columns.includes('error') && (
                  <td title={printJob.error.message}>
                    <span>{printJob.error.message}</span>
                  </td>
                )}
                {columns.includes('actions') && (
                  <td className="actions">
                    <Popover className={Classes.POPOVER_CONTENT_SIZING} enforceFocus={false}>
                      <Button minimal icon="more" intent="none" title="Actions" disabled={deletePrintJobMutation.isLoading} />
                      <Menu key="menu">
                        <MenuItem onClick={() => onRetryPrintJobActionClick(printJob)} icon="reset" text="Retry" />
                        <MenuItem
                          onClick={() => onDeletePrintJobActionClick(printJob)}
                          icon="trash"
                          text="Delete"
                          intent="danger"
                        />
                      </Menu>
                    </Popover>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      ) : (
        <Callout>There are no print jobs in the queue for this store.</Callout>
      )}
    </>
  );
};
