import React from 'react';

import {Button, ButtonGroup} from '@blueprintjs/core';
import {useMutation} from 'react-query';

import {deleteRevenueGroupRequest, updateRevenueGroupRequest} from '../../api/revenue-group.api';
import {useAlerts} from '../../contexts/alerts.context';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {RevenueGroupDto, RevenueGroupUpsertRequestDto} from '@kontactless/admin-api/revenue-group/revenue-group.dto';

interface RevenueGroupRowProps {
  revenueGroup: RevenueGroupDto;
  onEdit?: (revenueGroup: RevenueGroupDto) => void;
  onDelete?: (revenueGroup: RevenueGroupDto) => void;
}

export const RevenueGroupRow: React.FC<RevenueGroupRowProps> = ({revenueGroup, onEdit, onDelete}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {alertsDispatch} = useAlerts();

  const deleteRevenueGroupMutation = useMutation<{}, Error, number>((revenueGroupId) =>
    deleteRevenueGroupRequest(user.token, revenueGroupId)
  );
  const updateRevenueGroupMutation = useMutation<RevenueGroupDto, Error, RevenueGroupUpsertRequestDto>((update) =>
    updateRevenueGroupRequest(user.token, update, revenueGroup.id)
  );

  const onDeleteClick = async () => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Delete revenue group "${revenueGroup.name}"?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteRevenueGroupMutation.mutateAsync(revenueGroup.id);
            onDelete?.(revenueGroup);

            toaster.show({intent: 'success', message: 'RevenueGroup was deleted successfully'});
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred deleting the revenue group'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  return (
    <div className="revenue-group-row">
      <div className="name-col">
        <p>
          <span className="tw-text-gray-500">Name</span>
          <br />
          {revenueGroup.name}
        </p>
      </div>
      <div className="actions-col">
        <ButtonGroup minimal>
          <Button
            className="!tw-text-blue-600"
            text="Edit"
            intent="none"
            disabled={deleteRevenueGroupMutation.isLoading || updateRevenueGroupMutation.isLoading}
            onClick={() => onEdit?.(revenueGroup)}
          />
          <Button
            className="!tw-text-red-400"
            icon="trash"
            intent="danger"
            loading={deleteRevenueGroupMutation.isLoading}
            disabled={deleteRevenueGroupMutation.isLoading || updateRevenueGroupMutation.isLoading}
            onClick={onDeleteClick}
          />
        </ButtonGroup>
      </div>
    </div>
  );
};
