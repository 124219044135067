import React from 'react';
import {Button, Callout, FormGroup, Spinner} from '@blueprintjs/core';
import {DateTime} from 'luxon';
import {useQuery} from 'react-query';
import {fetchAllOrdersPdfRequest} from '../../api/order.api';
import {useAuth} from '../../contexts/auth.context';
import {OrderItemRow} from '../components/order-item-row.component';
import {getCustomerName, sortOrderItems} from '../../utils/helpers.utils';
import {OrderStatusBadge} from '../../components/ui/OrderStatusBadge';
import {formatPhoneNumber} from 'react-phone-number-input';
import useQuerystring from '../../hooks/useQueryString';
import {useStoreQuery} from '../../api/store.api';
import {useParams} from 'react-router-dom';

export function OrderHistoryPrintVersionPage() {
  const {user} = useAuth();
  const {dateEnd, dateStart, eventId} = useQuerystring<{dateStart: string; dateEnd: string; eventId?: string}>();

  const params = useParams<{companyId: string; storeId: string}>();

  const {data: store} = useStoreQuery(Number(params.storeId));
  const timezone = store?.timezone || 'America/Los_Angeles';

  const ordersQuery = useQuery(
    [
      'orders',
      {
        storeId: params.storeId,
        dateStart,
        dateEnd,
        eventId,
      },
    ],
    () =>
      fetchAllOrdersPdfRequest(user.token, {
        storeId: Number(params.storeId),
        dateStart,
        dateEnd,
        eventId: eventId ? +eventId : undefined,
      }),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const renderContent = () => {
    if (ordersQuery.isLoading) {
      return <Spinner intent="primary" />;
    }

    if (ordersQuery.isError) {
      return (
        <Callout className="scene-error" intent="danger">
          An error ocurred fetching the orders
        </Callout>
      );
    }

    const renderLocationName = (order) => (
      <>
        <FormGroup label="Section" className="name">
          {order.location?.section?.name}
        </FormGroup>

        <FormGroup label="Location" className="name">
          {order.location?.name}
        </FormGroup>
      </>
    );

    if (ordersQuery.data) {
      return (
        <>
          {ordersQuery?.data.data.map((order) => (
            <div key={order.id} className="kl-dialog order-details-dialog">
              <div className="dialog-title">
                <label>Order #{order?.number}</label>
                <p>{order ? DateTime.fromISO(order.createdAt).setZone(timezone).toFormat('d/MM/yyyy, h:mm:ss a ZZZZ') : ''}</p>
              </div>
              <div>
                <h4 className="section-title">Order details</h4>
                <div className="location-section">
                  <div className="left-content">
                    {!!order.location && renderLocationName(order)}
                    {!!order.event && (
                      <FormGroup label="Event" className="name">
                        {order.event?.name}
                      </FormGroup>
                    )}

                    <FormGroup label="Customer">
                      {getCustomerName(order?.customer) ?? 'No name'}
                      <br />
                      {!!order.customer?.phone && formatPhoneNumber(order.customer.phone)}
                    </FormGroup>
                  </div>
                  <div className="right-content">
                    <FormGroup label="Status">
                      {order.status && (
                        <>
                          <OrderStatusBadge status={order.status} />
                          <FormGroup className="status-updated-at" label="Status Updated At">
                            {DateTime.fromISO(order.statusUpdatedAt).toFormat('MM/dd/yy h:mm a')}
                          </FormGroup>
                        </>
                      )}
                    </FormGroup>
                    {!!order.scheduleDate && (
                      <FormGroup label="Scheduled for">
                        {DateTime.fromISO(order.scheduleDate).setZone(timezone).toFormat('d/MM/yy, h:mm a ZZZZ')}
                      </FormGroup>
                    )}
                    {order?.customerNotes && (
                      <FormGroup label="Customer Notes">
                        <i>{order.customerNotes}</i>
                      </FormGroup>
                    )}
                    {order?.type === 'takeaway' && (
                      <>
                        <FormGroup label="Pickup info">
                          <p>{order.pickupName}</p>
                          <p className="pickup-info">{order.pickupInfo}</p>
                        </FormGroup>
                      </>
                    )}
                  </div>
                </div>

                <h4 className="section-title">Items & Cost</h4>
                <div
                  className="order-items-list"
                  style={{
                    borderBottom: '1px solid #e5e5e5',
                    marginBottom: '1rem',
                  }}
                >
                  {sortOrderItems(order?.items ?? [])?.map((item) => <OrderItemRow key={item.id} item={item} />) ?? null}
                  <OrderItemRow item={{quantity: 1, type: 'total', title: 'Total', subtotal: order?.total ?? 0}} />
                </div>
              </div>
            </div>
          ))}
        </>
      );
    }
  };

  const [yearStart, monthStart, dayStart] = dateStart.split('T')[0].split('-');
  const [hourStart, minuteStart] = dateStart.split('T')[1].split(':');
  const [yearEnd, monthEnd, dayEnd] = dateEnd.split('T')[0].split('-');
  const [hourEnd, minuteEnd] = dateEnd.split('T')[1].split(':');

  return (
    <div className="scene scene-content orders-history-scene">
      <section className="card" id="orders-history">
        <header>
          <h1 className="scene-title">Orders History</h1>
          <h2>Store name</h2>
          <p style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            {DateTime.fromISO(dateStart, {zone: timezone})
              .set({
                year: +yearStart,
                month: +monthStart,
                day: +dayStart,
                hour: +hourStart,
                minute: +minuteStart,
              })
              .toFormat('MM/dd/yy h:mm a ZZZZ')}{' '}
            -{' '}
            {DateTime.fromISO(dateEnd, {zone: timezone})
              .set({
                year: +yearEnd,
                month: +monthEnd,
                day: +dayEnd,
                hour: +hourEnd,
                minute: +minuteEnd,
              })
              .toFormat('MM/dd/yy h:mm a ZZZZ')}
            <div className="no-print">
              <Button intent="primary" icon="print" text="Print" onClick={() => window.print()} />
            </div>
          </p>
        </header>
        <div style={{borderTop: '1px solid #e5e5e5', margin: '1rem 0'}} />
        <div>{renderContent()}</div>
      </section>
    </div>
  );
}
