import {TipsConfigurationDto, TipsConfigurationUpsertDto} from '@kontactless/admin-api/tips-configuration/tips-configuration.dto';
import {callApi} from '../api/api';

export const fetchAllTipsConfigurationsRequest = async (token: string, storeId?: number): Promise<TipsConfigurationDto[]> => {
  return callApi<TipsConfigurationDto[]>(`/tips-configurations`, {token, params: {storeId}}).then(({data}) => data);
};

export const updateTipsConfigurationRequest = async (
  token: string,
  tipsConfigurationId: number,
  payload: TipsConfigurationUpsertDto
): Promise<TipsConfigurationDto> => {
  return callApi<TipsConfigurationDto>(`/tips-configurations/${tipsConfigurationId}`, {
    token,
    method: 'PATCH',
    body: JSON.stringify(payload),
  }).then(({data}) => data);
};

export const createTipsConfigurationRequest = async (
  token: string,
  tipsConfiguration: TipsConfigurationUpsertDto
): Promise<TipsConfigurationDto> => {
  return callApi<TipsConfigurationDto>(`/tips-configurations`, {
    token,
    method: 'POST',
    body: JSON.stringify(tipsConfiguration),
  }).then(({data}) => data);
};

export const deleteTipsConfigurationRequest = async (token: string, tipsConfigurationId: number) => {
  return callApi<TipsConfigurationDto>(`/tips-configurations/${tipsConfigurationId}`, {
    token,
    method: 'DELETE',
  });
};
