import {CreateImageOptions} from '@kontactless/admin-api/image/image.dto';
import {callApi} from './api';

export async function createImageRequest(token: string, file: File, options: CreateImageOptions): Promise<{imageUrl: string}> {
  const body = new FormData();
  body.append('image', file);

  return callApi<{imageUrl: string}>(`/images`, {
    token,
    method: 'POST',
    isMultipart: true,
    body,
    params: {...options},
  }).then(({data}) => data);
}
