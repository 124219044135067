import React, {useMemo, useState} from 'react';

import {Button, Classes, Drawer, FormGroup, IDialogProps} from '@blueprintjs/core';
import classNames from 'classnames';
import {sortBy, uniq} from 'lodash';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {ApiError} from '../../api/api';
import {addOmnivoreMenuItemToMenuCategoryRequest, fetchAllOmnivoreCategoriesRequest} from '../../api/omnivore.api';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {MenuCategoryDto} from '@kontactless/admin-api/menu-category/menu-category.dto';
import {OmnivoreCategorySelector} from '../selectors/omnivore-category-selector.component';
import {ScreenNumberSelector} from '../selectors/omnivore-screen-number-selector.component';
import {MenuCategoryProductDto} from '@kontactless/admin-api/menu-category-product/menu-category-product.dto';
import {OmnivoreCategoryDto} from '@kontactless/admin-api/omnivore-category/omnivore-category.dto';
import {OmnivoreMenuItemDto} from '@kontactless/admin-api/omnivore-menu-item/omnivore-menu-item.dto';
import {fetchStoreRequest} from '../../api/store.api';
import {getPriceText, getScreenNumberFromOmnivoreId} from '../../utils/helpers.utils';

export interface OmnivoreCategoriesDrawerProps extends IDialogProps {
  storeId?: number;
  menuCategory?: MenuCategoryDto;
}

export const OmnivoreCategoriesDrawer: React.FC<OmnivoreCategoriesDrawerProps> = ({storeId, menuCategory, ...drawerProps}) => {
  const {user} = useAuth();
  const [selectedCategory, setSelectedCategory] = useState<OmnivoreCategoryDto | undefined>();
  const [selectedScreenNumber, setSelectedScreenNumber] = useState<number | undefined>();

  const categoriesQuery = useQuery({
    queryKey: ['stores', storeId, 'omnivore-categories'],
    queryFn: () => fetchAllOmnivoreCategoriesRequest(user.token, storeId!),
    enabled: !!storeId,
  });

  const storeQuery = useQuery({
    queryKey: ['stores', storeId],
    queryFn: () => fetchStoreRequest(user.token, storeId!),
  });

  const storeOmnivoreCategoryIds = storeQuery.data?.settings?.omnivoreCategories;

  const storeOmnivoreCategories = categoriesQuery.data?.filter(({categoryId}) => storeOmnivoreCategoryIds?.includes(categoryId));

  const allScreenNumbers = useMemo(() => {
    const allMenuItems = storeOmnivoreCategories?.flatMap(({menuItems}) => menuItems ?? []) ?? [];

    const screenNumbers = allMenuItems
      .map(({menuItemId}) => getScreenNumberFromOmnivoreId(menuItemId))
      .filter((screenNumber) => screenNumber != null)
      .map((menuItemId) => +menuItemId!);

    return sortBy(uniq(screenNumbers));
  }, [storeOmnivoreCategories]);

  const menuItems = useMemo(
    () =>
      selectedCategory?.menuItems?.filter(
        ({menuItemId}) => selectedScreenNumber == null || +getScreenNumberFromOmnivoreId(menuItemId)! === selectedScreenNumber
      ) ?? [],
    [selectedCategory, selectedScreenNumber]
  );

  return (
    <Drawer
      {...drawerProps}
      title={`Add product to ${menuCategory?.name}`}
      className={classNames('omnivore-categories-drawer', drawerProps.className)}
      size="600px"
    >
      <div className={Classes.DRAWER_BODY}>
        <header className="omnivore-drawer-header">
          <p>
            This list includes all of the items that have been pulled from the POS. Please create your menu using these items.
          </p>

          <div className="drawer-controls">
            <FormGroup label="Category">
              <OmnivoreCategorySelector
                storeId={storeId!}
                onItemSelected={setSelectedCategory}
                selectedItemId={selectedCategory?.categoryId}
                screenNumber={selectedScreenNumber}
                storeOmnivoreCategories={storeOmnivoreCategories}
                isLoading={categoriesQuery.isLoading}
                isError={categoriesQuery.isError}
                autoSelect
              />
            </FormGroup>
            {allScreenNumbers.length ? (
              <FormGroup label="Screen number">
                <ScreenNumberSelector
                  items={allScreenNumbers}
                  itemSelected={selectedScreenNumber}
                  onItemSelected={setSelectedScreenNumber}
                  fill
                />
              </FormGroup>
            ) : null}
          </div>
        </header>

        {storeId && menuCategory ? (
          <div className="omnivore-menu-items-list">
            {menuItems.map((menuItem) => (
              <OmnivoreMenuItemRow key={menuItem.menuItemId} storeId={storeId} menuCategory={menuCategory} menuItem={menuItem} />
            )) ?? null}
          </div>
        ) : null}
      </div>
    </Drawer>
  );
};

interface OmnivoreMenuItemRowProps {
  storeId: number;
  menuCategory: MenuCategoryDto;
  menuItem: OmnivoreMenuItemDto;
}

const OmnivoreMenuItemRow: React.FC<OmnivoreMenuItemRowProps> = ({menuItem, menuCategory, storeId}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const queryClient = useQueryClient();
  const addOmnivoreMenuItemToMenuCategoryMutation = useMutation<MenuCategoryProductDto, ApiError, OmnivoreMenuItemDto>(
    (menuItem) =>
      addOmnivoreMenuItemToMenuCategoryRequest(user.token, {
        storeId,
        menuCategoryId: menuCategory.id,
        menuItemId: menuItem.menuItemId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['menus', menuCategory.menuId]);
        toaster.show({intent: 'success', message: `"${menuItem.name}" was successfully added to "${menuCategory.name}"`});
      },
      onError: (error, menuItem) => {
        toaster.show({intent: 'danger', message: `An error ocurred adding "${menuItem.name}" to "${menuCategory.name}"`});
      },
    }
  );

  const isLoading = addOmnivoreMenuItemToMenuCategoryMutation.isLoading;

  const isMenuItemDisabled = (menuItem: OmnivoreMenuItemDto): boolean => {
    return (
      menuItem.pricePerUnit == null ||
      !!menuCategory?.menuCategoryProducts?.find(({product}) => product?.omnivoreMenuItemId === menuItem.menuItemId) ||
      isLoading
    );
  };

  return (
    <div key={menuItem.menuItemId} className={classNames('omnivore-menu-item', {disabled: isMenuItemDisabled(menuItem)})}>
      <Button
        icon="circle-arrow-left"
        text="Add"
        intent="primary"
        disabled={isMenuItemDisabled(menuItem)}
        onClick={() => addOmnivoreMenuItemToMenuCategoryMutation.mutate(menuItem)}
        loading={isLoading}
        minimal
      />
      <h2 className="menu-item-name">{menuItem.name}</h2>
      <h2 className="menu-item-price">{menuItem.pricePerUnit != null ? getPriceText(menuItem.pricePerUnit / 100) : ''}</h2>
    </div>
  );
};
