import React, {createRef, useMemo, useState} from 'react';

import {Button, ButtonProps, MenuItem, Tag} from '@blueprintjs/core';
import {Suggest, SuggestProps} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery, useQueryClient} from 'react-query';

import {TaxDto} from '@kontactless/admin-api/tax/tax.dto';

import {fetchAllTaxesRequest} from '../../api/tax.api';
import {useAuth} from '../../contexts/auth.context';
import {TaxUpsertDialog} from '../taxes/tax-upsert-dialog.component';

import './selectors.styles.scss';

interface TaxSelectorProps extends Omit<SuggestProps<TaxDto>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  fill?: boolean;
  selectedItemId?: number;
  storeId: number;
  onItemSelected: (item: TaxDto) => void;
  buttonProps?: ButtonProps;
}

const TaxSelectorComponent = Suggest.ofType<TaxDto>();

export const TaxSelector: React.FC<TaxSelectorProps> = ({selectedItemId, onItemSelected, buttonProps, storeId, ...props}) => {
  const {user} = useAuth();
  const queryClient = useQueryClient();
  const [isTaxUpsertDialogOpen, setIsTaxUpsertDialogOpen] = useState(false);
  const taxesQuery = useQuery(['taxes', +storeId], () => fetchAllTaxesRequest(user.token, +storeId));
  const inputRef = createRef<any>();

  const items = useMemo(() => {
    const noTaxItem: TaxDto = {id: 0, name: 'No Tax', percentage: 0, storeId};
    const createTaxItem: TaxDto = {id: -1, name: 'Create Tax', percentage: 0, storeId};

    return [noTaxItem, ...(taxesQuery.data ?? []), createTaxItem];
  }, [storeId, taxesQuery]);

  const selectedItem = useMemo(() => {
    if (selectedItemId == null) {
      return items[0];
    }

    return items.find(({id}) => id === selectedItemId);
  }, [items, selectedItemId]);

  return (
    <>
      <TaxSelectorComponent
        {...props}
        inputValueRenderer={(tax) => tax.name}
        className={classNames(props.className, {'bp3-fill': props.fill})}
        items={items}
        itemRenderer={(tax, {modifiers, handleClick}) => (
          <MenuItem
            active={selectedItemId === tax.id || (selectedItemId == null && tax.id === 0)}
            disabled={modifiers.disabled}
            key={tax.id}
            icon={tax.id === -1 ? 'add' : undefined}
            text={
              <div className="tax-menu-item">
                <span>{tax.name}</span>
                {tax.id !== -1 ? <span>{tax.percentage}%</span> : null}
              </div>
            }
            onClick={(ev) => {
              if (tax.id === -1) {
                setIsTaxUpsertDialogOpen(true);
              } else {
                handleClick(ev);
              }
            }}
          />
        )}
        popoverProps={{minimal: true}}
        onItemSelect={(value) => onItemSelected(value)}
        itemsEqual={(c1, c2) => c1.id === c2.id}
        selectedItem={selectedItem ?? null}
        itemPredicate={(query, c1) => c1.name.toLocaleLowerCase().includes(query.toLowerCase())}
        noResults={<MenuItem disabled={true} text="No results." />}
        ref={inputRef}
        inputProps={{
          ...(props.inputProps ?? {}),
          leftElement: taxesQuery.isLoading ? <Button minimal loading /> : undefined,
          placeholder: 'Choose one...',
          rightElement: selectedItem ? (
            <Tag minimal children={`${selectedItem.percentage}%`} />
          ) : (
            <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />
          ),
        }}
      />
      <TaxUpsertDialog
        isOpen={isTaxUpsertDialogOpen}
        storeId={storeId}
        onClose={() => {
          setIsTaxUpsertDialogOpen(false);
        }}
        onResolve={(tax) => {
          queryClient.invalidateQueries(['taxes', storeId]);
          onItemSelected(tax);
        }}
      />
    </>
  );
};
