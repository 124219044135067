import React from 'react';

import {Button, IButtonProps, MenuItem} from '@blueprintjs/core';
import {ISelectProps, Select} from '@blueprintjs/select';
import classNames from 'classnames';

import {DISCOUNT_TYPES} from '../../utils/constants';
import {getDiscountTypeLabel} from '../../utils/helpers.utils';
import {DiscountType} from '../../utils/types';

interface DiscountTypeSelectorProps extends Omit<ISelectProps<DiscountType>, 'itemRenderer' | 'items'> {
  fill?: boolean;
  selectedType?: DiscountType;
  buttonProps?: IButtonProps;
}

const DiscountTypeSelectorComponent = Select.ofType<DiscountType>();

export const DiscountTypeSelector: React.FC<DiscountTypeSelectorProps> = ({selectedType, buttonProps, ...props}) => {
  return (
    <DiscountTypeSelectorComponent
      {...props}
      filterable={false}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      items={[...DISCOUNT_TYPES]}
      itemRenderer={(type, {modifiers, handleClick}) => (
        <MenuItem
          key={type}
          active={selectedType === type}
          disabled={modifiers.disabled}
          onClick={handleClick}
          text={getDiscountTypeLabel(type)}
        />
      )}
    >
      <Button
        {...buttonProps}
        text={selectedType ? getDiscountTypeLabel(selectedType) : 'Type'}
        rightIcon="chevron-down"
        fill={props.fill}
      />
    </DiscountTypeSelectorComponent>
  );
};
