import {QueryClient} from 'react-query';

import {StoreDto} from '@kontactless/admin-api/store/store.dto';

export type CacheStoreAction =
  | {type: 'add-store'; store: StoreDto}
  | {type: 'remove-store'; storeId: number}
  | {type: 'update-store'; store: StoreDto};

export const dispatchStoreAction = (queryClient: QueryClient, action: CacheStoreAction) => {
  switch (action.type) {
    case 'add-store':
      addStore(queryClient, action.store);
      break;
    case 'remove-store':
      removeStore(queryClient, action.storeId);
      break;
    case 'update-store':
      updateStore(queryClient, action.store);
      break;
  }
};

const addStore = (queryClient: QueryClient, store: StoreDto) => {
  queryClient.invalidateQueries('stores');
};

const removeStore = (queryClient: QueryClient, storeId: number) => {
  queryClient.invalidateQueries('stores');
};

const updateStore = (queryClient: QueryClient, store: StoreDto) => {
  queryClient.invalidateQueries('stores');
};
