import React, {useEffect, useState} from 'react';

import {Button, NonIdealState, Spinner} from '@blueprintjs/core';
import classNames from 'classnames';
import {difference} from 'lodash';
import ImageUploading, {ErrorsType, ImageType, ImageUploadingPropsType} from 'react-images-uploading';
import {useMutation} from 'react-query';

import {createImageRequest} from '../../api/image.api';
import {useAuth} from '../../contexts/auth.context';
import {FILE_TYPES_ALLOWED} from '../../utils/constants';

import {CreateImageOptions} from '@kontactless/admin-api/image/image.dto';

interface ImageUploaderProps extends Omit<ImageUploadingPropsType, 'value' | 'onChange'> {
  value: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
  options?: CreateImageOptions;
}

interface ImageListItem extends ImageType {
  isLoading?: boolean;
  isError?: boolean;
  imageUrl?: string;
}

export function ImageUploader({value, onChange, disabled, options = {width: 400, quality: 90}, ...props}: ImageUploaderProps) {
  const {user} = useAuth();
  const [images, setImages] = useState<ImageListItem[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const createImageMutation = useMutation<{imageUrl: string}, Error, ImageListItem>(
    ({file}) => createImageRequest(user.token, file!, options),
    {
      onMutate: (imageToUpload: ImageListItem) => {
        setImages((images) => {
          const imageToRetry = images.find((image) => image.dataURL === imageToUpload.dataURL);
          if (imageToRetry) {
            return images.map((image) =>
              image.dataURL === imageToRetry.dataURL ? {...imageToRetry, isLoading: true, isError: false} : image
            );
          }
          return [...images, {...imageToUpload, isLoading: true}];
        });
      },
      onSuccess: ({imageUrl}, uploadedImage) => {
        setImages((images) =>
          images.map((image) => (image.dataURL === uploadedImage.dataURL ? {...image, isLoading: false, imageUrl} : image))
        );
      },
      onError: (err, failedImage) => {
        setImages((images) =>
          images.map((image) =>
            image.dataURL === failedImage.dataURL ? {...failedImage, isLoading: false, isError: true} : image
          )
        );
      },
    }
  );

  const onImageLoaded = async (imageList: ImageListItem[], addUpdateIndex: number[] | undefined) => {
    for (const index of addUpdateIndex ?? []) {
      createImageMutation.mutate(imageList[index]);
    }

    if (!addUpdateIndex?.length) {
      setImages(() => imageList);
    }
  };

  const onImageRefresh = (index: number) => {
    createImageMutation.mutate(images[index]);
  };

  useEffect(() => {
    const newImages = images.map((image) => (image.isLoading === true ? image.dataURL! : image.imageUrl! || image.dataURL!));
    const diff = difference(newImages, value);
    const mustChange = (value && value.length !== newImages.length) || diff.length;

    if (!firstLoad && mustChange) {
      onChange(newImages);
    }
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (value && images.length !== value.length && value.length) {
      setImages(() => value.map((url) => ({dataURL: url, isError: false, isLoading: false})));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const hasError = (errors: ErrorsType) => errors?.acceptType || errors?.maxFileSize || errors?.maxNumber;

  const getErrorMessage = (errors: ErrorsType) => {
    if (errors?.acceptType) {
      return `Only ${FILE_TYPES_ALLOWED.map((format) => `.${format}`).join(', ')} image formats are allowed`;
    }
    if (errors?.maxFileSize) {
      return 'Maximum image size is 12 MB';
    }
    if (errors?.maxNumber) {
      return 'Maximum number of files has been reached';
    }
  };

  return (
    <ImageUploading {...props} value={images} onChange={onImageLoaded} acceptType={[...FILE_TYPES_ALLOWED]}>
      {({imageList, onImageUpload, onImageRemove, isDragging, dragProps, errors}) => (
        <div className="image-uploader">
          <div className={classNames('drop-area', {dragging: isDragging})} {...dragProps}></div>
          {imageList.length === 0 ? (
            <NonIdealState
              icon="media"
              title="Drag and drop your images here"
              description="or"
              action={<Button icon="folder-open" disabled={disabled} outlined text="Browse files" onClick={onImageUpload} />}
            />
          ) : (
            <div className="image-list">
              {imageList.map((image, index) => (
                <figure key={image.dataURL} className="image-list-item">
                  {!!image.isLoading && (
                    <>
                      <div className="image-fader" />
                      <Spinner className="image-loading" />
                    </>
                  )}
                  {!!image.isError && (
                    <>
                      <div className="image-fader" />
                      <Button
                        disabled={disabled}
                        className="image-error"
                        icon="refresh"
                        intent="danger"
                        text="Retry"
                        onClick={() => onImageRefresh(index)}
                      />
                    </>
                  )}
                  <img src={image.dataURL} alt="" />
                  <Button
                    disabled={disabled}
                    className="control-close"
                    icon="small-cross"
                    intent="danger"
                    small
                    onClick={() => onImageRemove(index)}
                  />
                </figure>
              ))}
              {!props.maxNumber || imageList.length < props.maxNumber ? (
                <Button
                  className="image-list-item"
                  icon="plus"
                  disabled={disabled}
                  onClick={onImageUpload}
                  outlined
                  {...dragProps}
                />
              ) : null}
            </div>
          )}
          {hasError(errors) ? <p className="uploader-error">{getErrorMessage(errors)}</p> : null}
        </div>
      )}
    </ImageUploading>
  );
}
