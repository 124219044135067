import React, {createContext, useContext} from 'react';

import {useQueryClient} from 'react-query';

import {CacheDiscountAction, dispatchDiscountAction} from './discounts.reducer';
import {CacheOrderAction, dispatchOrderAction} from './orders.reducer';
import {CachePrintJobAction, dispatchPrintJobAction} from './print-jobs.reducer';
import {CachePrinterAction, dispatchPrinterAction} from './printers.reducer';
import {CacheRevenueGroupAction, dispatchRevenueGroupAction} from './revenue-groups.reducer';
import {CacheStoreAction, dispatchStoreAction} from './stores.reducer';
import {CacheUserAction, dispatchUserAction} from './users.reducer';

type CacheAction =
  | CacheUserAction
  | CacheOrderAction
  | CacheRevenueGroupAction
  | CachePrinterAction
  | CachePrintJobAction
  | CacheStoreAction
  | CacheDiscountAction;

type CacheActionDispatcher = (action: CacheAction) => void;

const CacheContext = createContext<{dispatchCacheAction: CacheActionDispatcher}>({} as any);
CacheContext.displayName = 'CacheContext';

export const CacheProvider: React.FC = ({children, ...props}) => {
  const queryClient = useQueryClient();

  const dispatchCacheAction: CacheActionDispatcher = (action) => {
    switch (action.type) {
      case 'add-user':
      case 'remove-user':
      case 'update-user':
        dispatchUserAction(queryClient, action);
        break;
      case 'add-order':
      case 'remove-order':
      case 'update-order':
        dispatchOrderAction(queryClient, action);
        break;
      case 'add-revenue-group':
      case 'remove-revenue-group':
      case 'update-revenue-group':
        dispatchRevenueGroupAction(queryClient, action);
        break;
      case 'upsert-printer':
      case 'remove-printer':
        dispatchPrinterAction(queryClient, action);
        break;
      case 'update-print-job':
      case 'remove-print-job':
        dispatchPrintJobAction(queryClient, action);
        break;
      case 'add-store':
      case 'remove-store':
      case 'update-store':
        dispatchStoreAction(queryClient, action);
        break;
      case 'add-discount':
      case 'remove-discount':
      case 'update-discount':
        dispatchDiscountAction(queryClient, action);
        break;
      default:
        console.error(`Cache Action "${(action as CacheAction).type}" is not valid`);
    }
  };

  return (
    <CacheContext.Provider value={{dispatchCacheAction}} {...props}>
      {children}
    </CacheContext.Provider>
  );
};

export const useCache = () => {
  const context = useContext(CacheContext);
  if (context === undefined) {
    throw new Error(`useCache must be used within a CacheProvider`);
  }
  return context;
};
