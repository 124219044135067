import React, {createRef, useMemo} from 'react';

import {Button, IButtonProps, InputGroup, MenuItem} from '@blueprintjs/core';
import {ISuggestProps, Suggest} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';

import {fetchAllOmnivoreTenderTypesRequest} from '../../api/omnivore.api';
import {useAuth} from '../../contexts/auth.context';
import {OmnivoreTenderTypeDto} from '@kontactless/admin-api/omnivore-tender-type/omnivore-tender-type.dto';

import './selectors.styles.scss';

interface OmnivoreTenderTypeSelectorProps
  extends Omit<ISuggestProps<OmnivoreTenderTypeDto>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  storeId: number;
  fill?: boolean;
  selectedItemId?: string;
  onItemSelected: (item: OmnivoreTenderTypeDto | undefined) => void;
  buttonProps?: IButtonProps;
}

const OmnivoreTenderTypeSelectorComponent = Suggest.ofType<OmnivoreTenderTypeDto>();

export const OmnivoreTenderTypeSelector: React.FC<OmnivoreTenderTypeSelectorProps> = ({
  storeId,
  selectedItemId,
  onItemSelected,
  buttonProps,
  ...props
}) => {
  const {user} = useAuth();
  const tenderTypesQuery = useQuery(['omnivore-tender-types', storeId], () =>
    fetchAllOmnivoreTenderTypesRequest(user.token, storeId)
  );
  const inputRef = createRef<any>();

  const selectedItem = useMemo(() => {
    return tenderTypesQuery.data?.find(({tenderTypeId}) => tenderTypeId === selectedItemId);
  }, [tenderTypesQuery.data, selectedItemId]);

  const getTenderTypeLabel = (tenderType: OmnivoreTenderTypeDto): string => {
    return `(${tenderType.posId ?? ''}) - ${tenderType.name ?? 'no name input'}`;
  };

  if (tenderTypesQuery.isError) {
    return <InputGroup {...(props.inputProps ?? {})} intent="danger" value="Error fetching omnivore tender types" readOnly />;
  }

  return (
    <OmnivoreTenderTypeSelectorComponent
      {...props}
      inputValueRenderer={(tenderType) => (tenderTypesQuery.isFetching ? '' : getTenderTypeLabel(tenderType))}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      items={tenderTypesQuery.data ?? []}
      itemRenderer={(tenderType, {modifiers, handleClick}) => (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={tenderType.tenderTypeId}
          onClick={handleClick}
          text={getTenderTypeLabel(tenderType)}
        />
      )}
      onItemSelect={(value) => onItemSelected(value)}
      itemsEqual={(e1, e2) => e1.tenderTypeId === e2.tenderTypeId}
      selectedItem={selectedItem ?? null}
      itemPredicate={(query, tenderType) =>
        !!tenderType.tenderTypeId.toLocaleLowerCase().includes(query.toLowerCase()) ||
        !!tenderType.name?.toLocaleLowerCase().includes(query.toLowerCase())
      }
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      inputProps={{
        ...(props.inputProps ?? {}),
        leftElement: tenderTypesQuery.isFetching ? <Button loading minimal /> : undefined,
        placeholder: tenderTypesQuery.isFetching ? 'Loading tender types...' : 'Choose one...',
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
      }}
    />
  );
};
