import React from 'react';

import {Icon, Spinner} from '@blueprintjs/core';
import {DateTime} from 'luxon';

import {PosStatusLogDto} from '@kontactless/admin-api/pos-status/pos-status.dto';

interface PosStatusComponentProps {
  posStatusLog: PosStatusLogDto[] | undefined;
}

export const PosStatusComponent: React.FC<PosStatusComponentProps> = ({posStatusLog}) => {
  const getStepDuration = (step: PosStatusLogDto) => {
    const {startTimeStamp, endTimeStamp} = step;

    if (!endTimeStamp) {
      return '–';
    }

    const {minutes, seconds} = DateTime.fromISO(endTimeStamp).diff(DateTime.fromISO(startTimeStamp), ['minutes', 'seconds']);

    let duration = `${seconds < 1 ? 1 : Math.trunc(seconds)}s`;
    if (minutes) {
      duration = `${minutes}m ${duration}`;
    }
    return duration;
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <Icon className="omnivore-pull-logs__step-icon" icon="tick-circle" intent="success" />;

      case 'in-progress':
        return <Spinner className="omnivore-pull-logs__step-icon" size={16} intent="warning" />;

      case 'error':
        return <Icon className="omnivore-pull-logs__step-icon" icon="error" intent="danger" />;

      default:
        return <Icon className="omnivore-pull-logs__step-icon" icon="circle" />;
    }
  };

  return (
    <ol className="omnivore-pull-logs">
      {posStatusLog ? (
        posStatusLog.map((step) => (
          <li key={step.stepId}>
            <div className="omnivore-pull-logs__step">
              {getStatusIcon(step.status)}
              <div>
                <p className="omnivore-pull-logs__step-name">{step.stepName}</p>
              </div>
              <span className="omnivore-pull-logs__step-duration">{getStepDuration(step)}</span>
            </div>
            {!!step.message && <div className="omnivore-pull-logs__step-message">{step.message}</div>}
          </li>
        ))
      ) : (
        <p>Waiting for pull to run...</p>
      )}
    </ol>
  );
};
