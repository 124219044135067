import {
  AuthenticationDetails,
  CodeDeliveryDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationDetailsData,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';

const POOL_ID = process.env.REACT_APP_COGNITO_POOL_ID!;
const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID!;

const poolCredentials: ICognitoUserPoolData = {
  UserPoolId: POOL_ID,
  ClientId: CLIENT_ID,
};

export interface SignInResult {
  result: 'success' | 'new-password-required';
  session?: CognitoUserSession;
}

export const createCognitoUser = (authenticationDetails: IAuthenticationDetailsData): CognitoUser => {
  const userPool = new CognitoUserPool(poolCredentials);
  return new CognitoUser({...authenticationDetails, Pool: userPool});
};

export const getCurrentUser = (): CognitoUser | null => {
  const userPool = new CognitoUserPool(poolCredentials);
  return userPool.getCurrentUser();
};

export const signIn = async (cognitoUser: CognitoUser, username: string, password: string): Promise<SignInResult> => {
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(new AuthenticationDetails({Username: username, Password: password}), {
      onSuccess: (session) => {
        return resolve({result: 'success', session});
      },
      onFailure: (error) => {
        if (error.code === 'NotAuthorizedException') {
          return reject(new Error('wrong_credentials'));
        }

        if (error.code === 'LimitExceededException') {
          return reject(new Error('reset_limit_exceeded'));
        }

        return reject(error);
      },
      newPasswordRequired: () => {
        return resolve({result: 'new-password-required'});
      },
    });
  });
};

export const completeNewPasswordChallenge = async (
  cognitoUser: CognitoUser,
  newPassword: string
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(newPassword, [], {
      onSuccess: resolve,
      onFailure: (err) => {
        if (err.code === 'InvalidPasswordException') {
          return reject(new Error('invalid_password'));
        }

        if (err.code === 'LimitExceededException') {
          return reject(new Error('reset_limit_exceeded'));
        }

        return reject(err);
      },
    });
  });
};

export const getSession = (cognitoUser: CognitoUser): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err) {
        return reject(err);
      }

      if (session) {
        return resolve(session);
      }

      return reject(new Error('invalid_session'));
    });
  });
};

export const signOut = (cognitoUser?: CognitoUser) => {
  if (cognitoUser) {
    cognitoUser.signOut();
  } else {
    getCurrentUser()?.signOut();
  }
};

export const changeUserPassword = (cognitoUser: CognitoUser, oldPassword: string, newPassword: string) => {
  return new Promise((resolve, reject) => {
    cognitoUser.changePassword(oldPassword, newPassword, (err: any, result) => {
      if (err) {
        if (err.code === 'InvalidParameterException' && err.message.includes('previousPassword')) {
          return reject(new Error('wrong_old_password'));
        }

        if (err.code === 'NotAuthorizedException') {
          return reject(new Error('wrong_old_password'));
        }

        if (err.code === 'InvalidPasswordException') {
          return reject(new Error('invalid_new_password'));
        }

        if (err.code === 'LimitExceededException') {
          return reject(new Error('limit_attemps_reached'));
        }

        return reject(err);
      }

      return resolve(result);
    });
  });
};

export const forgotPassword = (cognitoUser: CognitoUser): Promise<CodeDeliveryDetails> => {
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (data) => (data ? resolve(data.CodeDeliveryDetails) : reject(new Error('unknown_error'))),
      onFailure: reject,
    });
  });
};

export const confirmPassword = (cognitoUser: CognitoUser, verificationCode: string, newPassword: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (error: any) => {
        if (error.code === 'ExpiredCodeException') {
          return reject(new Error('invalid_verification_code'));
        }

        if (error.code === 'CodeMismatchException') {
          return reject(new Error('invalid_verification_code'));
        }

        if (error.code === 'InvalidPasswordException') {
          return reject(new Error('invalid_password'));
        }

        if (error.code === 'LimitExceededException') {
          return reject(new Error('reset_limit_exceeded'));
        }

        return reject(error);
      },
    });
  });
};

export const refreshSession = (cognitoUser?: CognitoUser, refreshToken?: CognitoRefreshToken): Promise<CognitoUserSession> => {
  if (cognitoUser && refreshToken) {
    return new Promise((resolve, reject) => {
      return cognitoUser.refreshSession(refreshToken, (err, result: CognitoUserSession) => {
        if (err) {
          return reject(err);
        }

        if (!result.isValid()) {
          return reject(new Error('invalid_session'));
        }

        resolve(result);
      });
    });
  } else {
    const user = getCurrentUser();

    if (!user) {
      throw new Error('user_not_found');
    }

    return new Promise((resolve, reject) => {
      getSession(user).then((session) => {
        user.refreshSession(
          new CognitoRefreshToken({RefreshToken: session.getRefreshToken().getToken()}),
          (err, result: CognitoUserSession) => {
            if (err) {
              return reject(err);
            }

            if (!result.isValid()) {
              return reject(new Error('invalid_session'));
            }

            resolve(result);
          }
        );
      });
    });
  }
};
