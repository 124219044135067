import React, {useState} from 'react';

import {Button, Classes, Dialog} from '@blueprintjs/core';
import {useQuery} from 'react-query';

import {fetchAllPrinters} from '../../api/printer.api';
import {useAuth} from '../../contexts/auth.context';
import {ErrorIcon, SuccessIcon, WarningIcon} from '../icons';
import {PrinterList} from './printer-list.component';

interface PrintersStatusProps {
  storeId: number;
}
export const PrintersStatus: React.FC<PrintersStatusProps> = ({storeId}) => {
  const {user} = useAuth();
  const {data: printers} = useQuery(['printers', storeId], () => fetchAllPrinters(user.token, storeId, {onlyEnabled: true}), {
    refetchInterval: 1000 * 30,
    enabled: ['superadmin', 'admin', 'company-admin', 'supervisor'].includes(user.securityLevel),
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const getGeneralStatusIcon = () => {
    if (printers?.some(({hasError}) => hasError)) {
      return ErrorIcon;
    }
    if (printers?.some(({hasWarning}) => hasWarning)) {
      return WarningIcon;
    }
    return SuccessIcon;
  };

  if (!printers?.length) {
    return null;
  }

  return (
    <>
      <Button
        outlined
        onClick={() => setIsDialogOpen(true)}
        text={`${printers?.length ? 'Printers' : 'Printer'} status`}
        rightIcon={getGeneralStatusIcon()}
      />
      <Dialog
        onClose={() => setIsDialogOpen(false)}
        isOpen={isDialogOpen}
        title={`${printers?.length ? 'Printers' : 'Printer'} status`}
        className="kl-dialog printer-dialog"
      >
        <div className={Classes.DIALOG_BODY}>
          <PrinterList storeId={storeId} columns={['name', 'paperStatus', 'coverStatus']} />
        </div>
      </Dialog>
    </>
  );
};
