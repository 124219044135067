import React from 'react';

import {Button, IButtonProps, MenuItem} from '@blueprintjs/core';
import {ISelectProps, Select} from '@blueprintjs/select';
import classNames from 'classnames';

import {SUPPORTED_TIMEZONES} from '../../utils/constants';
import {getTimezoneShortFormats} from '../../utils/helpers.utils';
import {Timezone} from '../../utils/types';

interface TimezoneSelectorProps extends Omit<ISelectProps<Timezone>, 'itemRenderer' | 'items'> {
  fill?: boolean;
  selectedItem?: Timezone;
  buttonProps?: IButtonProps;
}

const TimezoneSelectorComponent = Select.ofType<Timezone>();

export const TimezoneSelector: React.FC<TimezoneSelectorProps> = ({selectedItem, buttonProps, ...props}) => {
  return (
    <TimezoneSelectorComponent
      {...props}
      filterable={false}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{className: 'timezone-selector-popover', minimal: true}}
      items={[...SUPPORTED_TIMEZONES]}
      itemRenderer={(timezone, {modifiers, handleClick}) => (
        <MenuItem
          active={selectedItem === timezone}
          disabled={modifiers.disabled}
          key={timezone}
          onClick={handleClick}
          text={getTimezoneShortFormats(timezone)}
        />
      )}
      itemsEqual={(t1, t2) => t1 === t2}
    >
      <Button
        {...buttonProps}
        text={getTimezoneShortFormats(selectedItem!) ?? 'Timezone'}
        rightIcon="chevron-down"
        className="!tw-bg-white"
      />
    </TimezoneSelectorComponent>
  );
};
