import {Button, FormGroup, MenuItem, Tag} from '@blueprintjs/core';
import {MultiSelect} from '@blueprintjs/select';
import {ChangeEvent, useState} from 'react';

import {AdditionalChargeDto} from '@kontactless/admin-api/additional-charge/additional-charge.dto';
import {useAuth} from '../../../contexts/auth.context';
import {fetchAllAdditionalChargeRequest} from '../../../api/additional-charge.api';
import {useQuery} from 'react-query';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';

import {AdditionalChargeType} from '@kontactless/utils/types';
import AdditionalChargeUpsertDialog from '../../pages/AdditionalChargeUpsertDialog';
import {getPriceText} from '../../../utils/helpers.utils';

interface AdditionalChargesSelectProps {
  areAdditionalChargesEnabled: boolean;
  onAreAdditionalChargesEnabledChange(event: ChangeEvent<HTMLInputElement>): void;
  selectedAdditionalCharges: AdditionalChargeDto[];
  onChangeAdditionalCharges: (additionalCharge: AdditionalChargeDto[]) => void;
  disabled: boolean;
  store: StoreDto;
}

const AdditionalChargeSelector = MultiSelect.ofType<AdditionalChargeDto>();

export const AdditionalChargesSelect = ({
  store,
  selectedAdditionalCharges,
  onChangeAdditionalCharges,
  disabled,
}: AdditionalChargesSelectProps) => {
  const inputRef: any = {current: undefined};
  const {user} = useAuth();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const additionalChargesQuery = useQuery<AdditionalChargeDto[]>({
    queryKey: ['stores', store.id, 'additional-charges'],
    queryFn: () => fetchAllAdditionalChargeRequest(user.token, store.id),
  });

  const items = [...(additionalChargesQuery.data ?? []), {id: -1, name: 'Create additional charge'}];

  return (
    <div className="tw-w-full">
      <FormGroup className="!tw-mb-0">
        <AdditionalChargeSelector
          className="!tw-bg-red-500"
          resetOnSelect
          items={items as AdditionalChargeDto[]}
          popoverProps={{minimal: true, disabled}}
          itemRenderer={(additionalCharge, {modifiers, handleClick}) => (
            <MenuItem
              key={additionalCharge.id}
              onClick={(event) => {
                if (additionalCharge.id === -1) {
                  setIsCreateDialogOpen(true);
                } else {
                  handleClick(event);
                }
              }}
              intent={additionalCharge.id === -1 ? 'primary' : undefined}
              labelElement={
                additionalCharge.value ? <Tag minimal>{getValueText(additionalCharge.type, additionalCharge.value)}</Tag> : null
              }
              text={additionalCharge.name}
              icon={
                selectedAdditionalCharges
                  .map((selectedAdditionalCharge) => selectedAdditionalCharge.id)
                  .includes(additionalCharge.id)
                  ? 'tick'
                  : additionalCharge.id === -1
                  ? 'add'
                  : 'blank'
              }
            />
          )}
          selectedItems={selectedAdditionalCharges}
          onItemSelect={(item) =>
            onChangeAdditionalCharges(
              !selectedAdditionalCharges.map((additionalCharge) => additionalCharge.id).includes(item.id)
                ? [...selectedAdditionalCharges, item]
                : selectedAdditionalCharges
            )
          }
          onRemove={(item) =>
            onChangeAdditionalCharges(selectedAdditionalCharges?.filter((additionalCharge) => additionalCharge.id !== item.id))
          }
          tagRenderer={(additionalCharge) => (
            <div className="tw-flex tw-justify-between tw-gap-2">
              <p>{additionalCharge.name}</p>
              <p className="tw-font-bold">{getValueText(additionalCharge.type, additionalCharge.value)}</p>
            </div>
          )}
          itemsEqual={(m1, m2) => m1 === m2}
          itemPredicate={(query, value) => value.name.toLocaleLowerCase().includes(query.toLowerCase())}
          ref={inputRef}
          tagInputProps={{
            placeholder: 'Choose additional charge...',
            rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
            tagProps: {minimal: true},
            disabled,
          }}
        />
      </FormGroup>

      <AdditionalChargeUpsertDialog
        isOpen={isCreateDialogOpen}
        onClose={() => {
          setIsCreateDialogOpen(false);
        }}
        onAdditionalChargeUpserted={(additionalCharge) => {
          onChangeAdditionalCharges([...selectedAdditionalCharges, additionalCharge]);
          setIsCreateDialogOpen(false);
        }}
        storeId={store.id}
        isPosConnected={!!store.settings?.posId}
      />
    </div>
  );
};

function getValueText(type: AdditionalChargeType, value: number) {
  return type === 'flat' ? getPriceText(value) : `${value * 100}%`;
}
