import React from 'react';

import {Button, NonIdealState} from '@blueprintjs/core';

interface FatalErrorSceneProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const FatalErrorScene: React.FC<FatalErrorSceneProps> = ({resetErrorBoundary}) => {
  return (
    <section className="scene-fatal-error">
      <NonIdealState
        icon="error"
        title="Fatal Error"
        description="Something went terribly wrong."
        action={<Button intent="primary" text="Go to Home" onClick={() => resetErrorBoundary()} />}
      />
    </section>
  );
};
