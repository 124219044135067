import React, {HTMLAttributes} from 'react';

import classNames from 'classnames';

export interface DashboardWidgetProps extends HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
  errorMessage?: string;
}

export const DashboardWidget: React.FC<DashboardWidgetProps> = ({children, ...divProps}) => {
  return (
    <div {...divProps} className={classNames('dashboard-widget', divProps.className)}>
      <div className="widget-container">{children}</div>
    </div>
  );
};
