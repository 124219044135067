import React, {useMemo} from 'react';

import {Button, FormGroup, InputGroup} from '@blueprintjs/core';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import * as yup from 'yup';

import {updateStoreRequest} from '../../api/store.api';
import {PasswordInput} from '../../components/ui/password-input.component';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {StoreDto, StoreUpdateRequestDto} from '@kontactless/admin-api/store/store.dto';

interface ServerDirectPrintCredentialsFormProps {
  store: StoreDto;
}

interface ServerDirectPrintCredentialsFormModel {
  serverDirectPrintId: string;
  serverDirectPrintPassword: string;
}

const formValidator = yup.object().shape({
  serverDirectPrintId: yup.string().required('ID is required'),
  // .test({
  //   name: 'unique',
  //   message: 'This ID is already taken, choose another one',
  //   test: async value => {
  //     console.log('validatiing')
  //     const res = await new Promise((resolve, reject) => setTimeout(() => resolve('krusty_burger'), 500))
  //     return value === res;
  //   }
  // }),
  serverDirectPrintPassword: yup.string().required('Password is required'),
});

export const ServerDirectPrintCredentialsForm: React.FC<ServerDirectPrintCredentialsFormProps> = ({store}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();

  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: {isDirty, isSubmitting},
    reset,
  } = useForm<ServerDirectPrintCredentialsFormModel>({resolver: yupResolver(formValidator)});
  const queryClient = useQueryClient();
  const storeUpdateMutation = useMutation<StoreDto, Error, StoreUpdateRequestDto>((update) =>
    updateStoreRequest(user.token, update, store.id)
  );
  const settings = useMemo(() => store.settings!, [store]);

  const submitForm = async (form: ServerDirectPrintCredentialsFormModel) => {
    try {
      const updatedStore = await storeUpdateMutation.mutateAsync({settings: {...form}});

      queryClient.setQueryData<StoreDto>(['stores', store.id], () => ({...store, ...updatedStore}));

      reset({
        serverDirectPrintId: updatedStore.settings?.serverDirectPrintId!,
        serverDirectPrintPassword: updatedStore.settings?.serverDirectPrintPassword!,
      });
      toaster.show({intent: 'success', message: 'Stores settings were updated successfully'});
    } catch (exception: any) {
      const {error} = exception;
      if (error.message === 'server_direct_print_id_already_exists') {
        setError('serverDirectPrintId', {message: 'This ID is already taken. Please, choose another.'});
      } else {
        toaster.show({intent: 'danger', message: 'An error ocurred updating the store settings'});
      }
    }
  };

  const isLoading = storeUpdateMutation.isLoading || isSubmitting;

  return (
    <>
      <form className="server-direct-print-credentials-form" onSubmit={handleSubmit(submitForm)}>
        <FormGroup
          label="ID"
          intent={errors.serverDirectPrintId ? 'danger' : 'none'}
          helperText={errors.serverDirectPrintId ? errors.serverDirectPrintId.message : ''}
        >
          <InputGroup
            name="serverDirectPrintId"
            type="text"
            disabled={isLoading}
            intent={errors.serverDirectPrintId ? 'danger' : 'none'}
            defaultValue={settings.serverDirectPrintId?.toString()}
            inputRef={register()}
          />
        </FormGroup>

        <FormGroup
          label="Password"
          intent={errors.serverDirectPrintPassword ? 'danger' : 'none'}
          helperText={errors.serverDirectPrintPassword ? 'Password is required' : ''}
        >
          <PasswordInput
            name="serverDirectPrintPassword"
            disabled={isLoading}
            intent={errors.serverDirectPrintPassword ? 'danger' : 'none'}
            defaultValue={settings.serverDirectPrintPassword?.toString()}
            inputRef={register()}
          />
        </FormGroup>

        {isDirty && (
          <div className="actions">
            <Button text="Save changes" intent="primary" type="submit" disabled={isLoading} loading={isLoading} />
            <Button
              text="Cancel"
              minimal
              disabled={isLoading}
              onClick={() =>
                reset({
                  serverDirectPrintId: settings.serverDirectPrintId!,
                  serverDirectPrintPassword: settings.serverDirectPrintPassword!,
                })
              }
            />
          </div>
        )}
      </form>
    </>
  );
};
