import {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {Button, Icon, InputGroup, NonIdealState} from '@blueprintjs/core';
import {TipsConfigurationDto} from '@kontactless/admin-api/tips-configuration/tips-configuration.dto';
import {fetchStoreRequest} from '../../api/store.api';
import {useAuth} from '../../contexts/auth.context';
import {TipConfigurationCard} from '../components/TipConfigurationCard';
import {UpsertTipConfigurationDialog} from '../components/UpsertTipConfigurationDialog';
import {fetchAllTipsConfigurationsRequest} from '../tip-configurations.api';

export function TipsPage() {
  const {user} = useAuth();
  const [tipName, setTipName] = useState('');
  const [isTipConfigurationUpsertDialogOpen, setIsTipConfigurationUpsertDialogOpen] = useState(false);
  const [tipConfiguration, setTipConfiguration] = useState<TipsConfigurationDto>();

  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);
  const storeQuery = useQuery({queryKey: ['stores', storeId], queryFn: () => fetchStoreRequest(user.token, storeId)});

  const tipsConfigurationQuery = useQuery({
    queryKey: ['stores', storeId, 'tips-configurations'],
    queryFn: () => fetchAllTipsConfigurationsRequest(user.token, storeId),
  });

  const filteredTips = tipsConfigurationQuery.data?.filter((tip) =>
    tipName ? tip.name.toLowerCase().includes(tipName.toLowerCase()) : tip
  );

  if (!storeQuery.data) {
    return null;
  }

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-p-5">
      <div className="tw-flex tw-justify-between tw-mb-2">
        <header className="card-header">
          <h3 className="tw-text-xl tw-font-bold">Tips</h3>
        </header>
      </div>
      <div className="tw-flex tw-items-center tw-justify-between">
        <InputGroup
          onChange={(event) => setTipName(event?.target.value)}
          placeholder="Search tip..."
          value={tipName}
          rightElement={
            tipName ? (
              <Button onClick={() => setTipName('')} minimal icon="cross" />
            ) : (
              <Icon icon="search" size={14} className="tw-mr-2 tw-mt-2 tw-text-gray-400" />
            )
          }
        />
        <Button
          intent="primary"
          onClick={() => {
            setTipConfiguration(undefined);
            setIsTipConfigurationUpsertDialogOpen(true);
          }}
        >
          + Create tip
        </Button>
      </div>

      {!tipsConfigurationQuery.data?.length || !filteredTips?.length ? (
        <NonIdealState
          icon="settings"
          description="Looks like there are no tips yet"
          action={<Button intent="primary" text="Create tip" onClick={() => setIsTipConfigurationUpsertDialogOpen(true)} />}
        />
      ) : (
        <ul className="tw-flex tw-flex-col tw-gap-2 tw-space-y-2 tw-divide-y tw-divide-solid tw-divide-gray-200">
          {tipsConfigurationQuery.data
            ?.filter((tipConfiguration) =>
              tipName ? tipConfiguration.name.toLowerCase().includes(tipName.toLowerCase()) : tipConfiguration
            )
            .map((tipConfiguration) => {
              return (
                <li key={tipConfiguration.id} className="tw-pt-4 first:tw-pt-0">
                  <TipConfigurationCard
                    tipConfiguration={tipConfiguration}
                    store={storeQuery.data}
                    onEdit={(tipConfiguration) => {
                      setTipConfiguration(tipConfiguration);
                      setIsTipConfigurationUpsertDialogOpen(true);
                    }}
                  />
                </li>
              );
            })}
        </ul>
      )}

      <UpsertTipConfigurationDialog
        isOpen={isTipConfigurationUpsertDialogOpen}
        onClose={() => setIsTipConfigurationUpsertDialogOpen(false)}
        tipConfiguration={tipConfiguration}
        storeId={storeId}
      />
    </div>
  );
}
