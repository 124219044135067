import React, {useState} from 'react';

import {Button, InputGroup, NonIdealState, Spinner} from '@blueprintjs/core';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {deleteTaxRequest, fetchAllTaxesRequest} from '../../api/tax.api';
import {TaxUpsertDialog} from '../../components/taxes/tax-upsert-dialog.component';
import {useAuth} from '../../contexts/auth.context';

import {TaxDto} from '@kontactless/admin-api/tax/tax.dto';
import {useParams} from 'react-router-dom';
import {useAlerts} from '../../contexts/alerts.context';
import {useToasts} from '../../contexts/toasts.context';

export function TaxesPage() {
  const {user} = useAuth();
  const [isTaxDialogOpen, setIsTaxDialogOpen] = useState(false);
  const [taxEditing, setTaxEditing] = useState<TaxDto | undefined>();
  const [search, setSearch] = useState('');
  const queryClient = useQueryClient();
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);
  const {
    state: {toaster},
  } = useToasts();

  const taxesQuery = useQuery({
    queryKey: ['taxes', storeId],
    queryFn: () => fetchAllTaxesRequest(user.token, storeId),
  });

  const taxesFiltered =
    taxesQuery.data?.filter((tax) => (search ? tax.name.toLowerCase().includes(search.toLowerCase()) : tax)) || [];

  const {alertsDispatch} = useAlerts();

  const deleteTaxMutation = useMutation<{}, Error, number>((taxId) => deleteTaxRequest(user.token, taxId));

  const onDelete = async (tax: TaxDto) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: (
          <>
            <p>Delete tax?</p>
            <p>All products associated with this tax will be set with $0 tax</p>
          </>
        ),
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteTaxMutation.mutateAsync(tax?.id!);
            queryClient.invalidateQueries(['taxes', storeId]);

            toaster.show({intent: 'success', message: 'Tax was deleted successfully'});
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred deleting the tax'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-2 tw-border tw-p-4">
      <div className="tw-flex tw-justify-between tw-mb-2">
        <header className="card-header">
          <h3 className="tw-text-xl tw-font-bold">Taxes</h3>
        </header>
      </div>
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
        <InputGroup
          className="tw-w-56"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search taxes..."
          value={search}
          leftIcon="search"
        />
        <Button text="+ Add new tax" intent="primary" onClick={() => setIsTaxDialogOpen(true)} />
      </div>
      <div className="tw-flex tw-w-full tw-flex-col tw-gap-2 tw-border">
        {taxesQuery.isLoading && <Spinner />}

        {!taxesFiltered?.length && !taxesQuery.isLoading && (
          <NonIdealState
            icon="settings"
            description={
              search.length ? 'Looks like there are no taxes matching your search.' : 'Looks like there are no taxes yet.'
            }
          />
        )}
        {taxesFiltered?.length! > 0 && (
          <table className="tw-min-w-full tw-divide-y tw-divide-solid tw-divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0"
                >
                  Name
                </th>
                <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                  Percentage
                </th>
                <th scope="col" className="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 sm:tw-pr-0">
                  <span className="tw-sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="tw-divide-y tw-divide-solid tw-divide-gray-200">
              {taxesFiltered?.map((tax) => (
                <tr key={tax.id}>
                  <td className="tw-whitespace-nowrap tw-py-2 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">
                    {tax.name}
                  </td>
                  <td className="tw-whitespace-nowrap tw-px-3 tw-py-2 tw-text-sm tw-text-gray-500">{tax.percentage}%</td>
                  <td className="tw-relative tw-whitespace-nowrap tw-py-2 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium tw-space-x-2 sm:tw-pr-0">
                    <Button
                      minimal
                      text="Edit"
                      intent="primary"
                      onClick={() => {
                        setTaxEditing(tax);
                        setIsTaxDialogOpen(true);
                      }}
                    />
                    <Button minimal title="Delete" icon="trash" intent="danger" onClick={() => onDelete(tax)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <TaxUpsertDialog
        storeId={storeId}
        isOpen={isTaxDialogOpen}
        tax={taxEditing}
        onResolve={() => queryClient.invalidateQueries(['taxes', storeId])}
        onClose={() => {
          setIsTaxDialogOpen(false);
          setTaxEditing(undefined);
        }}
      />
    </div>
  );
}
