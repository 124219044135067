import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

export default function useQuerystring<T>(): T {
  const {search} = useLocation();

  return useMemo(() => {
    const urlSearchParams = Array.from(new URLSearchParams(search));

    return urlSearchParams.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as T);
  }, [search]);
}
