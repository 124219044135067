import React, {useEffect, useMemo} from 'react';
import {Button, Dialog, DialogProps} from '@blueprintjs/core';
import {Classes} from '@blueprintjs/core';
import classNames from 'classnames';
import {round, sumBy} from 'lodash';
import {DateTime} from 'luxon';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import {refundOrderRequest} from '../../api/order.api';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {getProductVersionTotals} from '../../utils/helpers.utils';
import {Timezone} from '../../utils/types';
import {OrderItemRow} from './order-item-row.component';
import {OrderDto, OrderRefundRequestDto} from '@kontactless/admin-api/order/order.dto';
import {OrderItemDto} from '@kontactless/admin-api/order-item/order-item.dto';

interface OrderRefundDialogProps extends DialogProps {
  timezone: Timezone;
  order: OrderDto;
}

interface RefundsForm {
  refund: Array<{item: OrderItemDto; count: number} | null>;
}

export const OrderRefundDialog: React.FC<OrderRefundDialogProps> = ({order, timezone, ...props}) => {
  const {user} = useAuth();
  const queryClient = useQueryClient();
  const {
    state: {toaster},
  } = useToasts();
  const {control, watch, reset, handleSubmit} = useForm<RefundsForm>({defaultValues: {refund: []}});
  const refundOrderMutation = useMutation<OrderDto, Error, {id: number} & OrderRefundRequestDto>((request) =>
    refundOrderRequest(user.token, request.id, request)
  );
  const time = order ? DateTime.fromISO(order.createdAt).setZone(timezone).toFormat('d/MM/yyyy, h:mm:ss a ZZZZ') : '';

  const countOfItemsInOrder = useMemo(
    () =>
      sumBy(
        order?.items?.filter(({type}) => type === 'product') ?? [],
        ({quantity, quantityRefunded}) => quantity - quantityRefunded
      ),
    [order]
  );

  const countOfItemsToRefund = sumBy(watch('refund') ?? [], (refund) => refund?.count ?? 0);

  const amountToRefund = -Math.max(
    round(
      sumBy(watch('refund') ?? [], (refund) =>
        refund && refund.item.productVersion && refund.item.quantity > 0
          ? refund.count * getProductVersionTotals(refund.item.productVersion).total
          : 0
      ),
      2
    ),
    0
  );

  const isTotalRefund = countOfItemsInOrder === countOfItemsToRefund;

  const submitForm = async (form: RefundsForm) => {
    try {
      await refundOrderMutation.mutateAsync({
        id: order!.id,
        itemsToRefund: form.refund
          .filter((r) => r?.count && r.count > 0)
          .map((refund) => ({orderItemId: refund!.item.id, count: refund!.count})),
      });
      toaster.show({intent: 'success', message: 'Order was refunded successfully'});
      queryClient.invalidateQueries(['stores', order.storeId, 'orders']);
      props.onClose?.({} as any);
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred refunding the order'});
    }
  };

  const makeTotalRefund = () => {
    reset({
      refund:
        order?.items
          ?.filter(({type}) => type === 'product')
          .map((item) => ({item, count: item.quantity - item.quantityRefunded})) ?? [],
    });
  };

  useEffect(() => {
    reset({refund: []});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Dialog
      title={
        <div className="dialog-title">
          <label>Refund Order #{order?.number}</label>
          <p>{time}</p>
        </div>
      }
      className="kl-dialog order-details-dialog"
      {...props}
    >
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="section-title">
          <h4>Items & Cost</h4>
          <Button className="pull-right" text="Select all items" onClick={makeTotalRefund} outlined small />
        </div>
        <div className="order-items-list">
          {order?.items?.map((item, index) => (
            <Controller
              key={item.id}
              name={`refund.${index}`}
              control={control}
              defaultValue={{item, count: 0}}
              render={(props) => (
                <OrderItemRow
                  key={item.id}
                  item={item}
                  isRefund
                  refundCount={props.value?.count}
                  onRefundCountChange={(count) => props.onChange({item, count})}
                />
              )}
            />
          )) ?? null}
          {amountToRefund !== 0 ? (
            <OrderItemRow
              item={{
                quantity: 1,
                type: 'refund',
                title: 'To refund',
                subtotal: isTotalRefund ? -(order?.total ?? 0) : amountToRefund,
              }}
              isRefund
            />
          ) : null}
          <OrderItemRow
            item={{
              quantity: 1,
              type: 'total',
              title: 'Total',
              subtotal: isTotalRefund ? 0 : (order?.total ?? 0) + amountToRefund,
            }}
            isRefund
          />
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={refundOrderMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text="Make refund"
          intent="primary"
          loading={refundOrderMutation.isLoading}
          disabled={refundOrderMutation.isLoading || amountToRefund === 0}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
};
