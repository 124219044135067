import {useMutation} from 'react-query';
import {clamp, round} from 'lodash';
import React, {useState} from 'react';
import Cropper from 'react-cropper';
import {createImageRequest} from '../../api/image.api';
import {Slider} from './Slider';
import {Button, Dialog} from '@blueprintjs/core';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {ArrowSmallLeftIcon, MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon, TrashIcon} from '@heroicons/react/24/outline';

import {PlusCircleIcon} from '@heroicons/react/20/solid';

import 'cropperjs/dist/cropper.css';

const FILE_LIMIT_SIZE = 12582912 as const; // 12 MB = 12 * 1024 * 1024
const CROPPER_ALLOWED_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'] as const;
type CropperAllowedFileType = typeof CROPPER_ALLOWED_FILE_TYPES[number];

interface ImageCropperInputProps {
  value: string;
  onFileUploaded: (value: string) => void;
  cropperDialogTitle: string;
  cropperAspectRatio: number;
  onIsFileTypeAllowed?: (value: boolean) => void;
  roundCroppedImage?: boolean;
}

const ZOOM_STEP = 0.1;
const MAX_ZOOM = 5;

export const ImageCropperInput = React.forwardRef<HTMLInputElement, ImageCropperInputProps>(
  (
    {value, onFileUploaded, cropperDialogTitle, cropperAspectRatio, roundCroppedImage = false, onIsFileTypeAllowed, ...props},
    ref
  ) => {
    const [isCropperDialogOpen, setIsCropperDialogOpen] = useState(false);
    const {user} = useAuth();
    const [imageBase64, setImageBase64] = useState('');
    const [inputFilePath, setInputFilePath] = useState('');
    const [fileSizeError, setFileSizeError] = useState(false);
    const [cropper, setCropper] = useState<Cropper | undefined>();
    const {
      state: {toaster},
    } = useToasts();

    const [{zoomValue, sliderMinValue}, setZoomParams] = useState({
      zoomValue: 0,
      sliderMinValue: 0,
      currentPercentage: 0,
    });

    const onCloseModal = () => {
      setImageBase64('');
      setZoomParams({zoomValue: 0, sliderMinValue: 0, currentPercentage: 0});
      setIsCropperDialogOpen(false);
    };

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      const files = event.target.files as FileList;
      const typeOfFile = files[0].type as CropperAllowedFileType;
      if (!CROPPER_ALLOWED_FILE_TYPES.includes(typeOfFile) && onIsFileTypeAllowed) {
        toaster.show({message: 'Please select a valid file type, JPG or PNG.', intent: 'danger'});
        onIsFileTypeAllowed(false);
        setTimeout(() => {
          onIsFileTypeAllowed(true);
        }, 5000);
      } else {
        setFileSizeError(false);
        setInputFilePath(value);
        if (onIsFileTypeAllowed) {
          onIsFileTypeAllowed(true);
        }
        if (files && files.length > 0) {
          const reader = new FileReader();
          reader.onload = () => {
            if (typeof reader.result === 'string') {
              setImageBase64(reader.result);
              setIsCropperDialogOpen(true);
              setInputFilePath('');
            }
          };
          reader.readAsDataURL(files[0]);
        }
      }
    };

    const createImageMutation = useMutation<{imageUrl: string}, Error, {file: File}>(
      ({file}) => createImageRequest(user.token, file!, {quality: 90, width: 1100}),
      {
        onSuccess: ({imageUrl}) => {
          onFileUploaded(imageUrl);
          onCloseModal();
        },
        onError: (err) => {
          console.error(err);
        },
      }
    );

    const getCropData = async () => {
      if (typeof cropper !== 'undefined') {
        const imageUrl = cropper.getCroppedCanvas().toDataURL();
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], 'image.jpg', {type: blob.type});
        return file;
      }
    };

    const getCropperPercentage = (zoom: number, minValue: number) => {
      const maxZoom = (MAX_ZOOM - minValue) / ZOOM_STEP;
      const currentPercentage = (zoom - minValue) / ZOOM_STEP;

      return round((currentPercentage * 100) / maxZoom);
    };

    const onApplyImageCrop = async () => {
      const file = await getCropData();
      if (file) {
        if (Math.round(file.size) > FILE_LIMIT_SIZE) {
          setFileSizeError(true);
        } else {
          createImageMutation.mutate({file});
        }
      }
    };

    const initialZoomChange = (initialValue: number, minValue: number) => {
      setZoomParams(({zoomValue, sliderMinValue, currentPercentage}) => {
        return {zoomValue: initialValue, sliderMinValue: minValue ? minValue : sliderMinValue, currentPercentage};
      });
    };

    const onZoomChange = (newValue: number) => {
      setZoomParams(({zoomValue, sliderMinValue, currentPercentage}) => {
        const newZoom = clamp(newValue, sliderMinValue, MAX_ZOOM);
        const newCurrentPercentage = getCropperPercentage(newZoom, sliderMinValue);

        cropper?.zoomTo(newZoom);

        return {zoomValue: newZoom, sliderMinValue, currentPercentage: newCurrentPercentage};
      });
    };

    const handleWheelEvent = (e: Cropper.ZoomEvent<HTMLImageElement>) => {
      if (!!e.detail.originalEvent && e.detail.originalEvent.type === 'wheel') {
        const newValue = round(e.detail.ratio, 2);

        if (newValue <= MAX_ZOOM) {
          onZoomChange(newValue);
        } else {
          onZoomChange(MAX_ZOOM);
          e.preventDefault();
        }
      }
    };

    const onCropperReady = (e: Cropper.ReadyEvent<HTMLImageElement>) => {
      const imageData = e.currentTarget.cropper.getImageData();
      const sliderMinValue = round(imageData.width / imageData.naturalWidth, 2);

      initialZoomChange(sliderMinValue, sliderMinValue);
    };

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const onClick = () => {
      fileInputRef.current?.click();
    };

    const isDisabledZoomIn = zoomValue === MAX_ZOOM;
    const isDisabledZoomOut = zoomValue === sliderMinValue;

    return (
      <div>
        {/* Drag and drop zone */}
        <div className="tw-relative tw-border tw-border-dashed tw-border-gray-200 hover:tw-border-blue-500 tw-p-4 tw-rounded">
          <input
            ref={fileInputRef}
            value={inputFilePath}
            onChange={onChangeFile}
            type="file"
            className="tw-opacity-0 tw-inset-0 tw-absolute tw-cursor-pointer"
            title=""
            accept="image/*"
          />

          {/* Image previw */}
          {!!value && <img src={value} className="tw-w-full tw-rounded tw-aspect-[11/5] tw-mb-4 tw-object-cover" />}

          {/* Delete image button */}
          {!!value && (
            <button
              className="tw-absolute tw-top-1 tw-right-1 tw-bg-white tw-h-7 tw-w-7 tw-rounded-full tw-shadow-lg tw-border tw-border-solid tw-border-gray-200 tw-flex tw-transition-all tw-cursor-pointer tw-text-gray-400 hover:tw-text-red-500"
              onClick={() => onFileUploaded('')}
            >
              <TrashIcon className="tw-w-5 tw-h-5 tw-m-auto" />
            </button>
          )}

          {/* Drag and drop zone content */}
          <div className="tw-flex tw-items-center tw-gap-2 tw-text-blue-500">
            <PlusCircleIcon className="tw-w-6 tw-h-6" />
            <p className="tw-text-sm">
              Drag and drop your image here or <span className="tw-underline tw-decoration-blue-300">browse files</span>
            </p>
          </div>
        </div>

        <Dialog isOpen={isCropperDialogOpen} className="kl-dialog !tw-w-auto">
          <div className="tw-flex tw-justify-between tw-m-4 align-center">
            <div className="tw-flex tw-gap-2.5 tw-items-center">
              <button
                className="tw-text-gray-900 tw-transition-all tw-cursor-pointer"
                onClick={onCloseModal}
                disabled={createImageMutation.isLoading}
              >
                <ArrowSmallLeftIcon className="tw--mt-2.5 tw-w-6 tw-h-6" />
              </button>
              <div className="tw-flex tw-flex-col">
                <h1 className="tw-text-xl tw-text-gray-900 tw-font-bold">{cropperDialogTitle}</h1>
                {fileSizeError ? (
                  <p className="text-danger text-sm-special">Sorry, the file is too large, your file must be less than 12 MB.</p>
                ) : (
                  <p className="tw-text-gray-500 text-sm-special">Place your image to crop it.</p>
                )}
              </div>
            </div>
            <div>
              <Button
                disabled={createImageMutation.isLoading}
                loading={createImageMutation.isLoading}
                intent="primary"
                text={createImageMutation.isLoading ? 'Uploading' : fileSizeError ? 'Choose a new file' : 'Apply'}
                onClick={fileSizeError ? onClick : onApplyImageCrop}
              />
            </div>
          </div>

          <div>
            <Cropper
              style={{height: 567, width: 720}}
              aspectRatio={cropperAspectRatio}
              preview=".img-preview"
              className={roundCroppedImage ? 'cropper-rounded' : ''}
              src={imageBase64}
              wheelZoomRatio={ZOOM_STEP}
              viewMode={1}
              cropBoxMovable={false}
              cropBoxResizable={false}
              background={false}
              dragMode="move"
              responsive={true}
              zoom={handleWheelEvent}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={setCropper}
              ready={onCropperReady}
              guides={false}
            />
          </div>
          <div className="tw-flex tw-relative tw-gap-6 tw-justify-center tw-items-center tw-mt-4">
            <button
              className={`${isDisabledZoomOut ? 'tw-text-gray-200' : 'tw-text-gray-300'} tw-transition-all tw-cursor-pointer `}
              onClick={() => onZoomChange(zoomValue - ZOOM_STEP)}
              disabled={isDisabledZoomOut}
            >
              <MagnifyingGlassMinusIcon className="tw-w-6 tw-h-6 tw-text-gray-500" />
            </button>
            <div className="tw-min-w-[400px]">
              <Slider
                value={[zoomValue]}
                disabled={createImageMutation.isLoading}
                min={sliderMinValue}
                max={MAX_ZOOM}
                step={ZOOM_STEP}
                onValueChange={([value]) => onZoomChange(value)}
              />
            </div>
            <button
              className={`${isDisabledZoomIn ? 'tw-text-gray-200' : 'tw-text-gray-300'} tw-transition-all tw-cursor-pointer`}
              onClick={() => onZoomChange(zoomValue + ZOOM_STEP)}
              disabled={isDisabledZoomIn}
            >
              <MagnifyingGlassPlusIcon className="tw-w-6 tw-h-6 tw-text-gray-500" />
            </button>
          </div>
        </Dialog>
      </div>
    );
  }
);
