import React, {useEffect, useState} from 'react';

import {Button} from '@blueprintjs/core';
import {DateRangePicker, DateRangePickerProps, DateRangeShortcut} from '@blueprintjs/datetime';
import {Popover2} from '@blueprintjs/popover2';
import {DateTime} from 'luxon';

import {getCompareTypeFromDates, getCompareTypeLabel, getShortcutDateRanges} from '../../utils/helpers.utils';
import {CompareType, DateTimeRange} from '../../utils/types';

export interface DateRangeButtonProps extends Omit<DateRangePickerProps, 'value' | 'defaultValue' | 'onChange'> {
  value?: DateTimeRange;
  onChange: (dateTimeRange: DateTimeRange, compareType: CompareType) => void;
}

const shortcuts = (): DateRangeShortcut[] => {
  return getShortcutDateRanges().map(([start, end, label]) => ({
    dateRange: [start.toJSDate(), end.toJSDate()],
    label: getCompareTypeLabel(label),
  }));
};

export const DateRangeButton: React.FC<DateRangeButtonProps> = ({value, onChange, ...props}) => {
  const [compareType, setCompareType] = useState(value ? getCompareTypeFromDates(value[0], value[1]) : 'custom');
  const [buttonText, setButtonText] = useState(value ? getButtonText(value, compareType) : 'Date Range');

  useEffect(() => {
    if (compareType && value) {
      setButtonText(getButtonText(value, compareType));
    }
  }, [value, compareType]);

  return (
    <Popover2
      position="bottom-right"
      content={
        <DateRangePicker
          {...props}
          allowSingleDayRange
          shortcuts={shortcuts()}
          timePrecision="minute"
          timePickerProps={{useAmPm: true}}
          value={value}
          onChange={([startDate, endDate]) => {
            const compareType = getCompareTypeFromDates(startDate, endDate);
            setCompareType(compareType);
            onChange([startDate, endDate], compareType);
          }}
        />
      }
    >
      <Button icon="calendar" outlined text={buttonText} />
    </Popover2>
  );
};

const getButtonText = ([start, end]: DateTimeRange, compareType: CompareType): string => {
  const startDateTime = start ? DateTime.fromJSDate(start) : null;
  const endDateTime = end ? DateTime.fromJSDate(end) : null;

  switch (compareType) {
    case 'today':
    case 'yesterday':
    case 'last-7-days':
    case 'last-30-days':
    case 'past-3-months':
    case 'past-6-months':
    case 'past-year':
    case 'past-2-years':
      return getCompareTypeLabel(compareType);
    default:
      if (startDateTime && endDateTime) {
        if (startDateTime.toISODate() === endDateTime.toISODate()) {
          return startDateTime.toLocaleString();
        }
        return `${startDateTime.toLocaleString()} - ${endDateTime.toLocaleString()}`;
      } else if (startDateTime) {
        return startDateTime.toLocaleString();
      } else if (endDateTime) {
        return endDateTime.toLocaleString();
      } else {
        return 'Date Range';
      }
  }
};
