import React from 'react';

import {Button, ButtonProps, Callout, MenuItem} from '@blueprintjs/core';
import {Suggest, SuggestProps} from '@blueprintjs/select';
import classNames from 'classnames';
import {ErrorBoundary} from 'react-error-boundary';
import PlacesAutocomplete, {Suggestion} from 'react-places-autocomplete';

import './selectors.styles.scss';

interface PlaceSelectorProps
  extends Omit<SuggestProps<Suggestion>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  value: string;
  fill?: boolean;
  buttonProps?: ButtonProps;
  onItemSelected: (value: string | undefined) => void;
}

const PlaceSelectorComponent = Suggest.ofType<Suggestion>();

export const PlaceSelector: React.FC<PlaceSelectorProps> = ({value, onItemSelected, buttonProps, ...props}) => {
  return (
    <ErrorBoundary FallbackComponent={() => <Callout intent="danger">An error ocurred loading this component</Callout>}>
      <PlacesAutocomplete value={value} onChange={(value) => onItemSelected(value)} onSelect={(value) => onItemSelected(value)}>
        {({loading, suggestions, getInputProps}) => {
          const inputProps = getInputProps();

          return (
            <PlaceSelectorComponent
              {...props}
              defaultSelectedItem={{description: props.inputProps?.defaultValue ?? ''} as any}
              inputValueRenderer={(suggestion) => suggestion.description || value}
              className={classNames(props.className, {'bp3-fill': props.fill})}
              popoverProps={{minimal: true}}
              items={[...suggestions]}
              itemRenderer={(suggestion, {modifiers, handleClick}) => (
                <MenuItem
                  active={modifiers.active}
                  disabled={modifiers.disabled}
                  key={suggestion.placeId}
                  onClick={handleClick}
                  text={suggestion.description}
                />
              )}
              onItemSelect={({description}) => onItemSelected(description)}
              // itemsEqual={(c1, c2) => c1.id === c2.id}
              // itemPredicate={(query, c1) => c1.name.toLocaleLowerCase().includes(query.toLowerCase())}
              noResults={<MenuItem disabled text="No results." />}
              inputProps={{
                ...(props.inputProps ?? {}),
                placeholder: 'Choose one...',
                rightElement: loading ? <Button minimal loading /> : undefined,
              }}
              onQueryChange={(value) => inputProps.onChange({target: {value}})}
            />
          );
        }}
      </PlacesAutocomplete>
    </ErrorBoundary>
  );
};
