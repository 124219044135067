import React from 'react';
import {BrowserRouter} from 'react-router-dom';

import {HotkeysProvider} from '@blueprintjs/core';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';

import {App} from './app';
import {AppWrapper} from './app-wrapper';
import {isSentryEnabled} from './utils/helpers.utils';

import './index.scss';
import ScrollTop from './common/components/layout/ScrollTop';

if ((module as any).hot) {
  (module as any).hot.accept();
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV,
  enabled: isSentryEnabled(),
});

ReactDOM.render(
  <BrowserRouter>
    <ScrollTop />
    <AppWrapper>
      <HotkeysProvider>
        <App />
      </HotkeysProvider>
    </AppWrapper>
  </BrowserRouter>,
  document.getElementById('root')
);
