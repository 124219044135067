import {Button, Callout, Dialog, DialogProps} from '@blueprintjs/core';
import {useEffect} from 'react';
import {useToasts} from '../../contexts/toasts.context';
import useClipboard from '../../hooks/useClipboard';

interface ApiKeyCreatedDialogProps extends DialogProps {
  isOpen: boolean;
  apiKey: string;
}

export function ApiKeyCreatedDialog({isOpen, apiKey, ...props}: ApiKeyCreatedDialogProps) {
  const {copy, copied} = useClipboard();

  const {
    state: {toaster},
  } = useToasts();

  useEffect(() => {
    if (copied) {
      toaster.show({message: 'API key copied to clipboard', intent: 'success'});
    }
  }, [copied, toaster]);

  return (
    <Dialog title="API Key Created" isOpen={isOpen} {...props}>
      <div className="tw-px-6 tw-pt-6 tw-flex tw-flex-col tw-gap-2 tw-text-sm">
        <p>Your API key has been successfully generated: </p>
        <Callout icon={null} intent="primary" className="tw-flex tw-items-center tw-justify-between">
          <span className="tw-font-bold">{apiKey}</span>
          <Button icon="duplicate" small intent="none" onClick={() => copy(apiKey)} title="Copy API Key" text="Copy" />
        </Callout>
        <p>Please make sure to copy and securely store your API key now. We won't display it again.</p>
        <p>Keep this key confidential and avoid sharing it publicly. If you lose it, you will need to generate a new one.</p>
        <Button intent="none" onClick={props.onClose} className="tw-w-20 tw-self-end tw-mt-2">
          Close
        </Button>
      </div>
    </Dialog>
  );
}
