import React, {ReactElement} from 'react';

import {CircularProgress} from '@material-ui/core';
import classNames from 'classnames';
import {isNil} from 'lodash';
import {Doughnut} from 'react-chartjs-2';

import {getColorForIndex} from '../../../utils/helpers.utils';
import {DashboardWidget, DashboardWidgetProps} from './dashboard-widget.component';

export interface DashboardWidgetTopCategoriesProps extends DashboardWidgetProps {
  title: string;
  centerTitle?: string | number;
  centerSubtitle?: string;
  datasets: Array<{categoryName: string; total: number}>;
  noDataComponent?: ReactElement;
}

export const DashboardWidgetTopCategories: React.FC<DashboardWidgetTopCategoriesProps> = ({
  title,
  datasets,
  noDataComponent,
  isLoading,
  centerTitle,
  centerSubtitle,
  errorMessage,
  ...itemProps
}) => {
  let component: ReactElement | null = null;

  if (isLoading) {
    component = <CircularProgress className="widget-spinner" />;
  } else if (!isNil(errorMessage)) {
    component = <p className="widget-error">{errorMessage}</p>;
  } else if (datasets.length === 0) {
    component = noDataComponent ?? null;
  } else if (!component) {
    component = (
      <>
        <div className="chart-container">
          <Doughnut
            data={{
              datasets: [
                {
                  label: 'Categories',
                  data: datasets.map(({total}) => total),
                  backgroundColor: datasets.map((_, idx) => getColorForIndex(idx)),
                  borderWidth: 1,
                },
              ],
              labels: datasets.map(({categoryName}) => categoryName),
            }}
            options={{legend: {display: false}, responsive: true, cutoutPercentage: 70}}
          />
          <div className="chart-center">
            <h3 className="center-title">{centerTitle}</h3>
            <small className="center-subtitle">{centerSubtitle}</small>
          </div>
        </div>
        <div className="chart-legend">
          {datasets.map(({categoryName}, idx) => (
            <div key={categoryName} className="chart-legend-item">
              <span className="legend-color" style={{backgroundColor: getColorForIndex(idx)}} />
              {categoryName}
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <DashboardWidget {...itemProps} className={classNames('widget-top-categories', itemProps.className)}>
      <h4 className="widget-title">{title}</h4>
      {component}
    </DashboardWidget>
  );
};
