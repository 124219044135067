import React, {useEffect} from 'react';

export const SupportScene: React.FC = () => {
  useEffect(() => {
    const scriptLibElement = document.createElement('script');
    const scriptAccountUrlElement = document.createElement('script');
    scriptAccountUrlElement.async = true;
    scriptAccountUrlElement.innerHTML = 'var helpjuice_account_url = "https://kontactless.helpjuice.com/";';
    scriptLibElement.src = 'https://s3.amazonaws.com/assets.helpjuice.com/helpjuice.min.js';
    document.body.appendChild(scriptAccountUrlElement);
    document.body.appendChild(scriptLibElement);
    return () => {
      document.body.removeChild(scriptAccountUrlElement);
      document.body.removeChild(scriptLibElement);
    };
  }, []);

  return (
    <div id="helpjuice_js_widget_content" className="support-dashboard">
      <img src="https://helpjuice.com/images/cube-theme/loading.gif" className="helpjuice-loading-spinner" />
    </div>
  );
};
