import React, {useMemo} from 'react';
import {Button, NonIdealState, Spinner} from '@blueprintjs/core';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {flexRender, getCoreRowModel, getPaginationRowModel, useReactTable} from '@tanstack/react-table';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {useAuth} from '../../contexts/auth.context';
import {useAlerts} from '../../contexts/alerts.context';
import {deleteOrdersReportRequest, fetchAllOrdersReports} from '../../api/orders-report.api';
import {useToasts} from '../../contexts/toasts.context';
import {createTableColumns} from './orders-reports-table.columns';
import {OrderStatus} from '../../utils/types';

const TableSpinner: React.FC<{columnsAmount: number}> = ({columnsAmount}) => {
  return (
    <tbody className="table__body">
      <tr className="table__row">
        <td colSpan={columnsAmount} className="table--empty">
          <Spinner intent="primary" />
        </td>
      </tr>
    </tbody>
  );
};

const EmptyState: React.FC<{columnsAmount: number}> = ({columnsAmount}) => {
  return (
    <tbody className="table__body">
      <tr className="table__row">
        <td colSpan={columnsAmount} className="table--empty">
          <NonIdealState
            title="No results found"
            icon="inbox-search"
            description={
              <>
                Change the store or export some data in <a href="#orders-history">Orders History</a>
              </>
            }
          />
        </td>
      </tr>
    </tbody>
  );
};

export interface OrdersReport {
  id?: number;
  startDate?: string;
  endDate?: string;
  fileUrl?: string;
  status?: OrderStatus;
  createdAt: string;
}

interface OrderReportsTableProps {
  store?: StoreDto;
}

export const OrderReportsTable: React.FC<OrderReportsTableProps> = ({store, ...props}) => {
  const {user} = useAuth();
  const queryClient = useQueryClient();
  const {alertsDispatch} = useAlerts();

  const {data: ordersReports, isLoading} = useQuery(
    ['orders-reports', store?.id!],
    () => fetchAllOrdersReports(user.token, store?.id!),
    {
      refetchInterval: 5000,
      enabled: !!store?.id,
    }
  );
  const {
    state: {toaster},
  } = useToasts();

  const deleteOrdersReportMutation = useMutation<{}, Error, number>((ordersReportId) =>
    deleteOrdersReportRequest(user.token, ordersReportId)
  );

  const onDeletePrintJobActionClick = async (ordersReport: OrdersReport) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: (
          <>
            Do you want to delete this report?.
            <br />
            This action can't be undone.
          </>
        ),
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Yes, delete it',
        cancelButtonText: `No, I don't`,
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteOrdersReportMutation.mutateAsync(ordersReport.id as number);
            queryClient.invalidateQueries(['orders-reports', store?.id!]);
            toaster.show({intent: 'success', message: 'Report was deleted successfully'});
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error while deleting the report'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  const data = useMemo(() => ordersReports || [], [ordersReports]);
  const columns = useMemo(() => createTableColumns(store as StoreDto, onDeletePrintJobActionClick), [store?.timezone]);

  const table = useReactTable({
    data,
    columns,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  }) as any;

  const pagination = useMemo(() => table.getState().pagination, [ordersReports, table.getState().pagination.pageIndex]);

  const renderTableBody = () => {
    if (isLoading || !ordersReports) {
      return <TableSpinner columnsAmount={columns.length} />;
    }
    if (data.length === 0) {
      return <EmptyState columnsAmount={columns.length} />;
    }

    return (
      <TransitionGroup component="tbody" className="table__body">
        {table.getRowModel().rows.map((row) => (
          <CSSTransition timeout={250} classNames="fade-in" key={row.original.id}>
            <tr key={row.original.id} className="table__row">
              {row.getAllCells().map((cell, index) => {
                return (
                  <td key={index} className={`table__cell ${cell.column.columnDef.className}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  };

  const renderPageButton = (pageIndex: number) => {
    const pageCount = table.getPageCount();
    /**
     * If we are either at the begining (index == 0 or 1), or at the end (index == n-1 or n),
     * we need to "look around" 3 or 4 pages to keep the pages displayed at a fixed count (5).
     * If we are somewhere in the middle (index > 1 or index < n-1), we look around 2 pages
     */

    let lookAround = 2;
    if (pagination.pageIndex === 0 || pageCount - 1 - pagination.pageIndex === 0) {
      lookAround += 2;
    } else if (pagination.pageIndex === 1 || pageCount - 1 - pagination.pageIndex === 1) {
      lookAround += 1;
    }
    if (pagination.pageIndex - lookAround > pageIndex || pageIndex - pagination.pageIndex > lookAround) {
      return null;
    }

    return (
      <Button
        text={pageIndex + 1}
        minimal={!(pagination.pageIndex === pageIndex)}
        onClick={() => table.setPageIndex(pageIndex)}
        intent={pagination.pageIndex === pageIndex ? 'primary' : 'none'}
      />
    );
  };

  const renderPaginationRecordsMessage = () => {
    const pageSize = table.getPageCount();
    const start = pagination.pageIndex * pageSize + 1;

    let end = (pagination.pageIndex + 1) * pageSize;
    if (end > data.length) {
      end = data.length;
    }

    return `Showing ${start} to ${end} out of ${data.length} records`;
  };

  return (
    <div className="p-2">
      <table className="table">
        <thead className="table__head">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="table__row" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className={`table__heading ${header.column.columnDef.className}`} key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {renderTableBody()}
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <div className="table__pagination">
        <div>
          <Button
            minimal
            icon="double-chevron-left"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          />
          <Button minimal icon="chevron-left" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
          {Array.from({length: table.getPageCount()}, (_, index) => index).map((pageIndex) => {
            return renderPageButton(pageIndex);
          })}
          <Button minimal icon="chevron-right" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
          <Button
            minimal
            icon="double-chevron-right"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          />
        </div>
        {!!data.length && (
          <div>
            <span>
              Page {pagination.pageIndex + 1} of {table.getPageCount()}
            </span>
            <span> – </span>
            <span>{renderPaginationRecordsMessage()}</span>
          </div>
        )}
      </div>
    </div>
  );
};
