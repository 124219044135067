import {sortBy} from 'lodash';
import {QueryClient} from 'react-query';

import {PrinterDto} from '@kontactless/admin-api/printer/printer.dto';

export type CachePrinterAction =
  | {type: 'upsert-printer'; printer: PrinterDto}
  | {type: 'remove-printer'; printer: {id: number; storeId: number}};

export const dispatchPrinterAction = (queryClient: QueryClient, action: CachePrinterAction) => {
  switch (action.type) {
    case 'upsert-printer':
      upsertPrinter(queryClient, action.printer);
      break;
    case 'remove-printer':
      removePrinter(queryClient, action.printer.id, action.printer.storeId);
      break;
  }
};

const removePrinter = (queryClient: QueryClient, printerId: number, storeId: number) => {
  queryClient.setQueryData<PrinterDto[] | undefined>(['printers', storeId], () => {
    const cachedPrinters = queryClient.getQueryData<PrinterDto[]>(['printers', storeId]);

    if (cachedPrinters) {
      return cachedPrinters.filter(({id}) => id !== printerId);
    }
  });
};

const upsertPrinter = (queryClient: QueryClient, printer: PrinterDto) => {
  queryClient.setQueryData<PrinterDto[] | undefined>(['printers', printer.storeId], () => {
    const cachedPrinters = queryClient.getQueryData<PrinterDto[]>(['printers', printer.storeId]);
    let printers: PrinterDto[] = [];
    if (cachedPrinters) {
      printers = sortBy(cachedPrinters.filter(({id}) => id !== printer.id).concat([printer]), ({id}) => id);

      return printers;
    }
  });
};
