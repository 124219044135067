import React, {useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Button, Callout, Card, IRef, InputGroup, NonIdealState, Spinner, Tag, useHotkeys} from '@blueprintjs/core';
import {faStoreSlash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useQuery} from 'react-query';

import {fetchAllStoresByCompanyIdRequest} from '../../api/store.api';
import {useAuth} from '../../contexts/auth.context';
import {OperationCreateDialog} from '../components/OperationCreateDialog';
import {OperationsListItem} from '../components/OperationsListItem';

import './stores.styles.scss';

export const OperationsList: React.FC = () => {
  const {user} = useAuth();

  const params = useParams<{companyId: string}>();
  const companyId = Number(params.companyId);
  const [isStoreCreateDialogOpen, setIsStoreCreateDialogOpen] = useState(false);
  const [nameFitler, setNameFitler] = useState('');

  const storesRequest = useQuery(['stores', {companyId}], () => fetchAllStoresByCompanyIdRequest(user.token, companyId));

  const inputRef = useRef<HTMLInputElement>();

  const hotkeys = useMemo(
    () => [
      {
        combo: 'SHIFT + S',
        global: true,
        label: 'Focus text input',
        onKeyDown: () => {
          inputRef.current?.focus();
        },
        preventDefault: true,
      },
    ],
    [inputRef.current]
  );

  const {} = useHotkeys(hotkeys);

  const filteredStores = useMemo(() => {
    const stores = storesRequest.data ?? [];

    return (
      stores.filter((store) => {
        let result = true;

        if (nameFitler) {
          result = store.name.toLowerCase().includes(nameFitler.toLowerCase());
        }
        return result;
      }) ?? []
    );
  }, [storesRequest, nameFitler]);

  const renderContent = () => {
    if (storesRequest.isLoading) {
      return <Spinner intent="primary" />;
    }

    if (storesRequest.isError) {
      return (
        <Callout intent="danger" className="scene-error">
          Error fetching stores.
        </Callout>
      );
    }

    if (storesRequest.data) {
      if (!filteredStores.length) {
        return (
          <Card>
            <NonIdealState
              icon={<FontAwesomeIcon icon={faStoreSlash} />}
              title="No stores found"
              description="We couldn't find any stores. Have you tried adding one?"
            />
          </Card>
        );
      }

      return (
        <ul className="tw-border-t tw-border-l tw-border-r tw-border-solid tw-border-gray-200 tw-text-gray-900">
          {filteredStores.map((store) => (
            <OperationsListItem key={store.id} store={store} />
          ))}
        </ul>
      );
    }
  };

  return (
    <>
      <section className="scene scene-content stores-scene">
        <header className="scene-header">
          <h1 className="scene-title">Stores</h1>
          <div className="actions">
            <InputGroup
              inputRef={inputRef as IRef<HTMLInputElement>}
              onChange={(event) => setNameFitler(event?.target.value)}
              placeholder="Search stores by name"
              value={nameFitler}
              rightElement={
                nameFitler ? <Button onClick={() => setNameFitler('')} minimal icon="cross" /> : <Tag minimal>Shift + S</Tag>
              }
            />
            {['superadmin', 'company-admin'].includes(user.securityLevel) && (
              <Button intent="primary" onClick={() => setIsStoreCreateDialogOpen(true)}>
                Add new store
              </Button>
            )}
          </div>
        </header>
        {renderContent()}
      </section>
      <OperationCreateDialog
        isOpen={isStoreCreateDialogOpen}
        companyId={companyId}
        onClose={() => setIsStoreCreateDialogOpen(false)}
      />
    </>
  );
};
