import React, {createRef, useMemo} from 'react';

import {Button, InputGroup, MenuItem} from '@blueprintjs/core';
import {ISuggestProps, Suggest} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';

import {fetchAllOmnivoreRevenueCentersRequest} from '../../api/omnivore.api';
import {useAuth} from '../../contexts/auth.context';
import {OmnivoreRevenueCenterDto} from '@kontactless/admin-api/omnivore-revenue-center/omnivore-revenue-center.dto';

import './selectors.styles.scss';

interface OmnivoreRevenueCenterSelectorProps
  extends Omit<ISuggestProps<OmnivoreRevenueCenterDto>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  storeId: number;
  fill?: boolean;
  selectedItemId?: string;
  onItemSelected: (item: OmnivoreRevenueCenterDto | undefined) => void;
}

const OmnivoreRevenueCenterSelectorComponent = Suggest.ofType<OmnivoreRevenueCenterDto>();

export const OmnivoreRevenueCenterSelector: React.FC<OmnivoreRevenueCenterSelectorProps> = ({
  storeId,
  selectedItemId,
  onItemSelected,
  ...props
}) => {
  const {user} = useAuth();
  const revenueCentersQuery = useQuery(['omnivore-revenue-centers', storeId], () =>
    fetchAllOmnivoreRevenueCentersRequest(user.token, storeId)
  );
  const inputRef = createRef<any>();

  const selectedItem = useMemo(() => {
    return revenueCentersQuery.data?.find(({revenueCenterId}) => revenueCenterId === selectedItemId);
  }, [revenueCentersQuery.data, selectedItemId]);

  const getRevenueCenterLabel = (revenueCenter: OmnivoreRevenueCenterDto): string => {
    return `(${revenueCenter.posId ?? ''}) - ${revenueCenter.name || 'no revenue center name'}`;
  };

  if (revenueCentersQuery.isError) {
    return <InputGroup {...(props.inputProps ?? {})} intent="danger" value="Error fetching omnivore revenue centers" readOnly />;
  }

  return (
    <OmnivoreRevenueCenterSelectorComponent
      {...props}
      inputValueRenderer={(revenueCenter) => (revenueCentersQuery.isFetching ? '' : getRevenueCenterLabel(revenueCenter))}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      items={revenueCentersQuery.data ?? []}
      itemRenderer={(revenueCenter, {modifiers, handleClick}) => (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={revenueCenter.revenueCenterId}
          onClick={handleClick}
          text={getRevenueCenterLabel(revenueCenter)}
        />
      )}
      onItemSelect={(value) => onItemSelected(value)}
      itemsEqual={(e1, e2) => e1.revenueCenterId === e2.revenueCenterId}
      selectedItem={selectedItem ?? null}
      itemPredicate={(query, revenueCenter) =>
        !!revenueCenter.revenueCenterId.toLocaleLowerCase().includes(query.toLowerCase()) ||
        !!revenueCenter.name?.toLocaleLowerCase().includes(query.toLowerCase())
      }
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      inputProps={{
        ...(props.inputProps ?? {}),
        leftElement: revenueCentersQuery.isFetching ? <Button loading minimal /> : undefined,
        placeholder: revenueCentersQuery.isFetching ? 'Loading recenue centers...' : 'Choose one...',
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
      }}
    />
  );
};
