import React, {useState} from 'react';
import {Button, Callout, FormGroup} from '@blueprintjs/core';
import classNames from 'classnames';
import {groupBy, maxBy, sortBy} from 'lodash';
import {DateTime} from 'luxon';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import useSound from 'use-sound';
import {
  fetchCurrentOrdersRequest,
  fetchOrdersCancelledRequest,
  fetchOrdersScheduledRequest,
  updateOrderStatusRequest,
} from '../../api/order.api';
import {OrderCard} from '../components/order-card.component';
import {OrderDetailsDialog} from '../components/OrderDetailsDialog';
import {OrdersListDialog} from '../components/orders-list-dialog.component';
import {PrintersStatus} from '../../components/printers/printers-status.component';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {OrderStatus} from '../../utils/types';
import {EventDto} from '@kontactless/admin-api/event/event.dto';
import {OrderDto, OrderStatusUpdateRequestDto} from '@kontactless/admin-api/order/order.dto';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchStoreRequest} from '../../api/store.api';
import {Header} from '../../common/components/layout/Header';
import {Container} from '../../common/components/layout/Container';
import {EventsMultiSelector} from '../../components/selectors/event-multi-selector.component';
import Restricted from '../../common/components/Restricted';

interface OrdersForm {
  events?: EventDto[];
  statuses: OrderStatus[];
}

export function OrdersPage() {
  const {user} = useAuth();
  const params = useParams<{companyId: string; storeId: string}>();
  const storeId = Number(params.storeId);
  const navigate = useNavigate();

  const [isOrderDetailsDialogOpen, setIsOrderDetailsDialogOpen] = useState(false);
  const [ordersListDialog, setOrdersListDialog] = useState<{
    isOpen: boolean;
    title: string;
    noItemsMessage: string;
    orders: OrderDto[];
  }>({isOpen: false, title: '', noItemsMessage: '', orders: []});

  const storeQuery = useQuery({queryKey: ['stores', storeId], queryFn: () => fetchStoreRequest(user.token, storeId)});

  const [selectedOrder, setSelectedOrder] = useState<OrderDto | undefined>();
  const [lastOrderNumber, setLastOrderNumber] = useState<number | null | undefined>();
  const {control, watch} = useForm<OrdersForm>({
    defaultValues: {
      events: [],
      statuses: ['received', 'preparing', 'ready', 'delivered'],
    },
  });

  const soundNewOrder = 'https://assets.kless.io/sounds/new-order.mp3';
  const soundReminder = 'https://assets.kless.io/sounds/order-reminder.mp3';

  const [playNewOrderAudio] = useSound(soundNewOrder);
  const [playOrderReminderAudio] = useSound(soundReminder);

  const events = watch('events');
  const ordersQueryKey = ['stores', storeId, 'orders', {eventIds: events?.map((e) => e.id)}];
  const queryClient = useQueryClient();
  const {
    state: {toaster},
  } = useToasts();

  const ordersQuery = useQuery({
    queryKey: ordersQueryKey,
    queryFn: () =>
      fetchCurrentOrdersRequest(
        user.token,
        storeId!,
        events?.map((e) => e.id)
      ),
    refetchInterval: 1000 * 30,
    onSuccess: (orders) => {
      if (storeQuery.data?.settings?.ringNotificationOnPlacedOrder) {
        const maxOrderNumber = maxBy(orders, ({number}) => number)?.number;

        // First fetch shouldn't ring
        if (lastOrderNumber === undefined) {
          setLastOrderNumber(maxOrderNumber ?? null);
          return;
        }

        // After the first time, sound only if there are orders
        if (lastOrderNumber === null && maxOrderNumber != null) {
          setLastOrderNumber(maxOrderNumber);
          playNewOrderAudio();
          return;
        }

        // After the second time sound always a new order arrives
        if (lastOrderNumber != null && maxOrderNumber != null && maxOrderNumber > lastOrderNumber) {
          setLastOrderNumber(maxOrderNumber);
          playNewOrderAudio();
        }
      }
    },
  });

  useQuery({
    queryKey: ['orders-reminder', storeId],
    queryFn: () => {
      const orderToRemind = ordersQuery.data?.find((order) => {
        if (order.status === 'received' && DateTime.fromISO(order.statusUpdatedAt).diffNow('minutes').minutes < -2) {
          return order;
        }
      });

      if (orderToRemind) {
        playOrderReminderAudio();
      }
    },
    refetchInterval: 1000 * 60 * 2,
  });

  const cancelledOrdersQuery = useQuery({
    queryKey: ['orders', storeId, 'cancelled'],
    queryFn: () =>
      fetchOrdersCancelledRequest(user.token, {
        storeId: storeId!,
        dateStart: DateTime.local().minus({days: 1}).toISO(),
        dateEnd: DateTime.local().toISO(),
      }),
    refetchInterval: 1000 * 30, // 5 sec
  });

  const scheduledOrdersQuery = useQuery({
    queryKey: ['orders', storeId, 'scheduled'],
    queryFn: () =>
      fetchOrdersScheduledRequest(user.token, {
        storeId: storeId!,
      }),
    refetchInterval: 1000 * 30, // 5 sec
  });

  const groupedOrders = groupBy(
    sortBy(
      ordersQuery.data?.map((order) => order),
      ({statusUpdatedAt}) => statusUpdatedAt
    ),
    ({status}) => status
  );

  const updateOrderStatusMutation = useMutation<
    OrderDto,
    Error,
    {id: number} & OrderStatusUpdateRequestDto,
    OrderDto[] | undefined
  >(
    (update: {id: number} & OrderStatusUpdateRequestDto): Promise<OrderDto> =>
      updateOrderStatusRequest(user.token, update.id, update),
    {
      onMutate: async (updatedOrder: {id: number} & OrderStatusUpdateRequestDto) => {
        await queryClient.cancelQueries(ordersQueryKey);
        const previousSnapshot: OrderDto[] | undefined = queryClient.getQueryData(ordersQueryKey);

        // Optimistically update to the new value
        queryClient.setQueryData(
          ordersQueryKey,
          previousSnapshot?.map((order) => {
            return order.id === updatedOrder.id ? {...order, status: updatedOrder.status} : order;
          })
        );

        return previousSnapshot;
      },
      onSuccess: (updatedOrder: OrderDto) => {
        toaster.show({intent: 'success', message: `Order #${updatedOrder.number} was updated to ${updatedOrder.status}`});
      },
      onError: (err: Error, updatedOrder: {id: number} & OrderStatusUpdateRequestDto, orders: OrderDto[] | undefined) => {
        console.error(`Error updating order ${updatedOrder.id}: ${err}`);
        queryClient.setQueryData(ordersQueryKey, orders);
        toaster.show({intent: 'danger', message: `The order could not be updated to ${updatedOrder.status}`});
      },
      onSettled: () => {
        queryClient.invalidateQueries(ordersQueryKey);
      },
    }
  );

  const updateOrderStatus = ({id: number, status: string}) => {
    return updateOrderStatusMutation.mutate({id: number, status: string});
  };

  return (
    <>
      <Header>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-gap-4 tw-items-center">
            <Restricted to={['superadmin', 'company-admin', 'supervisor']}>
              <Button
                text="Back"
                icon="chevron-left"
                minimal
                small
                className="!tw-bg-blue-100 !tw-rounded-lg"
                onClick={() => navigate('..')}
              />
            </Restricted>

            <h1 className="tw-text-base tw-font-bold">Orders</h1>
          </div>

          <Restricted to={['superadmin', 'company-admin', 'supervisor']}>
            <div className="tw-flex tw-gap-4 tw-items-center">
              <Button text="View orders history" minimal intent="primary" onClick={() => navigate('history')} />
            </div>
          </Restricted>
        </div>
      </Header>

      <Container>
        <div className="tw-flex tw-gap-4 tw-items-center tw-justify-between tw-w-full tw-mb-4">
          {storeQuery.data?.eventsFeatureEnabled && (
            <FormGroup label="Filter events" inline className="!tw-mb-0 !tw-items-center tw-whitespace-nowrap">
              <Controller
                name="events"
                control={control}
                render={(props) => (
                  <EventsMultiSelector
                    companyId={watch('companyId')}
                    storeId={storeId}
                    selectedValues={props.value}
                    onItemSelect={(event) => {
                      if (props.value.map(({id}) => id).includes(event.id)) {
                        props.onChange([...props.value.filter(({id}) => id !== event.id)]);
                      } else {
                        props.onChange([...props.value, event]);
                      }
                    }}
                    onRemove={(event) => {
                      props.onChange([...props.value.filter(({id}) => id !== event.id)]);
                    }}
                  />
                )}
              />
            </FormGroup>
          )}

          <div className="tw-flex tw-items-center tw-gap-4 tw-ml-auto">
            <PrintersStatus storeId={storeId} />
            <Button
              className="tw-truncate"
              text={`Cancelled orders ${cancelledOrdersQuery.data ? `(${cancelledOrdersQuery.data.length})` : ''}`}
              intent="primary"
              outlined
              disabled={cancelledOrdersQuery.isFetching || !cancelledOrdersQuery.data?.length}
              // rightIcon={cancelledOrdersQuery.isFetching && <Spinner size={15} />}
              onClick={async () => {
                setOrdersListDialog({
                  isOpen: true,
                  title: 'Cancelled Orders',
                  noItemsMessage: 'There were no orders cancelled in the last 24 hours',
                  orders: cancelledOrdersQuery.data ?? [],
                });
              }}
            />
            <Button
              className="tw-truncate"
              text={`Scheduled orders ${scheduledOrdersQuery.data ? `(${scheduledOrdersQuery.data.length})` : ''}`}
              intent="primary"
              outlined
              disabled={scheduledOrdersQuery.isFetching || !scheduledOrdersQuery.data?.length}
              // rightIcon={scheduledOrdersQuery.isFetching && <Spinner size={15} />}
              onClick={async () => {
                setOrdersListDialog({
                  isOpen: true,
                  title: 'Scheduled Orders',
                  noItemsMessage: 'There were no orders scheduled',
                  orders: scheduledOrdersQuery.data ?? [],
                });
              }}
            />
          </div>
        </div>
        {ordersQuery.error && (
          <Callout className="scene-error" intent="danger">
            An error ocurred fetching the orders
          </Callout>
        )}

        <div className="order-statuses-list">
          {watch('statuses').includes('received') && (
            <div className="order-status-item">
              <header className={classNames('status-header', 'is-received')}>
                <h1 className="status-title">Received</h1>
                <p className="total-orders">
                  Total <span className="total-orders-count">{groupedOrders.received?.length ?? 0}</span>
                </p>
              </header>
              <div className="orders-list no-scrollbar">
                {groupedOrders.received
                  ?.filter(({status}) => status === 'received')
                  .map((order) => (
                    <OrderCard
                      key={order.id}
                      order={order}
                      sendTo="preparing"
                      onSeeDetailsClick={() => {
                        setSelectedOrder(order);
                        setIsOrderDetailsDialogOpen(true);
                      }}
                      confirmOrderStatusUpdate={storeQuery.data?.settings?.confirmOrderStatusUpdate}
                      enabledNextStatuses={['preparing', 'ready', 'delivered']}
                      updateOrderStatus={updateOrderStatus}
                      isLoading={updateOrderStatusMutation.isLoading}
                    />
                  )) ?? null}
              </div>
            </div>
          )}
          {watch('statuses').includes('preparing') && (
            <div className="order-status-item">
              <header className={classNames('status-header', 'is-preparing')}>
                <h1 className="status-title">Preparing</h1>
                <p className="total-orders">
                  Total <span className="total-orders-count">{groupedOrders.preparing?.length ?? 0}</span>
                </p>
              </header>
              <div className="orders-list no-scrollbar">
                {groupedOrders.preparing
                  ?.filter(({status}) => status === 'preparing')
                  .map((order) => (
                    <OrderCard
                      key={order.id}
                      order={order}
                      sendTo="ready"
                      onSeeDetailsClick={() => {
                        setSelectedOrder(order);
                        setIsOrderDetailsDialogOpen(true);
                      }}
                      confirmOrderStatusUpdate={storeQuery.data?.settings?.confirmOrderStatusUpdate}
                      enabledNextStatuses={['received', 'ready', 'delivered']}
                      updateOrderStatus={updateOrderStatus}
                      isLoading={updateOrderStatusMutation.isLoading}
                    />
                  )) ?? null}
              </div>
            </div>
          )}
          {watch('statuses').includes('ready') && (
            <div className="order-status-item">
              <header className={classNames('status-header', 'is-ready')}>
                <h1 className="status-title">Ready</h1>
                <p className="total-orders">
                  Total <span className="total-orders-count">{groupedOrders.ready?.length ?? 0}</span>
                </p>
              </header>
              <div className="orders-list no-scrollbar">
                {groupedOrders.ready
                  ?.filter(({status}) => status === 'ready')
                  .map((order) => (
                    <OrderCard
                      key={order.id}
                      order={order}
                      sendTo="delivered"
                      onSeeDetailsClick={() => {
                        setSelectedOrder(order);
                        setIsOrderDetailsDialogOpen(true);
                      }}
                      confirmOrderStatusUpdate={storeQuery.data?.settings?.confirmOrderStatusUpdate}
                      enabledNextStatuses={['received', 'preparing', 'delivered']}
                      updateOrderStatus={updateOrderStatus}
                      isLoading={updateOrderStatusMutation.isLoading}
                    />
                  )) ?? null}
              </div>
            </div>
          )}
          {watch('statuses').includes('delivered') && (
            <div className="order-status-item">
              <header className={classNames('status-header', 'is-delivered')}>
                <h1 className="status-title">Delivered</h1>
                <p className="total-orders">
                  Total <span className="total-orders-count">{groupedOrders.delivered?.length ?? 0}</span>
                </p>
              </header>
              <div className="orders-list no-scrollbar">
                {groupedOrders.delivered
                  ?.filter(({status}) => status === 'delivered')
                  .map((order) => (
                    <OrderCard
                      key={order.id}
                      order={order}
                      onSeeDetailsClick={() => {
                        setSelectedOrder(order);
                        setIsOrderDetailsDialogOpen(true);
                      }}
                      confirmOrderStatusUpdate={storeQuery.data?.settings?.confirmOrderStatusUpdate}
                      disableOverdue
                      updateOrderStatus={updateOrderStatus}
                      isLoading={updateOrderStatusMutation.isLoading}
                    />
                  )) ?? null}
              </div>
            </div>
          )}
        </div>
      </Container>

      {!!selectedOrder && (
        <OrderDetailsDialog
          isOpen={isOrderDetailsDialogOpen}
          orderId={selectedOrder.id}
          timezone={storeQuery.data?.timezone || 'America/Los_Angeles'}
          onClose={() => {
            setSelectedOrder(undefined);
            setIsOrderDetailsDialogOpen(false);
          }}
        />
      )}
      <OrdersListDialog
        title={ordersListDialog.title}
        isOpen={ordersListDialog.isOpen}
        orders={ordersListDialog.orders}
        timezone={storeQuery.data?.timezone}
        noItems={<Callout icon="info-sign">{ordersListDialog.noItemsMessage}</Callout>}
        onClose={() => setOrdersListDialog({...ordersListDialog, isOpen: false})}
        updateOrderStatus={updateOrderStatus}
        isLoading={updateOrderStatusMutation.isLoading}
      />
    </>
  );
}
