import React, {useEffect, useState} from 'react';

import {Button, Classes, Dialog, DialogProps} from '@blueprintjs/core';
import classNames from 'classnames';
import QRCode from 'qrcode';

import {useToasts} from '../../contexts/toasts.context';
import {getQrUrl} from '../../utils/helpers.utils';
import {LocationDto} from '@kontactless/admin-api/location/location.dto';
import {LocationGroupsDto} from '@kontactless/admin-api/location-groups/location-groups.dto';

export interface LocationQrDialogProps extends DialogProps {
  location?: LocationDto | LocationGroupsDto;
}

export function LocationQrDialog({location, ...props}: LocationQrDialogProps) {
  const {
    state: {toaster},
  } = useToasts();
  const [qrUrl, setQrUrl] = useState<string>('');
  const [qrUrlError, setQrUrlError] = useState<string>('');
  const url = location?.qrCode ? getQrUrl(location.qrCode) : null;

  const onCopyClick = async () => {
    try {
      if (url) {
        await navigator.clipboard.writeText(url);
      }

      toaster.show({intent: 'success', message: `QR Address copied to clipboard`});
    } catch (error) {
      toaster.show({intent: 'danger', message: `An error ocurred copying the QR Address to the clipboard`});
    }
  };

  useEffect(() => {
    if (url) {
      QRCode.toDataURL(url).then(setQrUrl).catch(setQrUrlError);
    }
  }, [url]);

  return (
    <Dialog title={location?.name} className="kl-dialog location-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, 'tw-flex tw-items-center tw-flex-col')}>
        {!!qrUrlError && <p className="error">{qrUrlError}</p>}
        {!!qrUrl && (
          <figure>
            <img src={qrUrl} alt="QR Code" />
          </figure>
        )}
        <p className="center">
          <a href={url!} className="bp3-button bp3-intent-primary bp3-minimal" target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </p>
      </div>
      <footer className={classNames(Classes.DIALOG_FOOTER, 'center')}>
        <Button text="Copy QR Address" intent="primary" onClick={onCopyClick} />
      </footer>
    </Dialog>
  );
}
