import {callApi} from './api';
import {MenuCategoryProductDto} from '@kontactless/admin-api/menu-category-product/menu-category-product.dto';
import {ProductDto} from '@kontactless/admin-api/product/product.dto';
import {OmnivoreCategoryDto, OmnivoreStartPullRequestDto} from '@kontactless/admin-api/omnivore-category/omnivore-category.dto';
import {OmnivoreOrderTypeDto} from '@kontactless/admin-api/omnivore-order-type/omnivore-order-type.dto';
import {OmnivoreRevenueCenterDto} from '@kontactless/admin-api/omnivore-revenue-center/omnivore-revenue-center.dto';
import {OmnivoreTableDto} from '@kontactless/admin-api/omnivore-table/omnivore-table.dto';
import {OmnivoreServiceChargeDto} from '@kontactless/admin-api/omnivore-service-charge/omnivore-service-charge.dto';
import {OmnivoreTenderTypeDto} from '@kontactless/admin-api/omnivore-tender-type/omnivore-tender-type.dto';
import {OmnivoreEmployeeDto} from '@kontactless/admin-api/omnivore-employee/omnivore-employee.dto';
import {OmnivoreMenuItemAddToMenuCategoryRequestDto} from '@kontactless/admin-api/omnivore-menu-item/omnivore-menu-item.dto';

export const fetchAllOmnivoreCategoriesRequest = async (token: string, storeId: number): Promise<OmnivoreCategoryDto[]> => {
  return callApi<OmnivoreCategoryDto[]>(`/stores/${storeId}/omnivore/categories`, {token}).then(({data}) => data);
};

export const addOmnivoreMenuItemToMenuCategoryRequest = async (
  token: string,
  {storeId, menuCategoryId, menuItemId}: OmnivoreMenuItemAddToMenuCategoryRequestDto
): Promise<MenuCategoryProductDto> => {
  return callApi<MenuCategoryProductDto>(`/stores/${storeId}/omnivore/menu-items/${menuItemId}`, {
    token,
    method: 'POST',
    body: JSON.stringify({menuCategoryId}),
  }).then(({data}) => data);
};

export const fetchAllOmnivoreEmployeesRequest = async (token: string, storeId: number): Promise<OmnivoreEmployeeDto[]> => {
  return callApi<OmnivoreEmployeeDto[]>(`/stores/${storeId}/omnivore/employees`, {token}).then(({data}) => data);
};

export const fetchAllOmnivoreRevenueCentersRequest = async (
  token: string,
  storeId: number
): Promise<OmnivoreRevenueCenterDto[]> => {
  return callApi<OmnivoreRevenueCenterDto[]>(`/stores/${storeId}/omnivore/revenue-centers`, {token}).then(({data}) => data);
};

export const fetchAllOmnivoreOrderTypesRequest = async (token: string, storeId: number): Promise<OmnivoreOrderTypeDto[]> => {
  return callApi<OmnivoreOrderTypeDto[]>(`/stores/${storeId}/omnivore/order-types`, {token}).then(({data}) => data);
};

export const fetchAllOmnivoreTenderTypesRequest = async (token: string, storeId: number): Promise<OmnivoreTenderTypeDto[]> => {
  return callApi<OmnivoreTenderTypeDto[]>(`/stores/${storeId}/omnivore/tender-types`, {token}).then(({data}) => data);
};

export const fetchAllOmnivoreTablesRequest = async (token: string, storeId: number): Promise<OmnivoreTableDto[]> => {
  return callApi<OmnivoreTableDto[]>(`/stores/${storeId}/omnivore/tables`, {token}).then(({data}) => data);
};

export const fetchAllOmnivoreServiceChargesRequest = async (
  token: string,
  storeId: number
): Promise<OmnivoreServiceChargeDto[]> => {
  return callApi<OmnivoreServiceChargeDto[]>(`/stores/${storeId}/omnivore/service-charges`, {token}).then(({data}) => data);
};

export const startOmnivorePullRequest = async (
  token: string,
  storeId: number,
  body: OmnivoreStartPullRequestDto
): Promise<{}> => {
  return callApi<{}>(`/stores/${storeId}/omnivore/categories/pull`, {token, method: 'POST', body: JSON.stringify(body)}).then(
    ({data}) => data
  );
};

export const fetchProductConnectedToMenuItemRequest = async (
  token: string,
  storeId: number,
  menuItemId: string
): Promise<ProductDto> => {
  return callApi<ProductDto>(`/stores/${storeId}/omnivore/menu-items/${menuItemId}/product`, {token}).then(({data}) => data);
};
