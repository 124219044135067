import React from 'react';
import {Link} from 'react-router-dom';

import {Button, ButtonGroup, Card, Tag} from '@blueprintjs/core';
import {Tooltip2} from '@blueprintjs/popover2';
import {faCalendarAlt, faCog, faConciergeBell, faUtensils} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {DateTime} from 'luxon';

import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {getStoreImage, getStoreOpeningHoursString, isDateInOpenPeriod} from '../../utils/helpers.utils';

export interface OperationsListItemProps {
  store: StoreDto;
}

export const OperationsListItem: React.FC<OperationsListItemProps> = ({store}) => {
  const isOpen = isDateInOpenPeriod(DateTime.local().setZone(store.timezone), store.openingHours ?? []);
  const openingHoursText = getStoreOpeningHoursString(store.timezone, store?.openingHours!);

  return (
    <li className="tw-border-b tw-border-solid tw-border-gray-200">
      <div className="tw-flex tw-bg-white hover:tw-bg-gray-100 tw-cursor-pointer">
        <Link
          className={classNames('tw-w-full tw-flex tw-p-4 hover:tw-no-underline', {'tw-opacity-75': !store.isEnabled})}
          to={`${location.pathname}/${store.id}`}
        >
          <img
            className="tw-border-solid tw-border tw-border-gray-300 tw-align-middle tw-w-14 tw-h-14 tw-object-cover tw-rounded-full tw-mr-4"
            src={getStoreImage(store)}
            alt={store.name}
          />
          <div>
            <div className="tw-flex tw-items-center tw-mb-0.5">
              <h1
                title={store.name}
                className="tw-m-0 tw-text-lg	tw-font-semibold tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-gray-900"
              >
                {store.name}
              </h1>
              {store.isEnabled && (
                <Tag minimal className="tw-block tw-ml-2" intent={isOpen ? 'success' : 'danger'}>
                  {isOpen ? 'Open' : 'Closed'}
                </Tag>
              )}
            </div>
            {!store.isEnabled && (
              <p className="tw-overflow-hidden tw-text-ellipsis tw-mb-0.5 tw-whitespace-nowrap tw-text-gray-900">
                Store is disabled
              </p>
            )}
            {store.isEnabled && (
              <p className="tw-overflow-hidden tw-text-ellipsis tw-mb-0.5 tw-whitespace-nowrap tw-text-gray-900">
                Opening Hours: {openingHoursText}
              </p>
            )}
          </div>
        </Link>
        <div className="tw-ml-auto tw-w-36 tw-flex tw-items-center tw-mr-4">
          <ButtonGroup className="bp3-outlined">
            <Tooltip2 position="top" content="Ordering methods">
              <Link className="bp3-button bp3-outlined" to={`${location.pathname}/${store.id}/ordering-methods`}>
                <FontAwesomeIcon icon={faConciergeBell} />
              </Link>
            </Tooltip2>
            <Tooltip2 position="top" content="Events">
              <Link className="bp3-button bp3-outlined" to={`${location.pathname}/${store.id}/events`}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </Link>
            </Tooltip2>
            <Tooltip2 position="top" content="Menus">
              <Link className="bp3-button bp3-outlined" to={`${location.pathname}/${store.id}/menus`}>
                <FontAwesomeIcon icon={faUtensils} />
              </Link>
            </Tooltip2>
            <Tooltip2 position="top" content="Settings">
              <Link className="bp3-button bp3-outlined" to={`${location.pathname}/${store.id}/settings/general`}>
                <FontAwesomeIcon icon={faCog} />
              </Link>
            </Tooltip2>
          </ButtonGroup>
        </div>
      </div>
    </li>
  );
};

interface AddOperationsListItemProps {
  onClick: () => void;
}

export const AddOperationsListItem: React.FC<AddOperationsListItemProps> = ({onClick}) => {
  return (
    <Card onClick={onClick} className="store-add-card">
      <Button large icon="add" onClick={onClick}>
        Add new store
      </Button>
    </Card>
  );
};
