import * as SliderPrimitive from '@radix-ui/react-slider';
import {forwardRef} from 'react';

export const Slider = forwardRef<HTMLSpanElement, SliderPrimitive.SliderProps>((props, ref) => {
  const value = props.value || props.defaultValue;

  return (
    <SliderPrimitive.Slider {...props} ref={ref} className="tw-flex tw-relative tw-items-center tw-h-6 tw-select-none">
      <SliderPrimitive.Track className="tw-relative tw-grow tw-bg-blue-200 tw-rounded-full tw-outline-none tw-h-[4px]">
        <SliderPrimitive.Range className="tw-absolute tw-h-full tw-bg-blue-500 tw-rounded-full tw-outline-none" />
      </SliderPrimitive.Track>
      {value?.map((_, i) => (
        <SliderPrimitive.Thumb
          className="tw-block tw-z-10 tw-w-4 tw-h-4 tw-font-bold tw-bg-blue-500 tw-rounded-full tw-shadow-xl tw-outline-none"
          key={i}
        />
      ))}
    </SliderPrimitive.Slider>
  );
});
