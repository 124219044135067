import React from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {FocusStyleManager} from '@blueprintjs/core';
import {Settings} from 'luxon';
import {RootLayout} from './components/layout/RootLayout';
import {useAuth} from './contexts/auth.context';

import {StoreDashboardPage} from './scenes/dashboard/StoreDashboardPage';
import {LoadingAppScene} from './scenes/loading-app/loading-app.scene';
import {LoginScene} from './scenes/login/login.scene';

import {StorePagesLayout} from './operation/pages/StorePagesLayout';
import {UsersPage} from './scenes/users/UsersPage';
import {OperationsList} from './operations/pages/OperationsList';
import {STORE_ORDERS_HISTORY_PRINT_VERSION_SCENE_PATH} from './utils/paths';
import {getUserCompanyAndStoreData} from './utils/helpers.utils';

import {SupportScene} from './scenes/support/support.scene';
import {QrGeneratorScene} from './scenes/qr-generator/qr-generator.scene';
import {OrderingMethodsPage} from './operation/pages/OrderingMethodsPage';
import {OrderingMethodsDetailsPage} from './operation/pages/OrderingMethodsDetailsPage';
import {MenuDetailsPage} from './operation/pages/MenuDetailsPage';
import {MenusPageLayout} from './operation/pages/MenusPageLayout';
import {StoreSettingsPagesLayout} from './operation/pages/StoreSettingsPagesLayout';

// Store settings page
import {GeneralForm} from './operation/components/settings/GeneralForm';
import {NotificationsPage} from './operation/components/settings/NotificationsForm';
import {TimeframesPage} from './operation/components/settings/TimeframesForm';
import {CheckoutOptionsPage} from './operation/components/settings/CheckoutOptionsForm';
import {OmnivoreIntegrationSettingsPage} from './operation/components/settings/IntegrationsForm';
import {RevenueGroupsSettingsPage} from './operation/components/settings/RevenueGroupsForm';
import {PrinterSettingsPage} from './operation/components/settings/Printers';
import {SmsPage} from './operation/components/settings/GuestForm';

import {TaxesPage} from './operation/pages/TaxesPage';
import AdditionalChargesPage from './operation/pages/AdditionalChargesPage';
import {PromotionsPage} from './operation/pages/PromotionsPage';
import {OrdersHistoryPage} from './orders/pages/OrdersHistoryPage';
import {OrdersPage} from './orders/pages/OrdersPage';
import {OrderHistoryPrintVersionPage} from './orders/pages/OrderHistoryPrintVersionPage';
import {CompanyHomePage} from './home/pages/CompanyHomePage';
import {UpcomingEventsPage} from './events/pages/UpcomingEventsPage';
import {PastEventsPage} from './events/pages/PastEventsPage';
import {CreateEventPage} from './events/pages/CreateEventPage';
import {EventDetail} from './events/pages/EventDetailPage';
import {TipsPage} from './tips/pages/TipsPage';
import {OrderReceiptPage} from './orders/pages/OrderReceiptPage';
import {ApiKeysPage} from './companies/pages/ApiKeysPage';

FocusStyleManager.onlyShowFocusOnTabs();
Settings.defaultLocale = 'en';

export function App() {
  const {user, isLoadingUser, errorLoadingUser} = useAuth();
  const location = useLocation();

  if (isLoadingUser) {
    return <LoadingAppScene />;
  }

  if (!user || !user.token || errorLoadingUser) {
    return (
      <Routes>
        <Route path="/login" element={<LoginScene />} />
        <Route path="*" element={<Navigate to="/login" state={location.pathname} />} />
      </Routes>
    );
  }

  if (user.securityLevel === 'server') {
    return <ServerApp />;
  }

  if (user.securityLevel === 'supervisor') {
    return <SupervisorApp />;
  }

  return <CompanyAdminApp />;
}

function ServerApp() {
  const {user} = useAuth();
  const {companyId, storeId} = getUserCompanyAndStoreData(user);

  return (
    <Routes>
      <Route element={<RootLayout />}>
        <Route path="/companies/:companyId/stores/:storeId">
          <Route index element={<Navigate to="orders" replace />} />
          <Route path="orders" element={<OrdersPage />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={`/companies/${companyId}/stores/${storeId}`} replace />} />
    </Routes>
  );
}

function SupervisorApp() {
  const {user} = useAuth();
  const {companyId, storeId} = getUserCompanyAndStoreData(user);

  return (
    <Routes>
      <Route path="/companies/:companyId/stores/:storeId/orders/:orderId/receipt" element={<OrderReceiptPage />} />
      <Route path="/companies/:companyId/stores/:storeId" element={<RootLayout />}>
        <Route element={<StorePagesLayout />}>
          <Route index element={<StoreDashboardPage />} />

          <Route path="ordering-methods" element={<OrderingMethodsPage />} />

          <Route path="menus" element={<MenusPageLayout />}>
            <Route path=":menuId" element={<MenuDetailsPage />} />
          </Route>

          <Route path="promotions" element={<PromotionsPage />} />

          <Route path="settings" element={<StoreSettingsPagesLayout />}>
            <Route path="general" element={<GeneralForm />} />
            <Route path="tips" element={<TipsPage />} />
            <Route path="additional-charges" element={<AdditionalChargesPage />} />
            <Route path="taxes" element={<TaxesPage />} />
            <Route path="notifications" element={<NotificationsPage />} />
            <Route path="timeframes" element={<TimeframesPage />} />
            <Route path="sms-settings" element={<SmsPage />} />
            <Route path="checkout-options" element={<CheckoutOptionsPage />} />
            <Route path="integrations" element={<OmnivoreIntegrationSettingsPage />} />
            <Route path="revenue-groups" element={<RevenueGroupsSettingsPage />} />
            <Route path="printers" element={<PrinterSettingsPage />} />
            <Route index element={<Navigate to="general" replace />} />
          </Route>
        </Route>

        <Route path="ordering-methods/:orderingMethodId" element={<OrderingMethodsDetailsPage />} />

        <Route path="events">
          <Route index element={<UpcomingEventsPage />} />
          <Route path="past">
            <Route index element={<PastEventsPage />} />
            <Route path=":eventId" element={<EventDetail />} />
          </Route>
          <Route path="new" element={<CreateEventPage />} />
          <Route path=":eventId" element={<EventDetail />} />
        </Route>

        <Route path="orders">
          <Route index element={<OrdersPage />} />
          <Route path="history" element={<OrdersHistoryPage />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={`/companies/${companyId}/stores/${storeId}`} />} />
    </Routes>
  );
}

function CompanyAdminApp() {
  const {user} = useAuth();
  const {companyId} = getUserCompanyAndStoreData(user);

  return (
    <Routes>
      <Route path="/qr" element={<QrGeneratorScene />} />

      <Route path={STORE_ORDERS_HISTORY_PRINT_VERSION_SCENE_PATH} element={<OrderHistoryPrintVersionPage />} />
      <Route path="/companies/:companyId/stores/:storeId/orders/:orderId/receipt" element={<OrderReceiptPage />} />

      <Route path="/companies/:companyId" element={<RootLayout />}>
        <Route index element={<CompanyHomePage />} />
        <Route path="api" element={<ApiKeysPage />} />
        <Route path="users" element={<UsersPage />} />
        <Route path="support" element={<SupportScene />} />
        <Route path="stores">
          <Route index element={<OperationsList />} />
          <Route path=":storeId">
            <Route element={<StorePagesLayout />}>
              <Route index element={<StoreDashboardPage />} />
              <Route path="ordering-methods" element={<OrderingMethodsPage />} />

              <Route path="menus" element={<MenusPageLayout />}>
                <Route path=":menuId" element={<MenuDetailsPage />} />
              </Route>

              <Route path="promotions" element={<PromotionsPage />} />

              <Route path="settings" element={<StoreSettingsPagesLayout />}>
                <Route path="general" element={<GeneralForm />} />
                <Route path="tips" element={<TipsPage />} />
                <Route path="additional-charges" element={<AdditionalChargesPage />} />
                <Route path="taxes" element={<TaxesPage />} />
                <Route path="notifications" element={<NotificationsPage />} />
                <Route path="timeframes" element={<TimeframesPage />} />
                <Route path="sms-settings" element={<SmsPage />} />
                <Route path="checkout-options" element={<CheckoutOptionsPage />} />
                <Route path="integrations" element={<OmnivoreIntegrationSettingsPage />} />
                <Route path="revenue-groups" element={<RevenueGroupsSettingsPage />} />
                <Route path="printers" element={<PrinterSettingsPage />} />
                <Route index element={<Navigate to="general" replace />} />
              </Route>
            </Route>

            <Route path="ordering-methods/:orderingMethodId" element={<OrderingMethodsDetailsPage />} />

            <Route path="events">
              <Route index element={<UpcomingEventsPage />} />
              <Route path="past">
                <Route index element={<PastEventsPage />} />
                <Route path=":eventId" element={<EventDetail />} />
              </Route>
              <Route path="new" element={<CreateEventPage />} />
              <Route path=":eventId" element={<EventDetail />} />
            </Route>

            <Route path="orders">
              <Route index element={<OrdersPage />} />
              <Route path="history" element={<OrdersHistoryPage />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={`/companies/${companyId}`} />} />
    </Routes>
  );
}
