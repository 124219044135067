import React, {ReactElement, useEffect, useState} from 'react';

import {Button, Classes, Dialog, IDialogProps} from '@blueprintjs/core';
import classNames from 'classnames';

import {OrderDetailsDialog} from '../components/OrderDetailsDialog';
import {Timezone} from '../../utils/types';
import {OrderCard} from './order-card.component';
import {OrderDto} from '@kontactless/admin-api/order/order.dto';

export interface OrdersListDialogProps extends IDialogProps {
  timezone?: Timezone;
  orders: OrderDto[];
  noItems?: string | ReactElement;
  updateOrderStatus: ({id, status}: {id: number; status: string}) => void;
  isLoading: boolean;
}

export const OrdersListDialog: React.FC<OrdersListDialogProps> = ({
  orders,
  noItems,
  timezone,
  updateOrderStatus,
  isLoading,
  ...props
}) => {
  const [isOrderDetailsDialogOpen, setIsOrderDetailsDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderDto | undefined>();

  useEffect(() => {
    setIsOrderDetailsDialogOpen(false);
    setSelectedOrder(undefined);
  }, [orders]);

  return (
    <Dialog className="kl-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="orders-list">
          {orders.map((order) => (
            <OrderCard
              key={order.id}
              order={order}
              onSeeDetailsClick={(order) => {
                setSelectedOrder(order);
                setIsOrderDetailsDialogOpen(true);
              }}
              updateOrderStatus={updateOrderStatus}
              isLoading={isLoading}
            />
          )) ?? null}
          {orders.length === 0 && noItems ? noItems : null}
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button text="Close" outlined onClick={(ev) => props.onClose?.(ev)} />
      </footer>

      {!!selectedOrder && (
        <OrderDetailsDialog
          isOpen={isOrderDetailsDialogOpen}
          orderId={selectedOrder?.id}
          timezone={timezone ?? 'America/Los_Angeles'}
          onClose={() => {
            setSelectedOrder(undefined);
            setIsOrderDetailsDialogOpen(false);
          }}
        />
      )}
    </Dialog>
  );
};
