import React, {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {DateTime} from 'luxon';
import {useQuery} from 'react-query';
import {
  getAverageRating,
  getCustomerScansReport,
  getReport,
  getRevenueGroupsReport,
  getSalesHistory,
  getTopOrderedCategories,
  getTopSellingProducts,
} from '../../api/report.api';
import {DateRangeButton} from '../../components/selectors/date-range-button.component';
import {useAuth} from '../../contexts/auth.context';
import {getCompareTypeLabel} from '../../utils/helpers.utils';
import {CompareGroup, CompareGroups, CompareType, DateTimeRange} from '../../utils/types';
import {DashboardWidgetCustomerScans} from './widgets/widget-customer-scans.component';
import {DashboardWidgetList} from './widgets/widget-list.component';
import {DashboardWidgetRating} from './widgets/widget-rating.component';
import {DashboardWidgetRevenueGroups} from './widgets/widget-revenue-groups.component';
import {DashboardWidgetSalesHistory} from './widgets/widget-sales-history.component';
import {DashboardWidgetTopCategories} from './widgets/widget-top-categories.component';
import {DashboardWidgetTotal} from './widgets/widget-total.component';
import {fetchStoreRequest} from '../../api/store.api';

const getDiffPercentageString = (diffPercentage: number | 'inf'): string => {
  return diffPercentage === 'inf' ? '' : `${diffPercentage! >= 0 ? '+' : '-'}${Math.abs(diffPercentage).toFixed(0)}%`;
};

export function StoreDashboardPage() {
  const {user} = useAuth();
  const params = useParams<{companyId: string; storeId: string}>();
  const storeId = Number(params.storeId);
  const companyId = Number(params.companyId);

  const [is24HourDiff, setIs24HourDiff] = useState<boolean>(true);
  const [compareGroups, setCompareGroups] = useState<CompareGroups>({
    compareGroupFrom: {
      start: DateTime.local().startOf('day').toJSDate(),
      end: DateTime.local().endOf('day').toJSDate(),
      compareType: 'today',
    },
    compareGroupTo: {
      start: DateTime.local().plus({days: -1}).startOf('day').toJSDate(),
      end: DateTime.local().plus({days: -1}).endOf('day').toJSDate(),
      compareType: 'yesterday',
    },
  });

  const storeQuery = useQuery({queryKey: ['stores', storeId], queryFn: () => fetchStoreRequest(user.token, storeId)});

  const [stringifiedCompareGroups, compareGroupsQueryKey] = useMemo(
    () => [getCompareGroupsString(compareGroups), getCompareGroupsQueryKey(compareGroups)],
    [compareGroups]
  );

  const totalSalesQuery = useQuery(
    ['total-sales-report', compareGroupsQueryKey, companyId, storeId],
    () =>
      getReport('total-sales', {
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
        storeId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );
  const totalOrdersQuery = useQuery(
    ['total-orders-report', compareGroupsQueryKey, companyId, storeId],
    () =>
      getReport('total-orders', {
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
        storeId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );
  const totalTipsQuery = useQuery(
    ['total-tips-report', compareGroupsQueryKey, companyId, storeId],
    () =>
      getReport('total-tips', {
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
        storeId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );
  const averageOrderTotalQuery = useQuery(
    ['average-order-total-report', compareGroupsQueryKey, companyId, storeId],
    () =>
      getReport('average-order-total', {
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
        storeId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );
  const totalNewCustomersQuery = useQuery(
    ['total-new-customers-report', compareGroupsQueryKey, companyId, storeId],
    () =>
      getReport('new-customers', {
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
        storeId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );
  const exportGroupsQuery = useQuery(
    ['export-groups-report', compareGroupsQueryKey, companyId, storeId],
    () => getRevenueGroupsReport(user.token, stringifiedCompareGroups, companyId, storeId),
    {enabled: isQueryEnabled(compareGroups)}
  );
  const topSellingProductsQuery = useQuery(
    ['top-selling-products-report', compareGroupsQueryKey, companyId, storeId],
    () =>
      getTopSellingProducts({
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
        storeId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );
  const topOrderedCategoriesQuery = useQuery(
    ['top-ordered-categories-report', compareGroupsQueryKey, companyId, storeId],
    () =>
      getTopOrderedCategories({
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
        storeId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );
  const averageRatingQuery = useQuery(
    ['average-rating', compareGroupsQueryKey, companyId, storeId],
    () =>
      getAverageRating({
        token: user.token,
        compareRangeFrom: stringifiedCompareGroups.compareGroupFrom,
        companyId,
        storeId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );
  const salesHistoryQuery = useQuery(
    ['sales-history-report', compareGroupsQueryKey, companyId, storeId, is24HourDiff],
    () =>
      getSalesHistory({
        token: user.token,
        compareRangeFrom: stringifiedCompareGroups.compareGroupFrom,
        groupBy: is24HourDiff ? 'hours' : 'days',
        companyId,
        storeId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );
  const customerScansHistoryQuery = useQuery(
    ['customer-scans-history-report', compareGroupsQueryKey, companyId, storeId],
    () =>
      getCustomerScansReport({
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
        storeId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );

  const onCompareSelectorChange = useCallback(
    (compareGroup: 'from' | 'to', range: DateTimeRange, compareType: CompareType) => {
      switch (compareGroup) {
        case 'from':
          setCompareGroups({
            ...compareGroups,
            compareGroupFrom: {
              start: range[0],
              end: range[1],
              compareType,
            },
          });

          if (range[0] && range[1]) {
            setIs24HourDiff(Math.abs(DateTime.fromJSDate(range[0]).diff(DateTime.fromJSDate(range[1]), ['days']).days) < 1);
          } else {
            setIs24HourDiff(false);
          }
          break;
        case 'to':
          setCompareGroups({
            ...compareGroups,
            compareGroupTo: {
              start: range[0],
              end: range[1],
              compareType,
            },
          });

          if (range[0] && range[1]) {
            setIs24HourDiff(Math.abs(DateTime.fromJSDate(range[0]).diff(DateTime.fromJSDate(range[1]), ['days']).days) < 1);
          } else {
            setIs24HourDiff(false);
          }
          break;
      }
    },
    [compareGroups, setCompareGroups]
  );

  return (
    <section>
      <div className="tw-flex tw-gap-4 tw-items-center tw-mb-4">
        <DateRangeButton
          value={[compareGroups.compareGroupFrom.start ?? null, compareGroups.compareGroupFrom.end ?? null]}
          onChange={(value, compareType) => onCompareSelectorChange('from', value, compareType)}
        />
        <p className="compare-text">compared to</p>
        <DateRangeButton
          value={[compareGroups.compareGroupTo.start ?? null, compareGroups.compareGroupTo.end ?? null]}
          onChange={(value, compareType) => onCompareSelectorChange('to', value, compareType)}
        />
      </div>
      <div className="dashboard-widgets row">
        <div className="col-60">
          <div className="row totals-row">
            <DashboardWidgetTotal
              title="Total Sales"
              total={'$' + (totalSalesQuery.data?.total ?? 0).toFixed(2)}
              comparedTo={(totalSalesQuery.data?.comparedTo ?? 0).toFixed(2)}
              diffPercentage={getDiffPercentageString(totalSalesQuery.data?.diffPercentage!)}
              isLoading={totalSalesQuery.isLoading}
              errorMessage={(totalSalesQuery.error as Error) && 'Failed'}
              className="col-33"
            />
            <DashboardWidgetTotal
              title="Total Orders"
              total={Math.round(totalOrdersQuery.data?.total ?? 0).toString()}
              comparedTo={Math.round(totalOrdersQuery.data?.comparedTo ?? 0).toString()}
              diffPercentage={getDiffPercentageString(totalOrdersQuery.data?.diffPercentage!)}
              isLoading={totalOrdersQuery.isLoading}
              errorMessage={(totalOrdersQuery.error as Error) && 'Failed'}
              className="col-33"
            />
            <DashboardWidgetTotal
              title="Total Tips"
              total={'$' + (totalTipsQuery.data?.total ?? 0).toFixed(2)}
              comparedTo={(totalTipsQuery.data?.comparedTo ?? 0).toFixed(2)}
              diffPercentage={getDiffPercentageString(totalTipsQuery.data?.diffPercentage!)}
              isLoading={totalTipsQuery.isLoading}
              errorMessage={(totalTipsQuery.error as Error) && 'Failed'}
              className="col-33"
            />
          </div>
          <div className="row">
            <DashboardWidgetSalesHistory
              title="Sales Report"
              labelType={is24HourDiff ? 'hours' : 'days'}
              datasets={
                salesHistoryQuery.data?.length
                  ? [
                      {
                        label: getCompareTypeLabel(compareGroups.compareGroupFrom.compareType!),
                        data: salesHistoryQuery.data.map(({label, total}) => ({datetime: label, total})),
                      },
                    ]
                  : []
              }
              isLoading={salesHistoryQuery.isLoading}
              errorMessage={(salesHistoryQuery.error as Error) && 'Failed'}
              noDataComponent={<p className="widget-info">There are no sales in the selected range</p>}
              className="col-100"
            />
          </div>
          <div className="row totals-row">
            <div className="row vertical col-33">
              <DashboardWidgetTotal
                title="Average Spend Per Order"
                total={'$' + (averageOrderTotalQuery.data?.total ?? 0).toFixed(2)}
                comparedTo={(averageOrderTotalQuery.data?.comparedTo ?? 0).toFixed(2)}
                diffPercentage={getDiffPercentageString(averageOrderTotalQuery.data?.diffPercentage!)}
                isLoading={averageOrderTotalQuery.isLoading}
                errorMessage={(averageOrderTotalQuery.error as Error) && 'Failed'}
                className="col-100"
              />
              <DashboardWidgetTotal
                title="New Customers"
                total={(totalNewCustomersQuery.data?.total ?? 0).toFixed(0)}
                comparedTo={(totalNewCustomersQuery.data?.comparedTo ?? 0).toFixed(0)}
                diffPercentage={getDiffPercentageString(totalNewCustomersQuery.data?.diffPercentage!)}
                isLoading={totalNewCustomersQuery.isLoading}
                errorMessage={(totalNewCustomersQuery.error as Error) && 'Failed'}
                className="col-100"
              />
              <DashboardWidgetRating
                title="Average Rating"
                ratingProps={{value: averageRatingQuery.data?.rating, max: 5, precision: 0.1}}
                isLoading={averageRatingQuery.isLoading}
                errorMessage={(averageRatingQuery.error as Error) && 'Failed'}
                noRatingComponent={<p className="widget-info">No ratings available</p>}
                className="col-100"
              />
            </div>
            <div className="row col-66">
              <DashboardWidgetCustomerScans
                title="Customer Scans"
                datasets={[
                  {
                    label: getCompareTypeLabel(compareGroups.compareGroupFrom.compareType!),
                    data: (customerScansHistoryQuery.data ?? []).map(({label, current}) => ({label, total: current})),
                  },
                  {
                    label: getCompareTypeLabel(compareGroups.compareGroupTo.compareType!),
                    data: (customerScansHistoryQuery.data ?? []).map(({label, original}) => ({label, total: original})),
                  },
                ]}
                isLoading={customerScansHistoryQuery.isLoading}
                errorMessage={(customerScansHistoryQuery.error as Error) && 'Failed'}
                noDataComponent={<p className="widget-info">There are no sales in the selected range</p>}
                className="col-100"
              />
            </div>
          </div>
        </div>
        <div className="row vertical col-40">
          <DashboardWidgetList
            title="Top Selling Products"
            listItems={
              topSellingProductsQuery.data?.map((product) => ({
                id: product.id,
                title: product.name,
                subtitle: product.storeName,
                sideInfo: `${product.total} units sold`,
                imageUrl: product.imageUrl,
              })) ?? []
            }
            isLoading={topSellingProductsQuery.isLoading}
            errorMessage={(topSellingProductsQuery.error as Error) && 'Failed'}
            noItemsComponent={<p className="widget-info">No products were sold in the selected range</p>}
            className="col-100"
          />
          <DashboardWidgetTopCategories
            title="Popular Categories"
            centerTitle={topOrderedCategoriesQuery.data?.reduce((sum, {total}) => sum + total, 0) ?? ''}
            centerSubtitle="Items Sold"
            datasets={topOrderedCategoriesQuery.data ?? []}
            isLoading={topOrderedCategoriesQuery.isLoading}
            errorMessage={(topOrderedCategoriesQuery.error as Error) && 'Failed'}
            noDataComponent={<p className="widget-info">There are no orders in the selected range</p>}
            className="col-100"
          />
          {storeQuery.data?.revenueGroups?.length ? (
            <DashboardWidgetRevenueGroups
              title="Revenue Groups"
              listItems={exportGroupsQuery.data ?? []}
              isLoading={exportGroupsQuery.isLoading}
              errorMessage={(exportGroupsQuery.error as Error) && 'Failed'}
              noItemsComponent={<p className="widget-info">No export groups are configured</p>}
              className="col-100"
            />
          ) : null}
        </div>
      </div>
    </section>
  );
}

const isQueryEnabled = (compareGroups: CompareGroups): boolean => {
  let enabled = true;

  if (compareGroups.compareGroupFrom) {
    enabled = enabled && !!compareGroups.compareGroupFrom.start && !!compareGroups.compareGroupFrom.end;
  }

  if (compareGroups.compareGroupTo) {
    enabled = enabled && !!compareGroups.compareGroupTo.start && !!compareGroups.compareGroupTo.end;
  }

  return enabled;
};

const getCompareGroupsString = ({compareGroupFrom, compareGroupTo}: CompareGroups) => {
  return {
    compareGroupFrom: getCompareGroupStrings({start: compareGroupFrom.start, end: compareGroupFrom.end}),
    compareGroupTo: getCompareGroupStrings({start: compareGroupTo.start, end: compareGroupTo.end}),
  };
};

const getCompareGroupStrings = ({start, end}: CompareGroup): {start: string | null; end: string | null} => {
  return {
    start: start ? DateTime.fromJSDate(start).toISO() : null,
    end: end ? DateTime.fromJSDate(end).toISO() : null,
  };
};

const getCompareGroupsQueryKey = (compareGroups: CompareGroups): Array<string | null> => {
  const stringifiedCompareGroups = getCompareGroupsString(compareGroups);

  return [
    stringifiedCompareGroups.compareGroupFrom.start,
    stringifiedCompareGroups.compareGroupFrom.end,
    stringifiedCompareGroups.compareGroupTo.start,
    stringifiedCompareGroups.compareGroupTo.end,
  ];
};
