import React from 'react';
import {useParams} from 'react-router-dom';

import {useQuery} from 'react-query';

import {fetchMenuRequest} from '../../api/menu.api';

import {useAuth} from '../../contexts/auth.context';

import {CategoriesList} from '../components/menus/CategoriesList';

import '../styles/menus-page.styles.scss';

import {Button, NonIdealState, Spinner} from '@blueprintjs/core';

import {fetchStoreRequest} from '../../api/store.api';

export function MenuDetailsPage() {
  const {user} = useAuth();
  const params = useParams<{companyId: string; storeId: string; menuId?: string; page: string}>();
  const menuId = Number(params.menuId);
  const storeId = Number(params.storeId);

  const menuQuery = useQuery(['menus', menuId], () => fetchMenuRequest(user.token, menuId));
  const storeQuery = useQuery(['stores', storeId], () => fetchStoreRequest(user.token, storeId));

  if (storeQuery.isLoading || menuQuery.isLoading) {
    return <Spinner />;
  }

  if (!storeQuery.data || storeQuery.isError || !menuQuery.data || menuQuery.isError) {
    return (
      <NonIdealState
        icon="error"
        title="There was an error loading the store"
        action={<Button intent="primary" text="Try again" onClick={() => storeQuery.refetch()} />}
      />
    );
  }

  return <CategoriesList store={storeQuery.data} menu={menuQuery.data} />;
}
