import {Button, Icon} from '@blueprintjs/core';
import {DateRangePicker} from '@blueprintjs/datetime';
import {Popover2} from '@blueprintjs/popover2';
import {Select} from '@blueprintjs/select';
import classNames from 'classnames';
import {DateTime} from 'luxon';
import {useEffect, useState} from 'react';
import {ComparedToDateRangeLabels, HomeReportCompareGroups, StartingDateRangeLabels} from '../../utils/types';

interface DateFiltersProps {
  setCompareGroups(compareGroups: HomeReportCompareGroups): void;
}

const DateRangeSelect = Select.ofType<StartingDateRangeLabels | ComparedToDateRangeLabels>();

export const DateFilters = ({setCompareGroups}: DateFiltersProps) => {
  const [startingPeriod, setStartingPeriod] = useState<StartingDateRangeLabels>('Last 7 days');
  const [comparedToPeriod, setComparedToPeriod] = useState<ComparedToDateRangeLabels>('Previous period');

  const [startingDateRange, setStartingDateRange] = useState<{start: Date | null; end: Date | null}>({
    start: DateTime.local().startOf('day').minus({days: 7}).toJSDate(),
    end: DateTime.local().endOf('day').toJSDate(),
  });
  const [comparedToDateRange, setComparedToDateRange] = useState<{start: Date | null; end: Date | null}>({
    start: DateTime.local().minus({days: 14}).startOf('day').toJSDate(),
    end: DateTime.local().minus({days: 7}).endOf('day').toJSDate(),
  });

  const [startingDatesLabel, setStartingDatesLabel] = useState<string>('');
  const [comparedToDatesLabel, setComparedToDatesLabel] = useState<string>('');

  const [showComparedToDatesLabel, setShowComparedToDatesLabel] = useState(true);

  const [isStartingCalendarOpen, setIsStartingCalendarOpen] = useState(false);
  const [isComparedToCalendarOpen, setIsComparedToCalendarOpen] = useState(false);

  useEffect(() => {
    setStartingDatesLabel(getDatesLabel(startingDateRange.start, startingDateRange.end));
    setComparedToDatesLabel(
      getDatesLabel(
        getCompareToDateRanges(startingDateRange.start, startingDateRange.end, comparedToPeriod).start,
        getCompareToDateRanges(startingDateRange.start, startingDateRange.end, comparedToPeriod).end
      )
    );
    setCompareGroups({
      compareGroupFrom: {
        start: startingDateRange.start,
        end: startingDateRange.end,
      },
      compareGroupTo: getCompareToDateRanges(startingDateRange.start, startingDateRange.end, comparedToPeriod),
    });
  }, [startingDateRange, comparedToDateRange]);

  return (
    <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-items-center tw-gap-2">
      <div className="tw-flex tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-gray-200 tw-shadow-sm tw-bg-white">
        {!isStartingCalendarOpen ? (
          <DateRangeSelect
            items={[
              'Today',
              'Last 7 days',
              'Last 4 weeks',
              'Last 3 months',
              // 'Last 12 months',
              'Month to date',
              'Quarter to date',
              'Year to date',
              'Custom',
            ]}
            itemRenderer={(item, {handleClick}) => {
              return (
                <div key={item}>
                  <div className="tw-flex tw-cursor-pointer tw-items-center tw-gap-1 tw-p-1 tw-font-bold tw-text-zinc-700 ">
                    <Icon
                      icon="small-tick"
                      className={classNames('tw-invisible', {
                        '!tw-visible tw-font-bold !tw-text-blue-600': item === startingPeriod,
                      })}
                    />
                    <p
                      className={classNames({'tw-font-bold  !tw-text-blue-600': item === startingPeriod})}
                      onClick={(e) => (item !== 'Custom' ? handleClick(e) : setIsStartingCalendarOpen(true))}
                    >
                      {item}
                    </p>
                  </div>
                </div>
              );
            }}
            onItemSelect={(item) => {
              setStartingPeriod(item as StartingDateRangeLabels);
              setStartingDateRange(getStartingDateRanges(item as StartingDateRangeLabels));
            }}
            filterable={false}
            popoverProps={{position: 'bottom'}}
          >
            <Button minimal icon="calendar" text={startingPeriod} rightIcon="chevron-down" />
          </DateRangeSelect>
        ) : (
          <Popover2
            position="bottom-right"
            isOpen={isStartingCalendarOpen}
            onInteraction={() => setIsStartingCalendarOpen(false)}
            content={
              <DateRangePicker
                allowSingleDayRange
                shortcuts={false}
                defaultValue={[startingDateRange.start, startingDateRange.end]}
                timePrecision="minute"
                timePickerProps={{useAmPm: true}}
                onChange={([start, end]) => {
                  if (start && end) {
                    setStartingDateRange({start, end});
                  }
                }}
              />
            }
          >
            <Button minimal icon="calendar" text="Custom" rightIcon="chevron-down" />
          </Popover2>
        )}
        <div className="tw-border-l tw-border-solid tw-border-gray-300 tw-px-2">{startingDatesLabel}</div>
      </div>

      <p>compared to</p>

      <div className="tw-flex tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-gray-200 tw-shadow-sm tw-bg-white">
        {!isComparedToCalendarOpen ? (
          <DateRangeSelect
            items={['Previous period', 'Previous month', 'Previous quarter', 'Previous year', 'Custom', 'No comparison']}
            itemRenderer={(item, {handleClick}) => {
              return (
                <div key={item} className="tw-flex tw-cursor-pointer tw-items-center tw-gap-1 tw-p-1 tw-text-zinc-700">
                  <Icon
                    icon="small-tick"
                    className={classNames('tw-invisible', {
                      '!tw-visible tw-font-bold tw-text-blue-600': item === comparedToPeriod,
                    })}
                  />
                  <div
                    className={classNames('tw-flex tw-gap-8 tw-items-center tw-justify-between tw-w-full', {
                      'tw-text-blue-600': item === comparedToPeriod,
                    })}
                    onClick={(e) => (item !== 'Custom' ? handleClick(e) : setIsComparedToCalendarOpen(true))}
                  >
                    <p className="tw-font-bold">{item}</p>
                    {item !== 'Custom' && item !== 'No comparison' && (
                      <p>{getComparedToDatesLabel(item as ComparedToDateRangeLabels, startingDateRange)}</p>
                    )}
                  </div>
                </div>
              );
            }}
            onItemSelect={(item) => {
              setComparedToPeriod(item as ComparedToDateRangeLabels);
              setComparedToDateRange(
                getCompareToDateRanges(startingDateRange.start, startingDateRange.end, item as ComparedToDateRangeLabels)
              );
              {
                item === 'No comparison' ? setShowComparedToDatesLabel(false) : setShowComparedToDatesLabel(true);
              }
            }}
            filterable={false}
            popoverProps={{position: 'bottom'}}
          >
            <Button minimal icon="calendar" text={comparedToPeriod} rightIcon="chevron-down" />
          </DateRangeSelect>
        ) : (
          <Popover2
            position="bottom-right"
            isOpen={isComparedToCalendarOpen}
            onInteraction={() => setIsComparedToCalendarOpen(false)}
            content={
              <DateRangePicker
                allowSingleDayRange
                shortcuts={false}
                timePrecision="minute"
                timePickerProps={{useAmPm: true}}
                defaultValue={[comparedToDateRange.start, comparedToDateRange.end]}
                onChange={([start, end]) => {
                  setComparedToDatesLabel(getDatesLabel(start, end));
                  if (start && end) {
                    setCompareGroups({
                      compareGroupFrom: {
                        start: startingDateRange.start,
                        end: startingDateRange.end,
                      },
                      compareGroupTo: {start, end},
                    });
                  }
                }}
              />
            }
          >
            <Button minimal icon="calendar" text="Custom" rightIcon="chevron-down" />
          </Popover2>
        )}

        {showComparedToDatesLabel && (
          <div className="tw-border-l tw-border-solid tw-border-gray-300 tw-px-2">{comparedToDatesLabel}</div>
        )}
      </div>
    </div>
  );
};

const getComparedToDatesLabel = (
  item: StartingDateRangeLabels | ComparedToDateRangeLabels,
  startDate: {start: Date | null; end: Date | null}
) => {
  const dateRange = getCompareToDateRanges(startDate?.start, startDate?.end, item as ComparedToDateRangeLabels);
  return `${getDatesLabel(dateRange.start, dateRange.end)}`;
};

const getDatesLabel = (startDate: Date | null, endDate: Date | null): string => {
  if (startDate && endDate) {
    const startDateTime = DateTime.fromJSDate(startDate);
    const endDateTime = DateTime.fromJSDate(endDate);

    if (startDateTime.startOf('day').toLocaleString() === endDateTime.startOf('day').toLocaleString()) {
      return startDateTime.toLocaleString(DateTime.DATE_MED);
    }
    return `${startDateTime.toLocaleString(DateTime.DATE_MED)} - ${endDateTime.toLocaleString(DateTime.DATE_MED)}`;
  }

  return '';
};

const getStartingDateRanges = (compareType: StartingDateRangeLabels): {start: Date; end: Date} => {
  const today = DateTime.local();

  switch (compareType) {
    case 'Today':
      return {start: today.startOf('day').toJSDate(), end: today.toJSDate()};
    case 'Last 7 days':
      return {start: today.minus({days: 7}).toJSDate(), end: today.toJSDate()};
    case 'Last 4 weeks':
      return {start: today.minus({weeks: 4}).toJSDate(), end: today.toJSDate()};
    case 'Last 3 months':
      return {start: today.minus({months: 3}).toJSDate(), end: today.toJSDate()};
    case 'Last 12 months':
      return {start: today.minus({months: 12}).toJSDate(), end: today.toJSDate()};
    case 'Month to date':
      return {start: today.startOf('month').toJSDate(), end: today.toJSDate()};
    case 'Quarter to date':
      return {start: today.startOf('quarter').toJSDate(), end: today.toJSDate()};
    case 'Year to date':
      return {start: today.startOf('year').toJSDate(), end: today.toJSDate()};
    default:
      return {start: today.toJSDate(), end: today.toJSDate()};
  }
};

const getCompareToDateRanges = (
  startDate: Date | null,
  endDate: Date | null,
  comparedRangeLabel: ComparedToDateRangeLabels
): {start: Date | null; end: Date | null} => {
  if (startDate && endDate) {
    const startDateTime = DateTime.fromJSDate(startDate);
    const endDateTime = DateTime.fromJSDate(endDate);
    const diffDays = endDateTime.diff(startDateTime, 'days').days;

    switch (comparedRangeLabel) {
      case 'Previous period':
        return {
          start: startDateTime.startOf('day').minus({days: diffDays}).toJSDate(),
          end: startDateTime.minus({days: 1}).endOf('day').toJSDate(),
        };
      case 'Previous month':
        return {
          start: startDateTime.startOf('day').minus({month: 1}).toJSDate(),
          end: endDateTime.minus({month: 1}).endOf('day').toJSDate(),
        };
      case 'Previous quarter':
        return {
          start: startDateTime.startOf('day').minus({quarter: 1}).toJSDate(),
          end: endDateTime.minus({quarter: 1}).endOf('day').toJSDate(),
        };
      case 'Previous year':
        return {
          start: startDateTime.startOf('day').minus({year: 1}).toJSDate(),
          end: endDateTime.minus({year: 1}).endOf('day').toJSDate(),
        };
      case 'Custom':
      case 'No comparison':
        return {start: startDateTime.toJSDate(), end: endDateTime.toJSDate()};
      default:
        return {
          start: startDateTime.startOf('day').minus({days: diffDays}).toJSDate(),
          end: startDateTime.minus({days: 1}).endOf('day').toJSDate(),
        };
    }
  }
  return {start: null, end: null};
};
