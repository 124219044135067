import {Spinner} from '@blueprintjs/core';
import {PercentageChip} from './PercentageChip';

interface TopProductsProps {
  products: {
    amount: string;
    diffPercentage: string;
    total: number;
    name: string;
    store: string;
    imageUrl?: string;
  }[];
  isError: boolean;
  isLoading: boolean;
  showPercentage: boolean;
}

export default ({products, isError, isLoading, showPercentage}: TopProductsProps) => {
  if (isError) {
    return (
      <div className="tw-flex tw-w-full tw-flex-col tw-gap-3 tw-rounded tw-bg-white tw-p-3 tw-shadow">
        <p className="tw-mt-3 tw-text-red-700">Error</p>
      </div>
    );
  }

  const filteredProducs = products.filter((product) => product.total > 0);

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-3 tw-rounded tw-bg-white tw-p-3 tw-shadow">
      <p className="tw-mb-1 tw-text-xs tw-text-gray-500">Top Products</p>
      {isLoading ? (
        <div className="tw-mt-3 tw-flex tw-content-center tw-justify-center">
          <Spinner />
        </div>
      ) : (
        <ol className="tw-flex tw-flex-col tw-gap-4">
          {!filteredProducs.length ? (
            <p className="tw-mt-3 tw-text-gray-500">There are no products in the selected range</p>
          ) : (
            <>
              {filteredProducs?.map((product, i) => (
                <li className="tw-flex tw-items-center tw-gap-2 tw-text-xs" key={product.name}>
                  <span className="tw-mr-1 tw-text-gray-500">{i + 1}.</span>
                  {product.imageUrl && (
                    <img
                      className="tw-h-6 tw-shrink-0 tw-w-6 tw-shadow tw-object-cover tw-rounded"
                      src={product.imageUrl}
                      alt={product.name}
                    />
                  )}
                  <div className="tw-overflow-hidden tw-max-w-[140px]">
                    <p className="tw-font-bold tw-text-gray-700 tw-truncate">{product.name}</p>
                    <p className="tw-truncate">{product.store}</p>
                  </div>
                  <div className="tw-shrink-0 tw-ml-auto tw-text-right">
                    <p className="tw-text-xs tw-text-zinc-700">{`${product.total} units`}</p>
                    {showPercentage && <PercentageChip diffPercentage={product.diffPercentage.toString()} />}
                  </div>
                </li>
              ))}
            </>
          )}
        </ol>
      )}
    </div>
  );
};
