import React from 'react';

import {Document, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {capitalize} from 'lodash';
import {DateTime} from 'luxon';

import {getPriceText} from '../../utils/helpers.utils';
import {OrderDto} from '@kontactless/admin-api/order/order.dto';
import {OrderItemDto} from '@kontactless/admin-api/order-item/order-item.dto';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    color: 'black',
    fontSize: 12,
    padding: 35,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: 1,
    padding: 20,
  },
  common: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 1,
    padding: '5 15 0',
  },
  modifiers: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 1,
    padding: '5 15 0 35',
    color: 'grey',
  },
  attributes: {
    display: 'flex',
    flexDirection: 'column',
    margin: 5,
    paddingLeft: 15,
    gap: 2,
  },
  total: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 1,
    padding: '10 15 10',
  },
  itemsAndCost: {
    borderStyle: 'solid',
    borderTopStyle: 'solid',
    borderTopColor: 'black',
    borderLeftColor: '#ffffff',
    borderRightColor: '#ffffff',
    borderWidth: 0.75,
    padding: '5 15 5',
    backgroundColor: '#e5f5ff',
  },
  section: {
    margin: 1,
    padding: '5 15 5',
  },
});

interface PdfDocumentBodyProps {
  data: OrderDto[];
}

export const PdfDocumentBody = ({data}: PdfDocumentBodyProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {data.map((order: OrderDto, index) => {
          return (
            <React.Fragment key={order.id}>
              <View break={index !== 0}>
                <View style={styles.common}>
                  <Text>Date: {DateTime.fromISO(order.createdAt).toFormat('d/MM/yyyy, h:mm:ss a ZZZZ')} </Text>
                  <Text>Order number: {order.number}</Text>
                </View>
                <View>
                  {order.orderAttributeValues?.map(({orderAttribute: orderCustomAttribute, value}) => (
                    <Text key={orderCustomAttribute?.id} style={styles.attributes}>
                      {orderCustomAttribute?.name}: {value}
                    </Text>
                  ))}
                </View>
                <View style={styles.itemsAndCost}>
                  <Text>Items & Cost </Text>
                </View>
                {order.items?.map((item) => (
                  <ItemsSection item={item} key={item.id} />
                ))}
                <View style={styles.total}>
                  <Text>Total</Text>
                  <Text>{getPriceText(order.total)}</Text>
                </View>
              </View>
            </React.Fragment>
          );
        })}
        <Text style={styles.footer} render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`} fixed />
      </Page>
    </Document>
  );
};

interface ItemsSectionProps {
  item: OrderItemDto;
}

const ItemsSection = ({item}: ItemsSectionProps) => {
  const label = item.type === 'fee' ? 'Administration fee' : capitalize(item.type);
  return item.type === 'product' ? (
    <>
      <View style={styles.common} key={item.id}>
        <Text>{`${item.quantity}X ${item.title}`}</Text>
        <Text>{getPriceText(item.subtotal)}</Text>
      </View>
      {item.productVersion &&
        item.productVersion.modifierGroups.map((group) =>
          group.modifiers.map((modifier) => (
            <View style={styles.modifiers} key={modifier.id}>
              <Text>{modifier.name}</Text>
            </View>
          ))
        )}
    </>
  ) : (
    <View style={styles.common} key={item.id}>
      <Text>{label}</Text>
      <Text>{getPriceText(item.subtotal)}</Text>
    </View>
  );
};
