import React, {ReactElement} from 'react';

import {CircularProgress} from '@material-ui/core';
import classNames from 'classnames';
import {isNil, uniqBy} from 'lodash';
import {DateTime} from 'luxon';
import {Line} from 'react-chartjs-2';

import {getColorForIndex} from '../../../utils/helpers.utils';
import {DashboardWidget, DashboardWidgetProps} from './dashboard-widget.component';

export interface DashboardWidgetCustomerScansProps extends DashboardWidgetProps {
  title: string;
  datasets: Array<{label: string; data: Array<{label: string; total: number}>}>;
  noDataComponent?: ReactElement;
}

export const DashboardWidgetCustomerScans: React.FC<DashboardWidgetCustomerScansProps> = ({
  title,
  datasets,
  noDataComponent,
  isLoading,
  errorMessage,
  ...itemProps
}) => {
  let component: ReactElement | null = null;

  if (isLoading) {
    component = <CircularProgress className="widget-spinner" />;
  } else if (!isNil(errorMessage)) {
    component = <p className="widget-error">{errorMessage}</p>;
  } else if (datasets.length === 0) {
    component = noDataComponent ?? null;
  } else {
    component = (
      <div className="chart-container">
        <Line
          data={{
            datasets: datasets.map(({label, data}, idx) => ({
              data: data.map(({total}) => total),
              borderColor: (idx + 1) % 2 === 0 ? '#CDD1D6' : getColorForIndex(idx + 1),
              borderWidth: 2,
              backgroundColor: 'rgba(0, 0, 0, 0)',
              label,
            })),
            labels: uniqBy(
              datasets.flatMap(({data}) => data).map(({label}) => DateTime.fromFormat(label, 'H').toFormat('h a')),
              (label) => label
            ),
          }}
          options={{
            tooltips: {mode: 'nearest'},
            hover: {intersect: false},
            legend: {display: false},
            responsive: true,
            elements: {
              point: {
                radius: 1,
              },
            },
            scales: {
              xAxes: [{gridLines: {display: false}}],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                },
              ],
            },
          }}
        />
      </div>
    );
  }

  return (
    <DashboardWidget {...itemProps} className={classNames('widget-customer-scans', itemProps.className)}>
      <h4 className="widget-title">{title}</h4>
      {component}
    </DashboardWidget>
  );
};
