import React, {useEffect, useMemo} from 'react';

import {Button, IButtonProps, MenuItem} from '@blueprintjs/core';
import {ISelectProps, Select} from '@blueprintjs/select';
import classNames from 'classnames';

import {MenuDto} from '@kontactless/admin-api/menu/menu.dto';

interface MenuSelectorProps extends Omit<ISelectProps<MenuDto>, 'itemRenderer'> {
  fill?: boolean;
  selectedItemId?: number;
  buttonProps?: IButtonProps;
}

const MenuSelectorComponent = Select.ofType<MenuDto>();

export const MenuSelector: React.FC<MenuSelectorProps> = ({selectedItemId, buttonProps, ...props}) => {
  const selectedItem = useMemo(() => props.items.find(({id}) => id === selectedItemId), [props.items, selectedItemId]);

  useEffect(() => {
    props.onItemSelect(props.items[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MenuSelectorComponent
      {...props}
      filterable={false}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      itemRenderer={({id, name}, {modifiers, handleClick}) => (
        <MenuItem active={selectedItem?.id === id} disabled={modifiers.disabled} key={id} onClick={handleClick} text={name} />
      )}
      itemsEqual={(t1, t2) => t1 === t2}
    >
      <Button {...buttonProps} text={selectedItem ? selectedItem.name : 'Menu'} rightIcon="chevron-down" fill={props.fill} />
    </MenuSelectorComponent>
  );
};
