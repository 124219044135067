import {callApi} from './api';
import {
  MenuCategoryProductDto,
  MenuCategoryProductInsertRequestDto,
  MenuCategoryProductUpdateRequestDto,
  MenuCategoryProductsDeleteRequestDto,
} from '@kontactless/admin-api/menu-category-product/menu-category-product.dto';

export const createMenuCategoryProductRequest = async (
  token: string,
  category: MenuCategoryProductInsertRequestDto
): Promise<MenuCategoryProductDto> => {
  return callApi<MenuCategoryProductDto>(`/menu-category-products`, {token, method: 'POST', body: JSON.stringify(category)}).then(
    ({data}) => data
  );
};

export const updateMenuCategoryProductRequest = async (
  token: string,
  menuCategoryProductId: number,
  category: MenuCategoryProductUpdateRequestDto
): Promise<MenuCategoryProductDto> => {
  return callApi<MenuCategoryProductDto>(`/menu-category-products/${menuCategoryProductId}`, {
    token,
    method: 'PATCH',
    body: JSON.stringify(category),
  }).then(({data}) => data);
};

export const deleteMenuCategoryProductsRequest = async (
  token: string,
  body: MenuCategoryProductsDeleteRequestDto
): Promise<void> => {
  return callApi<void>(`/menu-category-products`, {token, method: 'DELETE', body: JSON.stringify(body)}).then(({data}) => data);
};
