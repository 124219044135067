import {Control, DeepMap, ErrorOption, FieldError} from 'react-hook-form';

import {ProductDto, ProductUpsertRequestDto} from '@kontactless/admin-api/product/product.dto';

export interface ProductUpsertDrawerProps {
  product?: ProductDto;
  menuCategoryId?: number;
  menuUpsellingItemsCount: number;
  title?: string;
  submitButtonText?: string;
  onClose: () => void;
  isOpen: boolean;
  pricesIncludeTax: boolean;
}

export interface ModifierFieldset {
  id?: number;
  name: string;
  description: string;
  isEnabled: boolean;
  price?: number;
  taxId: number | null;
  stockCount?: number;
  order: number;
}

export interface ModifierGroupFieldset {
  id?: number;
  name: string;
  taxId: number | null;
  minimumRequired: number;
  maximumAllowed?: number | null;
  modifiers?: ModifierFieldset[];
  modifiersCount?: any;
}

export interface ProductForm {
  id?: number;
  name: string;
  description: string;
  price?: number;
  taxId: number | null;
  modifierGroups: ModifierGroupFieldset[];
  attributes: Array<{id: number}>;
  calories: number | null;
  needsAgeVerification: boolean;
  tags: string[];
  stockCount: number | null;
  images: Array<{id: number; url: string}>;
  timeframes: Array<{id: number}>;
  revenueGroupId: number | null;
  isLinked: boolean;
  isUpselling: boolean;
}

type ProductFormKey = keyof ProductForm;
export interface FieldArrayProps {
  control: Control<ProductForm>;
  isFormLoading: boolean;
  parentIndex?: number;
  register: any;
  clearErrors?: (name?: string | string[] | undefined) => void;
  setError?: (name: string, error: ErrorOption) => void;
  errors?: DeepMap<ProductForm, FieldError>;
  storeId?: number;
  watch?: (key: ProductFormKey) => ProductForm[ProductFormKey];
  isOmnivoreProduct: boolean;
}

export const mapProductToProductForm = (product: ProductDto, pricesIncludeTax: boolean) => {
  const productForm: ProductForm = {
    id: product.id,
    name: product.name,
    description: product.description,
    price: pricesIncludeTax ? product.price + product.taxValue : product.price,
    calories: product.calories,
    needsAgeVerification: product.needsAgeVerification,
    tags: product.tags,
    stockCount: product.stockCount,
    taxId: product.taxId,
    revenueGroupId: product.revenueGroupId ?? null,
    isLinked: product.isLinked,
    attributes: product.attributes?.map((attribute) => ({id: attribute.id})) ?? [],
    images: product.images?.map((image) => ({id: image.id, url: image.url})) ?? [],
    timeframes: product.timeframes?.map((timeframe) => ({id: timeframe.id})) ?? [],
    modifierGroups:
      product.modifierGroups?.map((group) => ({
        id: group.id,
        name: group.name,
        taxId: group.modifiers?.[0]?.taxId || null,
        minimumRequired: group.minimumRequired,
        maximumAllowed: group.maximumAllowed,
        modifiers: group.modifiers?.map((modifier) => ({
          id: modifier.id,
          name: modifier.name,
          description: modifier.description,
          isEnabled: modifier.isEnabled,
          price: pricesIncludeTax ? modifier.price + modifier.taxValue : modifier.price,
          taxId: modifier.taxId,
          stockCount: modifier.stockCount === null ? undefined : modifier.stockCount,
          order: modifier.order,
        })),
      })) ?? [],
    isUpselling: product.isUpselling,
  };

  return productForm;
};

export const createProductUpsertRequest = (
  productForm: ProductForm,
  productId: number | undefined,
  storeId: number,
  menuCategoryId: number | undefined,
  isEnabled: boolean | undefined = true,
  omnivoreMenuItemId: string | null = null
): ProductUpsertRequestDto => ({
  id: productId,
  menuCategoryId,
  name: productForm.name,
  description: productForm.description,
  price: productForm.price,
  taxId: productForm.taxId ? productForm.taxId : undefined,
  taxPercentage: !productForm.taxId ? 0 : undefined,
  tags: productForm.tags,
  calories: productForm.calories,
  stockCount: productForm.stockCount,
  needsAgeVerification: productForm.needsAgeVerification,
  isEnabled,
  storeId,
  isLinked: productForm.isLinked,
  isUpselling: productForm.isUpselling,
  attributes: productForm.attributes,
  timeframes: productForm.timeframes || [],
  omnivoreMenuItemId: omnivoreMenuItemId,
  revenueGroupId: productForm.revenueGroupId,
  modifierGroups:
    productForm.modifierGroups?.map((group, index) => ({
      id: group.id,
      name: group.name,
      minimumRequired: group.minimumRequired,
      maximumAllowed: group.maximumAllowed,
      order: index,
      modifiers:
        group.modifiers?.map((modifier, index) => ({
          id: modifier.id,
          name: modifier.name,
          description: modifier.description,
          isEnabled: modifier.isEnabled,
          price: modifier.price,
          taxId: group.taxId ? group.taxId : undefined,
          taxPercentage: !group.taxId ? 0 : undefined,
          stockCount: modifier.stockCount,
          order: index,
        })) ?? [],
    })) ?? [],
  images: productForm.images,
});
