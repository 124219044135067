import {Button, InputGroup, Menu, MenuItem, NonIdealState, Spinner} from '@blueprintjs/core';
import {Popover2} from '@blueprintjs/popover2';
import {SectionDto} from '@kontactless/admin-api/section/section.dto';
import calendarSvg from '../../assets/calendar.svg';
import classNames from 'classnames';
import {DateTime} from 'luxon';
import {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {Link, useNavigate, useParams} from 'react-router-dom';
import {createSectionCopyRequest, deleteSectionRequest, fetchAllSectionsRequest} from '../../api/section.api';
import {fetchStoreRequest} from '../../api/store.api';
import {Badge} from '../../common/components/Badge';
import {useAlerts} from '../../contexts/alerts.context';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {getOrderingFlowTypeLabel, getStoreOpeningHoursString, isDateInOpenPeriod} from '../../utils/helpers.utils';

import {CreateOrderingMethodDialog} from '../components/sections/CreateOrderingMethodDialog';

import {fetchAllEventsRequest} from '../../api/event.api';

export function OrderingMethodsPage() {
  const {user} = useAuth();
  const navigate = useNavigate();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [orderingMethodName, setOrderingMethodName] = useState('');
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);
  const storeQuery = useQuery(['stores', storeId], () => fetchStoreRequest(user.token, storeId));
  const sectionsQuery = useQuery(['stores', storeId, 'ordering-methods'], () => fetchAllSectionsRequest(user.token, storeId));
  const eventsQuery = useQuery({
    queryKey: ['stores', storeId, 'events', {dateStart: DateTime.utc().toFormat('yyyy-MM-dd')}],
    queryFn: () =>
      fetchAllEventsRequest(user.token, {
        storeId: storeId,
        dateStart: DateTime.utc().toFormat('yyyy-MM-dd'),
      }),
    enabled: !!storeId,
  });

  const {alertsDispatch} = useAlerts();
  const {
    state: {toaster},
  } = useToasts();
  const deleteSectionMutation = useMutation<{}, Error, number>((sectionId) => deleteSectionRequest(user.token, sectionId));
  const queryClient = useQueryClient();

  const createSectionCopyMutation = useMutation<SectionDto, Error, number>((sectionId) =>
    createSectionCopyRequest(user.token, sectionId)
  );

  const onDeleteSection = async (section: SectionDto) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Delete ordering method "${section.name}"?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteSectionMutation.mutateAsync(section.id);
            toaster.show({intent: 'success', message: `Ordering method "${section.name}" was successfully deleted`});
            queryClient.invalidateQueries(['stores', storeId, 'ordering-methods']);
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred deleting the ordering method'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  const createCopy = async (section: SectionDto) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Do you want to create a copy of ordering method "${section.name}"?`,
        intent: 'primary',
        icon: 'clipboard',
        confirmButtonText: 'Copy',
        onConfirm: async (setAlert, removeAlert) => {
          setAlert({loading: true});
          createSectionCopyMutation.mutate(section.id, {
            onSuccess: (duplicatedSection) => {
              queryClient.invalidateQueries(['stores', storeId, 'ordering-methods']);
              toaster.show({intent: 'success', message: 'Ordering method copied successfully'});
              navigate(duplicatedSection.id.toString());
              removeAlert();
            },
            onError: () => {
              toaster.show({intent: 'danger', message: 'There was an error copying the ordering method'});
              setAlert({loading: false});
            },
          });
        },
      },
    });
  };

  const filteredSections =
    sectionsQuery.data?.filter((section) =>
      orderingMethodName.length ? section.name.toLowerCase().includes(orderingMethodName.toLowerCase()) : section
    ) ?? [];

  if (storeQuery.isLoading) {
    return <Spinner />;
  }

  if (!storeQuery.data || storeQuery.isError) {
    return (
      <NonIdealState
        icon="error"
        title="There was an error loading the store"
        action={<Button intent="primary" text="Try again" onClick={() => storeQuery.refetch()} />}
      />
    );
  }

  return (
    <>
      {/* Events banner */}
      {storeQuery.data.eventsFeatureEnabled && (
        <div className="tw-bg-blue-200 tw-mb-6 tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-6 tw-rounded">
          <div className="tw-flex tw-items-center tw-gap-6">
            <img src={calendarSvg} alt="" />
            <h2 className="tw-font-bold tw-text-xl">Events</h2>
            <h2 className="tw-text-base tw-flex tw-items-center tw-gap-2">
              {eventsQuery.data?.length ? (
                <>
                  You have <span className="tw-font-bold tw-text-xl">{eventsQuery.data?.length}</span> upcoming events
                </>
              ) : (
                <>You don't have upcoming events scheduled</>
              )}
            </h2>
          </div>

          <div className="tw-flex tw-gap-4 tw-items-center">
            <Button text="View events" minimal intent="primary" onClick={() => navigate('../events')} />
          </div>
        </div>
      )}

      {sectionsQuery.isLoading ? (
        <Spinner />
      ) : sectionsQuery.data?.length === 0 ? (
        <NonIdealState
          icon="search"
          title="There are no ordering methods yet"
          action={<Button intent="primary" text="+ Create ordering method" onClick={() => setIsCreateDialogOpen(true)} />}
        />
      ) : (
        <>
          <div className="tw-flex tw-items-center tw-mb-4 tw-justify-between">
            <InputGroup
              onChange={(event) => setOrderingMethodName(event?.target.value)}
              placeholder="Search ordering method..."
              value={orderingMethodName}
              leftIcon="search"
            />
            <Button intent="primary" onClick={() => setIsCreateDialogOpen(true)}>
              + Create ordering method
            </Button>
          </div>
          <ul className="tw-space-y-2">
            {filteredSections.map((section) => {
              const isOpen = isDateInOpenPeriod(DateTime.local().setZone(storeQuery.data.timezone), section.openingHours ?? []);
              return (
                <div className="tw-flex tw-bg-white tw-shadow tw-rounded tw-p-4 tw-items-center tw-border-solid" key={section.id}>
                  <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
                    <div className="tw-flex tw-items-center tw-gap-8 tw-justify-between">
                      <div className="tw-w-44">
                        <p className="tw-font-bold tw-mb-1">{section.name}</p>
                        <Badge text={getOrderingFlowTypeLabel(section.orderingFlowType)} />
                      </div>
                      <div className="tw-flex tw-gap-1 tw-items-center">
                        <span
                          className={classNames('tw-rounded-full tw-w-2 tw-h-2', {
                            'tw-bg-green-500': isOpen,
                            'tw-bg-red-500': !isOpen,
                          })}
                        ></span>
                        <p className="tw-font-bold">{isOpen ? 'Open' : 'Closed'}</p>
                        <p>{`${getStoreOpeningHoursString(storeQuery.data.timezone, section.openingHours!)}`}</p>
                      </div>
                    </div>
                    <div className="tw-flex tw-items-center tw-gap-4">
                      <Link to={section.id.toString()} className="tw-text-blue-600">
                        Edit
                      </Link>
                      <Popover2
                        className="item-action"
                        content={
                          <Menu>
                            <MenuItem icon="duplicate" text="Create copy" onClick={() => createCopy(section)} />
                            <MenuItem icon="trash" intent="danger" text="Delete" onClick={() => onDeleteSection?.(section)} />
                          </Menu>
                        }
                        position="bottom-right"
                      >
                        <Button icon="more" minimal />
                      </Popover2>
                    </div>
                  </div>
                </div>
              );
            })}
          </ul>
        </>
      )}

      <CreateOrderingMethodDialog
        store={storeQuery.data}
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
      />
    </>
  );
}
