import React, {useEffect} from 'react';

import {Button, Checkbox, Classes, Dialog, FormGroup, IDialogProps, InputGroup} from '@blueprintjs/core';
import {DateInput} from '@blueprintjs/datetime';
import classNames from 'classnames';
import {DateTime} from 'luxon';
import {Controller, useForm} from 'react-hook-form';
import {useMutation} from 'react-query';

import {createDiscountRequest, updateDiscountRequest} from '../../api/discount.api';
import {DiscountTypeSelector} from '../../components/selectors/discount-type-selector.component';
import {PercentageInput} from '../../components/ui/percentage-input.component';
import {PriceInput} from '../../components/ui/price-input.component';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {DATETIME_FORMAT} from '../../utils/constants';
import {DiscountType} from '../../utils/types';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';

import {DiscountCreateRequestDto, DiscountDto, DiscountUpdateRequestDto} from '@kontactless/admin-api/discount/discount.dto';

export interface DiscountUpsertDialogProps extends IDialogProps {
  store: StoreDto;
  discount?: DiscountDto;
  onResolve?: (discount: DiscountDto) => void;
}

interface DiscountForm {
  name: string;
  type: DiscountType;
  code?: string | null;
  discount: number;
  minimumAmount?: number;
  expirationDate?: Date | null;
  customerId?: number | null;
  includesModifiers?: boolean;
  includesTipsSurchargesFees?: boolean;
}

export function DiscountUpsertDialog({store, discount, onResolve, ...props}: DiscountUpsertDialogProps) {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {register, handleSubmit, errors, control, reset, watch} = useForm<DiscountForm>();
  const upsertDiscountMutation = useMutation<
    DiscountDto,
    Error,
    {id: number} | (DiscountCreateRequestDto | DiscountUpdateRequestDto)
  >((body) => {
    if (discount?.id) {
      return updateDiscountRequest(user.token, discount.id, body as DiscountUpdateRequestDto);
    } else {
      return createDiscountRequest(user.token, body as DiscountCreateRequestDto);
    }
  });

  const submitForm = async (form: DiscountForm) => {
    try {
      if (discount?.id) {
        const updatedDiscount = await upsertDiscountMutation.mutateAsync({
          ...form,
          storeId: store.id,
          expirationDate: form.expirationDate?.toISOString() ?? null,
        });

        props.onClose?.(undefined as any);
        onResolve?.(updatedDiscount);
      } else {
        const newDiscount = await upsertDiscountMutation.mutateAsync({
          ...form,
          storeId: store.id,
          expirationDate: form.expirationDate?.toISOString() ?? null,
        });

        props.onClose?.(undefined as any);
        onResolve?.(newDiscount);
      }

      toaster.show({
        intent: 'success',
        message: discount?.id ? 'Promotion updated successfully' : 'Promotion created successfully',
      });
    } catch (error) {
      console.error(error);
      toaster.show({
        intent: 'danger',
        message: discount?.id ? 'An error ocurred updating the promotion' : 'An error ocurred creating the promotion',
      });
    }
  };

  useEffect(() => {
    reset({
      name: discount?.name,
      code: discount?.code,
      type: discount?.type ?? 'coupon-flat',
      discount: discount?.discount ?? 0,
      expirationDate: discount?.expirationDate ? DateTime.fromISO(discount.expirationDate).toJSDate() : null,
      includesModifiers: discount?.includesModifiers,
      includesTipsSurchargesFees: discount?.includesTipsSurchargesFees,
      minimumAmount: discount?.minimumAmount ?? 0,
    });
  }, [discount, reset]);

  return (
    <Dialog title={discount ? 'Edit Promotion' : 'New Promotion'} className="kl-dialog discount-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          <FormGroup label="Type" intent={errors.type ? 'danger' : 'none'} helperText={errors.type ? 'Type is required' : ''}>
            <Controller
              name="type"
              control={control}
              render={(props) => (
                <DiscountTypeSelector
                  onItemSelect={props.onChange}
                  selectedType={props.value}
                  disabled={!!discount?.id}
                  buttonProps={{disabled: !!discount?.id}}
                  fill
                />
              )}
            />
          </FormGroup>

          <FormGroup label="Name" intent={errors.name ? 'danger' : 'none'} helperText={errors.name ? 'Name is required' : ''}>
            <InputGroup
              name="name"
              defaultValue={discount?.name ?? ''}
              intent={errors.name ? 'danger' : 'none'}
              inputRef={register({required: true})}
            />
          </FormGroup>

          {watch('type')?.startsWith('coupon-') && (
            <FormGroup label="Code" intent={errors.code ? 'danger' : 'none'} helperText={errors.code ? 'Code is required' : ''}>
              <InputGroup
                name="code"
                className="tw-uppercase"
                defaultValue={discount?.code ?? ''}
                intent={errors.code ? 'danger' : 'none'}
                inputRef={register({required: true})}
              />
            </FormGroup>
          )}

          <FormGroup
            label={getDiscountInputLabel(watch('type'))}
            intent={errors.discount ? 'danger' : 'none'}
            helperText={errors.discount ? 'Discount amount is required' : ''}
          >
            <Controller
              name="discount"
              control={control}
              render={(props) => {
                return watch('type') === 'coupon-percentage' ? (
                  <PercentageInput value={props.value} onChange={props.onChange} fill />
                ) : (
                  <PriceInput value={props.value} onChange={props.onChange} fill />
                );
              }}
            />
          </FormGroup>

          {watch('type')?.startsWith('coupon-') && (
            <FormGroup
              label="Minimum Amount"
              intent={errors.minimumAmount ? 'danger' : 'none'}
              helperText={errors.minimumAmount ? 'Minimum Amount is required' : ''}
            >
              <Controller
                name="minimumAmount"
                control={control}
                render={(props) => <PriceInput value={props.value} onChange={props.onChange} fill />}
              />
            </FormGroup>
          )}

          <Checkbox name="includesModifiers" label="Include modifiers" inputRef={register} />

          {watch('type') === 'coupon-percentage' && (
            <Checkbox
              name="includesTipsSurchargesFees"
              label="Include tips, additional charges and convenience fee"
              inputRef={register}
            />
          )}

          <FormGroup label={getExpirationDateInputLabel(watch('type'))} intent={errors.expirationDate ? 'danger' : 'none'}>
            <Controller
              name="expirationDate"
              control={control}
              render={(props) => (
                <DateInput
                  popoverProps={{position: 'bottom'}}
                  timePrecision="minute"
                  timePickerProps={{useAmPm: true}}
                  value={props.value}
                  onChange={(date) => props.onChange(date)}
                  maxDate={DateTime.local().plus({years: 5}).toJSDate()}
                  formatDate={(date) => DateTime.fromJSDate(date).toFormat(DATETIME_FORMAT)}
                  parseDate={(date) => DateTime.fromISO(date).toJSDate()}
                  rightElement={props.value ? <Button icon="cross" minimal onClick={() => props.onChange(null)} /> : undefined}
                  closeOnSelection={false}
                />
              )}
            />
          </FormGroup>
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={upsertDiscountMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text={discount ? 'Edit' : 'Create'}
          intent="primary"
          loading={upsertDiscountMutation.isLoading}
          disabled={upsertDiscountMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
}

const getDiscountInputLabel = (type: DiscountType): string => {
  switch (type) {
    case 'coupon-flat':
      return 'Discount Amount';
    case 'coupon-percentage':
      return 'Discount Percentage';
    case 'promotion-flat-price':
      return 'Product Price';
    default:
      return 'Discount Amount';
  }
};

const getExpirationDateInputLabel = (type: DiscountType): string => {
  switch (type) {
    case 'coupon-flat':
      return 'Expires At';
    case 'coupon-percentage':
      return 'Expires At';
    case 'promotion-flat-price':
      return 'Finishes At';
    default:
      return 'Expires At';
  }
};
