import React from 'react';

import {Button, MenuItem} from '@blueprintjs/core';
import {IMultiSelectProps, MultiSelect} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';

import {fetchAllOmnivoreCategoriesRequest} from '../../api/omnivore.api';
import {useAuth} from '../../contexts/auth.context';
import {OmnivoreCategoryDto} from '@kontactless/admin-api/omnivore-category/omnivore-category.dto';

interface OmnivoreCategoriesSelectorProps
  extends Omit<IMultiSelectProps<OmnivoreCategoryDto>, 'itemRenderer' | 'tagRenderer' | 'items'> {
  storeId: number;
  selectedCategoriesIds: string[];
}

const OmnivoreCategoriesSelectorComponent = MultiSelect.ofType<OmnivoreCategoryDto>();

export const OmnivoreCategoriesSelector: React.FC<OmnivoreCategoriesSelectorProps> = ({
  storeId,
  selectedCategoriesIds,
  ...props
}) => {
  const {user} = useAuth();
  const categoriesQuery = useQuery(
    ['omnivore-categories', storeId],
    () => fetchAllOmnivoreCategoriesRequest(user.token, storeId),
    {
      enabled: !!storeId,
      staleTime: Infinity,
    }
  );
  let inputRef: any = {current: undefined};

  const items = categoriesQuery.data ?? [];

  return (
    <OmnivoreCategoriesSelectorComponent
      {...props}
      items={items}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      itemRenderer={(category, {modifiers, handleClick}) => (
        <MenuItem
          active={false}
          key={category.categoryId}
          onClick={handleClick}
          text={`${category.categoryId} - ${category.name}`}
          icon={selectedCategoriesIds.includes(category.categoryId) ? 'tick' : 'blank'}
        />
      )}
      scrollToActiveItem={false}
      selectedItems={items.filter(({categoryId}) => selectedCategoriesIds.includes(categoryId))}
      tagRenderer={({name}) => name}
      itemsEqual={(m1, m2) => m1.categoryId === m2.categoryId}
      itemPredicate={(query, category) =>
        `${category.categoryId} - ${category.name}`.toLocaleLowerCase().includes(query.toLowerCase())
      }
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      tagInputProps={{
        ...(props.tagInputProps ?? null),
        inputRef: (input) => (inputRef = input),
        placeholder: 'Choose many...',
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
        leftIcon: categoriesQuery.isLoading ? <Button minimal loading /> : undefined,
        tagProps: {minimal: true},
      }}
    />
  );
};
