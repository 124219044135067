import React, {useState} from 'react';
import {useQueryClient} from 'react-query';
import {NonIdealState, Spinner} from '@blueprintjs/core';
import {Button, InputGroup} from '@blueprintjs/core';
import {useMutation, useQuery} from 'react-query';
import {deleteAdditionalChargeRequest, fetchAllAdditionalChargeRequest} from '../../api/additional-charge.api';
import {AdditionalChargeDto} from '@kontactless/admin-api/additional-charge/additional-charge.dto';
import {useAuth} from '../../contexts/auth.context';

import {useAlerts} from '../../contexts/alerts.context';

import {useToasts} from '../../contexts/toasts.context';

import {useParams} from 'react-router-dom';
import {AdditionalChargeType} from '../../utils/types';
import {getPriceText} from '../../utils/helpers.utils';
import AdditionalChargeUpsertDialog from './AdditionalChargeUpsertDialog';
import {fetchStoreRequest} from '../../api/store.api';

export default function AdditionalChargesPage() {
  const {user} = useAuth();
  const [search, setSearch] = useState('');
  const [isAdditionalChargeUpsertDialogOpen, setIsAdditionalChargeUpsertDialogOpen] = useState(false);
  const [additionalCharge, setAdditionalCharge] = useState<AdditionalChargeDto | undefined>(undefined);
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);

  const additionalChargesQuery = useQuery<AdditionalChargeDto[]>({
    queryKey: ['stores', storeId, 'additional-charges'],
    queryFn: () => fetchAllAdditionalChargeRequest(user.token, storeId),
  });
  const storeQuery = useQuery({
    queryKey: ['stores', storeId],
    queryFn: () => fetchStoreRequest(user.token, storeId),
    enabled: !!storeId,
  });

  const isPosConnected = !!storeQuery.data?.settings?.posId;

  const filteredAdditionalCharges =
    additionalChargesQuery.data?.filter((additionalCharge) =>
      search ? additionalCharge.name.toLowerCase().includes(search.toLowerCase()) : additionalCharge
    ) ?? [];

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-2 tw-border tw-p-6">
      <div className="tw-flex tw-justify-between tw-mb-2">
        <header className="card-header">
          <h3 className="tw-text-xl tw-font-bold">Additional charges</h3>
        </header>
      </div>
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
        <InputGroup
          className="tw-w-56"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search additional charge..."
          value={search}
          leftIcon="search"
        />
        <Button intent="primary" onClick={() => setIsAdditionalChargeUpsertDialogOpen(true)}>
          + Create additional charge
        </Button>
      </div>

      {filteredAdditionalCharges.length === 0 && !additionalChargesQuery.isLoading && (
        <NonIdealState
          icon="settings"
          description={
            search.length
              ? 'Looks like there are no additional charges matching your search.'
              : 'Looks like there are no additional charges yet.'
          }
        />
      )}

      {!!additionalChargesQuery.isLoading && <Spinner />}

      <AdditionalChargesTable
        additionalCharges={filteredAdditionalCharges}
        onEdit={(additionalCharge) => {
          setAdditionalCharge(additionalCharge);
          setIsAdditionalChargeUpsertDialogOpen(true);
        }}
        storeId={storeId}
      />

      <AdditionalChargeUpsertDialog
        isOpen={isAdditionalChargeUpsertDialogOpen}
        onClose={() => {
          setIsAdditionalChargeUpsertDialogOpen(false);
          setAdditionalCharge(undefined);
        }}
        additionalCharge={additionalCharge}
        isPosConnected={isPosConnected}
        storeId={storeId}
      />
    </div>
  );
}

function AdditionalChargesTable({
  additionalCharges,
  onEdit,
  storeId,
}: {
  additionalCharges: AdditionalChargeDto[];
  onEdit: (additionalCharge: AdditionalChargeDto) => void;
  storeId: number;
}) {
  const {user: authUser} = useAuth();
  const {alertsDispatch} = useAlerts();
  const queryClient = useQueryClient();
  const {
    state: {toaster},
  } = useToasts();

  const deleteAdditionalChargeMutation = useMutation({
    mutationFn: (id: number) => deleteAdditionalChargeRequest(authUser.token, id),
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId, 'additional-charges']);
      toaster.show({intent: 'success', message: 'Additional charge was deleted successfully'});
    },
    onError: (error) => {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred deleting the additional charge'});
    },
  });

  const onDeleteClick = async (additionalCharge: AdditionalChargeDto) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Delete additional charge "${additionalCharge.name}"?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            deleteAdditionalChargeMutation.mutate(additionalCharge.id);
            removeAlert();
          } catch (error) {
            setAlert({loading: false});
          }
        },
      },
    });
  };

  if (additionalCharges.length === 0) {
    return null;
  }

  const getFormatedValue = (value: number, type: AdditionalChargeType) => {
    if (type === 'percentage') {
      return `${value * 100}%`;
    }
    return getPriceText(value);
  };

  return (
    <table className="tw-min-w-full tw-divide-y tw-divide-solid tw-divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0"
          >
            Name
          </th>
          <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
            Value
          </th>
          <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
            Tax
          </th>
          <th scope="col" className="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 sm:tw-pr-0">
            <span className="tw-sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      <tbody className="tw-divide-y tw-divide-solid tw-divide-gray-200">
        {additionalCharges.map((additionalCharge) => (
          <tr key={additionalCharge.id}>
            <td className="tw-whitespace-nowrap tw-py-2 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">
              {additionalCharge.name}
            </td>
            <td className="tw-whitespace-nowrap tw-px-3 tw-py-2 tw-text-sm tw-text-gray-500">
              {getFormatedValue(additionalCharge.value, additionalCharge.type)}
            </td>
            <td className="tw-whitespace-nowrap tw-px-3 tw-py-2 tw-text-sm tw-text-gray-500">
              {additionalCharge.taxPercentage * 100}%
            </td>
            <td className="tw-relative tw-whitespace-nowrap tw-py-2 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium tw-space-x-2 sm:tw-pr-0">
              <Button minimal text="Edit" intent="primary" onClick={() => onEdit?.(additionalCharge)} />
              <Button minimal title="Delete" icon="trash" intent="danger" onClick={() => onDeleteClick(additionalCharge)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
