import {Button, FormGroup, MenuItem} from '@blueprintjs/core';
import {MultiSelect} from '@blueprintjs/select';
import {DiscountDto} from '@kontactless/admin-api/discount/discount.dto';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {fetchAllDiscountsRequest} from '../../../api/discount.api';
import {useQuery, useQueryClient} from 'react-query';
import {useAuth} from '../../../contexts/auth.context';
import {Tag} from '@blueprintjs/core';
import {useState} from 'react';
import {DiscountUpsertDialog} from '../../../components/discounts/discount-upsert-dialog.component';

interface PromotionsSelectProps {
  selectedDiscounts: DiscountDto[];
  disabled?: boolean;
  onChangeDiscounts(discounts: DiscountDto[]): void;
  store: StoreDto;
}

const PromotionsSelector = MultiSelect.ofType<DiscountDto>();

export const PromotionsSelect = ({store, disabled, onChangeDiscounts, selectedDiscounts}: PromotionsSelectProps) => {
  const inputRef: any = {current: undefined};
  const {user} = useAuth();
  const [isDiscountUpserDialogOpen, setIsDiscountUpserDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const discountsQuery = useQuery<DiscountDto[]>(['discounts', +store.id], () => fetchAllDiscountsRequest(user.token, store.id));

  const items = [...(discountsQuery.data ?? []), {id: -1, name: 'Create Promotion'}];

  return (
    <>
      <FormGroup className="!tw-mb-0">
        <PromotionsSelector
          resetOnSelect
          fill
          items={items as DiscountDto[]}
          popoverProps={{
            minimal: true,
          }}
          itemRenderer={(discount, {modifiers, handleClick}) => (
            <MenuItem
              key={discount.id}
              onClick={(ev) => {
                if (discount.id === -1) {
                  setIsDiscountUpserDialogOpen(true);
                } else {
                  handleClick(ev);
                }
              }}
              text={discount.name}
              icon={
                selectedDiscounts.map((selectedDiscount) => selectedDiscount.id).includes(discount.id)
                  ? 'tick'
                  : discount.id === -1
                  ? 'add'
                  : 'blank'
              }
              intent={discount.id === -1 ? 'primary' : undefined}
              labelElement={
                discount.id !== -1 && (
                  <div className="tw-mr-2 tw-ml-16">
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <DiscountLabel discount={discount} />
                    </div>
                  </div>
                )
              }
            />
          )}
          selectedItems={selectedDiscounts}
          onItemSelect={(item) =>
            onChangeDiscounts(
              !selectedDiscounts.map((discount) => discount.id).includes(item.id)
                ? [...selectedDiscounts, item]
                : selectedDiscounts
            )
          }
          onRemove={(item) => onChangeDiscounts(selectedDiscounts?.filter((discount) => discount.id !== item.id))}
          tagRenderer={(value) => value.name}
          itemsEqual={(m1, m2) => m1 === m2}
          itemPredicate={(query, value) => value.name.toLocaleLowerCase().includes(query.toLowerCase())}
          ref={inputRef}
          tagInputProps={{
            placeholder: 'Choose promotions...',
            rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
            tagProps: {minimal: true},
            disabled,
          }}
        />
      </FormGroup>
      <DiscountUpsertDialog
        store={store}
        isOpen={isDiscountUpserDialogOpen}
        onResolve={() => queryClient.invalidateQueries('discounts')}
        onClose={() => {
          setIsDiscountUpserDialogOpen(false);
        }}
      />
    </>
  );
};

interface DiscountLabelProps {
  discount: DiscountDto;
}

function DiscountLabel({discount}: DiscountLabelProps) {
  let text;
  let amount;

  if (discount.type === 'coupon-flat') {
    text = 'Coupon';
    amount = `$${discount.discount.toFixed(2)}`;
  }

  if (discount.type === 'coupon-percentage') {
    text = 'Coupon';
    amount = `${discount.discount.toFixed(2)}%`;
  }

  if (discount.type === 'promotion-flat-price') {
    text = 'Flat Price';
    amount = `$${discount.discount.toFixed(2)}`;
  }

  return (
    <div className="tw-flex tw-gap-2">
      <span className="tw-text-gray-500">{text}</span>
      <Tag minimal>{amount}</Tag>
    </div>
  );
}
