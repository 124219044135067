import React from 'react';

import {Button, Callout, Classes, Dialog, FormGroup, HTMLSelect, IDialogProps, InputGroup} from '@blueprintjs/core';
import {yupResolver} from '@hookform/resolvers/yup';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import {useMutation} from 'react-query';
import * as yup from 'yup';

import {createPrinterRequest, updatePrinterRequest} from '../../api/printer.api';
import {useAuth} from '../../contexts/auth.context';
import {useCache} from '../../contexts/cache/cache.context';
import {useToasts} from '../../contexts/toasts.context';
import {ORDER_STATUSES} from '../../utils/constants';
import {getOrderStatusLabel} from '../../utils/helpers.utils';
import {OrderStatus} from '../../utils/types';

import {PrinterCreateRequestDto, PrinterDto, PrinterUpdateRequestDto} from '@kontactless/admin-api/printer/printer.dto';

export interface PrinterUpsertDialogProps extends IDialogProps {
  storeId: number;
  printer?: PrinterDto;
  onSubmit?: (printer: PrinterDto) => void;
}

interface PrinterForm {
  name: string;
  orderStatusTrigger: OrderStatus;
}

const formValidator = yup.object().shape({
  name: yup.string().required(),
  orderStatusTrigger: yup
    .string()
    .oneOf([...ORDER_STATUSES])
    .required(),
});

export const PrinterUpsertDialog: React.FC<PrinterUpsertDialogProps> = ({storeId, printer, ...props}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {register, handleSubmit, errors, control} = useForm<PrinterForm>({resolver: yupResolver(formValidator)});
  const {dispatchCacheAction} = useCache();

  const upsertPrinterMutation = useMutation<PrinterDto, Error, PrinterCreateRequestDto | PrinterUpdateRequestDto>((body) => {
    if (printer?.id) {
      return updatePrinterRequest(user.token, body as PrinterUpdateRequestDto, printer.id);
    } else {
      return createPrinterRequest(user.token, body as PrinterCreateRequestDto);
    }
  });

  const submitForm = async (form: PrinterForm) => {
    try {
      const upsertedPrinter = await upsertPrinterMutation.mutateAsync({
        ...form,
        storeId,
      });

      props.onClose?.(undefined as any);
      props.onSubmit?.(upsertedPrinter);
      dispatchCacheAction({type: 'upsert-printer', printer: upsertedPrinter});
      toaster.show({intent: 'success', message: 'Printer was created successfully'});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred and the printer was not created'});
    }
  };

  return (
    <Dialog title={printer ? 'Edit printer' : 'Create printer'} className="kl-dialog printer-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <Callout icon="info-sign">Make sure the printer name matches the one you configured in the EPSON app</Callout>
        <br />
        <div className="dialog-form">
          <FormGroup
            label="Printer name"
            intent={errors.name ? 'danger' : 'none'}
            helperText={errors.name ? 'Name is required' : ''}
          >
            <InputGroup
              className="control"
              name="name"
              intent={errors.name ? 'danger' : 'none'}
              defaultValue={printer?.name ?? ''}
              inputRef={register({
                validate: {
                  required: (value: string) => value.trim() !== '',
                },
              })}
            />
          </FormGroup>

          <FormGroup
            label="Trigger"
            intent={errors.orderStatusTrigger ? 'danger' : 'none'}
            helperText={errors.orderStatusTrigger ? 'Trigger is invalid' : ''}
          >
            <Controller
              name="orderStatusTrigger"
              defaultValue={printer?.orderStatusTrigger ?? 'received'}
              control={control}
              render={(props) => (
                <HTMLSelect
                  fill
                  options={ORDER_STATUSES.map((status) => ({label: getOrderStatusLabel(status), value: status}))}
                  onChange={(event) => props.onChange(event.target.value)}
                  value={props.value}
                />
              )}
            />
          </FormGroup>
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button text="Cancel" minimal disabled={upsertPrinterMutation.isLoading} onClick={(ev) => props.onClose?.(ev)} />
        <Button
          text={printer ? 'Save changes' : 'Create'}
          intent="primary"
          loading={upsertPrinterMutation.isLoading}
          disabled={upsertPrinterMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
};
