import {callApi} from './api';

import {
  DiscountCreateRequestDto,
  DiscountDto,
  DiscountMenuCategoryProductCreateRequestDto,
  DiscountUpdateRequestDto,
} from '@kontactless/admin-api/discount/discount.dto';

export const fetchAllDiscountsRequest = async (token: string, storeId: number): Promise<DiscountDto[]> => {
  return callApi<DiscountDto[]>(`/discounts`, {token, params: {storeId}}).then(({data}) => data);
};

export const createDiscountRequest = async (token: string, discount: DiscountCreateRequestDto): Promise<DiscountDto> => {
  return callApi<DiscountDto>(`/discounts`, {token, method: 'POST', body: JSON.stringify(discount)}).then(({data}) => data);
};

export const createDiscountMenuCategoryProductRequest = async (
  token: string,
  body: DiscountMenuCategoryProductCreateRequestDto,
  discountId: number
): Promise<DiscountDto> => {
  return callApi<DiscountDto>(`/discounts/${discountId}/menu-category-products`, {
    token,
    method: 'POST',
    body: JSON.stringify(body),
  }).then(({data}) => data);
};

export const deleteDiscountMenuCategoryProductRequest = async (
  token: string,
  discountId: number,
  menuCategoryProductId: number
): Promise<DiscountDto> => {
  return callApi<DiscountDto>(`/discounts/${discountId}/menu-category-products/${menuCategoryProductId}`, {
    token,
    method: 'DELETE',
  }).then(({data}) => data);
};

export const updateDiscountRequest = async (
  token: string,
  discountId: number,
  discount: DiscountUpdateRequestDto
): Promise<DiscountDto> => {
  return callApi<DiscountDto>(`/discounts/${discountId}`, {token, method: 'PATCH', body: JSON.stringify(discount)}).then(
    ({data}) => data
  );
};

export const deleteDiscountRequest = async (token: string, discountId: number): Promise<{}> => {
  return callApi<{}>(`/discounts/${discountId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
