import React, {useMemo, useState} from 'react';

import {Button, HTMLTable} from '@blueprintjs/core';
import {sortBy} from 'lodash';
import {useMutation, useQueryClient} from 'react-query';

import {ApiError} from '../../../api/api';
import {deleteDiscountMenuCategoryProductRequest} from '../../../api/discount.api';
import {Pagination} from '../../../components/ui/Pagination';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';

import {getPaginationData} from '../../../utils/helpers.utils';

import {DiscountDto} from '@kontactless/admin-api/discount/discount.dto';
import {MenuCategoryProductDto} from '@kontactless/admin-api/menu-category-product/menu-category-product.dto';
interface PromotionsProductsTableProps {
  discount: DiscountDto;
}

export const PromotionsProductsTable: React.FC<PromotionsProductsTableProps> = ({discount}) => {
  const pageSize = 10;
  const [page, setPage] = useState(1);

  const menuCategoryProducts = useMemo(() => sortDiscountProducts(discount), [discount]);
  const {records, totalPages, totalRecords} = useMemo(() => getPaginationData(menuCategoryProducts, page, pageSize), [
    menuCategoryProducts,
    page,
    pageSize,
  ]);

  return (
    <HTMLTable className="tw-w-full" striped condensed>
      <thead>
        <tr>
          <th>Menu</th>
          <th>Category</th>
          <th>Product</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {records.map((menuCategoryProduct) => (
          <MenuCategoryProductRow key={menuCategoryProduct.id} discount={discount} menuCategoryProduct={menuCategoryProduct} />
        ))}
        <tr>
          <td colSpan={4}>
            <Pagination
              pageSize={pageSize}
              page={page}
              totalPages={totalPages}
              totalRecords={totalRecords}
              onPageSelected={setPage}
              hideInSinglePage
            />
          </td>
        </tr>
      </tbody>
    </HTMLTable>
  );
};

const MenuCategoryProductRow: React.FC<{discount: DiscountDto; menuCategoryProduct: MenuCategoryProductDto}> = ({
  discount,
  menuCategoryProduct,
}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const queryClient = useQueryClient();

  const deleteDiscountMenuCategoryProductMutation = useMutation<{}, ApiError, void>(
    () => deleteDiscountMenuCategoryProductRequest(user.token, discount.id, menuCategoryProduct.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['stores', discount.storeId, 'discounts']);

        toaster.show({
          intent: 'success',
          message: `"${menuCategoryProduct.product?.name ?? 'Product'}" removed from discount successfully`,
        });
      },
      onError: () => {
        toaster.show({
          intent: 'danger',
          message: `An error ocurred removing "${menuCategoryProduct.product?.name ?? 'Product'}" from discount`,
        });
      },
    }
  );

  return (
    <tr key={menuCategoryProduct.id}>
      <td>{menuCategoryProduct.menuCategory?.menu?.name || ''}</td>
      <td>{menuCategoryProduct.menuCategory?.name || ''}</td>
      <td>{menuCategoryProduct.product?.name || ''}</td>
      <td className="tw-flex tw-justify-end">
        <Button
          intent="danger"
          icon="trash"
          loading={deleteDiscountMenuCategoryProductMutation.isLoading}
          onClick={() => deleteDiscountMenuCategoryProductMutation.mutate()}
          small
          minimal
        />
      </td>
    </tr>
  );
};

const sortDiscountProducts = (discount: DiscountDto): MenuCategoryProductDto[] => {
  return sortBy(
    discount.menuCategoryProducts ?? [],
    (menuCategoryProduct) =>
      `m${menuCategoryProduct.menuCategory?.menuId}-c${menuCategoryProduct.menuCategoryId}-p${menuCategoryProduct.productId}`
  );
};
