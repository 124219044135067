import React from 'react';

import {Spinner} from '@blueprintjs/core';

import './loading-app.styles.scss';

export const LoadingAppScene: React.FC = () => {
  return (
    <section className="loading-app-scene">
      <Spinner intent="primary" />
    </section>
  );
};
