import {useEffect, useState} from 'react';
import {useToasts} from '../../contexts/toasts.context';
import useClipboard from '../../hooks/useClipboard';
import {Badge} from '../../common/components/Badge';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Icon, InputGroup, NonIdealState, Spinner} from '@blueprintjs/core';
import {Container} from '../../common/components/layout/Container';
import {Header} from '../../common/components/layout/Header';
import {OrdersFooter} from '../../common/components/layout/OrdersFooter';
import {useAuth} from '../../contexts/auth.context';
import {useQuery} from 'react-query';

import {fetchAllEventsRequest} from '../../api/event.api';
import {DateTime} from 'luxon';
import {EventGrid} from '../components/EventGrid';
import {orderBy} from 'lodash';
import {fetchStoreRequest} from '../../api/store.api';

export function UpcomingEventsPage() {
  const {user} = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);
  const preorderUrl = `${process.env.REACT_APP_CUSTOMER_APP_FREEDOMPAY_HOST}/stores/${storeId}/events`;

  const storeQuery = useQuery({
    queryKey: ['stores', storeId],
    queryFn: () => fetchStoreRequest(user.token, storeId),
  });

  const eventsQuery = useQuery({
    queryKey: ['stores', storeId, 'events', {dateStart: DateTime.utc().toFormat('yyyy-MM-dd')}],
    queryFn: () =>
      fetchAllEventsRequest(user.token, {
        storeId: storeId,
        dateStart: DateTime.utc().toFormat('yyyy-MM-dd'),
      }),
    enabled: !!storeId,
  });

  const filteredEvents =
    eventsQuery.data?.filter((event) => (search ? event.name.toLowerCase().includes(search.toLowerCase()) : event)) ?? [];

  if (!eventsQuery.data || !storeQuery.data) {
    return <Spinner />;
  }

  return (
    <>
      <>
        <Header>
          <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
            <div className="tw-flex tw-gap-4 tw-items-center">
              <Button
                text="Back"
                icon="chevron-left"
                minimal
                small
                className="!tw-bg-blue-100 !tw-rounded-lg"
                onClick={() => navigate('../ordering-methods')}
              />
              <h1 className="tw-text-base tw-font-bold tw-whitespace-nowrap">Upcoming events</h1>
            </div>

            <div className="tw-flex tw-gap-4 tw-items-center">
              <Button text="View past events" minimal intent="primary" onClick={() => navigate('past')} />

              <Button intent="primary" className="tw-ml-auto" onClick={() => navigate('new')}>
                + Add new event
              </Button>
            </div>
          </div>
        </Header>
        <Container>
          <div className="tw-flex tw-items-center tw-justify-between">
            <InputGroup
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Events..."
              value={search}
              leftIcon="search"
            />
            <PreorderLink url={preorderUrl} />
          </div>

          {/* Loading state */}
          {eventsQuery.isLoading && <Spinner />}

          {/* Empty state */}
          {!eventsQuery.isLoading && !eventsQuery.data.length && (
            <div className="tw-pt-10">
              <NonIdealState
                icon="settings"
                description={
                  search.length
                    ? `Looks like there are no upcoming events matching your search.`
                    : `Looks like there are no upcoming events yet.`
                }
                action={<Button intent="primary" text="+ Add new event" onClick={() => navigate('new')} />}
              />
            </div>
          )}

          <EventGrid timezone={storeQuery.data.timezone} events={orderBy(filteredEvents, ['startDatetime'], ['asc'])} />
        </Container>
        <OrdersFooter />
      </>
    </>
  );
}

function PreorderLink({url}: {url: string}) {
  const {copy, copied} = useClipboard();
  const {
    state: {toaster},
  } = useToasts();

  useEffect(() => {
    if (copied) {
      toaster.show({message: 'Pre-order link copied to clipboard', intent: 'success'});
    }
  }, [copied, toaster]);

  return (
    <>
      <div className="tw-flex tw-items-center tw-gap-2 tw-rounded tw-bg-white tw-p-1 tw-border-gray-200 tw-border tw-overflow-hidden tw-border-solid tw-text-gray-900">
        <div className="tw-font-semibold tw-truncate">
          <Badge text="Pre-order link" />
        </div>
        <Link to={url} target="_blank" className="tw-text-blue-500 hover:tw-text-blue-500 tw-rounded tw-truncate">
          {url}
        </Link>
        <Button small intent="primary" onClick={() => copy(url)} text="Copy" icon={<Icon icon="duplicate" />} />
      </div>
    </>
  );
}
