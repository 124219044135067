import React, {createRef, useMemo} from 'react';

import {Button, IButtonProps, InputGroup, MenuItem} from '@blueprintjs/core';
import {ISuggestProps, Suggest} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';

import {fetchAllOmnivoreOrderTypesRequest} from '../../api/omnivore.api';
import {useAuth} from '../../contexts/auth.context';
import {OmnivoreOrderTypeDto} from '@kontactless/admin-api/omnivore-order-type/omnivore-order-type.dto';

import './selectors.styles.scss';

interface OmnivoreOrderTypeSelectorProps
  extends Omit<ISuggestProps<OmnivoreOrderTypeDto>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  storeId: number;
  fill?: boolean;
  selectedItemId?: string;
  onItemSelected: (item: OmnivoreOrderTypeDto | undefined) => void;
  buttonProps?: IButtonProps;
}

const OmnivoreOrderTypeSelectorComponent = Suggest.ofType<OmnivoreOrderTypeDto>();

export const OmnivoreOrderTypeSelector: React.FC<OmnivoreOrderTypeSelectorProps> = ({
  storeId,
  selectedItemId,
  onItemSelected,
  buttonProps,
  ...props
}) => {
  const {user} = useAuth();
  const orderTypesQuery = useQuery(['omnivore-order-types', storeId], () =>
    fetchAllOmnivoreOrderTypesRequest(user.token, storeId)
  );
  const inputRef = createRef<any>();

  const selectedItem = useMemo(() => {
    return orderTypesQuery.data?.find(({orderTypeId}) => orderTypeId === selectedItemId);
  }, [orderTypesQuery.data, selectedItemId]);

  const getOrderTypeLabel = (orderType: OmnivoreOrderTypeDto): string => {
    return `(${orderType.posId ?? ''}) - ${orderType.name ?? 'no name input'}`;
  };

  if (orderTypesQuery.isError) {
    return <InputGroup {...(props.inputProps ?? {})} intent="danger" value="Error fetching omnivore order types" readOnly />;
  }

  return (
    <OmnivoreOrderTypeSelectorComponent
      {...props}
      inputValueRenderer={(orderType) => (orderTypesQuery.isFetching ? '' : getOrderTypeLabel(orderType))}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      items={orderTypesQuery.data ?? []}
      itemRenderer={(orderType, {modifiers, handleClick}) => (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={orderType.orderTypeId}
          onClick={handleClick}
          text={getOrderTypeLabel(orderType)}
        />
      )}
      onItemSelect={(value) => onItemSelected(value)}
      itemsEqual={(e1, e2) => e1.orderTypeId === e2.orderTypeId}
      selectedItem={selectedItem ?? null}
      itemPredicate={(query, orderType) =>
        !!orderType.orderTypeId.toLocaleLowerCase().includes(query.toLowerCase()) ||
        !!orderType.name?.toLocaleLowerCase().includes(query.toLowerCase())
      }
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      inputProps={{
        ...(props.inputProps ?? {}),
        leftElement: orderTypesQuery.isFetching ? <Button loading minimal /> : undefined,
        placeholder: orderTypesQuery.isFetching ? 'Loading order types...' : 'Choose one...',
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
      }}
    />
  );
};
