import React, {useEffect} from 'react';

import {Button, Icon, InputGroup, Switch} from '@blueprintjs/core';
import {Tooltip2} from '@blueprintjs/popover2';
import classNames from 'classnames';
import {DragDropContext, Draggable, Droppable, OnDragEndResponder} from 'react-beautiful-dnd';
import {Controller, useFieldArray} from 'react-hook-form';

import {PriceInput} from '../ui/price-input.component';
import {FieldArrayProps, ModifierFieldset} from './product-upsert-drawer.model';

export const ModifierFieldArray: React.FC<FieldArrayProps> = ({
  parentIndex,
  control,
  register,
  isFormLoading,
  clearErrors,
  setError,
  errors,
  isOmnivoreProduct,
}) => {
  const {fields, remove, append, move} = useFieldArray<ModifierFieldset>({
    control,
    name: `modifierGroups.${parentIndex}.modifiers`,
  });
  const fieldPath = (index: number, fieldName: string) => `modifierGroups.${parentIndex}.modifiers.${index}.${fieldName}`;
  const fieldHasError = (index: number, fieldName: string) =>
    !!(errors as any)?.modifierGroups?.[parentIndex!]?.modifiers?.[index]?.[fieldName];

  useEffect(() => {
    if (!fields.length) {
      setError?.(`modifierGroups[${parentIndex}].modifiersCount`, {
        type: 'required',
        message: 'You must add at least one extra',
      });
    } else {
      clearErrors?.(`modifierGroups[${parentIndex}].modifiersCount`);
    }
  }, [fields, setError, clearErrors, parentIndex]);

  const addModifier = () => append({name: '', price: undefined, taxId: null, isEnabled: true}, false);

  const onDragEnd: OnDragEndResponder = async (result) => {
    if (result && result.destination) {
      move(result.source.index, result.destination.index);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="drop-modifier">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, index) => (
                <React.Fragment key={`modifier-${field.id}`}>
                  <Draggable draggableId={`drag-modifier-${field.id}`} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <fieldset key={field.id} className="single-modifier">
                          {typeof field.id === 'number' && (
                            <input
                              type="hidden"
                              name={fieldPath(index, 'id')}
                              defaultValue={field.id}
                              ref={register({valueAsNumber: true})}
                            />
                          )}
                          <Icon
                            {...provided.dragHandleProps}
                            title="Drag to reorder the modifiers"
                            icon="drag-handle-vertical"
                            className="handle"
                          />
                          <Controller
                            name={fieldPath(index, 'isEnabled')}
                            control={control}
                            defaultValue={field.isEnabled}
                            render={(props) => (
                              <Switch
                                defaultChecked={field.isEnabled}
                                onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                              />
                            )}
                          />
                          <InputGroup
                            autoFocus={!field.name}
                            className={classNames('editable-text', {empty: !field.name})}
                            fill
                            placeholder="Enter the modifier name"
                            disabled={isFormLoading}
                            name={fieldPath(index, 'name')}
                            defaultValue={field.name}
                            inputRef={register({required: true})}
                            intent={fieldHasError(index, 'name') ? 'danger' : 'none'}
                          />

                          <Controller
                            name={fieldPath(index, 'price')}
                            control={control}
                            defaultValue={field.price?.toString() || '0'}
                            render={(props) => (
                              <Tooltip2
                                targetTagName="div"
                                disabled={!isOmnivoreProduct}
                                content="Price is read-only for Omnivore products"
                              >
                                <PriceInput
                                  fill
                                  value={props.value}
                                  disabled={isFormLoading || isOmnivoreProduct}
                                  onChange={(value) => props.onChange(value)}
                                />
                              </Tooltip2>
                            )}
                          />

                          {!isOmnivoreProduct && (
                            <Button
                              disabled={isFormLoading}
                              className="remove-modifier"
                              icon="small-cross"
                              minimal
                              onClick={() => remove(index)}
                              title="Remove modifier from group"
                            />
                          )}
                        </fieldset>
                      </div>
                    )}
                  </Draggable>
                </React.Fragment>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!isOmnivoreProduct && (
        <Button disabled={isFormLoading} icon="add" className="add-extra" onClick={addModifier}>
          Add modifier
        </Button>
      )}
    </>
  );
};
