import React from 'react';

import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

export const PhoneField: React.FC<any> = (props) => (
  <PhoneInput {...props} className={classNames('phone-field', 'bp3-input', props.className)} />
);
