import React, {useState} from 'react';

import {Alert, Drawer as BPDrawer, IDrawerProps, Intent} from '@blueprintjs/core';
import classNames from 'classnames';

export interface DrawerProps extends IDrawerProps {
  title: string;
  isOpen: boolean;
  close: () => void;
  confirmClose?: boolean;
  confirmCloseMessage?: string;
}

export const Drawer: React.FC<DrawerProps> = ({
  title,
  isOpen = false,
  close,
  confirmClose = false,
  confirmCloseMessage = 'Are you sure you want to quit? Unsaved changes will be lost.',
  children,
  ...props
}) => {
  const [showCloseAlert, setShowCloseAlert] = useState(false);

  const handleClose = () => {
    if (confirmClose) {
      setShowCloseAlert(true);
      return;
    }
    close();
  };

  const cancelCloseAlert = () => setShowCloseAlert(false);

  const confirmCloseAlert = () => {
    setShowCloseAlert(false);
    close();
  };

  return (
    <>
      <Alert
        cancelButtonText="No"
        confirmButtonText="Yes"
        icon="warning-sign"
        intent={Intent.DANGER}
        isOpen={showCloseAlert}
        onCancel={cancelCloseAlert}
        onConfirm={confirmCloseAlert}
      >
        <p>{confirmCloseMessage}</p>
      </Alert>
      <BPDrawer
        onClose={handleClose}
        title={title}
        autoFocus
        enforceFocus
        canEscapeKeyClose
        isOpen={isOpen}
        usePortal
        {...props}
        className={classNames(props.className, 'kl-drawer')}
      >
        {children}
      </BPDrawer>
    </>
  );
};
