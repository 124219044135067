import {Button, FormGroup, MenuItem} from '@blueprintjs/core';
import {Select} from '@blueprintjs/select';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {TipsConfigurationDto} from '@kontactless/admin-api/tips-configuration/tips-configuration.dto';
import {fetchAllTipsConfigurationsRequest} from '../tip-configurations.api';
import {useAuth} from '../../contexts/auth.context';
import {ChangeEvent, useState} from 'react';
import {useQuery} from 'react-query';
import {UpsertTipConfigurationDialog} from './UpsertTipConfigurationDialog';
import {TipConfigurationSummary} from './TipConfigurationSummary';

interface TipSelectProps {
  areOptionalTipsEnabled: boolean;
  onAreTipsEnabledChange(event: ChangeEvent<HTMLInputElement>): void;
  store: StoreDto;
  onTipSelected: (item: TipsConfigurationDto) => void;
  selectedTipConfigurationId?: number;
  disabled: boolean;
}

const Selector = Select.ofType<TipsConfigurationDto>();

export function TipSelector({store, selectedTipConfigurationId, onTipSelected, disabled}: TipSelectProps) {
  const {user} = useAuth();
  const [isTipConfigurationUpsertDialogOpen, setIsTipConfigurationUpsertDialogOpen] = useState(false);

  const tipConfigurationQuery = useQuery({
    queryKey: ['stores', store.id, 'tips-configurations'],
    queryFn: () => fetchAllTipsConfigurationsRequest(user.token, store.id),
  });

  const createTipItem: TipsConfigurationDto = {
    id: -1,
    name: 'Create Tip',
    tip1: 0,
    tip2: 0,
    tip3: 0,
    defaultTipOption: 1,
    isDefaultTipOptionFixed: false,
    defaultTipValue: 0,
    storeId: store.id,
  };

  const items = [...(tipConfigurationQuery.data ?? []), createTipItem];

  const selectedTipConfiguration = items.find((item) => item.id === selectedTipConfigurationId);

  return (
    <>
      <div className="tw-w-full">
        <FormGroup className="!tw-mb-0 tw-w-full" disabled={disabled}>
          <Selector
            matchTargetWidth
            filterable={false}
            popoverProps={{minimal: true}}
            items={items}
            disabled={disabled}
            itemRenderer={(item, {modifiers, handleClick}) => {
              return (
                <MenuItem
                  key={item.id}
                  disabled={disabled}
                  text={item.name}
                  intent={item.id === -1 ? 'primary' : undefined}
                  icon={item.id === -1 ? 'add' : selectedTipConfigurationId === item.id ? 'tick' : 'blank'}
                  labelElement={
                    item.id !== -1 && (
                      <div className="tw-mr-4">
                        <TipConfigurationSummary tipConfiguration={item} />
                      </div>
                    )
                  }
                  onClick={(ev) => {
                    if (item.id === -1) {
                      setIsTipConfigurationUpsertDialogOpen(true);
                    } else {
                      handleClick(ev);
                    }
                  }}
                />
              );
            }}
            onItemSelect={(item) => onTipSelected(item)}
          >
            <div className="tw-relative">
              <Button
                text={selectedTipConfiguration ? `${selectedTipConfiguration.name}` : 'Add a tip...'}
                rightIcon="chevron-down"
                fill
                className="tw-flex !tw-justify-between !tw-bg-white !tw-text-xs"
                disabled={disabled}
              />
              {!!selectedTipConfiguration && (
                <div className="tw-absolute tw-right-8 tw-top-[5px]">
                  <TipConfigurationSummary tipConfiguration={selectedTipConfiguration} />
                </div>
              )}
            </div>
          </Selector>
        </FormGroup>
      </div>
      <UpsertTipConfigurationDialog
        isOpen={isTipConfigurationUpsertDialogOpen}
        onClose={() => setIsTipConfigurationUpsertDialogOpen(false)}
        storeId={store.id}
        onTipConfigurationUpserted={(tipConfiguration) => {
          setIsTipConfigurationUpsertDialogOpen(false);
          onTipSelected(tipConfiguration);
        }}
      />
    </>
  );
}
