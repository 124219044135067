import React from 'react';

import {Controller, useForm} from 'react-hook-form';
import {useMutation} from 'react-query';
import {Button, FileInput, FormGroup, InputGroup, MenuItem, NumericInput} from '@blueprintjs/core';
import csvToJson from 'csvtojson';

import {QrGenerateRequest, generateQrsRequest} from '../../api/qr.api';
import {ImageUploader} from '../../components/images/image-uploader.component';
import {Selector} from '../../components/selectors/selector.component';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {QR_EXPORT_TEMPLATES} from '../../utils/constants';
import {convertBlobToBase64} from '../../utils/helpers.utils';
import {QrExportTemplate} from '../../utils/types';

interface QrGeneratorForm {
  url: string;
  quantity: number;
  template: QrExportTemplate;
  csv: File;
  images: string[];
}

export const QrGeneratorScene: React.FC = () => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {register, control, handleSubmit, watch, errors, setError} = useForm<QrGeneratorForm>();

  const generateQrsMutation = useMutation<{code: number; url: string}, Error, QrGenerateRequest>((body) =>
    generateQrsRequest(user.token, body)
  );

  const onSubmit = async (form: QrGeneratorForm) => {
    try {
      let image: string | undefined = undefined;

      if (form.images?.[0]) {
        try {
          image = await fetch(form.images[0])
            .then((response) => response.blob())
            .then(convertBlobToBase64);
        } catch (error) {
          toaster.show({intent: 'danger', message: 'An error ocurred fetching the uploaded image'});
          return;
        }
      }

      let csvJson: Array<{ATTRIBUTE: string; VALUE: string}> | undefined = undefined;

      if (form.csv) {
        try {
          csvJson = await csvToJson().fromString(await form.csv.text());
        } catch (error) {
          setError('csv', {type: 'format', message: 'CSV is invalid'});
          return;
        }
      }

      const {code, url} = await generateQrsMutation.mutateAsync({
        url: form.url,
        quantity: +form.quantity,
        template: form.template.name,
        csv: csvJson,
        image,
      });

      if (code === 200 || code === 201) {
        window.open(url, '_blank', 'fullscreen=yes');
      } else {
        toaster.show({intent: 'danger', message: 'An error ocurred creating generating the QR codes'});
      }
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred creating generating the QR codes'});
    }
  };

  return (
    <section className="scene scene-content scene-qr-generator">
      <h1 className="scene-title">QR Generator</h1>
      <div className="form-body card">
        <FormGroup label="URL" helperText={errors.url ? 'URL is required' : ''} intent={errors.url ? 'danger' : 'none'}>
          <InputGroup
            name="url"
            defaultValue={process.env.REACT_APP_APP_BASE_URL ?? ''}
            intent={errors.url ? 'danger' : 'none'}
            inputRef={register({required: true})}
          />
        </FormGroup>
        <FormGroup
          label="Quantity"
          helperText={errors.quantity ? 'Quantity is required' : ''}
          intent={errors.quantity ? 'danger' : 'none'}
        >
          <NumericInput
            name="quantity"
            defaultValue={1}
            min={1}
            intent={errors.quantity ? 'danger' : 'none'}
            inputRef={register({required: true})}
            fill
          />
        </FormGroup>
        <FormGroup label="CSV" helperText={errors.csv ? 'CSV is not valid' : ''} intent={errors.csv ? 'danger' : 'none'}>
          <Controller
            name="csv"
            control={control}
            defaultValue={null}
            intent={errors.csv ? 'danger' : 'none'}
            render={(props) => (
              <FileInput
                inputProps={{accept: '.csv'}}
                text={props.value?.name}
                hasSelection={!!props.value}
                onInputChange={({currentTarget: {files}}) => props.onChange(files?.[0] ?? props.value)}
                fill
              />
            )}
          />
        </FormGroup>
        <FormGroup
          label="Template"
          helperText={errors.template ? 'Template is required' : ''}
          intent={errors.template ? 'danger' : 'none'}
        >
          <Controller
            name="template"
            control={control}
            defaultValue={QR_EXPORT_TEMPLATES[0]}
            rules={{required: true}}
            render={(props) => (
              <Selector
                items={[...QR_EXPORT_TEMPLATES]}
                itemRenderer={(value: QrExportTemplate, {modifiers, handleClick}) => (
                  <MenuItem
                    key={value.name}
                    active={value.name === props.value?.name}
                    disabled={modifiers.disabled}
                    onClick={handleClick}
                    text={value.label}
                  />
                )}
                onItemSelect={props.onChange}
                selectedItem={props.value ?? null}
                buttonProps={{text: props.value?.label ?? 'Template', className: errors.template ? 'danger-outline' : ''}}
                fill
              />
            )}
          />
        </FormGroup>
        {watch('template')?.name.includes('upload') ? (
          <FormGroup>
            <Controller
              name="images"
              control={control}
              defaultValue={[]}
              render={(props) => <ImageUploader value={props.value} onChange={props.onChange} maxNumber={1} />}
            />
          </FormGroup>
        ) : null}
        <div className="form-actions">
          <Button
            text="Generate"
            intent="primary"
            disabled={generateQrsMutation.isLoading}
            loading={generateQrsMutation.isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </section>
  );
};
