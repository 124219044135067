import React, {useEffect, useState} from 'react';
import {Button, Collapse, FormGroup, Icon, InputGroup, Switch, TextArea} from '@blueprintjs/core';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import {isValidPhoneNumber} from 'react-phone-number-input';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {PlaceSelector} from '../../../components/selectors/place-selector.component';
import {TimezoneSelector} from '../../../components/selectors/timezone-selector.component';
import {PhoneField} from '../../../components/ui/phone-field.component';
import {useAlerts} from '../../../contexts/alerts.context';
import {useAuth} from '../../../contexts/auth.context';
import {useCache} from '../../../contexts/cache/cache.context';
import {useToasts} from '../../../contexts/toasts.context';
import {Timezone} from '../../../utils/types';

import {RolesChip} from '../../../components/ui/roles-chip';
import {StoreDto, StoreUpdateRequestDto} from '@kontactless/admin-api/store/store.dto';
import {deleteStoreRequest, fetchStoreRequest, updateStoreRequest} from '../../../api/store.api';
import {ImageUploader} from '../../../components/images/image-uploader.component';
import {TimeframeRow} from '../../../components/timeframes/TimeframeRow';
import {TimeframeUpsertDialog} from '../../../components/timeframes/timeframe-upsert-dialog.component';
import {TimeframeDto} from '@kontactless/admin-api/timeframe/timeframe.dto';

interface StoreUpdateForm {
  name: string;
  description: string;
  timezone: Timezone;
  isEnabled: boolean;
  imageUrl?: string[];
  confirmOrderStatusUpdate: boolean;
  showProductImagePlaceholder: boolean;
  formattedAddress: string;
  phone: string;
  email: string;
  instagram: string;
  facebook: string;
  twitter: string;
}

export function GeneralForm() {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {alertsDispatch} = useAlerts();
  const params = useParams<{companyId: string; eventId?: string; storeId: string; page: string}>();
  const storeId = Number(params.storeId);
  const storeQuery = useQuery(['stores', storeId], () => fetchStoreRequest(user.token, storeId));
  const [isTimeframeDialogOpen, setIsTimeframeDialogOpen] = useState(false);
  const [timeframeEditing, setTimeframeEditing] = useState<TimeframeDto | undefined>();

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: {isDirty},
    reset,
  } = useForm<StoreUpdateForm>({defaultValues: defaultForm});

  const {dispatchCacheAction} = useCache();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const storeUpdateMutation = useMutation<StoreDto, Error, StoreUpdateRequestDto>((update) =>
    updateStoreRequest(user.token, update, storeId)
  );
  const deleteStoreMutation = useMutation<{}, Error, number>((storeId) => deleteStoreRequest(user.token, storeId));
  const [isSocialNetworksOpen, setIsSocialNetworksOpen] = useState<boolean>(false);

  useEffect(() => {
    if (storeQuery.data) {
      reset({
        name: storeQuery.data.name,
        description: storeQuery.data.description,
        email: storeQuery.data.address?.email,
        formattedAddress: storeQuery.data.address?.formattedAddress,
        imageUrl: storeQuery.data.imageUrl ? [storeQuery.data.imageUrl] : [],
        isEnabled: storeQuery.data.isEnabled,
        confirmOrderStatusUpdate: storeQuery.data.settings?.confirmOrderStatusUpdate,
        showProductImagePlaceholder: storeQuery.data.settings?.showProductImagePlaceholder,
        timezone: storeQuery.data.timezone,
        phone: storeQuery.data.address?.phone,
        twitter: storeQuery.data.address?.twitter,
        facebook: storeQuery.data.address?.facebook,
        instagram: storeQuery.data.address?.instagram,
      });
    }
  }, [storeQuery.data, reset]);

  const submitForm = async (form: StoreUpdateForm) => {
    try {
      const updatedStore = await storeUpdateMutation.mutateAsync({
        ...form,
        imageUrl: form.imageUrl?.length ? form.imageUrl[0] : null,
        address: {...form, phone: form.phone ?? ''},
        settings: {
          confirmOrderStatusUpdate: form.confirmOrderStatusUpdate,
          showProductImagePlaceholder: form.showProductImagePlaceholder,
        },
      });

      queryClient.setQueryData<StoreDto>(['stores', storeId], () => ({...storeQuery.data, ...updatedStore}));

      toaster.show({intent: 'success', message: 'Stores settings were updated successfully'});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred updating the store settings'});
    }
  };

  const onStoreDeleteClick = () => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: (
          <>
            <b>Delete store?</b>
            <p>This action cannot be rollbacked.</p>
          </>
        ),
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteStoreMutation.mutateAsync(storeId);

            dispatchCacheAction({type: 'remove-store', storeId: storeId});
            navigate('/');

            toaster.show({intent: 'success', message: 'Store was deleted successfully'});
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred deleting the store'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  return (
    <>
      <div className="tw-item-center tw-flex tw-justify-between">
        <div className="tw-w-full">
          <section className="tw-p-6">
            <div className="tw-flex tw-items-center tw-justify-between">
              <h2 className="tw-text-zinc-700 tw-mb-4 tw-text-xl tw-font-bold">Store information</h2>
              {isDirty && (
                <div className="tw-flex tw-gap-2">
                  <Button text="Cancel" disabled={storeUpdateMutation.isLoading} onClick={() => reset()} />
                  <Button
                    text="Save changes"
                    type="submit"
                    intent="primary"
                    disabled={storeUpdateMutation.isLoading}
                    loading={storeUpdateMutation.isLoading}
                    onClick={handleSubmit(submitForm)}
                  />
                </div>
              )}
            </div>

            <div className="tw-w-[45rem]">
              <FormGroup label="Name" intent={errors.name ? 'danger' : 'none'} helperText={errors.name ? 'Name is required' : ''}>
                <InputGroup name="name" inputRef={register({required: true})} intent={errors.name ? 'danger' : 'none'} />
              </FormGroup>
              <FormGroup
                label="Description"
                intent={errors.description ? 'danger' : 'none'}
                helperText={errors.description ? 'Description is required' : ''}
              >
                <TextArea
                  fill
                  name="description"
                  inputRef={register({required: true})}
                  intent={errors.description ? 'danger' : 'none'}
                />
              </FormGroup>
              <FormGroup
                label="Timezone"
                intent={errors.timezone ? 'danger' : 'none'}
                helperText={errors.timezone ? 'Timezone is required' : ''}
              >
                <Controller
                  name="timezone"
                  control={control}
                  rules={{required: true}}
                  render={(props) => (
                    <TimezoneSelector
                      selectedItem={props.value}
                      onItemSelect={(timezone) => props.onChange(timezone)}
                      inputProps={{intent: errors.timezone ? 'danger' : 'none'}}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup label="Avatar">
                <Controller
                  name="imageUrl"
                  control={control}
                  render={(props) => <ImageUploader value={props.value} onChange={props.onChange} maxNumber={1} />}
                />
              </FormGroup>
            </div>
          </section>
          <hr />

          {/* Contact information */}
          <section className="tw-p-6">
            <h2 className="tw-text-zinc-700 tw-mb-4 tw-text-xl tw-font-bold">Contact information</h2>
            <div className="tw-w-[45rem]">
              <FormGroup label="Address" intent={errors.formattedAddress ? 'danger' : 'none'}>
                <Controller
                  name="formattedAddress"
                  control={control}
                  render={(props) => <PlaceSelector value={props.value || ''} onItemSelected={props.onChange} />}
                />
              </FormGroup>
              <FormGroup
                label="Phone number"
                intent={errors.phone ? 'danger' : 'none'}
                helperText={errors.phone ? 'Phone number is invalid' : ''}
              >
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    validate: {
                      format: (value: string) => (value ? isValidPhoneNumber(value) : true),
                    },
                  }}
                  render={(props) => (
                    <PhoneField
                      className={classNames({'bp3-intent-danger': !!errors.phone})}
                      defaultCountry="US"
                      value={props.value ?? ''}
                      onChange={props.onChange}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup label="Store email" intent={errors.email ? 'danger' : 'none'}>
                <InputGroup
                  placeholder="i.e. example@store.com"
                  name="email"
                  inputRef={register}
                  intent={errors.email ? 'danger' : 'none'}
                />
              </FormGroup>
              <div>
                <div
                  className="tw-flex tw-cursor-pointer tw-items-center tw-gap-2"
                  onClick={() => setIsSocialNetworksOpen(!isSocialNetworksOpen)}
                >
                  <p className="tw-text-blue-600">Add social networks</p>
                  <Icon icon={isSocialNetworksOpen ? 'chevron-up' : 'chevron-down'} className="tw-text-blue-600" />
                </div>
                <Collapse isOpen={isSocialNetworksOpen} keepChildrenMounted className="tw-flex tw-flex-col">
                  <div className="tw-mt-4">
                    <FormGroup label="Instagram URL" intent={errors.instagram ? 'danger' : 'none'}>
                      <InputGroup
                        placeholder="i.e. https://instagram.com/your.store"
                        name="instagram"
                        inputRef={register}
                        intent={errors.instagram ? 'danger' : 'none'}
                      />
                    </FormGroup>
                    <FormGroup label="Facebook URL" intent={errors.facebook ? 'danger' : 'none'}>
                      <InputGroup
                        placeholder="i.e. https://facebook.com/your.store"
                        name="facebook"
                        inputRef={register}
                        intent={errors.facebook ? 'danger' : 'none'}
                      />
                    </FormGroup>
                    <FormGroup label="Twitter URL" intent={errors.twitter ? 'danger' : 'none'}>
                      <InputGroup
                        placeholder="i.e. https://twitter.com/your.store"
                        name="twitter"
                        inputRef={register}
                        intent={errors.twitter ? 'danger' : 'none'}
                      />
                    </FormGroup>
                  </div>
                </Collapse>
              </div>
            </div>
          </section>
          <hr />

          {/* Opening hours */}
          <section className="tw-p-6">
            <h2 className="tw-text-zinc-700 tw-mb-4 tw-text-xl tw-font-bold">Opening hours</h2>
            {storeQuery.data?.openingHours?.length ? (
              <ul className="tw-divide-y tw-divide-solid tw-divide-gray-200">
                {storeQuery.data?.openingHours?.map((openingHours) => (
                  <TimeframeRow
                    showName={false}
                    timeframe={openingHours}
                    onDelete={() => queryClient.invalidateQueries(['stores', storeId])}
                    onEdit={(timeframe) => {
                      setTimeframeEditing(timeframe);
                      setIsTimeframeDialogOpen(true);
                    }}
                    onSwitch={() => queryClient.invalidateQueries(['stores', storeId])}
                  />
                ))}
              </ul>
            ) : (
              <p className="tw-mb-2 tw-text-gray-500">There are no opening hours set for this store</p>
            )}
            <div className="tw-mt-4">
              <Button
                intent="primary"
                onClick={() => {
                  setTimeframeEditing(undefined);
                  setIsTimeframeDialogOpen(true);
                }}
              >
                + Add opening hours
              </Button>
            </div>
          </section>
          <hr />

          <h2 className="tw-text-zinc-700 tw-my-4 tw-px-6 tw-text-xl tw-font-bold">Other settings</h2>
          <div className="tw-w-[45rem] tw-my-4 tw-flex tw-flex-col tw-gap-4 tw-px-6">
            {['superadmin'].includes(user.securityLevel) && (
              <>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-flex tw-flex-col">
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <p className="tw-font-bold">Active placeholders</p>
                      <RolesChip role="superadmin" />
                    </div>
                    <p className="tw-text-gray-500">Use a placeholder when products doesn't have any images</p>
                  </div>
                  <Controller
                    name="showProductImagePlaceholder"
                    control={control}
                    render={(props) => (
                      <Switch
                        name="showProductImagePlaceholder"
                        checked={props.value ?? true}
                        onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                      />
                    )}
                  />
                </div>
              </>
            )}
            <div className="tw-flex tw-justify-between">
              <div className="tw-flex tw-flex-col">
                <p className="tw-font-bold">Order changes</p>
                <p className="tw-text-gray-500">Ask for confirmation when changing the status of an order</p>
              </div>
              <Controller
                name="confirmOrderStatusUpdate"
                control={control}
                render={(props) => (
                  <Switch
                    name="confirmOrderStatusUpdate"
                    checked={props.value ?? true}
                    onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                  />
                )}
              />
            </div>
            <div className="tw-flex tw-justify-between">
              <div className="tw-flex tw-flex-col">
                <p className="tw-font-bold">Store status</p>
                <p className="tw-text-gray-500">Activate to enable the store</p>
              </div>
              <Controller
                name="isEnabled"
                control={control}
                render={(props) => (
                  <Switch
                    name="isEnabled"
                    checked={props.value ?? true}
                    onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                  />
                )}
              />
            </div>
          </div>
          {['superadmin'].includes(user.securityLevel) && (
            <>
              <hr />
              <div className="tw-mb-4 tw-px-6">
                <div className="tw-flex tw-items-center tw-gap-4">
                  <h2 className="tw-text-zinc-700 tw-my-4 tw-text-xl tw-font-bold">Delete store</h2>
                  <RolesChip role="superadmin" />
                </div>
                <Button
                  minimal
                  className="!tw-bg-rose-500 !tw-px-6 !tw-text-white"
                  text="Delete Store"
                  onClick={onStoreDeleteClick}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <TimeframeUpsertDialog
        storeId={storeId}
        isOpen={isTimeframeDialogOpen}
        isInputNameEnabled={false}
        timeframe={timeframeEditing}
        title={timeframeEditing ? 'Edit opening hours' : 'Add opening hours'}
        onResolve={() => queryClient.invalidateQueries(['stores', storeId])}
        type="store"
        onClose={() => {
          setIsTimeframeDialogOpen(false);
          setTimeframeEditing(undefined);
        }}
      />
    </>
  );
}

const defaultForm: StoreUpdateForm = {
  name: '',
  description: '',
  email: '',
  phone: '',
  formattedAddress: '',
  timezone: 'America/Los_Angeles',
  isEnabled: true,
  confirmOrderStatusUpdate: true,
  showProductImagePlaceholder: true,
  twitter: '',
  facebook: '',
  instagram: '',
  imageUrl: [],
};
