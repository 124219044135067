import React, {useEffect} from 'react';

import {Button, Checkbox, Classes, Dialog, FormGroup, IDialogProps, InputGroup} from '@blueprintjs/core';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import {useMutation} from 'react-query';

import {createMenuRequest, updateMenuRequest} from '../../../api/menu.api';
import {TimeframesSelector} from '../../../components/selectors/timeframes-selector.component';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';
import {TimeframeDto} from '@kontactless/admin-api/timeframe/timeframe.dto';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';

import '../../styles/menus-page.styles.scss';
import {MenuCreateRequestDto, MenuDto, MenuUpdateRequestDto} from '@kontactless/admin-api/menu/menu.dto';

export interface UpsertDialogProps extends IDialogProps {
  store: StoreDto;
  menu?: MenuDto;
  onResolve?: (menu: MenuDto) => void;
}

interface MenuForm {
  name: string;
  isPosMenu: boolean;
  timeframes: TimeframeDto[];
}

export const UpsertDialog: React.FC<UpsertDialogProps> = ({store, menu, onResolve, ...props}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {register, handleSubmit, control, errors, reset} = useForm<MenuForm>();
  const upsertMenuMutation = useMutation<MenuDto, Error, {id: number} | MenuCreateRequestDto | MenuUpdateRequestDto>((body) => {
    if (menu?.id) {
      return updateMenuRequest(user.token, menu.id, body as MenuUpdateRequestDto);
    } else {
      return createMenuRequest(user.token, body as MenuCreateRequestDto);
    }
  });

  const submitForm = async (form: MenuForm) => {
    try {
      if (menu?.id) {
        const updatedMenu = await upsertMenuMutation.mutateAsync({
          ...form,
          storeId: store.id,
        });

        props.onClose?.(undefined as any);
        onResolve?.(updatedMenu);
      } else {
        const newMenu = await upsertMenuMutation.mutateAsync({...form, storeId: store.id});

        props.onClose?.(undefined as any);
        onResolve?.(newMenu);
      }

      toaster.show({
        intent: 'success',
        message: menu?.id ? 'Menu updated successfully' : 'Menu created successfully',
      });
    } catch (error) {
      console.error(error);
      toaster.show({
        intent: 'danger',
        message: menu?.id ? 'An error ocurred updating the menu' : 'An error ocurred creating the menu',
      });
    }
  };

  useEffect(() => {
    reset(
      {
        name: menu?.name,
        isPosMenu: menu?.isPosMenu,
        timeframes: menu?.timeframes ?? [],
      },
      {dirtyFields: false}
    );
  }, [menu, reset]);

  return (
    <Dialog title={menu ? 'Edit Menu' : 'New Menu'} className="kl-dialog menu-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          <FormGroup
            label="Menu name"
            intent={errors.name ? 'danger' : 'none'}
            helperText={errors.name ? 'Name is required' : ''}
          >
            <InputGroup
              name="name"
              defaultValue={menu?.name ?? ''}
              intent={errors.name ? 'danger' : 'none'}
              inputRef={register({required: true})}
            />
          </FormGroup>
          <FormGroup label="Timeframes">
            <Controller
              name="timeframes"
              control={control}
              defaultValue={menu?.timeframes ?? []}
              render={(props) => (
                <TimeframesSelector
                  items={store.timeframes ?? []}
                  selectedTimeframesIds={props.value?.map(({id}) => id) ?? []}
                  onItemSelect={(timeframe) => {
                    const values = props.value ?? [];
                    if (values?.map(({id}) => id).includes(timeframe.id)) {
                      props.onChange((values ?? []).filter(({id}) => id !== timeframe.id));
                    } else {
                      props.onChange(values.concat(timeframe));
                    }
                  }}
                  onRemove={(timeframe) => {
                    props.onChange((props.value ?? []).filter(({id}) => id !== timeframe.id));
                  }}
                />
              )}
            />
          </FormGroup>
          {store.settings?.posId ? (
            <FormGroup helperText="When a menu is connected to a POS, you will only be able to add items that were pulled from the POS.">
              <Checkbox name="isPosMenu" label="Is this menu connected to your POS?" disabled={!!menu?.id} inputRef={register} />
            </FormGroup>
          ) : null}
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={upsertMenuMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text={menu ? 'Edit' : 'Create'}
          intent="primary"
          loading={upsertMenuMutation.isLoading}
          disabled={upsertMenuMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
};
