import React, {ReactElement, useState} from 'react';

import {Collapse, ICollapseProps, Icon} from '@blueprintjs/core';
import classNames from 'classnames';

export interface AccordionManualProps {
  title?: string;
  headerRightElement?: ReactElement;
  isOpen: boolean;
  className?: string;
  collapseProps?: ICollapseProps;
  onChange?: (isOpen: boolean) => void;
}

export const AccordionManual: React.FC<AccordionManualProps> = ({title, headerRightElement, children, ...props}) => {
  return (
    <div className={classNames('accordion', {open: props.isOpen}, props.className)}>
      <header className="accordion-header">
        <div className="header-content" onClick={() => props.onChange?.(!props.isOpen)}>
          <Icon className="icon" icon="chevron-down" />
          <h3 className="accordion-title">{title ?? null}</h3>
        </div>
        <div className="pull-right">{headerRightElement ?? null}</div>
      </header>
      <Collapse
        {...props.collapseProps}
        className={classNames('accordion-body', props.collapseProps?.className)}
        isOpen={props.isOpen}
        keepChildrenMounted
      >
        {children}
      </Collapse>
    </div>
  );
};

export interface AccordionAutomaticProps extends ICollapseProps {
  title?: string;
  defaultIsOpen?: boolean;
  headerRightElement?: ReactElement;
}

export const AccordionAutomatic: React.FC<AccordionAutomaticProps> = ({
  title,
  headerRightElement,
  defaultIsOpen,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen ?? false);

  return (
    <div className={classNames('accordion card', {open: isOpen})}>
      <header className="accordion-header">
        <div className="header-content" onClick={() => setIsOpen(!isOpen)}>
          <Icon className="icon" icon="chevron-down" />
          <h3 className="accordion-title">{title ?? null}</h3>
        </div>
        <div className="pull-right">{headerRightElement ?? null}</div>
      </header>
      <Collapse {...props} className={classNames('accordion-body', props.className)} isOpen={isOpen} keepChildrenMounted>
        {children}
      </Collapse>
    </div>
  );
};
