import {
  AdditionalChargeDto,
  CreateAdditionalChargeRequestDto,
  UpdateAdditionalChargeRequestDto,
} from '@kontactless/admin-api/additional-charge/additional-charge.dto';

import {callApi} from './api';

export const fetchAllAdditionalChargeRequest = async (token: string, storeId?: number): Promise<AdditionalChargeDto[]> => {
  return callApi<AdditionalChargeDto[]>('/additional-charges', {token, params: {storeId}}).then(({data}) => data);
};

export const createAdditionalChargeRequest = async (
  token: string,
  additionalCharge: CreateAdditionalChargeRequestDto
): Promise<AdditionalChargeDto> => {
  return callApi<AdditionalChargeDto>(`/additional-charges`, {
    token,
    method: 'POST',
    body: JSON.stringify(additionalCharge),
  }).then(({data}) => data);
};

export const updateAdditionalChargeRequest = async (
  token: string,
  additionalChargeId: number,
  payload: UpdateAdditionalChargeRequestDto
): Promise<AdditionalChargeDto> => {
  return callApi<AdditionalChargeDto>(`/additional-charges/${additionalChargeId}`, {
    token,
    method: 'PATCH',
    body: JSON.stringify(payload),
  }).then(({data}) => data);
};

export const deleteAdditionalChargeRequest = async (token: string, additionalChargeId: number): Promise<void> => {
  return callApi<void>(`/additional-charges/${additionalChargeId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
