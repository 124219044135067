import React from 'react';

import {Button, Classes, Dialog, FormGroup, IDialogProps} from '@blueprintjs/core';
import {useForm} from 'react-hook-form';
import {useMutation} from 'react-query';

import {PasswordInput} from '../../components/ui/password-input.component';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';

interface ChangePasswordDialogProps extends IDialogProps {}

interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({...props}) => {
  const {changePassword} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {register, errors, setError, handleSubmit} = useForm<ChangePasswordForm>();

  const onSubmit = async (form: ChangePasswordForm) => {
    if (form.newPassword === form.newPasswordConfirmation) {
      try {
        await changePassword(form.oldPassword, form.newPassword);
        toaster.show({intent: 'success', message: 'Password changed successfully'});
        props.onClose?.({} as any);
      } catch (error: any) {
        switch (error.message) {
          case 'wrong_old_password':
            setError('oldPassword', {types: {}, message: 'Incorrect password'});
            break;
          case 'invalid_new_password':
            setError('newPassword', {
              types: {},
              message:
                'Invalid password.\nIt must be 8 characters long, include one uppercase letter, include one lowercase letter, and one number',
            });
            break;
          case 'limit_attemps_reached':
            toaster.show({intent: 'danger', message: 'Attemps limit exceeded. Try again later'});
            break;
          default:
            toaster.show({intent: 'danger', message: 'An error ocurred changing the password'});
        }
      }
    } else {
      setError('newPasswordConfirmation', {types: {}, message: "Passwords don't match"});
    }
  };

  const changePasswordMutation = useMutation<void, Error, ChangePasswordForm>(onSubmit);

  return (
    <Dialog title="Change Password" className="kl-dialog" {...props}>
      <div className={Classes.DIALOG_BODY}>
        <div className="dialog-form">
          <FormGroup
            label="Current Password"
            intent={errors.oldPassword ? 'danger' : 'none'}
            helperText={errors.oldPassword ? errors.oldPassword.message || 'Current password is required' : ''}
          >
            <PasswordInput
              name="oldPassword"
              inputRef={register({required: true})}
              intent={errors.oldPassword ? 'danger' : 'none'}
              disabled={changePasswordMutation.isLoading}
            />
          </FormGroup>
          <FormGroup
            label="New Password"
            intent={errors.newPassword ? 'danger' : 'none'}
            helperText={
              errors.newPassword
                ? errors.newPassword.message || 'New password is required'
                : '8 characters long, One uppercase letter, One lowercase letter, One number, One special character'
            }
          >
            <PasswordInput
              name="newPassword"
              inputRef={register({required: true})}
              intent={errors.newPassword ? 'danger' : 'none'}
              disabled={changePasswordMutation.isLoading}
            />
          </FormGroup>
          <FormGroup
            label="Password Confirmation"
            intent={errors.newPasswordConfirmation ? 'danger' : 'none'}
            helperText={
              errors.newPasswordConfirmation ? errors.newPasswordConfirmation.message || 'Password confirmation is required' : ''
            }
          >
            <PasswordInput
              name="newPasswordConfirmation"
              inputRef={register({required: true})}
              intent={errors.newPasswordConfirmation ? 'danger' : 'none'}
              disabled={changePasswordMutation.isLoading}
            />
          </FormGroup>
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={changePasswordMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text="Confirm"
          intent="primary"
          loading={changePasswordMutation.isLoading}
          disabled={changePasswordMutation.isLoading}
          onClick={handleSubmit((form) => changePasswordMutation.mutate(form))}
        />
      </footer>
    </Dialog>
  );
};
