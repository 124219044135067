import React from 'react';

import {Button, MenuItem} from '@blueprintjs/core';
import {MultiSelect, MultiSelectProps} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';
import {useAuth} from '../../contexts/auth.context';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {fetchAllStoresByCompanyIdRequest} from '../../api/store.api';

interface MultiSelectorProps extends Omit<MultiSelectProps<StoreDto>, 'itemRenderer' | 'tagRenderer' | 'items'> {
  selectedValues: number[];
  companyId: number;
  onItemSelect: (value: StoreDto) => void;
  onRemove: (value: StoreDto) => void;
}

const MultiSelectorComponent = MultiSelect.ofType<StoreDto>();

export const StoreMultiSelector: React.FC<MultiSelectorProps> = ({
  selectedValues,
  companyId,
  onItemSelect,
  onRemove,
  ...props
}) => {
  let inputRef: any = {current: undefined};
  const {user} = useAuth();

  const storesQuery = useQuery({
    queryKey: ['companies', companyId, 'stores'],
    queryFn: () => fetchAllStoresByCompanyIdRequest(user.token, companyId),
    initialData: [],
  });

  const stores = storesQuery.data ?? [];

  const selectedItems = stores.filter(({id}) => selectedValues.includes(id)) ?? undefined;
  const availableItems = stores.filter(({id}) => !selectedValues.includes(id));

  return (
    <MultiSelectorComponent
      {...props}
      resetOnSelect
      items={availableItems ?? []}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{className: 'multi-selector-popover', minimal: true}}
      itemRenderer={(company, {modifiers, handleClick}) => (
        <MenuItem disabled={modifiers.disabled} key={company.id} onClick={handleClick} text={company.name} />
      )}
      selectedItems={selectedItems}
      onItemSelect={onItemSelect}
      onRemove={onRemove}
      tagRenderer={(value) => value.name}
      itemsEqual={(m1, m2) => m1 === m2}
      itemPredicate={(query, value) => value.name.toLocaleLowerCase().includes(query.toLowerCase())}
      ref={inputRef}
      tagInputProps={{
        placeholder: 'Input many...',
        ...(props.tagInputProps ?? null),
        inputRef: (input) => (inputRef = input),
        rightElement: (
          <Button
            icon="chevron-down"
            title="Select one or more stores"
            minimal
            onClick={() => inputRef.current?.inputElement?.focus()}
          />
        ),
        tagProps: {minimal: true},
      }}
    />
  );
};
