// General
export const SECURITY_LEVELS = ['superadmin', 'company-admin', 'supervisor', 'server'] as const;
export const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;
export const SUPPORTED_CURRENCIES = [{code: 'USD', symbol: '$'} as const, {code: 'CAD', symbol: 'CA$'} as const] as const;
export const SUPPORTED_COUNTRIES = [{name: 'United States', code: 'US'} as const, {name: 'Canada', code: 'CA'} as const] as const;
export const SUPPORTED_TIMEZONES = [
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/New_York',
  'America/Virgin',
  'America/Montevideo',
  'Pacific/Honolulu',
] as const;
export const FILE_LIMIT_SIZE = 12582912 as const; // 12 MB = 12 * 1024 * 1024
export const FILE_TYPES_ALLOWED = ['jpg', 'jpeg', 'png'] as const;
export const COLORS = [
  'rgba(51,102,255,1)',
  'rgba(111,186,26,1)',
  'rgba(255,82,48,1)',
  'rgba(12,186,249,1)',
  'rgba(255,193,50,1)',
  'rgb(160,232,91)',
  'rgb(42,104,102)',
  'rgb(92,214,244)',
  'rgb(67,42,183)',
  'rgb(121,172,253)',
  'rgb(45,81,146)',
  'rgb(229,144,199)',
  'rgb(150,29,107)',
  'rgb(57,238,192)',
  'rgb(126,43,25)',
  'rgb(35,152,13)',
  'rgb(209,31,11)',
  'rgb(54,244,89)',
  'rgb(224,87,225)',
  'rgb(97,129,46)',
  'rgb(226,209,203)',
  'rgb(171,121,80)',
  'rgb(255,0,135)',
  'rgb(216,218,115)',
  'rgb(125,10,246)',
] as const;
export const ADDITIONAL_CHARGE_TYPES = ['flat', 'percentage'] as const;
export const DISCOUNT_TYPES = ['coupon-flat', 'coupon-percentage', 'promotion-flat-price'] as const;
export const TIMEFRAME_TYPES = ['store', 'section', 'discount'] as const;

// Azure Storage
export const AZURE_ASSETS_STORAGE_HOST = 'https://devkallpodimg.blob.core.windows.net/images' as const;

// Orders
export const FEE_TYPES = ['customer', 'store', 'invoice'] as const;
export const PAYMENT_GATEWAYS = ['stripe', 'freedompay'] as const;
export const ORDER_ITEM_TYPES = ['product', 'tax', 'fee', 'tip', 'discount', 'refund', 'surcharge'] as const;
export const ORDER_TYPES = ['dine-in', 'takeaway'] as const;
export const ORDER_STATUSES = [
  'received',
  'preparing',
  'ready',
  'delivered',
  'cancelled-by-customer',
  'cancelled-by-store',
  'refunded',
  'partially-refunded',
] as const;
export const ORDERS_REPORT_STATUSES = ['pending', 'failed', 'ready'] as const;
export const ORDERING_FLOW_TYPES = ['delivery-to-location', 'pickup', 'single-location'] as const;

// Omnivore
export const POS_FETCH_STATUS = ['never-fetched', 'fetched', 'fetching', 'error'] as const;
export const OMNIVORE_SERVICE_CHARGE_TYPES = ['dollar', 'percent'] as const;

// Exports
export const LOCATION_EXPORT_TEMPLATES = [
  {name: 'no-template', label: 'No Template'} as const,
  {name: 'vertical', label: 'Vertical'} as const,
] as const;
export const QR_EXPORT_TEMPLATES = [
  {name: 'none', label: 'No Template (3 per page)'} as const,
  {name: 'vertical', label: 'Vertical (4 per page)'} as const,
  {name: 'upload-vertical', label: 'Upload (vertical)'} as const,
  {name: 'horizontal', label: 'Horizontal (8 per page)'} as const,
  {name: 'upload-horizontal', label: 'Upload (horizontal)'} as const,
  {name: 'table-tents-double', label: 'Table Tents x2'} as const,
  {name: 'upload-table-tents-double', label: 'Upload - Table Tents x2'} as const,
  {name: 'upload-phoenix', label: 'Upload - Phoenix'} as const,
] as const;

// Menu Category Products List
export const PRODUCTS_LIST_DEFAULT_LENGTH = 10 as const;

// Printers
export const PRINT_JOB_STATUSES = ['pending', 'processing', 'failed', 'printed'] as const;
export const PRINTER_PAPER_STATUSES = ['empty', 'near_end', 'ok'] as const;
export const PRINTER_COVER_STATUSES = ['open', 'closed'] as const;

// Users
export const USER_STATUSES = ['confirmation-required', 'confirmed'] as const;

// Dates
export const TIME_FORMAT = 'h:mm a' as const;
export const DATETIME_FORMAT = 'MM/dd/yy h:mm a' as const;

export const SELECTOR_TYPES = ['suggest', 'select'] as const;

// Images
export const IMAGE_TARGETS = ['event_cover'] as const;
