import {orderBy} from 'lodash';
import {QueryClient} from 'react-query';

import {PrintJobDto} from '@kontactless/admin-api/print-job/print-job.dto';

export type CachePrintJobAction =
  | {type: 'update-print-job'; printJob: PrintJobDto}
  | {type: 'remove-print-job'; printJob: {id: number; storeId: number}};

export const dispatchPrintJobAction = (queryClient: QueryClient, action: CachePrintJobAction) => {
  switch (action.type) {
    case 'update-print-job':
      upsertPrintJob(queryClient, action.printJob);
      break;
    case 'remove-print-job':
      removePrintJob(queryClient, action.printJob.id, action.printJob.storeId);
      break;
  }
};

const removePrintJob = (queryClient: QueryClient, printJobId: number, storeId: number) => {
  queryClient.setQueryData<PrintJobDto[] | undefined>(['print-jobs', storeId], () => {
    const cachedPrintJobs = queryClient.getQueryData<PrintJobDto[]>(['print-jobs', storeId]);

    if (cachedPrintJobs) {
      return cachedPrintJobs.filter(({id}) => id !== printJobId);
    }
  });
};

const upsertPrintJob = (queryClient: QueryClient, printJob: PrintJobDto) => {
  queryClient.setQueryData<PrintJobDto[] | undefined>(['print-jobs', printJob.storeId], () => {
    const cachedPrintJobs = queryClient.getQueryData<PrintJobDto[]>(['print-jobs', printJob.storeId]);
    let printJobs: PrintJobDto[] = [];
    if (cachedPrintJobs) {
      printJobs = orderBy(
        cachedPrintJobs.filter(({id}) => id !== printJob.id).concat([printJob]),
        ({createdAt}) => createdAt,
        'desc'
      );

      return printJobs;
    }
  });
};
