import React, {ReactElement} from 'react';

import {CircularProgress} from '@material-ui/core';
import classNames from 'classnames';
import {isNil} from 'lodash';

import {DashboardWidget, DashboardWidgetProps} from './dashboard-widget.component';

export interface DashboardWidgetTotalProps extends DashboardWidgetProps {
  title: string;
  total: string;
  comparedTo: string;
  diffPercentage?: string;
  errorMessage?: string;
}

export const DashboardWidgetTotal: React.FC<DashboardWidgetTotalProps> = ({
  title,
  total,
  diffPercentage,
  isLoading,
  errorMessage,
  comparedTo,
  ...itemProps
}) => {
  let component: ReactElement | null = null;

  if (isLoading) {
    component = <CircularProgress className="widget-spinner" />;
  } else if (!isNil(errorMessage)) {
    component = <p className="widget-error">{errorMessage}</p>;
  } else if (!component) {
    component = (
      <h2 className="widget-total-value">
        {total}
        {!isNil(diffPercentage) ? (
          <small
            className={classNames('widget-diff-percentage', {negative: +diffPercentage.replace(/[^\d\.\-]/g, '') < 0})}
            title={`Compared to ${comparedTo}`}
          >
            {diffPercentage}
          </small>
        ) : null}
      </h2>
    );
  }

  return (
    <DashboardWidget {...itemProps} className={classNames('widget-total', itemProps.className)}>
      <h4 className="widget-title">{title}</h4>
      {component}
    </DashboardWidget>
  );
};
