import {Badge, BadgeProps} from '../../common/components/Badge';

interface PercentageChipProps extends BadgeProps {
  diffPercentage: string;
}

export function PercentageChip({diffPercentage, ...props}: PercentageChipProps) {
  const diffPercentageSign = diffPercentage.split('')[0];

  const difference = diffPercentageSign === '+' ? 'positive' : diffPercentageSign === '-' ? 'negative' : 'neutral';

  return (
    <Badge
      variant={difference === 'positive' ? 'success' : difference === 'negative' ? 'danger' : 'default'}
      text={diffPercentage}
      className="tw-font-bold"
      {...props}
    />
  );
}
