import React, {useState} from 'react';
import {Callout, Spinner} from '@blueprintjs/core';
import {PrintJobList} from '../../../components/printers/print-job-list.component';
import {PrinterList} from '../../../components/printers/printer-list.component';
import {PrinterUpsertDialog} from '../../../components/printers/printer-upsert-dialog.component';
import {ServerDirectPrintCredentialsForm} from '../../../components/printers/server-direct-print-credentials-form.component';
import {PrinterDto} from '@kontactless/admin-api/printer/printer.dto';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {fetchStoreRequest} from '../../../api/store.api';
import {useAuth} from '../../../contexts/auth.context';

const PrinterCreateDialog = PrinterUpsertDialog;

export function PrinterSettingsPage() {
  const [isPrinterCreateDialogOpen, setIsPrinterCreateDialogOpen] = useState(false);
  const [printerEditing, setPrinterEditing] = useState<PrinterDto | undefined>();
  const {user} = useAuth();
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);
  const storeQuery = useQuery({queryKey: ['stores', storeId], queryFn: () => fetchStoreRequest(user.token, storeId)});

  const onPrinterCreateDialogClose = () => {
    setIsPrinterCreateDialogOpen(false);
    setPrinterEditing(undefined);
  };

  if (!storeQuery.data) {
    return <Spinner />;
  }

  return (
    <div className="tw-item-center tw-flex tw-w-[50rem] tw-flex-col tw-justify-between tw-rounded-xl tw-px-6 tw-my-4">
      <h3 className="tw-mb-4 tw-text-xl tw-font-bold">Printers settings</h3>
      {/* SDP Credentials */}
      <div className="settings-form">
        <header className="card-header">
          <h3 className="card-title">Server Direct Print Credentials</h3>
        </header>
        <Callout icon="info-sign" className="bp3-running-text">
          <p>Set up the following server URLs on your printer using the EPSON configuration app:</p>
          <p>
            <b>Print Request:</b>{' '}
            <span className="bp3-code"> {process.env.REACT_APP_API_BASE_URL}/api/v3/server-direct-print/print-request</span>
          </p>
          <p>
            <b>Status Notification:</b>{' '}
            <span className="bp3-code"> {process.env.REACT_APP_API_BASE_URL}/api/v3/server-direct-print/status-notification</span>
          </p>
        </Callout>
        <div className="card-body form-body">
          <ServerDirectPrintCredentialsForm store={storeQuery.data} />
        </div>
      </div>

      {/* Printers */}
      <div className="settings-form">
        <header className="card-header">
          <h3 className="card-title">Printers</h3>
          {/* TODO: Remove the code below once we are sure the automatic creation of printers works fine */}
          {/* <Button
            className="card-btn"
            text="Add printer"
            intent="primary"
            icon="add"
            minimal
            onClick={() => setIsPrinterCreateDialogOpen(true)}
          /> */}
        </header>
        <div className="card-body">
          <PrinterList storeId={storeId} />
        </div>
      </div>

      {/* Print jobs */}
      <div className="settings-form">
        <header className="card-header">
          <h3 className="card-title">Print jobs queue</h3>
        </header>
        <div className="card-body">
          <PrintJobList storeId={storeId} />
        </div>
      </div>

      <PrinterCreateDialog
        storeId={storeId}
        isOpen={isPrinterCreateDialogOpen}
        printer={printerEditing}
        onClose={onPrinterCreateDialogClose}
      />
    </div>
  );
}
