import {useState} from 'react';
import {Icon, Menu, MenuItem} from '@blueprintjs/core';
import kontactlessNegativeSvg from '../../assets/negative-isotype.svg';
import {useAuth} from '../../contexts/auth.context';
import {Popover2} from '@blueprintjs/popover2';
import {EmailNotificationsDialog} from './email-notifications-dialog.component';
import {ChangePasswordDialog} from './change-password-dialog.component';
import {Link, Outlet, useNavigate, useParams} from 'react-router-dom';

import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {Select} from '@blueprintjs/select';
import {ChevronRightIcon, ChevronUpDownIcon, QuestionMarkCircleIcon, UserCircleIcon} from '@heroicons/react/20/solid';
import {CompanyDto} from '@kontactless/admin-api/company/company.dto';
import Restricted from '../../common/components/Restricted';
import {Plug2Icon} from 'lucide-react';

const CompanySelect = Select.ofType<CompanyDto>();
const StoreSelect = Select.ofType<StoreDto>();

export function RootLayout() {
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [isNotificationsSettingsModalOpen, setIsNotificationsSettingsModalOpen] = useState(false);
  const {user, signOut} = useAuth();

  const navigate = useNavigate();

  const params = useParams<{companyId?: string; storeId?: string}>();
  const companyId = Number(params.companyId);
  const storeId = Number(params.storeId);

  let currentCompany: CompanyDto | undefined;
  if (!isNaN(companyId)) {
    currentCompany = user.companies.find(({id}) => id === +companyId);
  }

  const availableStores = user.stores.filter((store) => store.companyId === companyId);

  let currentStore: StoreDto | undefined;
  if (!isNaN(storeId)) {
    currentStore = availableStores.find((store) => store.id === storeId);
  }

  return (
    <>
      <nav className="tw-bg-blue-500 tw-sticky tw-top-0 tw-z-10">
        <div className="tw-mx-auto tw-max-w-7xl tw-px-4 sm:tw-px-6 lg:tw-px-8 xl:tw-px-0">
          <div className="tw-flex tw-h-16 tw-items-center tw-justify-between">
            {/* Left nav items */}
            <div className="tw-flex tw-items-center tw-gap-4">
              <div className="tw-shrink-0">
                <img className="tw-h-8" src={kontactlessNegativeSvg} alt="Kontactless logo" />
              </div>
              <ChevronRightIcon className="tw-h-6 tw-w-6 tw-text-white" />

              {/* Company selector */}
              {user.companies.length === 1 ? (
                <Link className="tw-text-white" to={`/companies/${companyId || 0}`}>
                  <div className="tw-flex tw-items-center tw-text-white tw-gap-2 tw-cursor-pointer">
                    {user?.companies[0].imageUrl && (
                      <div className="tw-rounded-full tw-bg-white tw-w-6 tw-h-6 tw-overflow-hidden">
                        <img src={user?.companies[0].imageUrl} className="tw-object-cover" alt="" />
                      </div>
                    )}
                    {user?.companies[0].name}
                  </div>
                </Link>
              ) : (
                <CompanySelect
                  items={user.companies}
                  itemPredicate={(query, event) => event.name.toLocaleLowerCase().includes(query.toLowerCase())}
                  itemRenderer={({id, name}, {modifiers, handleClick}) => (
                    <MenuItem
                      active={companyId === id}
                      disabled={modifiers.disabled}
                      key={id}
                      intent="none"
                      text={name}
                      href={`/companies/${id}`}
                    />
                  )}
                  popoverProps={{minimal: true}}
                  noResults={<MenuItem disabled={true} text="No results." />}
                  onItemSelect={console.log}
                >
                  {/* children become the popover target; render value here */}
                  <div className="tw-px-3 tw-py-2 tw-bg-blue-400 tw-rounded-lg tw-flex tw-items-center tw-text-white tw-gap-2 tw-cursor-pointer">
                    {currentCompany?.imageUrl && (
                      <div className="tw-h-6 tw-w-6 tw-rounded-full tw-overflow-hidden">
                        <img src={currentCompany.imageUrl} className="tw-object-cover" alt="" />
                      </div>
                    )}
                    {currentCompany?.name}

                    <ChevronUpDownIcon className="tw-h-6 tw-w-6 tw-text-white" />
                  </div>
                </CompanySelect>
              )}

              <ChevronRightIcon className="tw-h-6 tw-w-6 tw-text-white" />

              {/* Store selector */}
              {user.stores.length === 1 && !['superadmin', 'company-admin'].includes(user.securityLevel) ? (
                <Link className="tw-text-white" to={`/companies/${storeId}`}>
                  <div className="tw-flex tw-items-center tw-text-white tw-gap-2 tw-cursor-pointer">
                    {user?.stores[0].imageUrl && (
                      <div className="tw-rounded-full tw-bg-white tw-w-6 tw-h-6 tw-overflow-hidden">
                        <img src={user?.stores[0].imageUrl} className="tw-object-cover" alt="" />
                      </div>
                    )}
                    {user?.stores[0].name}
                  </div>
                </Link>
              ) : (
                <StoreSelect
                  items={availableStores ?? []}
                  itemPredicate={(query, event) => event.name.toLocaleLowerCase().includes(query.toLowerCase())}
                  itemRenderer={({id, name}, {modifiers, handleClick}) => (
                    <MenuItem
                      active={storeId === id}
                      disabled={modifiers.disabled}
                      key={id}
                      intent="none"
                      text={name}
                      href={`/companies/${companyId}/stores/${id}`}
                    />
                  )}
                  popoverProps={{minimal: true}}
                  noResults={<MenuItem disabled={true} text="No results." />}
                  onItemSelect={console.log}
                >
                  {/* children become the popover target; render value here */}
                  <button className="tw-px-3 tw-py-2 tw-h-10 tw-bg-blue-400 tw-rounded-lg tw-flex tw-items-center tw-text-white tw-gap-2 tw-cursor-pointer">
                    {!!currentStore ? (
                      <>
                        {currentStore?.imageUrl && (
                          <div className="tw-rounded-full tw-bg-white tw-w-6 tw-h-6 tw-overflow-hidden">
                            <img src={currentStore.imageUrl} className="tw-object-cover" alt="" />
                          </div>
                        )}
                        {currentStore?.name}
                      </>
                    ) : (
                      <span>Select store</span>
                    )}
                    <ChevronUpDownIcon className="tw-h-6 tw-w-6 tw-text-white" />
                  </button>
                </StoreSelect>
              )}
            </div>

            {/* Right nav items */}
            <div className="tw-flex tw-items-center tw-gap-6 tw-divide-x tw-divide-solid tw-divide-gray-300">
              {currentCompany?.hasApiKeysEnabled && (
                <Restricted to={['company-admin', 'superadmin']}>
                  <button className="tw-text-white tw-flex tw-items-center tw-gap-2" onClick={() => navigate(`api`)}>
                    <Plug2Icon className="tw-h-6 tw-w-6" /> API
                  </button>
                </Restricted>
              )}

              <Popover2
                position="top"
                content={
                  <Menu className="tw-grid tw-divide-y tw-divide-solid  tw-divide-gray-300">
                    <div>
                      <MenuItem text="Help center" href="https://intercom.help/kontactless-help-center/en" target="_blank" />
                    </div>
                    <div>
                      <MenuItem text="Terms of use" href="https://www.kontactless.io/terms-of-use" target="_blank"></MenuItem>
                      <MenuItem text="Privacy policy" href="https://www.kontactless.io/privacy-policy" target="_blank" />
                    </div>
                    <div>
                      <p className="tw-p-1.5 tw-text-xs"> v2.0.0 • Copyright © Kontactless 2023</p>
                    </div>
                  </Menu>
                }
              >
                <div className="tw-flex tw-items-center tw-gap-2">
                  <button className="tw-text-white tw-h-6 hover:tw-text-slate-100 active:tw-text-slate-200 tw-flex tw-items-center tw-pl-6 tw-gap-2">
                    <QuestionMarkCircleIcon className="tw-h-6 tw-w-6" /> Support
                  </button>
                </div>
              </Popover2>

              <Popover2
                position="top"
                content={
                  <Menu>
                    {['company-admin', 'supervisor'].includes(user.securityLevel) && (
                      <MenuItem text="Email Notifications" onClick={() => setIsNotificationsSettingsModalOpen(true)} />
                    )}
                    <MenuItem text="Change Password" onClick={() => setIsChangePasswordModalOpen(true)} />
                    <MenuItem text="Logout" onClick={() => signOut()} />
                  </Menu>
                }
              >
                <div className="tw-relative tw-cursor-pointer tw-border-solid tw-pl-6">
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <UserCircleIcon className="tw-h-6 tw-w-6 tw-text-white" />
                    <p className="tw-text-white tw-whitespace-nowrap">{user.name}</p>
                    <Icon icon="chevron-down" className="tw-text-white" />
                  </div>
                </div>
              </Popover2>
            </div>
          </div>
        </div>
      </nav>

      <Outlet />
      <ChangePasswordDialog isOpen={isChangePasswordModalOpen} onClose={() => setIsChangePasswordModalOpen(false)} />
      <EmailNotificationsDialog
        isOpen={isNotificationsSettingsModalOpen}
        onClose={() => setIsNotificationsSettingsModalOpen(false)}
      />
    </>
  );
}
