import React from 'react';
import {Outlet} from 'react-router-dom';

import '../styles/store-details.styles.scss';

import classNames from 'classnames';

import {NavLink} from 'react-router-dom';
import {Container} from '../../common/components/layout/Container';
import {Header} from '../../common/components/layout/Header';
import {OrdersFooter} from '../../common/components/layout/OrdersFooter';

function getActiveLinkClasses({isActive}: {isActive: boolean}) {
  return classNames('tw-border-solid !tw-no-underline tw-whitespace-nowrap tw-border-b-2 tw-py-5 tw-text-sm tw-font-semibold', {
    'tw-text-blue-500 tw-border-blue-500 hover:tw-text-blue-500': isActive,
    'tw-border-transparent hover:tw-border-gray-300 tw-text-gray-500 hover:tw-text-gray-700': !isActive,
  });
}

export function StorePagesLayout() {
  return (
    <>
      <Header>
        <nav className="tw-flex tw-w-full tw-mt-px">
          <ul className="tw-flex tw-gap-8 tw-items-center">
            <li>
              <NavLink className={getActiveLinkClasses} to="" end>
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink className={getActiveLinkClasses} to="ordering-methods">
                Ordering
              </NavLink>
            </li>
            <li>
              <NavLink className={getActiveLinkClasses} to="menus">
                Menus
              </NavLink>
            </li>
            <li>
              <NavLink className={getActiveLinkClasses} to="promotions">
                Promotions
              </NavLink>
            </li>
          </ul>
          <ul className="tw-flex tw-ml-auto tw-gap-8 tw-items-center">
            <li>
              <NavLink className={getActiveLinkClasses} to="settings">
                Settings
              </NavLink>
            </li>
          </ul>
        </nav>
      </Header>
      <main>
        <Container>
          <Outlet />
        </Container>
      </main>
      <OrdersFooter />
    </>
  );
}
