import {callApi} from './api';
import {SectionDto, SectionUpsertRequestDto} from '@kontactless/admin-api/section/section.dto';

export const fetchAllSectionsRequest = async (token: string, storeId?: number): Promise<SectionDto[]> => {
  return callApi<SectionDto[]>(`/sections`, {token, params: {storeId}}).then(({data}) => data);
};

export const fetchSectionRequest = async (token: string, sectionId: number): Promise<SectionDto> => {
  return callApi<SectionDto>(`/sections/${sectionId}`, {token}).then(({data}) => data);
};

export const createSectionRequest = async (token: string, section: SectionUpsertRequestDto): Promise<SectionDto> => {
  return callApi<SectionDto>(`/sections`, {token, method: 'POST', body: JSON.stringify(section)}).then(({data}) => data);
};

export const updateSectionRequest = async (
  token: string,
  section: SectionUpsertRequestDto,
  sectionId: number
): Promise<SectionDto> => {
  return callApi<SectionDto>(`/sections/${sectionId}`, {token, method: 'PATCH', body: JSON.stringify(section)}).then(
    ({data}) => data
  );
};

export const deleteSectionRequest = async (token: string, sectionId: number): Promise<{}> => {
  return callApi<{}>(`/sections/${sectionId}`, {token, method: 'DELETE'}).then(({data}) => data);
};

export const createSectionCopyRequest = async (token: string, sectionId: number): Promise<SectionDto> => {
  return callApi<SectionDto>(`/sections/copy/${sectionId}`, {token, method: 'POST'}).then(({data}) => data);
};

export async function getSectionsQRCode(token: string, sectionId: string) {
  return callApi<any>(`/sections/${sectionId}/qr-codes`, {token}).then(({data}) => data);
}
