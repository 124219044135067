import React from 'react';
import {Button, Callout, Spinner} from '@blueprintjs/core';
import {DateTime} from 'luxon';
import {useQuery} from 'react-query';
import {fetchOrderRequest} from '../../api/order.api';
import {useAuth} from '../../contexts/auth.context';

import {getCustomerName, getPriceText} from '../../utils/helpers.utils';

import {useStoreQuery} from '../../api/store.api';
import {useParams} from 'react-router-dom';

export function OrderReceiptPage() {
  const {user} = useAuth();

  const params = useParams<{companyId: string; storeId: string; orderId: string}>();

  const storeQuery = useStoreQuery(Number(params.storeId));
  const timezone = storeQuery.data?.timezone || 'America/Los_Angeles';

  const orderQuery = useQuery({
    queryKey: ['order', params.orderId],
    queryFn: () => fetchOrderRequest(user.token, +params.orderId!),
  });

  const order = orderQuery.data;

  return (
    <>
      {orderQuery.isLoading && (
        <section className="tw-h-screen tw-flex tw-items-center tw-justify-center">
          <Spinner intent="primary" />
        </section>
      )}
      {orderQuery.isError && (
        <Callout className="scene-error" intent="danger">
          An error ocurred fetching the orders
        </Callout>
      )}
      {order && (
        <div className="tw-grid tw-place-content-center">
          <section className="tw-my-8 tw-w-[640px] tw-rounded-lg tw-border tw-bg-white tw-border-gray-200 print:tw-shadow-none tw-shadow-lg tw-border-solid tw-p-8 tw-space-y-4">
            {/* Header */}
            <div>
              {/* <img
                className="tw-border-solid tw-border tw-border-gray-300 tw-align-middle tw-w-12 tw-h-12 tw-object-cover tw-rounded-full"
                src={getStoreImage(order.store!)}
                alt={order.store?.name}
              /> */}
              <p className="tw-text-2xl tw-font-bold">Order #{order.number}</p>
              <p>{DateTime.fromISO(order.createdAt).setZone(timezone).toFormat('MMMM d, yyyy - HH:mm a')}</p>
            </div>

            <hr className="tw-border-b tw-border-solid tw-border-gray-200" />

            {/* Store and customer info */}
            <div className="tw-flex tw-justify-between tw-text-sm">
              <div>
                <p className="tw-text-lg tw-font-semibold">{storeQuery.data?.name}</p>
                <p>{storeQuery.data?.address?.formattedAddress}</p>
                <p>{storeQuery.data?.address?.phone}</p>
              </div>
              <div className="tw-text-right">
                <p className="tw-text-lg tw-font-semibold">{getCustomerName(order.customer) ?? order.payment?.cardName}</p>
                <p>{order.customer?.phone}</p>
              </div>
            </div>

            <hr className="tw-border-b tw-border-solid tw-border-gray-200" />

            {/* Order summary */}
            <div>
              <h4 className="tw-text-lg tw-font-semibold tw-mb-4">Summary</h4>
              <ul className="tw-space-y-3">
                {order.items?.map((item) => (
                  <li
                    key={item.id}
                    className="tw-flex tw-justify-between tw-border-solid tw-border-b tw-border-gray-200 tw-pb-3 last:tw-border-none items-center"
                  >
                    <div>
                      {item.quantity}x {item.title}
                    </div>
                    <div>{getPriceText(item.subtotal)}</div>
                  </li>
                ))}
                <li className="tw-flex tw-justify-between tw-text-lg tw-font-semibold items-center">
                  <div>Total</div>
                  <div>{getPriceText(order.total)}</div>
                </li>
              </ul>
            </div>
          </section>
          <div className="tw-flex tw-justify-center tw-items-center print:tw-hidden">
            <Button intent="primary" icon="print" text="Print" onClick={() => window.print()} />
          </div>
        </div>
      )}
    </>
  );
}
