import {Button, InputGroup} from '@blueprintjs/core';

import {Link} from 'react-router-dom';
import {Badge} from '../../common/components/Badge';

import {getDiffPercentageString, getPriceText} from '../../utils/helpers.utils';

import {PercentageChip} from './PercentageChip';

import {PaginationState, Table, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable} from '@tanstack/react-table';
import {motion} from 'framer-motion';
import {useMemo} from 'react';

interface StoresReportsTable {
  storeInfo: {
    id: number;
    name: string;
    imageUrl: string;
    isOpen: boolean;
  };
  sales: {
    total: number;
    salesDiffPercentage?: number | 'inf';
  };
  orders: {
    total: number;
    ordersDiffPercentage?: number | 'inf';
  };
}

interface OperationsSectionProps {
  storeNameFilter: string;
  setStoreNameFitler(value: string): void;
  isLoading: boolean;
  storesReport: StoresReportsTable[];
  showPercentage: boolean;
}

export function OperationsSection({
  storeNameFilter,
  setStoreNameFitler,
  isLoading,
  storesReport,
  showPercentage,
}: OperationsSectionProps) {
  const table = useReactTable<StoresReportsTable>({
    data: storesReport ?? [],
    columns: [
      {
        header: 'Store',
        accessorKey: 'storeInfo',
        cell: (info) => (
          <div className="tw-flex tw-items-center tw-gap-2 tw-py-1">
            {info.getValue().imageUrl && (
              <img
                className="tw-h-6 tw-shrink-0 tw-w-6 tw-shadow tw-object-cover tw-rounded-full"
                src={info.getValue().imageUrl}
                alt={info.getValue()}
              />
            )}
            <div className="tw-text-gray-900 tw-font-semibold">{info.getValue().name}</div>
            <Badge variant={info.getValue().isOpen ? 'success' : 'danger'} text={info.getValue().isOpen ? 'OPEN' : 'CLOSED'} />
          </div>
        ),
      },
      {
        header: 'Sales',
        accessorKey: 'sales',
        cell: (info) => (
          <div className="tw-flex tw-gap-2 tw-items-center">
            <span>{getPriceText(info.getValue().total ?? 0)}</span>
            {showPercentage && (
              <PercentageChip diffPercentage={getDiffPercentageString(info.getValue().salesDiffPercentage ?? 0)} />
            )}
          </div>
        ),
      },
      {
        header: 'Orders',
        accessorKey: 'orders',
        cell: (info) => (
          <div className="tw-flex tw-gap-2 tw-items-center">
            {info.getValue().total ?? 0}
            {showPercentage && (
              <PercentageChip diffPercentage={getDiffPercentageString(info.getValue().ordersDiffPercentage ?? 0)} />
            )}
          </div>
        ),
      },
      {
        header: '',
        id: 'manage',
        cell: (info) => (
          <Link
            className="tw-text-blue-600  hover:tw-text-blue-600 tw-text-center"
            to={`stores/${info.row.original.storeInfo.id}`}
          >
            Manage
          </Link>
        ),
      },
    ],
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pagination: {
        pageSize: 6,
      },
    },
  }) as any;

  const pagination = useMemo(() => table.getState().pagination, [storesReport, table.getState().pagination.pageIndex]);

  return (
    <>
      <div className="tw-border-b tw-border-solid tw-border-gray-200 tw-p-4">
        <div className="tw-flex tw-items-center tw-justify-between">
          <div>
            <h3 className="tw-text-base tw-font-bold tw-leading-6 tw-text-gray-900">Stores</h3>
            <Link className="tw-text-blue-600 hover:tw-text-blue-600 tw-text-center" to="stores">
              View all stores
            </Link>
          </div>
          <InputGroup
            leftIcon="search"
            onChange={(event) => setStoreNameFitler(event?.target.value)}
            placeholder="Search operation..."
            value={storeNameFilter}
          />
        </div>
      </div>

      <div className="tw-px-4 sm:tw-px-6 lg:tw-px-8">
        <div className="tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
          <div className="tw-inline-block tw-min-w-full tw-align-middle">
            <table className="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-text-xs">
              <thead className="tw-border-solid tw-border-b tw-border-gray-200">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="tw-px-3 tw-py-2 tw-text-left tw-font-semibold tw-text-gray-900"
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <motion.tr
                    key={row.id}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    className="tw-border-solid tw-border-b tw-border-gray-200"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td className="tw-py-4 tw-px-3" key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </motion.tr>
                ))}
              </tbody>
            </table>
            <div className="table__pagination">
              <div>
                <Button
                  minimal
                  icon="double-chevron-left"
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                />
                <Button minimal icon="chevron-left" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
                {Array.from({length: table.getPageCount()}, (_, index) => index).map((pageIndex) => {
                  return <PageButton key={pageIndex} pagination={pagination} pageIndex={pageIndex} table={table} />;
                })}
                <Button minimal icon="chevron-right" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
                <Button
                  minimal
                  icon="double-chevron-right"
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                />
              </div>
              {!!storesReport?.length && (
                <div>
                  <span>
                    Page {pagination.pageIndex + 1} of {table.getPageCount()}
                  </span>
                  <span> – </span>
                  <PaginationRecordsMessage table={table} pagination={pagination} data={storesReport} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface PaginationRecordsMessageProps {
  table: Table<StoresReportsTable>;
  pagination: PaginationState;
  data: StoresReportsTable[];
}

const PaginationRecordsMessage = ({table, pagination, data}: PaginationRecordsMessageProps) => {
  const pageSize = table.getPageCount();
  const start = pagination.pageIndex * pageSize + 1;

  let end = (pagination.pageIndex + 1) * pageSize;
  if (end > data.length) {
    end = data.length;
  }
  return <span className="tw-mr-4">{`Showing ${start} to ${end} out of ${data.length} records`}</span>;
};

interface PageButtonProps {
  pagination: PaginationState;
  pageIndex: number;
  table: Table<StoresReportsTable>;
}

const PageButton = ({pagination, pageIndex, table}: PageButtonProps) => {
  const pageCount = table.getPageCount();
  let lookAround = 2;
  if (pagination.pageIndex === 0 || pageCount - 1 - pagination.pageIndex === 0) {
    lookAround += 2;
  } else if (pagination.pageIndex === 1 || pageCount - 1 - pagination.pageIndex === 1) {
    lookAround += 1;
  }
  if (pagination.pageIndex - lookAround > pageIndex || pageIndex - pagination.pageIndex > lookAround) {
    return null;
  }

  return (
    <Button
      text={pageIndex + 1}
      minimal={!(pagination.pageIndex === pageIndex)}
      onClick={() => table.setPageIndex(pageIndex)}
      intent={pagination.pageIndex === pageIndex ? 'primary' : 'none'}
    />
  );
};
