import React, {useEffect} from 'react';

import {Button, Checkbox, Classes, Dialog, DialogProps, FormGroup, InputGroup, Spinner} from '@blueprintjs/core';

import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {ApiError} from '../../api/api';
import {createLocationRequest, fetchLocationRequest, updateLocationRequest} from '../../api/location.api';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {OmnivoreTableSelector} from '../selectors/omnivore-table-selector.component';

import {LocationCreateRequestDto, LocationDto, LocationUpdateRequestDto} from '@kontactless/admin-api/location/location.dto';

import {OrderingFlowType} from '@kontactless/utils/types';
import {LocationGroupsDto} from '@kontactless/admin-api/location-groups/location-groups.dto';
import {fetchLocationTypesRequest} from '../../api/location-type.api';

export interface LocationUpsertDialogProps extends DialogProps {
  sectionId: number;
  storeId: number;
  locationId?: number;
  orderingFlowType: OrderingFlowType;
  isPosConnected: boolean;
  omnivoreRevenueCenterId: string | null;
  onClose: () => void;
  locationGroup: LocationGroupsDto;
}

interface LocationTypeAttribueField {
  locationTypeAttributeId: number;
  value: string;
}

interface LocationForm {
  attributes: LocationTypeAttribueField[];
  omnivoreMenuItemId?: string;
  omnivoreTableId?: string;
  isPosConnected: boolean;
}

export function LocationUpsertDialog({
  storeId,
  sectionId,
  locationId,
  locationGroup,
  orderingFlowType,
  isPosConnected,
  omnivoreRevenueCenterId,
  onClose,
  ...props
}: LocationUpsertDialogProps) {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const queryClient = useQueryClient();

  const {register, handleSubmit, control, errors, watch, reset, setError} = useForm<LocationForm>({
    defaultValues: {isPosConnected: false},
  });

  const locationTypeAttributeFields = useFieldArray<LocationTypeAttribueField>({control, name: 'attributes'});

  const locationTypesQuery = useQuery({
    queryKey: ['stores', storeId, 'location-types'],
    queryFn: () => fetchLocationTypesRequest(user.token, storeId),
    enabled: props.isOpen && !!storeId,
  });

  const locationQuery = useQuery({
    queryKey: ['location', locationId],
    queryFn: () => fetchLocationRequest(user.token, locationId!),
    enabled: props.isOpen && !!locationId,
  });

  const currentLocationType = locationTypesQuery.data?.find(({id}) => id === locationGroup.locationTypeId);

  const upsertLocationMutation = useMutation<LocationDto, Error, LocationCreateRequestDto | LocationUpdateRequestDto>({
    mutationKey: locationId ? ['update-location', locationId] : 'create-location',
    mutationFn: (body) => {
      if (locationId) {
        return updateLocationRequest(user.token, body as LocationUpdateRequestDto, locationId);
      } else {
        return createLocationRequest(user.token, body as LocationCreateRequestDto);
      }
    },
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(['section', sectionId]);
      queryClient.invalidateQueries(['location', locationId]);
      toaster.show({intent: 'success', message: 'Location was created successfully'});
    },
    onError: (error: any) => {
      if (['omnivore_menu_item_id_invalid', 'store_pos_id_missing'].includes((error as ApiError)?.error?.message)) {
        setError('omnivoreMenuItemId', {message: 'Menu Item was not found in the POS'});
      } else {
        toaster.show({intent: 'danger', message: 'An error ocurred creating the location'});
      }
    },
  });

  const submitForm = async (form: LocationForm) => {
    let upsertLocationBody: LocationCreateRequestDto | LocationUpdateRequestDto = {
      locationTypeId: locationGroup.locationTypeId,
      omnivoreMenuItemId: form.isPosConnected ? null : form.omnivoreMenuItemId,
      omnivoreTableId: form.isPosConnected ? form.omnivoreTableId : null,
      locationGroupId: locationGroup.id,
      attributes: form.attributes,
    };

    if (!locationId) {
      upsertLocationBody = {...upsertLocationBody, sectionId: sectionId, locationGroupId: locationGroup.id};
    }

    upsertLocationMutation.mutate(upsertLocationBody);
  };

  useEffect(() => {
    reset({
      isPosConnected: !!locationQuery.data?.omnivoreTableId,
      attributes: currentLocationType?.attributes?.map((attribute) => ({
        locationTypeAttributeId: attribute.id,
        value: '', // TODO: Try to set the value here. For now, it's being set as the default value in the input
      })),
      omnivoreMenuItemId: undefined,
      omnivoreTableId: undefined,
    });
  }, [locationQuery.data, currentLocationType, reset]);

  return (
    <Dialog
      title={locationId ? `Edit location` : `Add location to "${locationGroup.name}"`}
      className="kl-dialog location-dialog"
      onClose={() => onClose?.()}
      {...props}
    >
      {locationQuery.isFetching || locationQuery.isLoading ? (
        <div className="tw-mt-4">
          <Spinner />
        </div>
      ) : (
        <>
          <div className={Classes.DIALOG_BODY}>
            <div className="dialog-form">
              {locationTypeAttributeFields.fields.map((field, index) => (
                <FormGroup
                  className="form-control"
                  key={field.locationTypeAttributeId}
                  label={`${currentLocationType?.name} ${currentLocationType?.attributes
                    ?.find(({id}) => id === field.locationTypeAttributeId)
                    ?.name.toLowerCase()}`}
                  intent={errors.attributes?.[0]?.value ? 'danger' : 'none'}
                  helperText={
                    errors.attributes?.[0]?.value
                      ? `${currentLocationType?.name} ${currentLocationType?.attributes
                          ?.find(({id}) => id === field.locationTypeAttributeId)
                          ?.name.toLowerCase()} is required`
                      : ''
                  }
                >
                  <input
                    type="hidden"
                    name={`attributes[${index}].locationTypeAttributeId`}
                    defaultValue={field.locationTypeAttributeId}
                    ref={register({valueAsNumber: true})}
                  />
                  <InputGroup
                    name={`attributes[${index}].value`}
                    defaultValue={
                      locationQuery.data?.attributes?.find(
                        ({locationTypeAttributeId}) => locationTypeAttributeId === field.locationTypeAttributeId
                      )?.value ?? ''
                    }
                    inputRef={register({
                      required: currentLocationType?.attributes?.find(({id}) => id === field.locationTypeAttributeId)?.required,
                    })}
                  />
                </FormGroup>
              ))}

              {isPosConnected ? (
                <>
                  <Checkbox
                    className="form-control"
                    name="isPosConnected"
                    label="Is this location connected to the POS?"
                    inputRef={register}
                  />
                  {watch('isPosConnected') ? (
                    <FormGroup
                      className="form-control"
                      label="Link this location to a POS table"
                      intent={errors.omnivoreTableId ? 'danger' : 'none'}
                      helperText={errors.omnivoreTableId ? 'POS Table is required' : ''}
                    >
                      <Controller
                        name="omnivoreTableId"
                        control={control}
                        defaultValue={locationQuery.data?.omnivoreTableId}
                        rules={{required: true}}
                        render={(props) => (
                          <OmnivoreTableSelector
                            storeId={storeId}
                            revenueCenterId={omnivoreRevenueCenterId}
                            selectedItemId={props.value}
                            onItemSelected={(table) => props.onChange(table?.tableId)}
                            inputProps={{intent: errors.omnivoreTableId ? 'danger' : 'none'}}
                            fill
                          />
                        )}
                      />
                    </FormGroup>
                  ) : (
                    <FormGroup
                      className="form-control"
                      label="Link this location to a POS menu item"
                      intent={errors.omnivoreMenuItemId ? 'danger' : 'none'}
                      helperText={errors.omnivoreMenuItemId?.message || ''}
                    >
                      <InputGroup
                        name="omnivoreMenuItemId"
                        defaultValue={locationQuery.data?.omnivoreMenuItemId ?? ''}
                        placeholder="Menu Item POS Id"
                        intent={errors.omnivoreMenuItemId ? 'danger' : 'none'}
                        inputRef={register}
                      />
                    </FormGroup>
                  )}
                </>
              ) : null}
            </div>
          </div>
          <footer className={Classes.DIALOG_FOOTER}>
            <Button
              text="Cancel"
              intent="danger"
              outlined
              disabled={upsertLocationMutation.isLoading}
              onClick={() => onClose?.()}
            />
            <Button
              text={locationQuery.data ? 'Save changes' : 'Create location'}
              intent="primary"
              loading={upsertLocationMutation.isLoading}
              disabled={upsertLocationMutation.isLoading}
              onClick={handleSubmit(submitForm)}
            />
          </footer>
        </>
      )}
    </Dialog>
  );
}
