import React from 'react';
import {NavLink, Outlet} from 'react-router-dom';
import '../styles/settings-page.styles.scss';
import {cn} from '../../common/utils';
import {
  BadgeDollarSignIcon,
  BellIcon,
  CalendarClockIcon,
  CoinsIcon,
  CreditCardIcon,
  GroupIcon,
  ListPlusIcon,
  MessageSquare,
  PrinterIcon,
  StoreIcon,
  WorkflowIcon,
} from 'lucide-react';

import Restricted from '../../common/components/Restricted';

function getActiveLinkClasses({isActive}: {isActive: boolean}) {
  return cn(
    'tw-group tw-flex tw-items-center tw-gap-x-3 hover:tw-no-underline hover:tw-text-blue-500 tw-rounded tw-p-2 tw-text-sm tw-leading-6 tw-font-semibold',
    {
      'tw-bg-gray-50 tw-text-indigo-600': isActive,
      'tw-text-gray-700 hover:tw-text-indigo-600 hover:tw-bg-gray-50': !isActive,
    }
  );
}

export function StoreSettingsPagesLayout() {
  return (
    <div className="tw-grid tw-grid-cols-12 tw-gap-8">
      <div className="tw-col-span-3 tw-self-start">
        <nav aria-label="Sidebar" className="tw-bg-white tw-shadow tw-rounded tw-p-4">
          <ul className="tw-space-y-4">
            <li>
              <div className="tw-text-xs tw-font-semibold tw-leading-6 tw-mb-1 tw-text-gray-400">General</div>
              <ul className="tw-space-y-1">
                <li>
                  <NavLink className={getActiveLinkClasses} to="general">
                    <StoreIcon className="tw-h-4" />
                    Store information
                  </NavLink>
                </li>
                <li>
                  <NavLink className={getActiveLinkClasses} to="notifications">
                    <BellIcon className="tw-h-4" />
                    Notifications
                  </NavLink>
                </li>
                <li>
                  <NavLink className={getActiveLinkClasses} to="sms-settings">
                    <MessageSquare className="tw-h-4" />
                    SMS Settings
                  </NavLink>
                </li>
                <li>
                  <NavLink className={getActiveLinkClasses} to="timeframes">
                    <CalendarClockIcon className="tw-h-4" />
                    Timeframes
                  </NavLink>
                </li>
                <li>
                  <NavLink className={getActiveLinkClasses} to="printers">
                    <PrinterIcon className="tw-h-4" />
                    Printers
                  </NavLink>
                </li>
                <li>
                  <NavLink className={getActiveLinkClasses} to="integrations">
                    <WorkflowIcon className="tw-h-4" />
                    Integrations
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <div className="tw-text-xs tw-font-semibold tw-leading-6 tw-mb-1 tw-text-gray-400">Payments</div>
              <ul className="tw-space-y-1">
                <li>
                  <NavLink className={getActiveLinkClasses} to="tips">
                    <CoinsIcon className="tw-h-4" />
                    Tips
                  </NavLink>
                </li>
                <li>
                  <NavLink className={getActiveLinkClasses} to="taxes">
                    <BadgeDollarSignIcon className="tw-h-4" />
                    Taxes
                  </NavLink>
                </li>
                <li>
                  <NavLink className={getActiveLinkClasses} to="additional-charges">
                    <ListPlusIcon className="tw-h-4" />
                    Additional charges
                  </NavLink>
                </li>
                <Restricted to="superadmin">
                  <li>
                    <NavLink className={getActiveLinkClasses} to="checkout-options">
                      <CreditCardIcon className="tw-h-4" />
                      Checkout options
                    </NavLink>
                  </li>
                </Restricted>
                <li>
                  <NavLink className={getActiveLinkClasses} to="revenue-groups">
                    <GroupIcon className="tw-h-4" />
                    Revenue groups
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <main className="tw-col-span-9 tw-self-start tw-bg-white tw-rounded-lg tw-shadow">
        <Outlet />
      </main>
    </div>
  );
}
