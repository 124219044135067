import React, {useEffect} from 'react';

import {Button, FormGroup, InputGroup, Spinner, Switch, Tag} from '@blueprintjs/core';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import * as yup from 'yup';

import {fetchStoreRequest, updateStoreRequest} from '../../../api/store.api';
import {MultiSelector} from '../../../components/selectors/multi-selector.component';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';
import {StoreDto, StoreUpdateRequestDto} from '@kontactless/admin-api/store/store.dto';
import {useParams} from 'react-router-dom';

interface StoreUpdateForm {
  ringNotificationOnPlacedOrder: boolean;
  overdueTimeMinutes: number;
  overdueEmails: string[];
  overduePhones: string[];
}

const formValidator = yup.object().shape({
  ringNotificationOnPlacedOrder: yup.boolean().required(),
  overdueTimeMinutes: yup.number().required().min(0),
  overdueEmails: yup.array(yup.string().email()).required(),
});

export function NotificationsPage() {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: {isDirty},
    reset,
  } = useForm<StoreUpdateForm>({defaultValues: defaultForm, resolver: yupResolver(formValidator)});
  const queryClient = useQueryClient();
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);

  const storeQuery = useQuery({queryKey: ['stores', storeId], queryFn: () => fetchStoreRequest(user.token, storeId)});

  const storeUpdateMutation = useMutation<StoreDto, Error, StoreUpdateRequestDto>((update) =>
    updateStoreRequest(user.token, update, storeId)
  );

  const storeSettings = storeQuery.data?.settings;

  const submitForm = async (form: StoreUpdateForm) => {
    try {
      const updatedStore = await storeUpdateMutation.mutateAsync({settings: {...form}});

      queryClient.setQueryData<StoreDto>(['stores', storeId], () => ({...storeQuery.data, ...updatedStore}));

      toaster.show({intent: 'success', message: 'Stores settings were updated successfully'});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred updating the store settings'});
    }
  };

  useEffect(() => {
    if (storeSettings) {
      reset({
        overdueEmails: storeSettings?.overdueEmails ?? [],
        overduePhones: storeSettings?.overduePhones ?? [],
        overdueTimeMinutes: storeSettings?.overdueTimeMinutes,
        ringNotificationOnPlacedOrder: storeSettings?.ringNotificationOnPlacedOrder,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeSettings]);

  if (!storeSettings) {
    return <Spinner />;
  }

  return (
    <div className="tw-max-w-[480px] tw-px-6">
      <header className="card-header">
        <h2 className="tw-text-zinc-700 tw-my-4 tw-text-xl tw-font-bold">Notifications settings</h2>
      </header>
      <div className="card-body form-body">
        {isDirty ? (
          <div className="form-actions">
            <Button text="Cancel" minimal disabled={storeUpdateMutation.isLoading} onClick={() => reset()} />
            <Button
              text="Save"
              minimal
              disabled={storeUpdateMutation.isLoading}
              loading={storeUpdateMutation.isLoading}
              onClick={handleSubmit(submitForm)}
            />
          </div>
        ) : null}

        <FormGroup
          label=""
          className="tw-font-bold"
          intent={errors.ringNotificationOnPlacedOrder ? 'danger' : 'none'}
          helperText={errors.ringNotificationOnPlacedOrder ? 'There is an error' : ''}
        >
          <Controller
            name="ringNotificationOnPlacedOrder"
            control={control}
            defaultValue={storeSettings?.ringNotificationOnPlacedOrder}
            render={(props) => (
              <div className="tw-flex tw-w-full">
                <div className="tw-w-full tw-flex-col">
                  <div>
                    <div className="tw-mt-4 tw-text-sm tw-font-bold">Active sound notification</div>
                  </div>
                  <div className="tw-text-sm tw-font-normal">Turn on sound notifications for new orders.</div>
                </div>
                <div>
                  <Switch
                    className="tw-mt-4"
                    defaultChecked={props.value}
                    onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                    inputRef={register}
                    large
                    alignIndicator="left"
                  />
                </div>
              </div>
            )}
          />
        </FormGroup>

        <hr className="-tw-mx-4 tw-mt-6" />

        <div className="tw-my-6 tw-text-sm tw-font-normal">Overdue settings</div>

        <FormGroup
          label="Overdue order time limit"
          className="tw-font-bold"
          intent={errors.overdueTimeMinutes ? 'danger' : 'none'}
          helperText={errors.overdueTimeMinutes ? 'Invalid overdue time minutes' : ''}
        >
          <InputGroup
            className="tw-mt-2 tw-w-3/6"
            name="overdueTimeMinutes"
            type="number"
            rightElement={<Tag minimal children="MIN" />}
            min={0}
            intent={errors.overdueTimeMinutes ? 'danger' : 'none'}
            inputRef={register({required: true})}
          />
        </FormGroup>

        <FormGroup
          label="Send overdue notifications to"
          className="tw-font-bold"
          intent={errors.overdueEmails ? 'danger' : 'none'}
          helperText={errors.overdueEmails ? 'There is an invalid email' : ''}
        >
          <Controller
            name="overdueEmails"
            control={control}
            render={(props) => (
              <MultiSelector
                className="tw-mt-2"
                onItemSelect={(value) => props.onChange(props.value.concat([value]))}
                onRemove={(value) => props.onChange(props.value.filter((v: string) => v !== value))}
                selectedValues={props.value}
                tagInputProps={{placeholder: 'Input many emails...', intent: errors.overdueEmails ? 'danger' : 'none'}}
              />
            )}
          />
        </FormGroup>
      </div>
    </div>
  );
}

const defaultForm: StoreUpdateForm = {
  overdueEmails: [],
  overduePhones: [],
  overdueTimeMinutes: 20,
  ringNotificationOnPlacedOrder: true,
};
