import {Checkbox, Tag} from '@blueprintjs/core';

import {DayOfWeek} from '../../utils/types';
import {DAYS_OF_WEEK} from '../../utils/constants';
import {cn} from '../../common/utils';

interface WeekListCheckboxProps {
  values: DayOfWeek[];
  onChange?: (newValues: DayOfWeek[]) => void;
  readonly?: boolean;
}

export const WeekListCheckbox = ({values, onChange, readonly = false}: WeekListCheckboxProps) => {
  const onDayClick = (clickedDay: DayOfWeek) => {
    if (readonly) {
      return;
    }
    const selectedDays: DayOfWeek[] = values.includes(clickedDay)
      ? values.filter((day) => day !== clickedDay)
      : [...values, clickedDay];

    onChange?.(selectedDays);
  };

  return (
    <div className="tw-flex tw-gap-1">
      {DAYS_OF_WEEK.map((day) => (
        <DayCheckbox readonly={readonly} label={day} checked={values.includes(day)} onClick={() => onDayClick(day)} key={day} />
      ))}
    </div>
  );
};

interface DayCheckboxProps {
  label: string;
  checked: boolean;
  onClick: () => void;
  readonly?: boolean;
}

function DayCheckbox({checked, label, onClick, readonly = false}: DayCheckboxProps) {
  return (
    <label className={cn(!readonly && 'tw-cursor-pointer')}>
      <Tag round minimal intent={checked ? 'primary' : 'none'} className="tw-w-7 tw-h-7 !tw-p-1 tw-text-center">
        <span className="tw-capitalize">{label.slice(0, 2)}</span>
      </Tag>
      {/* <span className="tw-capitalize">{label.slice(0, 3)}</span> */}
      <Checkbox checked={checked} onChange={onClick} className="!tw-hidden" />
    </label>
  );
}
