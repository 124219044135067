import {EventDto} from '@kontactless/admin-api/event/event.dto';
import {DateTime} from 'luxon';

import {Badge} from '../../common/components/Badge';
import {EventCard} from './EventCard';

interface EventsContainerProps {
  events: EventDto[];
  timezone: string;
}

export function EventGrid({events, timezone}: EventsContainerProps) {
  const eventsGroupedByMonth = events.reduce((acc, event) => {
    const month = DateTime.fromISO(event.startDatetime).toFormat('MMMM y');
    if (acc.has(month)) {
      const monthEvents = acc.get(month);
      if (monthEvents) {
        monthEvents.push(event);
      }
    } else {
      acc.set(month, [event]);
    }
    return acc;
  }, new Map<string, EventDto[]>());

  return (
    <>
      {Array.from(eventsGroupedByMonth).map(([month, events]) => (
        <div key={month}>
          <div className="tw-my-6">
            <MonthSeparator month={month} />
          </div>
          <div className="tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-5">
            {events.map((event) => (
              <EventCard key={event.id} event={event} timezone={timezone} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

interface MonthSeparatorProps {
  month: string;
}

function MonthSeparator({month}: MonthSeparatorProps) {
  return (
    <div className="tw-flex tw-gap-4 tw-items-center">
      <Badge text={month} variant="primary" />
      <hr className="tw-flex-1  tw-bg-gray-200 tw-text-gray-200" />
    </div>
  );
}
