import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, FormGroup, Menu, MenuItem, NonIdealState, Spinner} from '@blueprintjs/core';
import {Popover2} from '@blueprintjs/popover2';
import {usePDF} from '@react-pdf/renderer';
import FileSaver from 'file-saver';
import {DateTime} from 'luxon';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQuery} from 'react-query';
import {downloadAllOrdersRequest, fetchAllOrdersPdfRequest, fetchAllOrdersRequest} from '../../api/order.api';

import {PdfDocumentBody} from '../components/orders-pdf.component';

import {DateRangeButton} from '../../components/selectors/date-range-button.component';
import {EventSelector} from '../../components/selectors/event-selector.component';

import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';

import {OrderReportsTable} from '../components/orders-reports-table.component';

import {OrderDto} from '@kontactless/admin-api/order/order.dto';

import {Container} from '../../common/components/layout/Container';
import {Header} from '../../common/components/layout/Header';
import {fetchStoreRequest} from '../../api/store.api';

import {OrderHistoryTable} from '../components/OrderHistoryTable';

interface FiltersForm {
  eventId?: number;
  dateRange?: {start: Date | null; end: Date | null};
}

export function OrdersHistoryPage() {
  const now = new Date();
  const {user} = useAuth();
  const navigate = useNavigate();
  const params = useParams<{companyId: string; storeId: string}>();
  const storeId = Number(params.storeId);
  const storeQuery = useQuery(['stores', storeId], () => fetchStoreRequest(user.token, storeId));

  const {control, watch} = useForm<FiltersForm>({
    defaultValues: {
      dateRange: {
        start: DateTime.fromJSDate(now).startOf('day').minus({days: 30}).toJSDate(),
        end: DateTime.fromJSDate(now).startOf('day').plus({days: 1}).toJSDate(),
      },
    },
  });

  const {
    state: {toaster},
  } = useToasts();

  const [page, setPage] = useState(1);
  const [pdfData, setPdfData] = useState<OrderDto[]>([]);

  const ordersQuery = useQuery({
    queryKey: [
      'stores',
      storeId,
      'orders',
      {
        storeId,
        eventId: watch('eventId'),
        page,
        pageSize: 10,
        dateStart: watch('dateRange')?.start?.toISOString(),
        dateEnd: watch('dateRange')?.end?.toISOString(),
      },
    ],
    queryFn: () =>
      fetchAllOrdersRequest(user.token, {
        storeId,
        eventId: watch('eventId'),
        page,
        pageSize: 10,
        dateStart: watch('dateRange')?.start?.toISOString(),
        dateEnd: watch('dateRange')?.end?.toISOString(),
      }),
    onSuccess: ({pagination}) => {
      if (pagination?.page !== page) {
        setPage(page);
      }
    },
    enabled: !!watch('dateRange')?.start,
    keepPreviousData: true,
  });

  const [pdfInstance, updatePdf] = usePDF({document: <PdfDocumentBody data={pdfData} />});

  useEffect(() => {
    if (pdfData.length > 0) {
      updatePdf();
    }
  }, [pdfData]);

  useEffect(() => {
    if (!pdfInstance.error && pdfInstance.blob && pdfData.length > 0) {
      const {start, end} = watch('dateRange')!;
      const fileName = `orders_${start!.toISOString().split('T')[0]}_${end!.toISOString().split('T')[0]}.pdf`;
      FileSaver.saveAs(pdfInstance.blob, fileName);
      setPdfData([]);
    }
  }, [pdfInstance.blob]);

  const ordersExportMutation = useMutation({
    mutationFn: () =>
      downloadAllOrdersRequest(user.token, {
        pageSize: 10,
        storeId,
        page,
        dateStart: watch('dateRange')?.start?.toISOString(),
        dateEnd: watch('dateRange')?.end?.toISOString(),
        eventId: watch('eventId'),
      }),
    onSuccess: () => {
      toaster.show({
        intent: 'success',
        message: (
          <>
            Your report will be available to download shortly in <b>"Exported Data"</b>
          </>
        ),
        action: {href: '#exported-data', icon: 'chevron-right', text: 'Go to Exported Data'},
      });
    },
    onError: ({error}: any) => {
      if (error) {
        toaster.show({
          intent: 'danger',
          message: error?.message ?? 'An error occurred while generating your report',
        });
      }
    },
  });

  const fetchPdfData = async () => {
    try {
      toaster.show({
        message: 'Your PDF will be downloaded shortly',
        intent: 'success',
      });
      const {data} = await fetchAllOrdersPdfRequest(user.token, {
        storeId: storeId,
        dateStart: watch('dateRange')?.start?.toISOString(),
        dateEnd: watch('dateRange')?.end?.toISOString(),
        eventId: watch('eventId'),
      });
      setPdfData(data);
    } catch (error) {
      toaster.show({
        message: 'Something went worng while generating your PDF. Try again.',
        intent: 'danger',
      });
    }
  };

  const orders = ordersQuery.data?.data ?? [];
  const pagination = ordersQuery.data?.pagination;

  return (
    <>
      <Header>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-gap-4 tw-items-center">
            <Button
              text="Back"
              icon="chevron-left"
              minimal
              small
              className="!tw-bg-blue-100 !tw-rounded-lg"
              onClick={() => navigate('..')}
            />
            <h1 className="tw-text-base tw-font-bold">Orders history</h1>
          </div>
        </div>
      </Header>
      <Container>
        <section className="tw-bg-white tw-border tw-shadow tw-p-6 tw-rounded-xl tw-mb-6">
          <header>
            <div className="tw-flex tw-items-center tw-justify-between tw-mb-2">
              {storeQuery.data?.eventsFeatureEnabled && (
                <div className="tw-flex tw-gap-4 tw-items-center">
                  <FormGroup label="Event">
                    <Controller
                      name="eventId"
                      control={control}
                      render={(props) => (
                        <EventSelector
                          componentType="suggest"
                          storeId={storeId}
                          selectedItemId={props.value}
                          onItemSelect={(event) => props.onChange(event?.id)}
                          buttonProps={{large: false}}
                          fill={false}
                        />
                      )}
                    />
                  </FormGroup>
                </div>
              )}
              <div className="tw-flex tw-gap-4 tw-items-end">
                <FormGroup label="Date range">
                  <Controller
                    name="dateRange"
                    control={control}
                    render={(props) => (
                      <DateRangeButton
                        value={[props.value?.start ?? null, props.value?.end ?? null]}
                        onChange={([start, end]) => props.onChange({start, end})}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Popover2
                    position="bottom"
                    content={
                      <Menu>
                        <MenuItem icon="archive" text="Excel" onClick={() => ordersExportMutation.mutate()} />
                        <MenuItem icon="archive" text="PDF" onClick={() => fetchPdfData()} />
                        <MenuItem
                          icon="share"
                          text="Print version"
                          href={`print-version?${new URLSearchParams({
                            dateEnd: watch('dateRange')?.end?.toISOString() ?? DateTime.now().toISODate(),
                            dateStart: watch('dateRange')?.start?.toISOString() ?? DateTime.now().toISODate(),
                            eventId: watch('eventId')?.toString() ?? '0',
                          }).toString()}`}
                          target="_blank"
                        />
                      </Menu>
                    }
                  >
                    <Button
                      intent="primary"
                      icon="download"
                      text="Export Data"
                      disabled={!ordersQuery.data?.data.length}
                      loading={ordersExportMutation.isLoading}
                    />
                  </Popover2>
                </FormGroup>
              </div>
            </div>
          </header>
          <div className="tw-overflow-x-auto">
            {/* Empty State */}
            {orders.length === 0 && !ordersQuery.isLoading && <NonIdealState icon="search" title="No orders found" />}

            {/* Loading state */}
            {ordersQuery.isLoading && <Spinner />}

            {/* Orders table */}
            {orders.length > 0 && (
              <OrderHistoryTable
                orders={orders}
                pagination={pagination!}
                onPageChange={setPage}
                currentPage={page}
                timezone={storeQuery.data?.timezone ?? 'America/Los_Angeles'}
                eventsFeatureEnabled={Boolean(storeQuery.data?.eventsFeatureEnabled)}
              />
            )}
          </div>
        </section>

        {/* Exported data */}
        <section className="tw-bg-white tw-border tw-shadow tw-p-6 tw-rounded-xl">
          <header className="tw-mb-2">
            <h1 className="tw-font-bold tw-text-xl">Exported data</h1>
            <p className="tw-text-gray-700 tw-text-sm">{storeQuery.data?.name}</p>
          </header>
          <div>
            <OrderReportsTable store={storeQuery.data} />
          </div>
        </section>
      </Container>
    </>
  );
}
