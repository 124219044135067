import * as React from 'react';
import {VariantProps, cva} from 'class-variance-authority';
import {getOrderStatusLabel} from '../../utils/helpers.utils';
import {OrderStatus} from '../../utils/types';
import {cn} from '../../common/utils';

const orderStatusBadgeVariants = cva(
  'tw-inline-flex tw-gap-1 tw-items-center tw-font-semibold tw-rounded-md tw-whitespace-nowrap',
  {
    variants: {
      status: {
        received: 'tw-bg-cyan-100 tw-text-cyan-800',
        preparing: 'tw-bg-yellow-100 tw-text-yellow-800',
        ready: 'tw-bg-green-100 tw-text-green-800',
        delivered: 'tw-bg-purple-100 tw-text-purple-800',
        'cancelled-by-customer': 'tw-bg-gray-100 tw-text-gray-800',
        'cancelled-by-store': 'tw-bg-gray-100 tw-text-gray-800',
        refunded: 'tw-bg-red-100 tw-text-red-800',
        'partially-refunded': 'tw-bg-red-100 tw-text-red-800',
      },
      size: {
        default: 'tw-px-1.5 tw-py-0.5 tw-text-xs',
        lg: 'tw-px-2 tw-py-0.5 tw-text-sm',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
);

export interface OrderStatusBadgeProps
  extends React.BaseHTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof orderStatusBadgeVariants> {
  status: OrderStatus;
}

const OrderStatusBadge = React.forwardRef<HTMLSpanElement, OrderStatusBadgeProps>(({className, status, size, ...props}, ref) => {
  const styles = orderStatusBadgeVariants({status, size});
  return (
    <span className={cn(styles, className)} ref={ref} {...props}>
      {getOrderStatusLabel(status)}
    </span>
  );
});

OrderStatusBadge.displayName = 'Badge';

export {OrderStatusBadge, orderStatusBadgeVariants};
