import {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {DateTime} from 'luxon';
import {useQuery} from 'react-query';

import {bestPerformingStoresReport, getReport, getTopOrderedCategories, getTopSellingProducts} from '../../api/report.api';
import {useAuth} from '../../contexts/auth.context';
import {CompareGroup, HomeReportCompareGroups} from '../../utils/types';

import {fetchAllStoresRequest} from '../../api/store.api';
import {fetchAllUsersRequest} from '../../api/user.api';
import {UserDto} from '@kontactless/admin-api/user/user.dto';
import {OperationsSection} from '../components/OperationsSection';
import {LastLoggedUsersSection} from '../components/LastLoggedUsersSection';
import {orderBy} from 'lodash';
import SummarySection from '../components/SummarySection';

import {DateFilters} from '../components/DateFilters';
import {isDateInOpenPeriod} from '../../utils/helpers.utils';

export function CompanyHomePage() {
  const {user} = useAuth();
  const params = useParams<{companyId: string}>();
  const companyId = Number(params.companyId);

  const [compareGroups, setCompareGroups] = useState<HomeReportCompareGroups>({
    compareGroupFrom: {
      start: DateTime.local().startOf('day').toJSDate(),
      end: DateTime.local().endOf('day').toJSDate(),
    },
    compareGroupTo: {
      start: DateTime.local().minus({day: 1}).startOf('day').toJSDate(),
      end: DateTime.local().minus({day: 1}).endOf('day').toJSDate(),
    },
  });
  const [storeNameFilter, setStoreNameFitler] = useState('');
  const [userNameFilter, setUserNameFilter] = useState('');
  const [isUserUpsertDialogOpen, setIsUserUpsertDialogOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<UserDto | undefined>(undefined);

  const showPercentage = !(
    compareGroups.compareGroupFrom?.start?.toLocaleString() === compareGroups.compareGroupTo?.start?.toLocaleString() &&
    compareGroups.compareGroupFrom?.end?.toLocaleString() === compareGroups.compareGroupTo?.end?.toLocaleString()
  );

  const [stringifiedCompareGroups, compareGroupsQueryKey] = useMemo(
    () => [getCompareGroupsString(compareGroups), getCompareGroupsQueryKey(compareGroups)],
    [compareGroups]
  );

  const totalSalesQuery = useQuery(
    ['total-sales-report', compareGroupsQueryKey, companyId],
    () =>
      getReport('total-sales', {
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );

  const bestPerformingStoresQuery = useQuery(
    ['best-performing-stores', compareGroupsQueryKey, companyId],
    () =>
      bestPerformingStoresReport({
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );

  const totalOrdersQuery = useQuery(
    ['total-orders-report', compareGroupsQueryKey, companyId],
    () =>
      getReport('total-orders', {
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );

  const averageOrderTotalQuery = useQuery(
    ['average-order-total-report', compareGroupsQueryKey, companyId],
    () =>
      getReport('average-order-total', {
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );

  const totalNewCustomersQuery = useQuery(
    ['total-new-customers-report', compareGroupsQueryKey, companyId],
    () =>
      getReport('new-customers', {
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );

  const topSellingProductsQuery = useQuery(
    ['top-selling-products-report', compareGroupsQueryKey, companyId],
    () =>
      getTopSellingProducts({
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );

  const topOrderedCategoriesQuery = useQuery(
    ['top-ordered-categories-report', compareGroupsQueryKey, companyId],
    () =>
      getTopOrderedCategories({
        token: user.token,
        compareGroups: stringifiedCompareGroups,
        companyId,
      }),
    {enabled: isQueryEnabled(compareGroups)}
  );

  const storesRequest = useQuery({
    queryKey: ['companies', companyId, 'stores'],
    queryFn: () => fetchAllStoresRequest(user.token, companyId),
  });

  const usersRequest = useQuery<UserDto[]>({
    queryKey: user.companies ? ['companies', companyId, 'users'] : ['users'],
    queryFn: () => fetchAllUsersRequest(user.token, companyId),
  });

  const filteredStores =
    storesRequest.data?.filter((store) =>
      storeNameFilter ? store.name.toLowerCase().includes(storeNameFilter.toLowerCase()) : store
    ) ?? [];

  const storesReport = orderBy(
    filteredStores.map((store) => ({
      storeInfo: {
        id: store.id,
        name: store.name,
        imageUrl: store.imageUrl ?? '',
        isOpen: isDateInOpenPeriod(DateTime.local().setZone(store.timezone ?? 'America/Los_Angeles'), store.openingHours ?? []),
      },
      sales: {
        total: bestPerformingStoresQuery.data?.find((data) => data.id === store.id)?.totalSales ?? 0,
        salesDiffPercentage: bestPerformingStoresQuery.data?.find((data) => data.id === store.id)?.salesDiffPercentage,
      },
      orders: {
        total: bestPerformingStoresQuery.data?.find((data) => data.id === store.id)?.totalOrders ?? 0,
        ordersDiffPercentage: bestPerformingStoresQuery.data?.find((data) => data.id === store.id)?.ordersDiffPercentage,
      },
    })),
    'sales.total',
    'desc'
  );

  const filteredUsers = orderBy(
    usersRequest.data?.filter((user) => {
      return userNameFilter
        ? user.fullName.toLowerCase().includes(userNameFilter.toLowerCase()) ||
            user.username.toLowerCase().includes(userNameFilter.toLowerCase())
        : user;
    }),
    (user) => user.lastLogin || '',
    'desc'
  ).slice(0, 5);

  return (
    <main className="tw-mx-auto tw-flex tw-h-full tw-flex-col tw-gap-4 tw-py-10 tw-px-6 tw-max-w-screen-xl">
      <section className="tw-flex tw-flex-col tw-gap-2">
        <h2 className="tw-text-3xl tw-font-bold tw-text-gray-700 tw-mb-4">Summary</h2>
        <div className="tw-flex tw-mb-4 tw-items-center tw-justify-between">
          <DateFilters setCompareGroups={setCompareGroups} />
        </div>
        <SummarySection
          salesData={totalSalesQuery.data}
          isSalesDataError={totalSalesQuery.isError}
          isSalesDataLoading={totalSalesQuery.isLoading}
          ordersData={totalOrdersQuery.data}
          isOrdersDataLoading={totalOrdersQuery.isLoading}
          isOrdersDataError={totalOrdersQuery.isError}
          newCustomersData={totalNewCustomersQuery.data}
          isNewCustomersLoading={totalNewCustomersQuery.isLoading}
          isNewCustomersError={totalNewCustomersQuery.isError}
          averageOrderTotalData={averageOrderTotalQuery.data}
          isAverageOrderTotalLoading={averageOrderTotalQuery.isLoading}
          isAverageOrderTotalError={averageOrderTotalQuery.isError}
          bestPerformingStoresData={bestPerformingStoresQuery.data}
          isBestPerformingStoresLoading={bestPerformingStoresQuery.isLoading}
          isBestPerformingStoresError={bestPerformingStoresQuery.isError}
          topOrderedCategoriesData={topOrderedCategoriesQuery.data}
          isTopOrderedCategoriesLoading={topOrderedCategoriesQuery.isLoading}
          isTopOrderedCategoriesError={topOrderedCategoriesQuery.isError}
          topSellingProductsData={topSellingProductsQuery.data}
          isTopSellingProductsLoading={topSellingProductsQuery.isLoading}
          isTopSellingProductsError={topSellingProductsQuery.isError}
          showPercentage={showPercentage}
        />
      </section>
      <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4">
        <section className="tw-bg-white tw-border tw-shadow tw-rounded-lg tw-self-start">
          <OperationsSection
            storeNameFilter={storeNameFilter}
            setStoreNameFitler={setStoreNameFitler}
            isLoading={storesRequest.isLoading}
            storesReport={storesReport}
            showPercentage={showPercentage}
          />
        </section>
        <section className="tw-bg-white tw-border tw-shadow tw-rounded-lg tw-self-start">
          <LastLoggedUsersSection
            companyId={companyId}
            userNameFilter={userNameFilter}
            setUserNameFilter={setUserNameFilter}
            isLoading={usersRequest.isLoading}
            users={filteredUsers}
            setUserToEdit={setUserToEdit}
            userToEdit={userToEdit}
            isUserUpsertDialogOpen={isUserUpsertDialogOpen}
            setIsUserUpsertDialogOpen={setIsUserUpsertDialogOpen}
          />
        </section>
      </div>
    </main>
  );
}

const isQueryEnabled = (compareGroups: HomeReportCompareGroups): boolean => {
  let enabled = true;

  if (compareGroups.compareGroupFrom) {
    enabled = enabled && !!compareGroups.compareGroupFrom.start && !!compareGroups.compareGroupFrom.end;
  }

  if (compareGroups.compareGroupTo) {
    enabled = enabled && !!compareGroups.compareGroupTo.start && !!compareGroups.compareGroupTo.end;
  }

  return enabled;
};

const getCompareGroupsString = ({compareGroupFrom, compareGroupTo}: HomeReportCompareGroups) => {
  return {
    compareGroupFrom: getCompareGroupStrings({start: compareGroupFrom.start, end: compareGroupFrom.end}),
    compareGroupTo: getCompareGroupStrings({start: compareGroupTo.start, end: compareGroupTo.end}),
  };
};

const getCompareGroupStrings = ({start, end}: CompareGroup): {start: string | null; end: string | null} => {
  return {
    start: start ? DateTime.fromJSDate(start).toISO() : null,
    end: end ? DateTime.fromJSDate(end).toISO() : null,
  };
};

const getCompareGroupsQueryKey = (compareGroups: HomeReportCompareGroups): Array<string | null> => {
  const stringifiedCompareGroups = getCompareGroupsString(compareGroups);

  return [
    stringifiedCompareGroups.compareGroupFrom.start,
    stringifiedCompareGroups.compareGroupFrom.end,
    stringifiedCompareGroups.compareGroupTo.start,
    stringifiedCompareGroups.compareGroupTo.end,
  ];
};
