import {callApi} from './api';
import {UserCreateRequestDto, UserDto, UserUpdateRequestDto} from '@kontactless/admin-api/user/user.dto';

export const fetchAllUsersRequest = async (token: string, companyId?: number): Promise<UserDto[]> => {
  return callApi<UserDto[]>('/users', {token, params: {companyId}}).then(({data}) => data);
};

export const fetchUserRequest = async (token: string): Promise<UserDto> => {
  return callApi<UserDto>(`/users/me`, {token}).then(({data}) => data);
};

export const createUserRequest = async (token: string, insert: UserCreateRequestDto): Promise<UserDto> => {
  return callApi<UserDto>('/users', {token, method: 'POST', body: JSON.stringify(insert)}).then(({data}) => data);
};

export const updateUserRequest = async (token: string, userId: number, update: UserUpdateRequestDto): Promise<UserDto> => {
  return callApi<UserDto>(`/users/${userId}`, {token, method: 'PATCH', body: JSON.stringify(update)}).then(({data}) => data);
};

export const updateCurrentUserRequest = async (token: string, update: UserUpdateRequestDto): Promise<UserDto> => {
  return callApi<UserDto>(`/users/me`, {token, method: 'PATCH', body: JSON.stringify(update)}).then(({data}) => data);
};

export const resendUserInviteRequest = async (token: string, userId: number): Promise<UserDto> => {
  return callApi<UserDto>(`/users/${userId}/resend-invite`, {token, method: 'POST'}).then(({data}) => data);
};

export const deleteUserRequest = async (token: string, userId: number): Promise<{}> => {
  return callApi<{}>(`/users/${userId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
