import React from 'react';

import {Button, Classes, Dialog, DialogProps, FormGroup, InputGroup} from '@blueprintjs/core';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';

import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {LocationTypeSelector} from '../selectors/location-type-selector.component';

import {
  LocationGroupCreateRequestDto,
  LocationGroupUpdateRequestDto,
  LocationGroupsDto,
} from '@kontactless/admin-api/location-groups/location-groups.dto';
import {createLocationGroupsRequest, updateLocationGroupRequest} from '../../api/location-groups.api';

export interface LocationGroupUpsertDialogProps extends DialogProps {
  sectionId: number;
  storeId: number;
  locationGroup?: LocationGroupsDto;
  onClose: () => void;
}

interface LocationGroupsForm {
  locationTypeId: number;
  name: string;
}

export function LocationGroupUpsertDialog({
  sectionId,
  storeId,
  locationGroup,
  onClose,
  ...props
}: LocationGroupUpsertDialogProps) {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const queryClient = useQueryClient();
  const {register, handleSubmit, control, errors} = useForm<LocationGroupsForm>();

  const upsertLocationGroupMutation = useMutation<
    LocationGroupsDto,
    Error,
    LocationGroupCreateRequestDto | LocationGroupUpdateRequestDto
  >({
    mutationKey: locationGroup?.id ? ['update-location-group', locationGroup.id] : 'create-location-group',
    mutationFn: (body) => {
      if (locationGroup?.id) {
        return updateLocationGroupRequest(user.token, body as LocationGroupUpdateRequestDto, locationGroup.id);
      } else {
        return createLocationGroupsRequest(user.token, body as LocationGroupCreateRequestDto);
      }
    },
    onSuccess: ({sectionId}) => {
      onClose();
      queryClient.invalidateQueries(['section', sectionId]);
      toaster.show({
        intent: 'success',
        message: locationGroup?.id ? 'Section was updated successfully' : 'Section was created successfully',
      });
    },
    onError: (error) => {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred while creating the section'});
    },
  });

  const submitForm = async (form: LocationGroupsForm) => {
    let body: LocationGroupUpdateRequestDto | LocationGroupCreateRequestDto;
    if (locationGroup?.id) {
      body = {id: locationGroup.id, locationTypeId: form.locationTypeId, isEnabled: locationGroup.isEnabled, name: form.name};
    } else {
      body = {...form, sectionId, isEnabled: true, locationTypeId: form.locationTypeId};
    }

    upsertLocationGroupMutation.mutate(body);
  };

  const isEditLocationGroupTypeDisabled = Boolean(locationGroup?.locations?.length);

  return (
    <Dialog
      title={locationGroup ? 'Edit section' : 'New section'}
      className="kl-dialog location-dialog"
      onClose={() => onClose?.()}
      {...props}
    >
      <div className={classNames(Classes.DIALOG_BODY)}>
        <div className="dialog-form">
          <FormGroup
            className="form-control"
            label="Name"
            intent={errors.name ? 'danger' : 'none'}
            helperText={errors.name ? 'Name is required' : ''}
          >
            <InputGroup
              name="name"
              intent={errors.name ? 'danger' : 'none'}
              defaultValue={locationGroup?.name}
              inputRef={register({required: true})}
            />
          </FormGroup>
          <FormGroup
            label="Location group type"
            helperText={
              isEditLocationGroupTypeDisabled
                ? `You can't change this section location type because it already has locations in it`
                : null
            }
          >
            <Controller
              name="locationTypeId"
              control={control}
              rules={{required: true}}
              defaultValue={locationGroup?.locationTypeId}
              render={(props) => (
                <LocationTypeSelector
                  storeId={storeId}
                  disabled={isEditLocationGroupTypeDisabled}
                  buttonProps={{disabled: isEditLocationGroupTypeDisabled}}
                  selectedItemId={props.value}
                  onItemSelect={(locationType) => props.onChange(locationType.id)}
                  fill
                />
              )}
            />
          </FormGroup>
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={upsertLocationGroupMutation.isLoading}
          onClick={(ev) => onClose?.()}
        />
        <Button
          text={location ? 'Save changes' : 'Create location'}
          intent="primary"
          loading={upsertLocationGroupMutation.isLoading}
          disabled={upsertLocationGroupMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
}
