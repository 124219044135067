import {CreateEventRequestDto, EventDto, EventsRequestDto, UpdateEventRequestDto} from '@kontactless/admin-api/event/event.dto';
import {callApi} from './api';

export const fetchAllEventsRequest = async (
  token: string,
  {storeId, dateStart, dateEnd}: EventsRequestDto
): Promise<EventDto[]> => {
  return callApi<EventDto[]>(`/events`, {token, params: {storeId, dateStart, dateEnd}}).then(({data}) => data);
};

export const fetchEventRequest = async (token: string, eventId: number): Promise<EventDto> => {
  return callApi<EventDto>(`/events/${eventId}`, {token}).then(({data}) => data);
};

export const createEventRequest = async (token: string, event: CreateEventRequestDto): Promise<EventDto> => {
  return callApi<EventDto>(`/events`, {token, method: 'POST', body: JSON.stringify(event)}).then(({data}) => data);
};

export const updateEventRequest = async (token: string, eventId: number, event: UpdateEventRequestDto): Promise<EventDto> => {
  return callApi<EventDto>(`/events/${eventId}`, {token, method: 'PATCH', body: JSON.stringify(event)}).then(({data}) => data);
};

export const deleteEventRequest = async (token: string, eventId: number): Promise<{}> => {
  return callApi<{}>(`/events/${eventId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
