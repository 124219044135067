import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {useMutation, useQueryClient} from 'react-query';
import {deleteApiKeyRequest} from '../../api/api-key.api';
import {ApiError} from '../../api/api';
import {Button} from '@blueprintjs/core';
import {DateTime} from 'luxon';
import {useAlerts} from '../../contexts/alerts.context';
import {ApiKeyDto} from '@kontactless/admin-api/api-key/api-key.dto';

export interface ApiKeyListItemProps {
  apiKey: ApiKeyDto;
  companyId: number;
}

export function ApiKeyListItem({apiKey, companyId}: ApiKeyListItemProps) {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const queryClient = useQueryClient();
  const {alertsDispatch} = useAlerts();

  const deleteApiKeyMutation = useMutation<{}, ApiError, number>({
    mutationFn: (apiKeyId) => deleteApiKeyRequest(user.token, apiKeyId),
    onSuccess: () => {
      queryClient.invalidateQueries(['api-keys', companyId]);
      toaster.show({
        intent: 'success',
        message: 'API Key was deleted successfully',
      });
    },
    onError: () => {
      toaster.show({
        intent: 'danger',
        message: 'An error ocurred deleting API Key',
      });
    },
  });

  const confirmAPIKeyDelete = () => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: (
          <div className="tw-my-2 tw-flex tw-flex-col tw-gap-2">
            <h1 className="tw-font-bold tw-text-lg">Are you sure you want to delete this API key?</h1>
            <p className="tw-text-gray-500">You API access using this key will be restricted and this action cannot be undone.</p>
          </div>
        ),
        intent: 'danger',
        confirmButtonText: 'Yes, delete it',
        onConfirm: async (setAlert, removeAlert) => {
          setAlert({loading: true});
          deleteApiKeyMutation.mutate(apiKey.id);
          removeAlert();
        },
      },
    });
  };

  return (
    <div className="tw-flex tw-items-center tw-gap-4">
      <p className="tw-w-1/2 tw-truncate"> {apiKey.name}</p>
      <p className="tw-w-1/4 tw-text-gray-600">{DateTime.fromISO(apiKey.createdAt).toFormat('LL/dd/yy t')}</p>

      <div className="tw-w-1/4 tw-flex">
        <Button
          minimal
          intent="danger"
          className="tw-ml-auto"
          icon="trash"
          text="Delete"
          loading={deleteApiKeyMutation.isLoading}
          disabled={deleteApiKeyMutation.isLoading}
          onClick={() => confirmAPIKeyDelete()}
        />
      </div>
    </div>
  );
}
