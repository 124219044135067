import React, {useEffect} from 'react';

import {Button, FormGroup, InputGroup, Spinner, Switch, Tag} from '@blueprintjs/core';
import {yupResolver} from '@hookform/resolvers/yup';
import {capitalize, startCase} from 'lodash';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import * as yup from 'yup';

import {fetchStoreRequest, updateStoreRequest} from '../../../api/store.api';
import {Selector} from '../../../components/selectors/selector.component';
import {PriceInput} from '../../../components/ui/price-input.component';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';
import {StoreDto, StoreUpdateRequestDto} from '@kontactless/admin-api/store/store.dto';
import {FEE_TYPES, PAYMENT_GATEWAYS} from '../../../utils/constants';
import {FeeType, PaymentGateway} from '../../../utils/types';

import {Navigate, useParams} from 'react-router-dom';

interface StoreUpdateForm {
  feeType?: FeeType;
  feeValue?: number;
  paymentGateway: PaymentGateway | null;
  freedomPayStoreId?: string | null;
  freedomPayTerminalId?: string | null;
  freedompayKey: string | null;
  stripeAccountId?: string | null;
  allowsRoomPayment: boolean;
}

const formValidator = yup.object().shape({
  paymentGateway: yup
    .string()
    .nullable()
    .oneOf([...PAYMENT_GATEWAYS, null]),
  feeType: yup.string().oneOf([...FEE_TYPES]),
  freedompayKey: yup.string().nullable(),
  feeValue: yup.number().min(0),
});

export function CheckoutOptionsPage() {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const queryClient = useQueryClient();

  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);

  const storeQuery = useQuery({queryKey: ['stores', storeId], queryFn: () => fetchStoreRequest(user.token, storeId)});

  const storeSettings = storeQuery.data?.settings;

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: {isDirty},
    reset,
    watch,
  } = useForm<StoreUpdateForm>({
    defaultValues: defaultForm,
    resolver: yupResolver(formValidator),
  });

  const storeUpdateMutation = useMutation<StoreDto, Error, StoreUpdateRequestDto>((update) =>
    updateStoreRequest(user.token, update, storeId)
  );

  const submitForm = async (form: StoreUpdateForm) => {
    try {
      const updatedStore = await storeUpdateMutation.mutateAsync({
        settings: {...form, feeValue: form.feeValue},
      });

      queryClient.setQueryData<StoreDto>(['stores', storeId], () => ({...storeQuery.data, ...updatedStore}));

      toaster.show({intent: 'success', message: 'Stores settings were updated successfully'});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred updating the store settings'});
    }
  };

  useEffect(() => {
    reset({
      feeType: storeQuery.data?.settings?.feeType,
      feeValue: storeQuery.data?.settings?.feeValue,
      paymentGateway: storeQuery.data?.settings?.paymentGateway,
      stripeAccountId: storeQuery.data?.settings?.stripeAccountId,
      freedomPayStoreId: storeQuery.data?.settings?.freedomPayStoreId,
      freedomPayTerminalId: storeQuery.data?.settings?.freedomPayTerminalId,
      freedompayKey: storeQuery.data?.settings?.freedompayKey,
      allowsRoomPayment: storeQuery.data?.settings?.allowsRoomPayment,
    });
  }, [storeQuery.data, reset]);

  if (!storeSettings) {
    return <Spinner />;
  }

  if (user.securityLevel !== 'superadmin') {
    return <Navigate to=".." />;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-4">
      <div className="tw-px-6">
        <div className="tw-flex tw-items-start tw-justify-between">
          <h3 className="tw-my-4 tw-text-xl tw-font-bold tw-flex tw-items-center tw-gap-3">
            Checkout Options
            <Tag intent="danger" minimal round>
              Superadmin
            </Tag>
          </h3>
          {isDirty && (
            <div>
              <Button text="Cancel" minimal disabled={storeUpdateMutation.isLoading} onClick={() => reset()} />
              <Button
                text="Save"
                minimal
                disabled={storeUpdateMutation.isLoading}
                loading={storeUpdateMutation.isLoading}
                onClick={handleSubmit(submitForm)}
                intent="primary"
              />
            </div>
          )}
        </div>

        <div className="tw-mb-2 tw-flex tw-items-center tw-gap-2">
          <p className="tw-font-bold">Gateway</p>
        </div>
        <FormGroup
          intent={errors.paymentGateway ? 'danger' : 'none'}
          helperText={errors.paymentGateway ? 'Payment gateway is invalid' : ''}
        >
          <Controller
            name="paymentGateway"
            defaultValue={storeSettings?.paymentGateway ?? 'No Gateway'}
            control={control}
            render={(props) => (
              <Selector
                items={PAYMENT_GATEWAYS.map(startCase).concat(['No Gateway'])}
                onItemSelect={(value) => (value === 'No Gateway' ? props.onChange(null) : props.onChange(value.toLowerCase()))}
                selectedItem={startCase(props.value ?? 'No Gateway')}
                buttonProps={{className: errors.paymentGateway ? 'danger-outline' : ''}}
              />
            )}
          />
        </FormGroup>
        {watch('paymentGateway') === 'stripe' && (
          <>
            <FormGroup
              intent={errors.stripeAccountId ? 'danger' : 'none'}
              helperText={errors.stripeAccountId ? 'Stripe account ID is required' : ''}
              label="Stripe account ID"
            >
              <InputGroup
                name="stripeAccountId"
                intent={errors.stripeAccountId ? 'danger' : 'none'}
                defaultValue={storeSettings?.stripeAccountId ?? ''}
                inputRef={register({required: true})}
              />
            </FormGroup>
          </>
        )}
        {watch('paymentGateway') === 'freedompay' && (
          <>
            <FormGroup label="Freedompay Key" intent={errors.freedompayKey ? 'danger' : 'none'}>
              <InputGroup
                name="freedompayKey"
                intent={errors.freedompayKey ? 'danger' : 'none'}
                inputRef={register({required: true})}
              />
            </FormGroup>
            <FormGroup
              label="Store ID"
              intent={errors.freedomPayStoreId ? 'danger' : 'none'}
              helperText={errors.freedomPayStoreId ? 'Store ID is required' : ''}
            >
              <InputGroup
                name="freedomPayStoreId"
                intent={errors.freedomPayStoreId ? 'danger' : 'none'}
                defaultValue={storeSettings?.freedomPayStoreId ?? ''}
                inputRef={register({required: true})}
              />
            </FormGroup>
            <FormGroup
              label="Terminal ID"
              intent={errors.freedomPayTerminalId ? 'danger' : 'none'}
              helperText={errors.freedomPayTerminalId ? 'Terminal ID is required' : ''}
            >
              <InputGroup
                name="freedomPayTerminalId"
                intent={errors.freedomPayTerminalId ? 'danger' : 'none'}
                defaultValue={storeSettings?.freedomPayTerminalId ?? ''}
                inputRef={register({required: true})}
              />
            </FormGroup>
          </>
        )}
        <div className="tw-my-4 tw-flex tw-items-center tw-gap-2">
          <p>Fee settings</p>
        </div>
        <div>
          <FormGroup
            label="Fee type"
            intent={errors.feeType ? 'danger' : 'none'}
            helperText={errors.feeType ? 'Fee type is invalid' : ''}
          >
            <Controller
              name="feeType"
              defaultValue={storeSettings?.feeType ?? 'customer'}
              control={control}
              render={(props) => (
                <Selector
                  items={FEE_TYPES.map(capitalize)}
                  onItemSelect={(value) => props.onChange(value.toLowerCase())}
                  selectedItem={capitalize(props.value)}
                  buttonProps={{className: errors.feeType ? 'danger-outline' : ''}}
                />
              )}
            />
          </FormGroup>
        </div>
        <FormGroup
          label="Fee charged by Kontactless"
          intent={errors.feeValue ? 'danger' : 'none'}
          helperText={errors.feeValue ? 'Fee value is invalid' : ''}
        >
          <Controller
            name="feeValue"
            control={control}
            render={(props) => <PriceInput value={props.value} onChange={props.onChange} fill />}
            inputRef={register({required: true})}
          />
        </FormGroup>
      </div>
      <hr />
      <div className="tw-flex tw-items-center tw-justify-between tw-px-6">
        <div className="tw-flex tw-flex-col">
          <p className="tw-font-bold">Ask for personal info during the checkout</p>
          <p className="tw-text-gray-500">Ask for room number and last name</p>
        </div>
        <Switch name="allowsRoomPayment" inputRef={register} />
      </div>
    </div>
  );
}

const defaultForm: StoreUpdateForm = {
  feeType: 'customer',
  feeValue: 50,
  allowsRoomPayment: false,
  freedompayKey: '',
  paymentGateway: null,
};
