import React, {Dispatch, ReactNode, createContext, useContext, useReducer} from 'react';

import {Alert, IAlertProps} from '@blueprintjs/core';

type AlertProps = Omit<IAlertProps, 'isOpen' | 'onConfirm'> & {
  children?: ReactNode;
  onConfirm?: (
    setAlert: (alert: Partial<AlertProps>) => void,
    removeAlert: () => void,
    ev?: React.SyntheticEvent<HTMLElement>
  ) => Promise<unknown>;
};

export interface AlertsState {
  alert: AlertProps | null;
}

export interface AlertsContextState {
  alertsState: AlertsState;
  alertsDispatch: Dispatch<AlertsAction>;
}

export type AlertsAction = {type: 'set-alert'; alert: Omit<AlertProps, 'isOpen'>} | {type: 'remove-alert'};

const initialState: AlertsState = {alert: null};

const AlertsContext = createContext<AlertsContextState>({} as any);
AlertsContext.displayName = 'AlertsContext';

export const AlertsProvider: React.FC = ({children, ...props}) => {
  const [alertsState, alertsDispatch] = useReducer((state: AlertsState, action: AlertsAction) => {
    switch (action.type) {
      case 'set-alert':
        return {...state, alert: action.alert};
      case 'remove-alert':
        return {...state, alert: null};
      default:
        return state;
    }
  }, initialState);

  const setAlert = (alert: Partial<AlertProps>) => {
    alertsDispatch({type: 'set-alert', alert: {...alertsState.alert, ...alert}});
  };

  const removeAlert = () => {
    alertsDispatch({type: 'remove-alert'});
  };

  return (
    <AlertsContext.Provider value={{alertsState, alertsDispatch}} {...props}>
      {children}
      <Alert
        canEscapeKeyCancel
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        {...alertsState.alert}
        isOpen={alertsState.alert !== null}
        onConfirm={(ev) => alertsState.alert?.onConfirm?.(setAlert, removeAlert, ev)}
        onCancel={(ev) => {
          alertsState.alert?.onCancel?.(ev);
          removeAlert();
        }}
      />
    </AlertsContext.Provider>
  );
};

export const useAlerts = () => {
  const context = useContext(AlertsContext);
  if (context === undefined) {
    throw new Error(`useAlerts must be used within an AlertsProvider`);
  }
  return context;
};
