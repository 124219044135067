import React from 'react';

import {Button, MenuItem} from '@blueprintjs/core';
import {IMultiSelectProps, MultiSelect} from '@blueprintjs/select';
import classNames from 'classnames';

interface MultiSelectorProps extends Omit<IMultiSelectProps<string>, 'itemRenderer' | 'tagRenderer' | 'items'> {
  selectedValues: string[];
}

const MultiSelectorComponent = MultiSelect.ofType<string>();

export const MultiSelector: React.FC<MultiSelectorProps> = ({selectedValues, ...props}) => {
  let inputRef: any = {current: undefined};

  return (
    <MultiSelectorComponent
      {...props}
      resetOnSelect
      items={selectedValues}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{className: 'multi-selector-popover', minimal: true}}
      itemRenderer={() => null}
      selectedItems={selectedValues}
      tagRenderer={(value) => value}
      itemsEqual={(m1, m2) => m1 === m2}
      itemPredicate={(query, value) => value.toLocaleLowerCase().includes(query.toLowerCase())}
      ref={inputRef}
      tagInputProps={{
        placeholder: 'Input many...',
        ...(props.tagInputProps ?? null),
        inputRef: (input) => (inputRef = input),
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
        tagProps: {minimal: true},
      }}
      createNewItemFromQuery={(value) => value}
      createNewItemRenderer={(value, active, handleClick) => (
        <MenuItem text={`Create "${value}"`} icon="add" onClick={handleClick} shouldDismissPopover={false} />
      )}
    />
  );
};
