import React, {useEffect} from 'react';

import {Button, Classes, Dialog, FormGroup, IDialogProps, InputGroup} from '@blueprintjs/core';
import {yupResolver} from '@hookform/resolvers/yup';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import * as yup from 'yup';
import {createUserRequest, updateUserRequest} from '../../api/user.api';
import {SecurityLevelSelector} from '../../components/selectors/security-level-selector.component';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {UserCreateRequestDto, UserDto, UserUpdateRequestDto} from '@kontactless/admin-api/user/user.dto';
import {SECURITY_LEVELS} from '../../utils/constants';
import {SecurityLevel} from '../../utils/types';

import {StoreMultiSelector} from '../../components/selectors/store-multiselector.component';

export interface UserUpsertDialogProps extends IDialogProps {
  user?: UserDto;
  companyId: number;
}

interface UserForm {
  username: string;
  fullName: string;
  email: string;
  securityLevel: SecurityLevel;
  stores: number[] | undefined;
}

const formValidator = () =>
  yup.object().shape({
    username: yup
      .string()
      .min(4, 'Username must be at least 4 characters long')
      .matches(/^\S*$/, "Username can't contain white spaces")
      .matches(/^[\w-.]*$/, 'Special characters not allowed (except for dot, hyphen and underscore)')
      .required(),
    fullName: yup.string().required(),
    email: yup.string().required().email('Email is invalid'),
    securityLevel: yup
      .string()
      .required()
      .oneOf([...SECURITY_LEVELS]),
    stores: yup.mixed().when('securityLevel', {
      is: (securityLevel: SecurityLevel) => ['supervisor'].includes(securityLevel),
      then: yup.array().of(yup.number()).required(),
      otherwise: yup.mixed().nullable(),
    }),
  });

export function UserUpsertDialog({user, companyId, ...props}: UserUpsertDialogProps) {
  const {user: authUser} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const queryClient = useQueryClient();

  const {register, handleSubmit, control, errors, setError, reset, watch, formState} = useForm<UserForm>({
    resolver: yupResolver(formValidator()),
    mode: 'onChange',
  });

  const userUpsertMutation = useMutation<UserDto, Error, UserCreateRequestDto | UserUpdateRequestDto>({
    mutationKey: 'upsert-user',
    mutationFn: (body) => {
      if (typeof body.stores === 'number') {
        body.stores = [body.stores];
      }
      if (typeof body.companies === 'number') {
        body.companies = [body.companies];
      }
      if (user?.id) {
        return updateUserRequest(authUser.token, user?.id, body as UserUpdateRequestDto);
      } else {
        return createUserRequest(authUser.token, body as UserCreateRequestDto);
      }
    },
    onSuccess: () => {
      props.onClose?.(undefined as any);
      queryClient.invalidateQueries(['companies', companyId, 'users']);
      toaster.show({
        intent: 'success',
        message: user?.id ? 'User updated successfully' : 'User created successfully',
      });
    },
    onError: (error: any) => {
      if (error?.error?.message === 'username_taken') {
        setError('username', {type: 'taken', message: 'Username already exists'});
      } else if (error?.error?.message === 'email_taken') {
        setError('email', {type: 'email', message: 'Email already exists'});
      } else {
        console.error(error);
        toaster.show({
          intent: 'danger',
          message: user?.id ? 'An error ocurred updating the user' : 'An error ocurred creating the user',
        });
      }
    },
  });

  const submitForm = async (form: UserForm) => {
    const updatedForm = {
      ...form,
      stores: form.securityLevel === 'company-admin' ? undefined : form.stores,
    };

    userUpsertMutation.mutate({
      ...updatedForm,
      companies: [companyId],
    });
  };

  useEffect(() => {
    reset({
      username: user?.username,
      fullName: user?.fullName,
      email: user?.email,
      securityLevel: user?.securityLevel,
      stores: user?.stores?.map((s) => s.id),
    });
  }, [user, reset]);

  const showUsernameError = (usernameError): string => {
    if (usernameError) {
      if (['taken', 'matches', 'min'].includes(usernameError.type)) {
        return usernameError.message!;
      }
      return 'Username is required';
    }
    return '';
  };

  return (
    <Dialog title={user ? 'Edit User' : 'New User'} className="kl-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          <FormGroup
            label="Username"
            intent={errors.username ? 'danger' : 'none'}
            helperText={showUsernameError(errors.username)}
          >
            <InputGroup name="username" intent={errors.username ? 'danger' : 'none'} inputRef={register} readOnly={!!user?.id} />
          </FormGroup>
          <FormGroup
            label="Name"
            intent={errors.fullName ? 'danger' : 'none'}
            helperText={errors.fullName ? 'Name is required' : ''}
          >
            <InputGroup
              name="fullName"
              intent={errors.fullName ? 'danger' : 'none'}
              inputRef={register}
              disabled={userUpsertMutation.isLoading}
            />
          </FormGroup>
          <FormGroup
            label="Email"
            intent={errors.email ? 'danger' : 'none'}
            helperText={errors.email ? (errors.email.type === 'email' ? errors.email.message : 'Email is required') : ''}
          >
            <InputGroup
              name="email"
              intent={errors.email ? 'danger' : 'none'}
              inputRef={register}
              disabled={userUpsertMutation.isLoading}
            />
          </FormGroup>
          <FormGroup
            label="Role"
            intent={errors.securityLevel ? 'danger' : 'none'}
            helperText={errors.securityLevel ? 'Role is required' : ''}
          >
            <Controller
              name="securityLevel"
              control={control}
              defaultValue={user?.securityLevel}
              render={(props) => (
                <SecurityLevelSelector
                  selectedItem={props.value}
                  onItemSelect={props.onChange}
                  currentSecurityLevel={authUser.securityLevel}
                  disabled={userUpsertMutation.isLoading}
                  buttonProps={{className: errors.securityLevel ? 'danger-outline' : '', disabled: userUpsertMutation.isLoading}}
                  fill
                />
              )}
            />
          </FormGroup>

          {['superadmin', 'admin', 'company-admin'].includes(authUser.securityLevel) &&
            ['supervisor', 'server'].includes(watch('securityLevel')) && (
              <FormGroup
                label="Store"
                intent={errors.stores ? 'danger' : 'none'}
                helperText={errors.stores ? 'Store is required' : ''}
              >
                <Controller
                  name="stores"
                  control={control}
                  defaultValue={user?.stores?.map((s) => s.id) || []}
                  render={(props) => (
                    <StoreMultiSelector
                      onItemSelect={(value) => {
                        !props.value.includes(value.id) &&
                          props.onChange(props.value.filter((el) => el !== undefined).concat(value.id));
                      }}
                      onRemove={(value) => {
                        props.onChange(props.value.filter((values) => values !== value.id));
                      }}
                      selectedValues={props.value}
                      companyId={companyId}
                      tagInputProps={{placeholder: 'Choose many...', intent: errors.stores ? 'danger' : 'none'}}
                    />
                  )}
                />
              </FormGroup>
            )}
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={userUpsertMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text={user ? 'Save changes' : 'Create user'}
          intent="primary"
          loading={userUpsertMutation.isLoading}
          disabled={userUpsertMutation.isLoading || !formState.isValid}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
}
