import React, {useEffect} from 'react';

import {Button, Classes, Dialog, DialogProps, FormGroup, InputGroup} from '@blueprintjs/core';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import {useMutation} from 'react-query';

import {createMenuCategoryRequest, updateMenuCategoryRequest} from '../../../api/menu-category.api';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';

import '../../styles/menus-page.styles.scss';
import {
  MenuCategoryCreateRequestDto,
  MenuCategoryDto,
  MenuCategoryUpdateRequestDto,
} from '@kontactless/admin-api/menu-category/menu-category.dto';
import {MenuDto} from '@kontactless/admin-api/menu/menu.dto';

export interface CategoryUpsertDialogProps extends DialogProps {
  menu: MenuDto;
  category?: MenuCategoryDto;
  onResolve?: (category: MenuCategoryDto) => void;
}

interface MenuCategoryForm {
  name: string;
}

export const CategoryUpsertDialog: React.FC<CategoryUpsertDialogProps> = ({menu, category, onResolve, ...props}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {register, handleSubmit, errors, reset} = useForm<MenuCategoryForm>();
  const upsertMenuCategoryMutation = useMutation<
    MenuCategoryDto,
    Error,
    MenuCategoryCreateRequestDto | MenuCategoryUpdateRequestDto
  >((body) => {
    if (category?.id) {
      return updateMenuCategoryRequest(user.token, category.id, body as MenuCategoryUpdateRequestDto);
    } else {
      return createMenuCategoryRequest(user.token, body as MenuCategoryCreateRequestDto);
    }
  });

  const submitForm = async (form: MenuCategoryForm) => {
    try {
      if (category?.id) {
        const updatedCategory = await upsertMenuCategoryMutation.mutateAsync({
          name: form.name,
        });

        props.onClose?.(undefined as any);
        onResolve?.(updatedCategory);
      } else {
        const newCategory = await upsertMenuCategoryMutation.mutateAsync({
          name: form.name,
          order: category?.order ?? 0,
          menuId: menu.id,
        });

        props.onClose?.(undefined as any);
        onResolve?.(newCategory);
      }

      toaster.show({
        intent: 'success',
        message: category?.id ? 'Category updated successfully' : 'Category created successfully',
      });
    } catch (error) {
      console.error(error);
      toaster.show({
        intent: 'danger',
        message: category?.id ? 'An error ocurred updating the category' : 'An error ocurred creating the category',
      });
    }
  };

  useEffect(() => {
    reset({name: category?.name ?? ''}, {dirtyFields: false});
  }, [category, reset]);

  return (
    <Dialog title={category?.id ? 'Edit Menu Category' : 'New Menu Category'} className="kl-dialog menu-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          <FormGroup
            label="Category name"
            intent={errors.name ? 'danger' : 'none'}
            helperText={errors.name ? 'Name is required' : ''}
          >
            <InputGroup
              name="name"
              defaultValue={category?.name ?? ''}
              intent={errors.name ? 'danger' : 'none'}
              inputRef={register({required: true})}
            />
          </FormGroup>
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={upsertMenuCategoryMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text={category?.id ? 'Edit' : 'Create'}
          intent="primary"
          loading={upsertMenuCategoryMutation.isLoading}
          disabled={upsertMenuCategoryMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
};
