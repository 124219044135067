import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Spinner} from '@blueprintjs/core';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {fetchEventRequest, updateEventRequest} from '../../api/event.api';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {EventDto, UpdateEventRequestDto} from '@kontactless/admin-api/event/event.dto';
import {fetchStoreRequest} from '../../api/store.api';
import {Header} from '../../common/components/layout/Header';
import {Container} from '../../common/components/layout/Container';
import {EventForm} from '../components/EventForm';

export function EventDetail() {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const params = useParams<{companyId: string; eventId?: string; storeId: string; page: string}>();
  const storeId = Number(params.storeId);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const eventUpdateMutation = useMutation<EventDto, Error, UpdateEventRequestDto>({
    mutationKey: 'update-event',
    mutationFn: (update) => updateEventRequest(user.token, event!.id, update),
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId, 'events']);
      toaster.show({intent: 'success', message: 'Event updated successfully'});
      navigate('..');
    },
  });

  const eventQuery = useQuery({
    queryKey: ['events', +params.eventId!],
    queryFn: () => fetchEventRequest(user.token, +params.eventId!),
    enabled: !!params.eventId,
  });

  const storeQuery = useQuery(['stores', storeId], () => fetchStoreRequest(user.token, storeId));
  const event = eventQuery.data;

  return (
    <>
      <Header>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-gap-4 tw-items-center">
            <Button
              text="Back"
              icon="chevron-left"
              minimal
              small
              className="!tw-bg-blue-100 !tw-rounded-lg"
              onClick={() => navigate('..')}
            />
            <h1 className="tw-text-base tw-font-bold">Event details</h1>
          </div>

          <div className="tw-flex tw-gap-4 tw-items-center">
            <Button text="Cancel" outlined disabled={eventUpdateMutation.isLoading} onClick={() => navigate('..')} />
            <Button
              text="Save changes"
              type="submit"
              intent="primary"
              disabled={eventUpdateMutation.isLoading}
              loading={eventUpdateMutation.isLoading}
              form="update-event-form"
            />
          </div>
        </div>
      </Header>
      <Container>
        {eventQuery.isLoading && (
          <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
            <Spinner />
          </div>
        )}

        {event && (
          <EventForm
            id="update-event-form"
            storeId={storeId}
            timezone={storeQuery.data?.timezone || 'America/Los_Angeles'}
            event={event}
            onSubmit={eventUpdateMutation.mutate}
          />
        )}
      </Container>
    </>
  );
}
