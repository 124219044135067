import {callApi} from './api';

export type ReportType = 'total-sales' | 'total-orders' | 'total-tips' | 'average-order-total' | 'new-customers';

export interface ReportTotalData {
  total: number;
  diffPercentage: number | 'inf';
  comparedTo: number;
}

interface CompareGroups {
  compareGroupFrom: {start: string | null; end: string | null};
  compareGroupTo: {start: string | null; end: string | null};
}

export const getReport = async (
  reportType: ReportType,
  params: {token: string; compareGroups: CompareGroups; companyId: number; storeId?: number}
) => {
  return callApi<ReportTotalData>(`/reports/${reportType}`, {
    token: params.token,
    params: {
      compareRangeFromStart: params.compareGroups.compareGroupFrom.start ?? undefined,
      compareRangeFromEnd: params.compareGroups.compareGroupFrom.end ?? undefined,
      compareRangeToStart: params.compareGroups.compareGroupTo.start ?? undefined,
      compareRangeToEnd: params.compareGroups.compareGroupTo.end ?? undefined,
      companyId: params.companyId,
      storeId: params.storeId,
    },
  }).then(({data}) => data);
};

export interface StoresTotalSalesData {
  id: number;
  totalSales: number;
  salesDiffPercentage: number | 'inf';
  ordersDiffPercentage: number | 'inf';
  name: string;
  totalOrders: number;
}

export const bestPerformingStoresReport = async (params: {
  token: string;
  compareGroups: CompareGroups;
  companyId: number;
  storeId?: number;
}) => {
  return callApi<StoresTotalSalesData[]>(`/reports/best-performing-stores`, {
    token: params.token,
    params: {
      compareRangeFromStart: params.compareGroups.compareGroupFrom.start ?? undefined,
      compareRangeFromEnd: params.compareGroups.compareGroupFrom.end ?? undefined,
      compareRangeToStart: params.compareGroups.compareGroupTo.start ?? undefined,
      compareRangeToEnd: params.compareGroups.compareGroupTo.end ?? undefined,
      companyId: params.companyId,
    },
  }).then(({data}) => data);
};

export type TopSellingProductsReport = Array<{
  id: number;
  name: string;
  storeName: string;
  imageUrl?: string;
  total: number;
  comparedTo: number;
  diffPercentage: number | 'inf';
}>;

export const getTopSellingProducts = async (params: {
  token: string;
  compareGroups: CompareGroups;
  companyId: number;
  storeId?: number;
}) => {
  return callApi<TopSellingProductsReport>(`/reports/top-selling-products`, {
    token: params.token,
    params: {
      compareRangeFromStart: params.compareGroups.compareGroupFrom.start ?? undefined,
      compareRangeFromEnd: params.compareGroups.compareGroupFrom.end ?? undefined,
      compareRangeToStart: params.compareGroups.compareGroupTo.start ?? undefined,
      compareRangeToEnd: params.compareGroups.compareGroupTo.end ?? undefined,
      companyId: params.companyId,
      storeId: params.storeId,
      limit: 5,
    },
  }).then(({data}) => data);
};

export type TopOrderedCategoriesReport = Array<{
  categoryName: string;
  total: number;
  compareTo: number;
  diffPercentage: string;
}>;

export const getTopOrderedCategories = async (params: {
  token: string;
  compareGroups: CompareGroups;
  companyId: number;
  storeId?: number;
}) => {
  return callApi<TopOrderedCategoriesReport>(`/reports/top-ordered-categories`, {
    token: params.token,
    params: {
      compareRangeFromStart: params.compareGroups.compareGroupFrom.start ?? undefined,
      compareRangeFromEnd: params.compareGroups.compareGroupFrom.end ?? undefined,
      compareRangeToStart: params.compareGroups.compareGroupTo.start ?? undefined,
      compareRangeToEnd: params.compareGroups.compareGroupTo.end ?? undefined,
      companyId: params.companyId,
      storeId: params.storeId,
    },
  }).then(({data}) => data);
};

export const getAverageRating = async (params: {
  token: string;
  compareRangeFrom: {start: string | null; end: string | null};
  companyId: number;
  storeId?: number;
}) => {
  return callApi<{rating: number}>(`/reports/average-rating`, {
    token: params.token,
    params: {
      compareRangeFromStart: params.compareRangeFrom.start ?? undefined,
      compareRangeFromEnd: params.compareRangeFrom.end ?? undefined,
      companyId: params.companyId,
      storeId: params.storeId,
    },
  }).then(({data}) => data);
};

export const getSalesHistory = async (params: {
  token: string;
  compareRangeFrom: {start: string | null; end: string | null};
  groupBy: 'days' | 'hours';
  companyId: number;
  storeId?: number;
}) => {
  return callApi<Array<{label: string; total: number}>>(`/reports/sales-history`, {
    token: params.token,
    params: {
      compareRangeFromStart: params.compareRangeFrom.start ?? undefined,
      compareRangeFromEnd: params.compareRangeFrom.end ?? undefined,
      companyId: params.companyId,
      storeId: params.storeId,
      groupBy: params.groupBy,
    },
  }).then(({data}) => data);
};

export const getCustomerScansReport = async (params: {
  token: string;
  compareGroups: CompareGroups;
  companyId: number;
  storeId?: number;
}) => {
  return callApi<Array<{label: string; current: number; original: number}>>(`/reports/customer-scans`, {
    token: params.token,
    params: {
      compareRangeFromStart: params.compareGroups.compareGroupFrom.start ?? undefined,
      compareRangeFromEnd: params.compareGroups.compareGroupFrom.end ?? undefined,
      compareRangeToStart: params.compareGroups.compareGroupTo.start ?? undefined,
      compareRangeToEnd: params.compareGroups.compareGroupTo.end ?? undefined,
      companyId: params.companyId,
      storeId: params.storeId,
    },
  }).then(({data}) => data);
};

export type RevenueGroupReport = Array<{
  id: number;
  name: string;
  totalAmount: number;
  totalTax: number;
}>;

export const getRevenueGroupsReport = async (
  token: string,
  compareGroups: CompareGroups,
  companyId: number,
  storeId?: number
) => {
  return callApi<RevenueGroupReport>(`/reports/revenue-groups`, {
    token,
    params: {
      compareRangeFromStart: compareGroups.compareGroupFrom.start ?? undefined,
      compareRangeFromEnd: compareGroups.compareGroupFrom.end ?? undefined,
      companyId: companyId,
      storeId: storeId,
    },
  }).then(({data}) => data);
};
