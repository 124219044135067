import {QrExportTemplateName} from '../utils/types';
import {callApi} from './api';

export interface QrGenerateRequest {
  url: string;
  template: QrExportTemplateName;
  quantity: number;
  csv?: Array<{ATTRIBUTE: string; VALUE: string}>;
  image?: string;
}

// TODO: Refactor this endpoint so it invokes v3
export const generateQrsRequest = async (token: string, body: QrGenerateRequest) => {
  return callApi<{code: number; url: string}>('/qr/generate', {token, method: 'POST', body: JSON.stringify(body)}, true).then(
    ({data}) => data
  );
};
