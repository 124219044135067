import React, {useEffect, useState} from 'react';
import {Button, FormGroup, InputGroup, Switch} from '@blueprintjs/core';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {fetchStoreRequest, updateStoreRequest} from '../../../api/store.api';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';
import {StoreDto, StoreUpdateRequestDto} from '@kontactless/admin-api/store/store.dto';
import {useParams} from 'react-router-dom';

interface StoreUpdateForm {
  sendSmsReadyToGo: boolean;
  sendSmsCancelledByStore: boolean;
  sendSmsAfterPlaceOrder: boolean;
  sendSmsAfterOrderDelivered: boolean;
  orderReadyForPickUpMessage: string;
  orderReadyForDeliveryMessage: string;
}

export function SmsPage() {
  const {user} = useAuth();
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);
  const {
    state: {toaster},
  } = useToasts();

  const storeQuery = useQuery({queryKey: ['stores', storeId], queryFn: () => fetchStoreRequest(user.token, storeId)});

  const {
    register,
    handleSubmit,
    control,
    errors,
    formState: {isDirty},
    reset,
    watch,
  } = useForm<StoreUpdateForm>();
  const queryClient = useQueryClient();

  const [orderReadyForDeliveryMessagePreview, setOrderReadyForDeliveryMessagePreview] = useState<string>();
  const [orderReadyForPickupMessagePreview, setOrderReadyForPickupMessagePreview] = useState<string>();

  const storeUpdateMutation = useMutation<StoreDto, Error, StoreUpdateRequestDto>((update) =>
    updateStoreRequest(user.token, update, storeId)
  );

  const submitForm = async (form: StoreUpdateForm) => {
    try {
      const updatedStore = await storeUpdateMutation.mutateAsync({settings: {...form}});

      queryClient.setQueryData<StoreDto>(['stores', storeId], () => ({...storeQuery.data, ...updatedStore}));

      toaster.show({intent: 'success', message: 'Stores settings were updated successfully'});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred updating the store settings'});
    }
  };

  useEffect(() => {
    reset({
      sendSmsAfterPlaceOrder: !!storeQuery.data?.settings?.sendSmsAfterPlaceOrder,
      sendSmsCancelledByStore: !!storeQuery.data?.settings?.sendSmsCancelledByStore,
      sendSmsReadyToGo: !!storeQuery.data?.settings?.sendSmsReadyToGo,
      sendSmsAfterOrderDelivered: !!storeQuery.data?.settings?.sendSmsAfterOrderDelivered,
      orderReadyForDeliveryMessage: storeQuery.data?.settings?.orderReadyForDeliveryMessage,
      orderReadyForPickUpMessage: storeQuery.data?.settings?.orderReadyForPickUpMessage,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeQuery.data]);

  useEffect(() => {
    setOrderReadyForDeliveryMessagePreview(watch('orderReadyForDeliveryMessage')?.replace(/{order-number}/gi, '1234'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('orderReadyForDeliveryMessage')]);

  useEffect(() => {
    setOrderReadyForPickupMessagePreview(watch('orderReadyForPickUpMessage')?.replace(/{order-number}/gi, '1234'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('orderReadyForPickUpMessage')]);

  return (
    <div className="tw-min-w-[650px] tw-px-6">
      <header className="card-header tw-flex tw-justify-between tw-items-center">
        <h2 className="tw-text-zinc-700 tw-my-4 tw-text-xl tw-font-bold">SMS settings</h2>
        {isDirty ? (
          <div className="form-actions tw-flex tw-gap-2">
            <Button text="Cancel" disabled={storeUpdateMutation.isLoading} onClick={() => reset()} />
            <Button
              text="Save"
              type="submit"
              intent="primary"
              disabled={storeUpdateMutation.isLoading}
              loading={storeUpdateMutation.isLoading}
              onClick={handleSubmit(submitForm)}
            />
          </div>
        ) : null}
      </header>
      <div className="card-body form-body">
        {/* <div className="tw-my-4 tw-text-sm tw-font-normal">Messaging</div> */}

        <FormGroup
          className="tw-font-bold"
          intent={errors.sendSmsReadyToGo ? 'danger' : 'none'}
          helperText={errors.sendSmsReadyToGo ? 'There is an error' : ''}
        >
          <Controller
            name="sendSmsReadyToGo"
            control={control}
            defaultValue={!!storeQuery.data?.settings?.sendSmsReadyToGo}
            render={(props) => (
              <div className="tw-flex tw-w-full">
                <div className="tw-w-full tw-flex-col">
                  <div>
                    <div className="tw-text-sm tw-font-bold">Send SMS when the order is ready to be delivered</div>
                  </div>
                  <div className="tw-text-sm tw-font-normal tw-text-gray-400">
                    Let customer know order is ready to be picked up or will be delivered soon.
                  </div>
                </div>
                <div>
                  <Switch
                    className="tw-mt-4"
                    checked={props.value}
                    onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                    inputRef={register}
                    large
                    alignIndicator="left"
                  />
                </div>
              </div>
            )}
          />
        </FormGroup>

        {!!watch('sendSmsReadyToGo') && (
          <>
            <p className="tw-mt-2 tw-mb-1 tw-text-sm tw-text-gray-800 tw-ml-5">SMS customization</p>

            <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4 tw-mx-5">
              <div className="tw-bg-gray-100 tw-p-4 tw-rounded">
                <FormGroup
                  intent={errors.orderReadyForDeliveryMessage ? 'danger' : 'none'}
                  helperText={errors.orderReadyForDeliveryMessage ? 'This field is required' : ''}
                >
                  <p className="tw-mb-2 tw-text-sm tw-text-gray-900">Order Ready for Delivery SMS</p>
                  <Controller
                    name="orderReadyForDeliveryMessage"
                    control={control}
                    rules={{required: 'This field is required'}}
                    defaultValue={storeQuery.data?.settings?.orderReadyForDeliveryMessage}
                    render={(props) => (
                      <InputGroup className="tw-w-full tw-max-h-20" value={props.value} onChange={props.onChange} />
                    )}
                  />
                  <p className="tw-text-xs tw-text-gray-500">
                    Use {'{order-number}'} as a placeholder where you want the actual order number to appear
                  </p>
                </FormGroup>
                {orderReadyForDeliveryMessagePreview && (
                  <>
                    <p className="tw-mt-2 tw-mb-1 tw-text-xs tw-text-gray-700">SMS preview</p>
                    <div className="tw-bg-gray-200 tw-px-4 tw-py-2 tw-rounded tw-text-gray-700">
                      {orderReadyForDeliveryMessagePreview}
                    </div>
                  </>
                )}
              </div>

              <div className="tw-bg-gray-100 tw-p-4 tw-rounded">
                <FormGroup
                  intent={errors.orderReadyForPickUpMessage ? 'danger' : 'none'}
                  helperText={errors.orderReadyForPickUpMessage ? 'This field is required' : ''}
                >
                  <p className="tw-mb-2 tw-text-sm tw-text-gray-900">Order Ready for Pickup SMS </p>
                  <Controller
                    name="orderReadyForPickUpMessage"
                    control={control}
                    rules={{required: 'This field is required'}}
                    defaultValue={storeQuery.data?.settings?.orderReadyForPickUpMessage}
                    render={(props) => (
                      <InputGroup className="tw-w-full tw-max-h-20" value={props.value} onChange={props.onChange} />
                    )}
                  />
                  <p className="tw-text-xs tw-text-gray-500">
                    Use {'{order-number}'} as a placeholder where you want the actual order number to appear
                  </p>
                </FormGroup>
                {orderReadyForPickupMessagePreview && (
                  <>
                    <p className="tw-mt-2 tw-mb-1 tw-text-xs tw-text-gray-700">SMS preview</p>
                    <div className="tw-bg-gray-200 tw-px-4 tw-py-2 tw-rounded tw-text-gray-700">
                      {orderReadyForPickupMessagePreview}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <FormGroup
          className="tw-font-bold"
          intent={errors.sendSmsCancelledByStore ? 'danger' : 'none'}
          helperText={errors.sendSmsCancelledByStore ? 'There is an error' : ''}
        >
          <Controller
            name="sendSmsCancelledByStore"
            control={control}
            defaultValue={storeQuery.data?.settings?.sendSmsCancelledByStore}
            render={(props) => (
              <div className="tw-flex tw-w-full">
                <div className="tw-w-full tw-flex-col">
                  <div>
                    <div className="tw-text-sm tw-font-bold">Send SMS when an order was cancelled by the store</div>
                  </div>
                  <div className="tw-text-sm tw-font-normal tw-text-gray-400">Let guest know the order was cancelled.</div>
                </div>
                <div>
                  <Switch
                    className="tw-mt-4"
                    checked={props.value}
                    onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                    inputRef={register}
                    large
                    alignIndicator="left"
                  />
                </div>
              </div>
            )}
          />
        </FormGroup>

        <FormGroup
          className="tw-font-bold"
          intent={errors.sendSmsAfterPlaceOrder ? 'danger' : 'none'}
          helperText={errors.sendSmsAfterPlaceOrder ? 'There is an error' : ''}
        >
          <Controller
            name="sendSmsAfterPlaceOrder"
            control={control}
            defaultValue={storeQuery.data?.settings?.sendSmsAfterPlaceOrder}
            render={(props) => (
              <div className="tw-flex tw-w-full">
                <div className="tw-w-full tw-flex-col">
                  <div>
                    <div className="tw-text-sm tw-font-bold">Send SMS right after the order is placed</div>
                  </div>
                  <div className="tw-text-sm tw-font-normal tw-text-gray-400">
                    Let customer know order was received and provide link to access the order.
                  </div>
                </div>
                <div>
                  <Switch
                    className="tw-mt-4"
                    checked={props.value}
                    onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                    inputRef={register}
                    large
                    alignIndicator="left"
                  />
                </div>
              </div>
            )}
          />
        </FormGroup>

        <FormGroup
          className="tw-font-bold"
          intent={errors.sendSmsAfterOrderDelivered ? 'danger' : 'none'}
          helperText={errors.sendSmsAfterOrderDelivered ? 'There is an error' : ''}
        >
          <Controller
            name="sendSmsAfterOrderDelivered"
            control={control}
            defaultValue={storeQuery.data?.settings?.sendSmsAfterOrderDelivered}
            render={(props) => (
              <div className="tw-flex tw-w-full">
                <div className="tw-w-full tw-flex-col">
                  <div>
                    <div className="tw-text-sm tw-font-bold">Send SMS 30 minutes after the order is delivered</div>
                  </div>
                  <div className="tw-text-sm tw-font-normal tw-text-gray-400">Message will invite guest to leave feedback.</div>
                </div>
                <div>
                  <Switch
                    className="tw-mt-4"
                    checked={props.value}
                    onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                    inputRef={register}
                    large
                    alignIndicator="left"
                  />
                </div>
              </div>
            )}
          />
        </FormGroup>
      </div>
    </div>
  );
}
