import React, {useState} from 'react';

import {Button, IInputGroupProps2, InputGroup} from '@blueprintjs/core';

interface PasswordInputProps extends IInputGroupProps2 {
  defaultPasswordShow?: boolean;
  showPassword?: boolean;
  onShowPasswordChange?: (showPassword: boolean) => void;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  defaultPasswordShow,
  showPassword,
  onShowPasswordChange,
  ...inputGroupProps
}) => {
  const [showPasswordState, setShowPasswordState] = useState(defaultPasswordShow ?? false);

  const isPasswordShown = showPassword != null ? showPassword : showPasswordState;

  return (
    <InputGroup
      type={isPasswordShown ? 'text' : 'password'}
      rightElement={
        <Button
          tabIndex={-1}
          rightIcon={isPasswordShown ? 'eye-on' : 'eye-off'}
          minimal
          text={isPasswordShown ? 'Hide password' : 'Show password'}
          onClick={() => {
            if (showPassword != null) {
              onShowPasswordChange?.(!showPassword);
            } else {
              setShowPasswordState(!showPasswordState);
            }
          }}
        />
      }
      {...inputGroupProps}
    />
  );
};
