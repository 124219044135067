import {Button} from '@blueprintjs/core';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {TipsConfigurationDto} from '@kontactless/admin-api/tips-configuration/tips-configuration.dto';
import {useAuth} from '../../contexts/auth.context';
import {useMutation, useQueryClient} from 'react-query';
import {deleteTipsConfigurationRequest} from '../tip-configurations.api';
import {useToasts} from '../../contexts/toasts.context';
import {useAlerts} from '../../contexts/alerts.context';
import {TipConfigurationSummary} from './TipConfigurationSummary';

interface TipsCardProps {
  tipConfiguration: TipsConfigurationDto;
  store: StoreDto;
  onEdit(tipConfiguration: TipsConfigurationDto): void;
}

export function TipConfigurationCard({tipConfiguration, store, onEdit}: TipsCardProps) {
  const {user} = useAuth();
  const queryClient = useQueryClient();
  const {
    state: {toaster},
  } = useToasts();
  const {alertsDispatch} = useAlerts();

  const tipsConfigurationDeleteMutation = useMutation<{}, Error, number>({
    mutationKey: ['delete-tips-configuration'],
    mutationFn: (tipConfigurationId) => deleteTipsConfigurationRequest(user.token, tipConfigurationId),
  });

  const onDelete = async (tipConfigurationId: number) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: (
          <div className="tw-my-2 tw-flex tw-flex-col tw-gap-2">
            <h1 className="tw-font-bold tw-text-lg">Delete tip group?</h1>
            <p className="tw-text-gray-500">
              Ordering methods that use this tip configuration will be updated and will not have a tip configuration selected.
            </p>
          </div>
        ),
        intent: 'danger',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await tipsConfigurationDeleteMutation.mutateAsync(tipConfigurationId);
            toaster.show({intent: 'success', message: 'Tip group deleted successfully'});
            removeAlert();
            queryClient.invalidateQueries(['stores', store.id, 'tips-configurations']);
          } catch (error) {
            toaster.show({intent: 'danger', message: 'The tip group could not be deleted'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  return (
    <div key={tipConfiguration.id} className="tw-flex tw-items-center tw-gap-4">
      <div>
        <header>
          <h3 className="tw-text-gray-900 tw-text-xs tw-font-bold">{tipConfiguration.name}</h3>
          <p className="tw-text-gray-500 tw-text-xs tw-mb-2">
            {tipConfiguration.isDefaultTipOptionFixed
              ? `Tip will be fixed and customers won't be able to change it`
              : `Customer is able to choose the tip`}
          </p>
        </header>

        <TipConfigurationSummary tipConfiguration={tipConfiguration} />
      </div>
      <div className="tw-ml-auto tw-flex tw-items-center tw-gap-2">
        {/* Edit */}
        <Button minimal text="Edit" intent="primary" onClick={() => onEdit(tipConfiguration)} />
        {/* Delete */}
        <Button minimal icon="trash" intent="danger" onClick={() => onDelete(tipConfiguration.id)} />
      </div>
    </div>
  );
}
