import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {round} from 'lodash';

import {Button, NonIdealState, Toaster} from '@blueprintjs/core';
import {cloneDeep, sortBy} from 'lodash';
import {DragDropContext, Draggable, Droppable, OnDragEndResponder} from 'react-beautiful-dnd';
import {useMutation, useQueryClient} from 'react-query';

import {updateMenuCategoryProductRequest} from '../../../api/menu-category-product.api';
import {deleteMenuCategoryRequest, reorderMenuCategoryRequest} from '../../../api/menu-category.api';
import {OmnivoreCategoriesDrawer} from '../../../components/omnivore/omnivore-categories-drawer.component';
import {ProductUpsertDrawer} from '../../../components/product-upsert-drawer/product-upsert-drawer.component';
import {useAlerts} from '../../../contexts/alerts.context';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';

import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {MenuCategoryReorderResponseDto} from '@kontactless/admin-api/menu-category/menu-category.dto';
import {CategoryAccordion} from './CategoryAccordion';
import {CategoryProductsSelectedToast} from './CategoryProductsSelectedToast';
import {CategoryUpsertDialog} from './CategoryUpsertDialog';
import {MenuCategoryDto, MenuCategoryReorderRequestDto} from '@kontactless/admin-api/menu-category/menu-category.dto';
import {
  MenuCategoryProductDto,
  MenuCategoryProductUpdateRequestDto,
} from '@kontactless/admin-api/menu-category-product/menu-category-product.dto';
import {ProductDto} from '@kontactless/admin-api/product/product.dto';
import {MenuDto} from '@kontactless/admin-api/menu/menu.dto';
import {OmnivoreMenuItemDto} from '@kontactless/admin-api/omnivore-menu-item/omnivore-menu-item.dto';

export interface CategoriesListProps {
  store: StoreDto;
  menu: MenuDto;
}

export const CategoriesList: React.FC<CategoriesListProps> = ({store, menu}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {alertsDispatch} = useAlerts();
  const [selectedMenuCategory, setSelectedMenuCategory] = useState<MenuCategoryDto | undefined>();
  const [categoryToCreate, setCategoryToCreate] = useState<{order: number} | undefined>();
  const [selectedMenuCategoryProducts, setSelectedMenuCategoryProducts] = useState<MenuCategoryProductDto[]>([]);
  const [selectedMenuItem] = useState<OmnivoreMenuItemDto | undefined>();
  const [isProductDrawerOpen, setIsProductDrawerOpen] = useState(false);
  const [isMenuCategoryUpsertDialogOpen, setIsMenuCategoryUpsertDialogOpen] = useState(false);
  const [isOmnivoreCategoriesDrawerOpen, setIsOmnivoreCategoriesDrawerOpen] = useState(false);

  const reorderMenuCategoryMutation = useMutation<
    MenuCategoryReorderResponseDto,
    Error,
    {id: number} & MenuCategoryReorderRequestDto
  >((update) => reorderMenuCategoryRequest(user.token, update.id, update));
  const deleteMenuCategoryMutation = useMutation<void, Error, number>((menuCategoryId) =>
    deleteMenuCategoryRequest(user.token, menuCategoryId)
  );
  const updateMenuCategoryProductMutation = useMutation<any, Error, {id: number} & MenuCategoryProductUpdateRequestDto>(
    (update) =>
      updateMenuCategoryProductRequest(user.token, update.id, {
        menuCategoryId: update.menuCategoryId,
        productId: update.productId,
        order: update.order,
      })
  );
  const sortedCategories = useMemo(() => sortBy(menu.menuCategories ?? [], ({order}) => order), [menu]);
  const queryClient = useQueryClient();

  // Menu Categories --
  const onUpsertedCategory = (category: MenuCategoryDto) => {
    setSelectedMenuCategory(undefined);

    queryClient.setQueryData<MenuDto | undefined>(['menus', category.menuId], () => {
      const cachedMenu = queryClient.getQueryData<MenuDto>(['menus', category.menuId]);

      if (cachedMenu) {
        return {
          ...cachedMenu,
          menuCategories: cachedMenu
            .menuCategories!.filter(({id}) => id !== category.id)
            .map((cat) => ({
              ...cat,
              order: cat.order >= category.order ? cat.order + 1 : cat.order,
            }))
            .concat([{...category, order: category.order}]),
        };
      }
    });
  };

  const onRemoveCategory = async (category: MenuCategoryDto) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Delete category "${category.name}"?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteMenuCategoryMutation.mutateAsync(category.id);
            toaster.show({intent: 'success', message: `Category "${category.name}" successfully deleted`});

            queryClient.setQueryData<MenuDto | undefined>(['menus', category.menuId], () => {
              const cachedMenu = queryClient.getQueryData<MenuDto>(['menus', category.menuId]);

              if (cachedMenu) {
                return {
                  ...cachedMenu,
                  menuCategories: cachedMenu!
                    .menuCategories!.filter(({id}) => id !== category.id)
                    .map((cat) => ({
                      ...cat,
                      order: cat.order >= category.order ? cat.order - 1 : cat.order,
                    })),
                };
              }
            });
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred deleting the category'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  const onMenuCategoriesReordered = (menuCategoriesReorder: Array<{id: number; order: number}>) => {
    queryClient.setQueryData<MenuDto | undefined>(['menus', menu.id], () => {
      const cachedMenu = queryClient.getQueryData<MenuDto>(['menus', menu.id]);

      if (cachedMenu) {
        return {
          ...cachedMenu,
          menuCategories: cachedMenu.menuCategories?.map((category) => ({
            ...category,
            order: menuCategoriesReorder!.find(({id}) => id === category.id)?.order ?? category.order,
          })),
        };
      }
    });
  };

  // Menu Category Products --
  const onMenuCategoryProductsDeleted = async (menuId: number, menuCategoryProducts: MenuCategoryProductDto[]) => {
    try {
      setSelectedMenuCategoryProducts([]);

      const menuCategoryProductsIds = menuCategoryProducts.map(({id}) => id);

      queryClient.setQueryData<MenuDto | undefined>(['menus', menuId], () => {
        const cachedMenu = queryClient.getQueryData<MenuDto>(['menus', menuId]);

        if (cachedMenu) {
          const menuCategories = [...cachedMenu.menuCategories!];

          for (const menuCategory of menuCategories) {
            menuCategory.menuCategoryProducts = sortBy(
              menuCategory.menuCategoryProducts!.filter(({id}) => !menuCategoryProductsIds.includes(id)),
              ({order}) => order
            );

            for (const [order, menuCategoryProduct] of menuCategory.menuCategoryProducts.entries()) {
              menuCategoryProduct.order = order;
            }
          }

          return {...cachedMenu, menuCategories};
        }
      });
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred removing the products'});
    }
  };

  const onMenuCategoryProductSelected = (menuCategoryProduct: MenuCategoryProductDto) => {
    const selectedProduct = selectedMenuCategoryProducts.find(({id}) => id === menuCategoryProduct.id);

    setSelectedMenuCategoryProducts(
      selectedProduct
        ? selectedMenuCategoryProducts.filter(({id}) => id !== selectedProduct.id)
        : selectedMenuCategoryProducts.concat([menuCategoryProduct])
    );
  };

  const onMenuCategoryProductsReordered = (
    fromCategory: {id: number; menuCategoryProducts: MenuCategoryProductDto[]},
    toCategory?: {id: number; menuCategoryProducts: MenuCategoryProductDto[]}
  ) => {
    queryClient.setQueryData<MenuDto | undefined>(['menus', menu.id], () => {
      const cachedMenu = queryClient.getQueryData<MenuDto>(['menus', menu.id]);

      if (cachedMenu) {
        return {
          ...cachedMenu,
          menuCategories: cachedMenu.menuCategories?.map((category) => ({
            ...category,
            menuCategoryProducts:
              category.id === fromCategory.id
                ? fromCategory.menuCategoryProducts
                : category.id === toCategory?.id
                ? toCategory?.menuCategoryProducts
                : category.menuCategoryProducts,
          })),
        };
      }
    });
  };

  // Products --
  const onProductStateChange = (product: ProductDto, isEnabled: boolean) => {
    queryClient.setQueryData<MenuDto | undefined>(['menus', menu.id], () => {
      const cachedMenu = queryClient.getQueryData<MenuDto>(['menus', menu.id]);

      if (cachedMenu) {
        const menuCategories = [...cachedMenu.menuCategories!];

        for (const menuCategory of menuCategories) {
          for (const menuCategoryProduct of menuCategory.menuCategoryProducts!) {
            if (menuCategoryProduct.product?.id === product.id) {
              menuCategoryProduct.product.isEnabled = isEnabled;
            }
          }
        }

        return {...cachedMenu, menuCategories};
      }
    });
  };

  const createProductFromOmnivoreMenuItem = (menuItem: OmnivoreMenuItemDto): ProductDto => {
    return {
      name: menuItem.name,
      omnivoreMenuItemId: menuItem.menuItemId,
      price: round((menuItem.pricePerUnit ?? 0) / 100, 2),
      id: 0,
      calories: null,
      description: '',
      isEnabled: true,
      needsAgeVerification: false,
      stockCount: null,
      storeId: 0,
      tags: [],
      taxId: null,
      taxPercentage: 0,
      taxValue: 0,
      revenueGroupId: null,
      isLinked: false,
      isUpselling: false,
      modifierGroups: menuItem.modifierGroups?.map((omnivoreModifierGroup) => ({
        name: omnivoreModifierGroup.name,
        description: '',
        id: 0,
        minimumRequired: omnivoreModifierGroup.minimum,
        maximumAllowed: omnivoreModifierGroup.maximum,
        productId: 0,
        order: 0,
        omnivoreModifierGroupId: null,
        modifiers: omnivoreModifierGroup.modifiers?.map((omnivoreModifier, index) => ({
          name: omnivoreModifier.name,
          price: round((omnivoreModifier.pricePerUnit ?? 0) / 100, 2),
          id: 0,
          description: '',
          taxValue: 0,
          isEnabled: true,
          taxPercentage: 0,
          taxId: null,
          stockCount: null,
          modifierGroupId: 0,
          omnivoreModifierId: '',
          order: index,
        })),
      })),
    };
  };

  //  Drag & Drop Handler

  const onDragEnd: OnDragEndResponder = async (result) => {
    if (!result || !result.destination) {
      return;
    }

    if (result.type === 'category') {
      if (result.source.index === result.destination.index) {
        return;
      }

      // Make Optimistic update
      const menuBackup = cloneDeep(menu);
      const sortedCategoriesCopy = Array.from(sortedCategories);
      const [productCategory] = sortedCategoriesCopy.splice(result.source.index, 1);
      sortedCategoriesCopy.splice(result.destination.index, 0, productCategory);
      const categoriesReordered = sortedCategoriesCopy.map((category, idx) => ({id: category.id, order: idx}));

      onMenuCategoriesReordered(categoriesReordered);

      try {
        await reorderMenuCategoryMutation.mutateAsync({id: productCategory.id, newOrder: result.destination.index});
        toaster.show({intent: 'success', message: 'The order of the category was successfully updated'});
      } catch (error) {
        console.error(error);

        // Rollback optimistic update
        queryClient.setQueryData(['menus', menuBackup.id], () => menuBackup);
        toaster.show({intent: 'danger', message: 'Reorder failed'});
      }
    }

    if (result.type === 'product') {
      if (result.destination.droppableId === result.source.droppableId && result.source.index === result.destination.index) {
        return;
      }

      const update = {
        fromCategoryId: +result.source.droppableId.split('-').pop()!,
        toCategoryId: +result.destination.droppableId.split('-').pop()!,
        menuCategoryProductId: +result.draggableId.split('-').pop()!,
        order: result.destination.index,
      };

      // Make Optimistic update
      const menuBackup = cloneDeep(menu);
      const fromCategory = menu.menuCategories!.find(({id}) => id === update.fromCategoryId);
      const toCategory = menu.menuCategories!.find(({id}) => id === update.toCategoryId);
      const menuCategoryProduct = fromCategory?.menuCategoryProducts!.find(({id}) => id === update.menuCategoryProductId);

      if (!fromCategory || !toCategory || !menuCategoryProduct) {
        return;
      }

      if (fromCategory.id === toCategory.id) {
        const reorderedProducts = Array.from(sortBy(fromCategory.menuCategoryProducts!, ({order}) => order));
        const [product] = reorderedProducts.splice(result.source.index, 1);
        reorderedProducts.splice(result.destination.index, 0, product);

        onMenuCategoryProductsReordered({
          id: fromCategory.id,
          menuCategoryProducts: reorderedProducts.map((product, idx) => ({...product, order: idx})),
        });
      } else {
        // Check product does not exists in target category
        if (toCategory.menuCategoryProducts?.find(({productId}) => productId === menuCategoryProduct.productId)) {
          toaster.show({
            intent: 'danger',
            message: `"${menuCategoryProduct.product!.name}" is already present in "${toCategory.name}"`,
          });
          return;
        }

        const reorderedProducts = Array.from(sortBy(toCategory.menuCategoryProducts!, ({order}) => order));
        reorderedProducts.splice(result.destination.index, 0, menuCategoryProduct);
        onMenuCategoryProductsReordered(
          {
            id: fromCategory.id,
            menuCategoryProducts: fromCategory
              .menuCategoryProducts!.filter(({id}) => id !== menuCategoryProduct.id)
              .map((product, idx) => ({...product, order: idx})),
          },
          {id: toCategory.id, menuCategoryProducts: reorderedProducts.map((product, idx) => ({...product, order: idx}))}
        );
      }

      try {
        await updateMenuCategoryProductMutation.mutateAsync({
          id: update.menuCategoryProductId,
          menuCategoryId: update.toCategoryId,
          productId: menuCategoryProduct.productId,
          order: update.order,
        });
        toaster.show({
          intent: 'success',
          message: `The order of the product "${menuCategoryProduct.product!.name}" was successfully updated`,
        });
      } catch (error) {
        console.error(error);

        // Rollback optimistic update
        queryClient.setQueryData(['menus', menuBackup.id], () => menuBackup);
        toaster.show({intent: 'danger', message: `Reorder of ${menuCategoryProduct.product!.name} failed`});
      }
    }
  };

  const openProductDrawer = (menuCategory: MenuCategoryDto) => {
    setSelectedMenuCategory(menuCategory);

    if (menu.isPosMenu) {
      setIsOmnivoreCategoriesDrawerOpen(true);
    } else {
      setIsProductDrawerOpen(true);
    }
  };

  const closeProductDrawer = () => {
    setSelectedMenuCategory(undefined);
    setIsProductDrawerOpen(false);
  };

  const openMenuCategoryInsertDialog = (order: number) => {
    setCategoryToCreate({order});
    setIsMenuCategoryUpsertDialogOpen(true);
  };

  const openMenuCategoryUpdateDialog = (menuCategory: MenuCategoryDto) => {
    setSelectedMenuCategory(menuCategory);
    setIsMenuCategoryUpsertDialogOpen(true);
  };

  const closeMenuCategoryUpsertDialog = () => {
    setSelectedMenuCategory(undefined);
    setCategoryToCreate(undefined);
    setIsMenuCategoryUpsertDialogOpen(false);
  };

  const pricesIncludeTax = store.company?.country === 'CA';

  useEffect(() => {
    if (selectedMenuCategory) {
      setSelectedMenuCategory(menu.menuCategories?.find(({id}) => id === selectedMenuCategory.id));
    }
  }, [menu, selectedMenuCategory]);

  return (
    <div className="tw-flex-col tw-w-full">
      {sortedCategories.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="tw-mb-4">
            <AddCategoryLine onClick={() => openMenuCategoryInsertDialog(0)} />
          </div>

          <Droppable droppableId="drop-category" type="category">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="tw-space-y-4">
                {sortedCategories.map((category, index) => (
                  <Fragment key={`category-${category.id}`}>
                    <Draggable draggableId={`drag-category-${category.id}`} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <CategoryAccordion
                            menuCategory={category}
                            addedProducts={[]}
                            selectedMenuCategoryProducts={selectedMenuCategoryProducts}
                            onEditMenuCategoryClick={() => openMenuCategoryUpdateDialog(category)}
                            onRemoveMenuCategoryClick={() => onRemoveCategory(category)}
                            onProductStateChange={onProductStateChange}
                            onMenuCategoryProductSelected={onMenuCategoryProductSelected}
                            onAddProductClick={() => openProductDrawer(category)}
                            pricesIncludeTax={pricesIncludeTax}
                            menuUpsellingItemsCount={menu.upsellingItemsCount}
                          />
                        </div>
                      )}
                    </Draggable>
                    <AddCategoryLine onClick={() => openMenuCategoryInsertDialog(index + 1)} />
                  </Fragment>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <NonIdealState
          icon="settings"
          description="Looks like this menu has no categories yet."
          action={
            <Button intent="primary" text="Click here to create a Category" onClick={() => openMenuCategoryInsertDialog(0)} />
          }
        />
      )}
      <CategoryUpsertDialog
        isOpen={isMenuCategoryUpsertDialogOpen}
        menu={menu}
        category={selectedMenuCategory ?? (categoryToCreate as MenuCategoryDto)}
        onResolve={onUpsertedCategory}
        onClose={closeMenuCategoryUpsertDialog}
      />
      <ProductUpsertDrawer
        title={`Add new product to ${selectedMenuCategory?.name}`}
        isOpen={isProductDrawerOpen}
        onClose={closeProductDrawer}
        menuCategoryId={selectedMenuCategory?.id}
        product={selectedMenuItem ? createProductFromOmnivoreMenuItem(selectedMenuItem) : undefined}
        pricesIncludeTax={pricesIncludeTax}
        menuUpsellingItemsCount={menu.upsellingItemsCount}
      />
      {menu.isPosMenu ? (
        <OmnivoreCategoriesDrawer
          isOpen={isOmnivoreCategoriesDrawerOpen}
          storeId={menu.storeId}
          menuCategory={selectedMenuCategory}
          onClose={() => {
            setSelectedMenuCategory(undefined);
            setIsOmnivoreCategoriesDrawerOpen(false);
          }}
        />
      ) : null}
      <Toaster position="bottom">
        {selectedMenuCategoryProducts.length > 0 && !selectedMenuCategory ? (
          <CategoryProductsSelectedToast
            menuId={menu.id}
            menuCategoryProducts={selectedMenuCategoryProducts}
            onMenuCategoryProductsDeleted={onMenuCategoryProductsDeleted}
            onDismiss={() => setSelectedMenuCategoryProducts([])}
          />
        ) : null}
      </Toaster>
    </div>
  );
};

interface AddCategoryLineProps {
  onClick: () => void;
}

const AddCategoryLine: React.FC<AddCategoryLineProps> = ({onClick}) => {
  return (
    <div className="tw-w-full tw-flex tw-items-center tw-gap-4">
      <div className="tw-border-b tw-border-solid tw-h-px tw-flex-1 tw-border-gray-200" />
      <Button icon="add" small text="Add Category" minimal onClick={onClick} />
      <div className="tw-border-b tw-border-solid tw-h-px tw-flex-1 tw-border-gray-200" />
    </div>
  );
};
