import {useEffect} from 'react';

import {Button, Checkbox, Classes, Dialog, DialogProps, FormGroup, InputGroup} from '@blueprintjs/core';
import {yupResolver} from '@hookform/resolvers/yup';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import * as yup from 'yup';

import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {
  AdditionalChargeDto,
  CreateAdditionalChargeRequestDto,
  UpdateAdditionalChargeRequestDto,
} from '@kontactless/admin-api/additional-charge/additional-charge.dto';
import {createAdditionalChargeRequest, updateAdditionalChargeRequest} from '../../api/additional-charge.api';
import {AdditionalChargeType} from '../../utils/types';
import {TaxSelector} from '../../components/selectors/tax-selector.component';
import {AdditionalChargeTypeSelector} from '../../components/selectors/additional-charge-type-selector.component';
import {OmnivoreServiceChargeSelector} from '../../components/selectors/omnivore-service-charge-selector.component';

export interface AdditionalChargeUpsertDialogProps extends DialogProps {
  additionalCharge?: AdditionalChargeDto;
  storeId: number;
  isPosConnected: boolean;
  onAdditionalChargeUpserted?: (additionalCharge: AdditionalChargeDto) => void;
}

interface AdditionalChargeForm {
  name: string;
  type: AdditionalChargeType;
  value: number;
  taxId: number | null;
  omnivoreServiceChargeId?: string;
  isPosConnected?: boolean;
}

const formValidator = () =>
  yup.object().shape({
    name: yup.string().required(),
    type: yup.string().required(),
    value: yup.number().required(),
    taxId: yup.number().nullable(),
    isPosConnected: yup.boolean(),
    omnivoreServiceChargeId: yup.string(),
  });

export default function AdditionalChargeUpsertDialog({
  additionalCharge,
  storeId,
  isPosConnected,
  onAdditionalChargeUpserted,
  ...props
}: AdditionalChargeUpsertDialogProps) {
  const {user: authUser} = useAuth();
  const queryClient = useQueryClient();
  const {
    state: {toaster},
  } = useToasts();

  const {register, handleSubmit, control, errors, reset, watch} = useForm<AdditionalChargeForm>({
    resolver: yupResolver(formValidator()),
    mode: 'onChange',
  });

  const onSuccess = (additionalCharge: AdditionalChargeDto) => {
    queryClient.invalidateQueries(['stores', additionalCharge.storeId, 'additional-charges']);
    props.onClose?.(undefined as any);
    toaster.show({intent: 'success', message: 'Additional charge created successfully'});
    onAdditionalChargeUpserted?.(additionalCharge);
  };

  const additionalChargeCreateMutation = useMutation<AdditionalChargeDto, Error, CreateAdditionalChargeRequestDto>({
    mutationKey: ['create-additional-charge'],
    mutationFn: (payload) => createAdditionalChargeRequest(authUser.token, payload),
    onSuccess,
    onError: (error: any) => {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred creating the additional charge'});
    },
  });

  const additionalChargeUpdateMutation = useMutation<AdditionalChargeDto, Error, UpdateAdditionalChargeRequestDto>({
    mutationKey: ['update-additional-charge'],
    mutationFn: (payload) => updateAdditionalChargeRequest(authUser.token, additionalCharge!.id, payload),
    onSuccess,
    onError: (error: any) => {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred updating the additional charge'});
    },
  });

  const submitForm = async (form: AdditionalChargeForm) => {
    if (additionalCharge) {
      additionalChargeUpdateMutation.mutate({
        type: form.type,
        name: form.name,
        value: form.type === 'percentage' ? form.value / 100 : form.value,
        taxId: form.taxId,
        omnivoreServiceChargeId: form.isPosConnected ? form.omnivoreServiceChargeId : null,
      });
    } else {
      additionalChargeCreateMutation.mutate({
        type: form.type,
        name: form.name,
        value: form.type === 'percentage' ? form.value / 100 : form.value,
        taxId: form.taxId,
        omnivoreServiceChargeId: form.omnivoreServiceChargeId,
        storeId,
      });
    }
  };

  const isUpsertLoading = additionalChargeUpdateMutation.isLoading || additionalChargeCreateMutation.isLoading;

  useEffect(() => {
    reset({
      name: additionalCharge?.name,
      type: additionalCharge?.type,
      value: additionalCharge?.type === 'percentage' ? additionalCharge?.value * 100 : additionalCharge?.value,
      taxId: additionalCharge?.taxId,
      omnivoreServiceChargeId: additionalCharge?.omnivoreServiceChargeId || undefined,
      isPosConnected: !!additionalCharge?.omnivoreServiceChargeId,
    });
  }, [additionalCharge, reset]);

  return (
    <Dialog title={additionalCharge ? 'Edit Additional Charge' : 'New Additional Charge'} className="kl-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          {isPosConnected && (
            <Checkbox
              className="form-control"
              name="isPosConnected"
              onChange={() => reset({omnivoreServiceChargeId: undefined})}
              label="Select an additional charge from the POS"
              inputRef={register}
            />
          )}
          {isPosConnected && watch('isPosConnected') && (
            <FormGroup
              className="form-control"
              intent={errors.omnivoreServiceChargeId ? 'danger' : 'none'}
              helperText={errors.omnivoreServiceChargeId ? 'POS Service Charge is required' : ''}
            >
              <Controller
                name="omnivoreServiceChargeId"
                control={control}
                defaultValue={additionalCharge?.omnivoreServiceChargeId}
                rules={{required: true}}
                render={(props) => (
                  <OmnivoreServiceChargeSelector
                    storeId={storeId}
                    selectedItemId={props.value}
                    onItemSelected={(serviceCharge) => {
                      props.onChange(serviceCharge?.serviceChargeId);
                      if (serviceCharge) {
                        reset({
                          isPosConnected: true,
                          omnivoreServiceChargeId: serviceCharge.serviceChargeId,
                          type: serviceCharge.type === 'dollar' ? 'flat' : 'percentage',
                          value: serviceCharge.price / 100,
                        });
                      }
                    }}
                    inputProps={{intent: errors.omnivoreServiceChargeId ? 'danger' : 'none'}}
                    fill
                  />
                )}
              />
            </FormGroup>
          )}

          <FormGroup label="Name" intent={errors.name ? 'danger' : 'none'} helperText={errors.name ? 'Name is required' : ''}>
            <InputGroup name="name" intent={errors.name ? 'danger' : 'none'} inputRef={register} />
          </FormGroup>

          <FormGroup label="Type" intent={errors.type ? 'danger' : 'none'} helperText={errors.type ? 'Type is required' : ''}>
            <Controller
              name="type"
              control={control}
              defaultValue={additionalCharge?.type}
              render={(props) => (
                <AdditionalChargeTypeSelector
                  fill
                  selectedItem={props.value}
                  onItemSelect={props.onChange}
                  disabled={isUpsertLoading || watch('isPosConnected')}
                  buttonProps={{
                    className: errors.type ? 'danger-outline' : '',
                    disabled: isUpsertLoading || watch('isPosConnected'),
                  }}
                />
              )}
            />
          </FormGroup>

          <FormGroup label="Value" intent={errors.value ? 'danger' : 'none'} helperText={errors.value ? 'Value is required' : ''}>
            <InputGroup
              name="value"
              intent={errors.value ? 'danger' : 'none'}
              inputRef={register}
              disabled={isUpsertLoading}
              readOnly={watch('isPosConnected')}
            />
          </FormGroup>

          <FormGroup label="Tax" intent={errors.taxId ? 'danger' : 'none'} helperText={errors.taxId ? 'Tax is required' : ''}>
            <Controller
              name="taxId"
              control={control}
              defaultValue={additionalCharge?.taxId ?? null}
              render={(props) => (
                <TaxSelector
                  fill
                  storeId={Number(storeId)}
                  disabled={isUpsertLoading}
                  selectedItemId={props.value}
                  popoverProps={{usePortal: false}}
                  onItemSelected={(tax) => props.onChange(!!tax.id ? tax.id : null)}
                  inputProps={{intent: errors?.taxId ? 'danger' : 'none'}}
                />
              )}
            />
          </FormGroup>
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button text="Cancel" intent="danger" outlined disabled={isUpsertLoading} onClick={(ev) => props.onClose?.(ev)} />
        <Button
          text={additionalCharge ? 'Save changes' : '+ Create'}
          intent="primary"
          loading={isUpsertLoading}
          disabled={isUpsertLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
}
