import * as fileSaver from 'file-saver';
import queryString from 'query-string';

import {signOut} from '../utils/aws-cognito.utils';

export type QueryParams = Record<string, string | number | string[] | number[] | undefined | boolean>;

export interface ApiCallConfig extends RequestInit {
  token?: string;
  params?: QueryParams;
  isMultipart?: boolean;
}

export interface PaginationRequest {
  page: number;
  pageSize: number;
  offset: number;
}

export interface PaginationResponse {
  page: number;
  pageSize: number;
  totalRecords: number;
  totalPages: number;
}

export interface ApiError {
  response: Response;
  error: Error;
}

export interface ApiResponse<T> {
  data: T;
  error?: ApiError;
  pagination?: PaginationResponse;
}

export const getApiEndpoint = (endpoint: string, params: QueryParams = {}, isLegacy: boolean = false): string => {
  const host = process.env.REACT_APP_API_BASE_URL;
  const queryParams = Object.keys(params ?? {}).length > 0 ? `?${queryString.stringify(params)}` : '';
  return `${host}/api/${isLegacy ? '' : 'v3'}${endpoint}${queryParams}`;
};

export const callApi = async <T>(
  endpoint: string,
  {token, params, ...config}: ApiCallConfig,
  isLegacy: boolean = false
): Promise<ApiResponse<T>> => {
  return fetch(getApiEndpoint(endpoint, params, isLegacy), {
    ...config,
    mode: 'cors',
    headers: {
      ...(config.headers ?? null),
      ...(token ? {Authorization: `Bearer ${token}`} : null),
      ...(config.body && !config.isMultipart
        ? {'Content-Type': (config.headers as any)?.['Content-Type'] ?? `application/json`}
        : null),
    },
  }).then(async (response) => {
    if (response.ok) {
      if (response.headers.get('content-disposition')?.startsWith('attachment')) {
        const exec = /filename="(.*)"/gi.exec(response.headers.get('content-disposition')!);
        const fileName = exec?.[1] ?? 'download';
        fileSaver.saveAs(await response.blob(), fileName);
        return {data: null};
      }

      const responseJson = await response.json();

      if (isLegacy) {
        return {data: responseJson};
      }

      if (responseJson.data != null) {
        return {...responseJson, data: responseJson.data as T};
      }

      throw new Error('no_data_field_in_response');
    }
    const {error}: {error: Error} = await response.json().catch();

    if (error.message === 'authorization_token_invalid') {
      signOut();
      window.location.reload();
    }

    throw {response, error};
  });
};
