import React, {createRef, useMemo, useState} from 'react';

import {Button, IButtonProps, MenuItem} from '@blueprintjs/core';
import {ISuggestProps, Suggest} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';

import {fetchAllRevenueGroupsRequest} from '../../api/revenue-group.api';
import {useAuth} from '../../contexts/auth.context';
import {RevenueGroupDto} from '@kontactless/admin-api/revenue-group/revenue-group.dto';

import './selectors.styles.scss';

import {useCache} from '../../contexts/cache/cache.context';
import {RevenueGroupUpsertDialog} from '../revenue-groups/revenue-group-upsert-dialog.component';

interface RevenueGroupSelectorProps
  extends Omit<ISuggestProps<RevenueGroupDto>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  fill?: boolean;
  selectedItemId?: number;
  storeId: number;
  onItemSelected: (item: RevenueGroupDto | undefined) => void;
  buttonProps?: IButtonProps;
}

const RevenueGroupSelectorComponent = Suggest.ofType<RevenueGroupDto>();

export const RevenueGroupSelector: React.FC<RevenueGroupSelectorProps> = ({
  selectedItemId,
  onItemSelected,
  buttonProps,
  storeId,
  ...props
}) => {
  const {user} = useAuth();
  const {dispatchCacheAction} = useCache();
  const [isRevenueGroupUpsertDialogOpen, setIsRevenueGroupUpsertDialogOpen] = useState(false);
  const revenueGroupsQuery = useQuery(['revenue-groups', +storeId], () => fetchAllRevenueGroupsRequest(user.token, +storeId));
  const inputRef = createRef<any>();

  const items = useMemo(() => {
    const createRevenueGroupItem: RevenueGroupDto = {id: -1, name: 'Create Revenue Group', storeId};

    return (revenueGroupsQuery.data ?? []).concat([createRevenueGroupItem]);
  }, [storeId, revenueGroupsQuery]);

  const selectedItem = useMemo(() => {
    return items.find(({id}) => id === selectedItemId) ?? null;
  }, [items, selectedItemId]);

  return (
    <>
      <RevenueGroupSelectorComponent
        {...props}
        inputValueRenderer={(revenueGroup) => revenueGroup.name}
        className={classNames(props.className, {'bp3-fill': props.fill})}
        items={items}
        itemRenderer={(revenueGroup, {modifiers, handleClick}) => (
          <MenuItem
            active={selectedItemId === revenueGroup.id || (selectedItemId == null && revenueGroup.id === 0)}
            disabled={modifiers.disabled}
            key={revenueGroup.id}
            icon={revenueGroup.id === -1 ? 'add' : undefined}
            text={revenueGroup.name}
            onClick={(ev) => {
              if (revenueGroup.id === -1) {
                setIsRevenueGroupUpsertDialogOpen(true);
              } else {
                handleClick(ev);
              }
            }}
          />
        )}
        popoverProps={{minimal: true}}
        onItemSelect={(value) => onItemSelected(value)}
        itemsEqual={(c1, c2) => c1.id === c2.id}
        selectedItem={selectedItem ?? null}
        itemPredicate={(query, c1) => c1.name.toLocaleLowerCase().includes(query.toLowerCase())}
        noResults={<MenuItem disabled={true} text="No results." />}
        ref={inputRef}
        inputProps={{
          ...(props.inputProps ?? {}),
          leftElement: revenueGroupsQuery.isLoading ? <Button minimal loading /> : undefined,
          placeholder: 'Choose one...',
          rightElement: selectedItem ? (
            <Button icon="cross" minimal onClick={() => onItemSelected(undefined)} />
          ) : (
            <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />
          ),
        }}
      />
      <RevenueGroupUpsertDialog
        isOpen={isRevenueGroupUpsertDialogOpen}
        storeId={storeId}
        onClose={() => {
          setIsRevenueGroupUpsertDialogOpen(false);
        }}
        onResolve={(revenueGroup) => {
          dispatchCacheAction({type: 'add-revenue-group', revenueGroup});
          onItemSelected(revenueGroup);
        }}
      />
    </>
  );
};
