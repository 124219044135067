import React, {createRef} from 'react';

import {Button, Switch} from '@blueprintjs/core';
import {DateTime} from 'luxon';
import {useMutation, useQueryClient} from 'react-query';
import {deleteTimeframeRequest, updateTimeframeRequest} from '../../api/timeframe.api';
import {useAuth} from '../../contexts/auth.context';

import './timeframes.styles.scss';

import {useAlerts} from '../../contexts/alerts.context';
import {useToasts} from '../../contexts/toasts.context';

import {TimeframeDto, TimeframeUpdateRequestDto} from '@kontactless/admin-api/timeframe/timeframe.dto';
import {WeekListCheckbox} from './timeframe-week.component';
import {DayOfWeek} from '../../utils/types';
import {DAYS_OF_WEEK} from '../../utils/constants';

interface TimeframeRowProps {
  timeframe: TimeframeDto;
  size?: 'small' | 'large';
  showName?: boolean;
  onSwitch?: (timeframe: TimeframeDto) => void;
  onEdit?: (timeframe: TimeframeDto) => void;
  onDelete?: (timeframe: TimeframeDto) => void;
}

const convertTimeTo12hrs = (time: string): string => {
  return DateTime.fromFormat(time, 'HH:mm:ss').toFormat('h:mm a');
};

export function TimeframeRow({timeframe, size = 'large', showName = true, onEdit, onDelete, onSwitch}: TimeframeRowProps) {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const queryClient = useQueryClient();

  const {alertsDispatch} = useAlerts();
  const switchRef = createRef<HTMLInputElement>();
  const deleteTimeframeMutation = useMutation<void, Error, number>((timeframeId) =>
    deleteTimeframeRequest(user.token, timeframeId)
  );

  const updateTimeframeMutation = useMutation<TimeframeDto, Error, TimeframeUpdateRequestDto>((update) =>
    updateTimeframeRequest(user.token, update, timeframe.id)
  );

  const onSwitchClick = async (isEnabled: boolean) => {
    try {
      const updatedTimeframe = await updateTimeframeMutation.mutateAsync({...timeframe, isEnabled});
      onSwitch?.(updatedTimeframe);

      toaster.show({intent: 'success', message: 'Timeframe state was changed successfully'});
    } catch (error) {
      console.error(error);
      if (switchRef.current) {
        switchRef.current.checked = false;
      }
      toaster.show({intent: 'danger', message: 'An error ocurred changing the timeframe state'});
    }
  };

  const onDeleteClick = async () => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Delete timeframe "${timeframe.name}"?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteTimeframeMutation.mutateAsync(timeframe.id);
            onDelete?.(timeframe);

            queryClient.invalidateQueries(['stores', timeframe.storeId, 'discounts']);
            toaster.show({intent: 'success', message: 'Timeframe was deleted successfully'});
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred deleting the timeframe'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  return (
    <div className="tw-bg-white tw-py-4 first:tw-pt-0 tw-w-full last:tw-pb-0">
      {showName && <div className="tw-flex tw-w-full tw-items-center tw-text-sm tw-font-bold tw-h-6">{timeframe.name}</div>}
      <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
        <div>
          <div className="tw-flex tw-mb-1.5 tw-text-xs tw-items-start tw-justify-start">
            From {convertTimeTo12hrs(timeframe.startTime)} to {convertTimeTo12hrs(timeframe.endTime)}
          </div>

          <div className="tw-flex tw-flex-col tw-items-start tw-justify-start">
            <div className="tw-text-xs">
              <WeekListCheckbox
                readonly
                values={timeframe.daysOfWeek?.length ? timeframe.daysOfWeek : ((DAYS_OF_WEEK as unknown) as DayOfWeek[])}
              />
            </div>
          </div>
        </div>
        <div className="tw-ml-auto tw-flex tw-items-center tw-gap-1">
          <Button onClick={() => onEdit?.(timeframe)} minimal intent="primary" text="Edit" />
          <Switch
            defaultChecked={timeframe.isEnabled}
            disabled={deleteTimeframeMutation.isLoading || updateTimeframeMutation.isLoading}
            onChange={({currentTarget: {checked}}) => onSwitchClick(checked)}
            inputRef={switchRef}
          />
          <Button onClick={onDeleteClick} minimal intent="danger" title="Delete" icon="trash" />
        </div>
      </div>
    </div>
  );
}
