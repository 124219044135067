import {useAuth} from '../../contexts/auth.context';
import {Button, Callout, NonIdealState} from '@blueprintjs/core';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';

import {getApiKeysRequest} from '../../api/api-key.api';

import {useState} from 'react';
import {ApiKeyCreatedDialog} from '../components/ApiKeyCreatedDialog';
import {ApiKeyListItem} from '../components/ApiKeyListItem';

import {CreateApiKeyDialog} from '../components/CreateApiKeyDialog';

export function ApiKeysPage() {
  const {user} = useAuth();
  const params = useParams<{companyId: string}>();
  const companyId = Number(params.companyId);

  const [isApiKeyCreatedDialogOpen, setIsApiKeyCreatedDialogOpen] = useState(false);
  const [isCreateApiKeyDialogOpen, setIsCreateApiKeyDialogOpen] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const apiKeysQuery = useQuery({
    queryKey: ['api-keys', companyId],
    queryFn: () => getApiKeysRequest(user.token, companyId),
  });

  const apiKeys = apiKeysQuery.data;

  if (apiKeysQuery.isLoading) {
    return null;
  }

  return (
    <>
      <div className="tw-flex tw-flex-col tw-m-auto tw-max-w-2xl tw-mt-8 tw-space-y-6">
        <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
          <h1 className="tw-text-gray-900 tw-text-lg tw-font-bold">Kontactless API</h1>
        </div>

        <div className="tw-bg-white tw-shadow tw-rounded tw-p-4">
          <h2 className="tw-text-gray-900 tw-text-base tw-font-bold tw-mb-2">Base URL</h2>

          <p className="tw-text-sm">
            The following is the base URL for the Kontactless API. Refer to the{' '}
            <a
              href="https://listo-docs.notion.site/Kontactless-API-Specification-b8729608afb3431bbf3b8f7e785bed86"
              target="_blank"
              rel="noopener noreferrer"
              className="tw-text-blue-500"
            >
              API specification
            </a>{' '}
            for more information on available endpoints and how to use them.
          </p>

          <Callout icon={null} intent="primary" className="tw-mt-4">
            <p>{process.env.REACT_APP_KONTACTLESS_API_BASE_URL}</p>
          </Callout>
        </div>

        <div className="tw-bg-white tw-shadow tw-rounded tw-p-4">
          <div className="tw-flex tw-items-center tw-justify-between tw-mb-6">
            <h2 className="tw-text-gray-900 tw-text-base tw-font-bold">API keys</h2>
            {!!apiKeys?.length && (
              <Button intent="primary" onClick={() => setIsCreateApiKeyDialogOpen(true)} icon="plus">
                Create API key
              </Button>
            )}
          </div>
          {!apiKeys?.length ? (
            <NonIdealState
              icon="settings"
              title="No API key created yet"
              action={
                <Button intent="primary" onClick={() => setIsCreateApiKeyDialogOpen(true)} icon="plus">
                  Create API key
                </Button>
              }
            />
          ) : (
            <div>
              <div className="tw-flex tw-items-center tw-border-b tw-border-solid tw-pb-2 tw-border-gray-200">
                <div className="tw-font-bold tw-w-1/2">Name</div>

                <div className="tw-font-bold tw-w-1/4">Created at</div>
              </div>

              <ul className="tw-mt-2 tw-flex tw-flex-col tw-gap-2">
                {apiKeys?.map((apiKey) => (
                  <ApiKeyListItem key={apiKey.id} companyId={companyId} apiKey={apiKey} />
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <CreateApiKeyDialog
        companyId={companyId}
        isOpen={isCreateApiKeyDialogOpen}
        onApiKeyCreated={(apiKey) => {
          setIsCreateApiKeyDialogOpen(false);
          setIsApiKeyCreatedDialogOpen(true);
          setApiKey(apiKey);
        }}
        onClose={() => setIsCreateApiKeyDialogOpen(false)}
      />
      <ApiKeyCreatedDialog
        isOpen={isApiKeyCreatedDialogOpen}
        apiKey={apiKey}
        onClose={() => setIsApiKeyCreatedDialogOpen(false)}
      />
    </>
  );
}
