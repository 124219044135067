import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';

import {CognitoUser} from 'amazon-cognito-identity-js';

import * as awsCognito from '../utils/aws-cognito.utils';
import {SecurityLevel} from '../utils/types';
import {fetchUserRequest} from '../api/user.api';
import {useQuery} from 'react-query';
import {UserDto} from '@kontactless/admin-api/user/user.dto';
import {CompanyDto} from '@kontactless/admin-api/company/company.dto';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';
export interface UserClaims {
  userId: string;
  username: string;
  name: string;
  email: string;
  token: string;
  companies: CompanyDto[];
  stores: StoreDto[];
  securityLevel: SecurityLevel;
  receiveOrderCreatedEmail: boolean;
  receiveFeedbackEmails: boolean;
  receiveOrderCancelledEmail: boolean;
}

export interface AuthContextState {
  user: UserClaims;
  isLoadingUser: boolean;
  errorLoadingUser: boolean;
  signIn: (username: string, password: string, newPassword?: string) => Promise<awsCognito.SignInResult>;
  signOut: () => void;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  confirmPassword: (verificationCode: string, newPassword: string) => Promise<void>;
  forgotPassword: (
    username: string
  ) => Promise<{
    attribute: string;
    deliveryMedium: string;
    destination: string;
  }>;
}

const AuthContext = createContext<AuthContextState>({} as any);
AuthContext.displayName = 'AuthContext';

export const AuthProvider: React.FC = (props) => {
  const currentCognitoUser = awsCognito.getCurrentUser();
  const [token, setToken] = useState<string | null>(null);

  const currentSessionQuery = useQuery({
    queryKey: ['session'],
    queryFn: () => awsCognito.refreshSession(currentCognitoUser!),
    onSuccess: (data) => {
      setToken(data.getIdToken().getJwtToken());
    },
    onError: (error) => {
      console.error(error);
      signOut();
    },
    enabled: !!currentCognitoUser,
    staleTime: 1000 * 60 * 20,
    refetchInterval: 1000 * 60 * 20,
  });

  const currentUserQuery = useQuery({
    queryKey: ['current-user', token],
    queryFn: () => fetchUserRequest(token!),
    onError: (error) => {
      console.error(error);
      signOut();
    },
    enabled: !!token,
    staleTime: 1000 * 60 * 20,
  });

  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>();

  const signIn = useCallback(
    async (username: string, password: string, newPassword?: string): Promise<{result: 'success' | 'new-password-required'}> => {
      try {
        if (newPassword) {
          const session = await awsCognito.completeNewPasswordChallenge(cognitoUser!, newPassword);
          setToken(session?.getIdToken().getJwtToken() ?? '');
          return {result: 'success'};
        } else {
          const cognitoUser = awsCognito.createCognitoUser({Username: username, Password: password});
          setCognitoUser(cognitoUser);
          const {result, session} = await awsCognito.signIn(cognitoUser, username, password);
          setToken(session?.getIdToken().getJwtToken() ?? '');
          return {result};
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cognitoUser]
  );

  const signOut = useCallback(() => {
    awsCognito.signOut(cognitoUser);
    setCognitoUser(undefined);
    setToken(null);
    currentUserQuery.remove();
    currentSessionQuery.remove();
  }, [cognitoUser]);

  const changePassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      try {
        await awsCognito.changeUserPassword(cognitoUser!, oldPassword, newPassword);
      } catch (error: any) {
        if (!['wrong_old_password', 'invalid_new_password', 'limit_attemps_reached'].includes(error.message)) {
          console.error(error);
        }
        throw error;
      }
    },
    [cognitoUser]
  );

  const forgotPassword = useCallback(async (username: string): Promise<{
    attribute: string;
    deliveryMedium: string;
    destination: string;
  }> => {
    try {
      const cognitoUser = awsCognito.createCognitoUser({Username: username});
      const {
        AttributeName: attribute,
        DeliveryMedium: deliveryMedium,
        Destination: destination,
      } = await awsCognito.forgotPassword(cognitoUser!);

      setCognitoUser(cognitoUser);

      return {attribute, deliveryMedium, destination};
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const confirmPassword = useCallback(
    async (verificationCode: string, newPassword: string): Promise<void> => {
      try {
        await awsCognito.confirmPassword(cognitoUser!, verificationCode, newPassword);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [cognitoUser]
  );

  const value: AuthContextState = useMemo(
    () => ({
      user: getUserClaims(currentUserQuery.data!, token!),
      isLoadingUser: currentUserQuery.isLoading || currentSessionQuery.isLoading,
      errorLoadingUser: currentUserQuery.isError || currentSessionQuery.isError,
      signIn,
      signOut,
      changePassword,
      forgotPassword,
      confirmPassword,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUserQuery, signIn, signOut, changePassword, forgotPassword, confirmPassword]
  );

  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an  AuthProvider`);
  }
  return context;
};

function getUserClaims(user: UserDto, token: string): UserClaims {
  return {
    userId: user?.id.toString(),
    username: user?.username,
    name: user?.fullName,
    email: user?.email,
    companies: user?.companies ?? [],
    stores: user?.stores ?? [],
    securityLevel: user?.securityLevel,
    token: token,
    receiveOrderCreatedEmail: !!user?.receiveOrderCreatedEmail,
    receiveOrderCancelledEmail: !!user?.receiveOrderCancelledEmail,
    receiveFeedbackEmails: !!user?.receiveFeedbackEmails,
  };
}
