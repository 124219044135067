import {callApi} from './api';
import {PrintJobDto, PrintJobUpdateRequestDto} from '@kontactless/admin-api/print-job/print-job.dto';

export const fetchAllPrintJobs = async (token: string, storeId: number): Promise<PrintJobDto[]> => {
  return callApi<PrintJobDto[]>(`/print-jobs`, {token, method: 'GET', params: {storeId}}).then(({data}) => data);
};

export const updatePrintJobRequest = async (
  token: string,
  printJob: PrintJobUpdateRequestDto,
  printJobId: number
): Promise<PrintJobDto> => {
  return callApi<PrintJobDto>(`/print-jobs/${printJobId}`, {token, method: 'PATCH', body: JSON.stringify(printJob)}).then(
    ({data}) => data
  );
};

export const deletePrintJobRequest = async (token: string, printerId: number): Promise<PrintJobDto> => {
  return callApi<PrintJobDto>(`/print-jobs/${printerId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
