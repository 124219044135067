import {Alignment, Button, Callout, InputGroup, Spinner, Switch, Tag} from '@blueprintjs/core';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchSectionRequest, updateSectionRequest} from '../../api/section.api';
import {useAuth} from '../../contexts/auth.context';
import {FormGroup} from '@blueprintjs/core';
import {useMutation, useQueryClient} from 'react-query';
import {useToasts} from '../../contexts/toasts.context';
import {DiscountDto} from '@kontactless/admin-api/discount/discount.dto';
import {Controller, useForm} from 'react-hook-form';
import {MenuDto} from '@kontactless/admin-api/menu/menu.dto';
import {OrderingFlowType} from '../../utils/types';
import {SectionDto, SectionUpsertRequestDto} from '@kontactless/admin-api/section/section.dto';
import {MenusSelector} from '../../components/selectors/menus-selector.component';
import {NumberWrapper} from '../../components/ui/number-wrapper';
import {AdditionalChargesSelect} from '../components/ordering-methods/AdditionalChargesSelect';
import {PromotionsSelect} from '../components/ordering-methods/PromotionsSelect';
import {TimeframeUpsertDialog} from '../../components/timeframes/timeframe-upsert-dialog.component';
import {TimeframeDto} from '@kontactless/admin-api/timeframe/timeframe.dto';
import classNames from 'classnames';

import {Link} from 'react-router-dom';
import {AdditionalChargeDto} from '@kontactless/admin-api/additional-charge/additional-charge.dto';
import {Locations} from '../components/sections/Locations';
import {OrderingFlowTypeSelector} from '../../components/selectors/ordering-flow-type-selection.component';
import {fetchStoreRequest} from '../../api/store.api';
import {TimeframeRow} from '../../components/timeframes/TimeframeRow';
import {Container} from '../../common/components/layout/Container';
import {Header} from '../../common/components/layout/Header';
import {OrdersFooter} from '../../common/components/layout/OrdersFooter';
import {OmnivoreRevenueCenterSelector} from '../../components/selectors/omnivore-revenue-center-selector.component';
import {fetchMenusRequest} from '../../api/menu.api';
import {ImageUploader} from '../../components/images/image-uploader.component';
import Restricted from '../../common/components/Restricted';
import {TipSelector} from '../../tips/components/TipsSelector';

interface SectionForm {
  name?: string;
  menus?: MenuDto[];
  discounts?: DiscountDto[];
  orderingFlowType: OrderingFlowType;
  isViewOnly: boolean;
  areSchedulableOrdersAllowed?: boolean;
  minutesToShowScheduledOrders: number;
  freeItemsLimit?: string | null;
  omnivoreRevenueCenterId?: string | null;
  imageUrl?: string[];
  tipsConfigurationId: number | null;
  areOptionalTipsEnabled: boolean;
  areAdditionalChargesEnabled: boolean;
  arePromotionsEnabled: boolean;
  additionalCharges: AdditionalChargeDto[];
}

export function OrderingMethodsDetailsPage() {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams<{storeId?: string; companyId: string; orderingMethodId: string}>();
  const orderingMethodId = Number(params.orderingMethodId);
  const storeId = Number(params.storeId);

  const [isTimeframeDialogOpen, setIsTimeframeDialogOpen] = useState(false);
  const [timeframeEditing, setTimeframeEditing] = useState<TimeframeDto | undefined>();

  const {
    register,
    handleSubmit,
    control,
    errors,
    reset,
    watch,
    setValue,
    formState: {},
  } = useForm<SectionForm>();

  /* Queries */
  const sectionQuery = useQuery({
    queryKey: ['section', orderingMethodId],
    queryFn: () => fetchSectionRequest(user.token, orderingMethodId),
    enabled: !!storeId,
  });

  const storeQuery = useQuery({
    queryKey: ['stores', storeId],
    queryFn: () => fetchStoreRequest(user.token, storeId),
    enabled: !!storeId,
  });

  const menusQuery = useQuery({
    queryKey: ['stores', storeId, 'menus'],
    queryFn: () => fetchMenusRequest(user.token, storeId),
    enabled: !!storeId,
  });

  /* Mutations */
  const sectionUpdateMutation = useMutation<SectionDto, Error, SectionUpsertRequestDto>((update) =>
    updateSectionRequest(user.token, update, orderingMethodId)
  );

  const isViewOnly = Boolean(sectionQuery.data?.isViewOnly);

  useEffect(() => {
    reset(
      {
        name: sectionQuery.data?.name,
        freeItemsLimit: sectionQuery.data?.freeItemsLimit?.toString(),
        orderingFlowType: sectionQuery.data?.orderingFlowType,
        areSchedulableOrdersAllowed: sectionQuery.data?.areSchedulableOrdersAllowed,
        minutesToShowScheduledOrders: sectionQuery.data?.minutesToShowScheduledOrders,
        omnivoreRevenueCenterId: sectionQuery.data?.omnivoreRevenueCenterId,
        menus: sectionQuery.data?.menus ?? [],
        discounts: sectionQuery.data?.discounts ?? [],
        imageUrl: sectionQuery.data?.imageUrl ? [sectionQuery.data?.imageUrl] : [],
        tipsConfigurationId: sectionQuery.data?.tipsConfigurationId ?? null,
        areOptionalTipsEnabled: sectionQuery.data?.areOptionalTipsEnabled,
        areAdditionalChargesEnabled: sectionQuery.data?.areAdditionalChargesEnabled,
        additionalCharges: sectionQuery.data?.additionalCharges,
        arePromotionsEnabled: sectionQuery.data?.arePromotionsEnabled,
      },
      {dirtyFields: false}
    );
  }, [sectionQuery.data, reset]);

  const isPosSection = !!storeQuery.data?.settings?.posId;

  const submitForm = async (form: SectionForm) => {
    try {
      await sectionUpdateMutation.mutateAsync({
        name: form.name,
        freeItemsLimit: form.freeItemsLimit ? +form.freeItemsLimit : null,
        orderingFlowType: form.orderingFlowType,
        isViewOnly: form.isViewOnly,
        areSchedulableOrdersAllowed: form.areSchedulableOrdersAllowed,
        minutesToShowScheduledOrders: +(form.minutesToShowScheduledOrders || sectionQuery.data?.minutesToShowScheduledOrders!),
        isPosConnected: form.menus?.some(({isPosMenu}) => isPosMenu),
        omnivoreRevenueCenterId: form.omnivoreRevenueCenterId ?? null,
        menus: form.menus,
        discounts: form.discounts,
        additionalCharges: form.additionalCharges,
        imageUrl: form.imageUrl?.length ? form.imageUrl[0] : null,
        tipsConfigurationId: form.tipsConfigurationId ?? undefined,
        areOptionalTipsEnabled: form.areOptionalTipsEnabled,
        areAdditionalChargesEnabled: form.areAdditionalChargesEnabled,
        arePromotionsEnabled: form.arePromotionsEnabled,
      });

      queryClient.invalidateQueries(['section', orderingMethodId]);

      toaster.show({intent: 'success', message: 'Ordering method updated successfully'});
    } catch (error) {
      console.log(error);
      toaster.show({intent: 'danger', message: 'An error ocurred updating the section'});
    }
  };

  if (storeQuery.isLoading) {
    return <Spinner />;
  }

  if (sectionQuery.isLoading) {
    return (
      <div className="tw-flex tw-w-full tw-justify-center tw-pt-10">
        <Spinner intent="primary" />
      </div>
    );
  }

  return (
    <>
      {sectionQuery.data && (
        <>
          <Header>
            <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-gap-4 tw-items-center">
                <Button
                  text="Back"
                  icon="chevron-left"
                  minimal
                  small
                  className="!tw-bg-blue-100 !tw-rounded-lg"
                  disabled={sectionUpdateMutation.isLoading}
                  onClick={() => navigate('../ordering-methods')}
                />
                <h1 className="tw-text-base tw-font-bold">Ordering method details</h1>
              </div>

              <div className="tw-flex tw-gap-4 tw-items-center">
                <Restricted to="superadmin">
                  <Controller
                    name="isViewOnly"
                    defaultValue={isViewOnly}
                    control={control}
                    render={(props) => (
                      <Switch
                        label="View Only"
                        alignIndicator={Alignment.RIGHT}
                        name="isViewOnly"
                        checked={props.value ?? isViewOnly}
                        onChange={({currentTarget: {checked}}) => props.onChange(checked)}
                      />
                    )}
                  />
                </Restricted>
                <Button
                  text="Cancel"
                  outlined
                  disabled={sectionUpdateMutation.isLoading}
                  onClick={() => navigate('../ordering-methods')}
                />
                <Button
                  text="Save changes"
                  type="submit"
                  intent="primary"
                  disabled={sectionUpdateMutation.isLoading}
                  loading={sectionUpdateMutation.isLoading}
                  onClick={handleSubmit(submitForm)}
                />
              </div>
            </div>
          </Header>
          <Container>
            <div className="tw-flex tw-gap-4">
              <div className="tw-w-1/2 tw-flex tw-flex-col">
                <NumberWrapper>
                  {/* Name & Logo */}
                  <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full tw-bg-white tw-rounded-md tw-shadow tw-p-4 tw-mb-4">
                    <FormGroup
                      label="Name"
                      intent={errors.name ? 'danger' : 'none'}
                      helperText={errors.name ? 'Name is required' : ''}
                      className="!tw-mb-0"
                    >
                      <InputGroup
                        name="name"
                        intent={errors.name ? 'danger' : 'none'}
                        inputRef={register({validate: {required: (value: string) => value.trim() !== ''}})}
                      />
                    </FormGroup>
                    <FormGroup label="Logo" className="!tw-mb-0">
                      <Controller
                        name="imageUrl"
                        defaultValue={[]}
                        control={control}
                        render={(props) => <ImageUploader value={props.value} onChange={props.onChange} maxNumber={1} />}
                      />
                    </FormGroup>
                  </div>

                  {/* Menus */}
                  <div className="tw-flex tw-w-full tw-bg-white tw-rounded-md tw-shadow tw-p-4 tw-mb-4">
                    <FormGroup label="Menus" className="!tw-mb-0 tw-w-full">
                      <Controller
                        name="menus"
                        control={control}
                        defaultValue={[]}
                        render={(props) => (
                          <MenusSelector
                            items={menusQuery.data ?? []}
                            selectedMenusIds={props.value?.map(({id}) => id) ?? []}
                            disabledType={
                              props.value?.length > 0
                                ? props.value.some(({isPosMenu}) => isPosMenu)
                                  ? 'regular-menus'
                                  : 'pos-menus'
                                : undefined
                            }
                            onItemSelect={(menu) => {
                              if (props.value.map(({id}) => id).includes(menu.id)) {
                                props.onChange([...props.value.filter(({id}) => id !== menu.id)]);
                              } else {
                                props.onChange([...props.value, menu]);
                              }
                            }}
                            onRemove={(menu) => {
                              props.onChange([...props.value.filter(({id}) => id !== menu.id)]);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </div>

                  {/* Opening Hours */}
                  {!isViewOnly && (
                    <div className="tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-md tw-shadow tw-p-4 tw-mb-4 tw-transition-all">
                      <p className="tw-mb-4 tw-font-semibold tw-text-zinc-800 tw-text-sm">Ordering Hours</p>
                      {!sectionQuery.data?.openingHours?.length && (
                        <Callout icon="info-sign">There are no ordering hours set for this store</Callout>
                      )}
                      <div className="tw-flex tw-flex-wrap tw-w-full tw-divide-y tw-divide-solid tw-divide-gray-200">
                        {sectionQuery.data.openingHours?.map((timeframe) => (
                          <TimeframeRow
                            key={timeframe.id}
                            timeframe={timeframe}
                            showName={false}
                            size="small"
                            onDelete={() => queryClient.invalidateQueries(['section', orderingMethodId])}
                            onEdit={() => {
                              setTimeframeEditing(timeframe);
                              setIsTimeframeDialogOpen(true);
                            }}
                          />
                        ))}
                      </div>
                      <div className="tw-mt-4">
                        <Button
                          text="Add ordering hours"
                          intent="primary"
                          icon="plus"
                          minimal
                          onClick={() => setIsTimeframeDialogOpen(true)}
                          className="tw-mt-2 tw-flex tw-w-fit"
                        />
                      </div>
                    </div>
                  )}

                  {/* Schedule Orders */}
                  {!isViewOnly && (
                    <div
                      className={classNames(
                        'tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-md tw-shadow tw-p-4 tw-mb-4 tw-transition-all tw-gap-4',
                        {
                          'tw-opacity-75 tw-bg-gray-200 tw-shadow-none': !watch('areSchedulableOrdersAllowed'),
                        }
                      )}
                    >
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <div>
                          <p className="tw-font-semibold tw-text-zinc-800 tw-text-sm">Schedule orders</p>
                          <p className="tw-text-gray-500 tw-text-xs">
                            With this option enabled, customers will be able to set a date and time for their orders to be ready
                            for pickup or delivered.
                          </p>
                        </div>
                        <Switch name="areSchedulableOrdersAllowed" inputRef={register} />
                      </div>
                      <FormGroup
                        className="!tw-mb-0"
                        label="Time ahead to show scheduled orders"
                        intent={errors.minutesToShowScheduledOrders ? 'danger' : 'none'}
                        disabled={!watch('areSchedulableOrdersAllowed')}
                        helperText={errors.minutesToShowScheduledOrders ? 'Time ahead is required' : ''}
                      >
                        <InputGroup
                          name="minutesToShowScheduledOrders"
                          type="number"
                          disabled={!watch('areSchedulableOrdersAllowed')}
                          intent={errors.minutesToShowScheduledOrders ? 'danger' : 'none'}
                          rightElement={<Tag minimal>minutes</Tag>}
                          inputRef={register({required: true})}
                        />
                      </FormGroup>
                    </div>
                  )}

                  {/* Delivery methods */}
                  <div className="tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-md tw-shadow tw-p-4 tw-mb-4 tw-transition-all tw-gap-4">
                    {isViewOnly && <p className="tw-font-semibold tw-text-zinc-800 tw-text-sm">Locations</p>}
                    {!isViewOnly && (
                      <FormGroup label="Delivery methods" className="!tw-mb-0">
                        <Controller
                          name="orderingFlowType"
                          defaultValue={sectionQuery.data.orderingFlowType}
                          control={control}
                          render={(props) => (
                            <OrderingFlowTypeSelector
                              fill
                              value={props.value}
                              user={user}
                              onOrderingFlowTypeSelected={props.onChange}
                            />
                          )}
                        />
                      </FormGroup>
                    )}
                    <Locations store={storeQuery.data!} section={sectionQuery.data} />
                  </div>
                </NumberWrapper>
              </div>

              <div className="tw-w-1/2 tw-space-y-4">
                {!isViewOnly && (
                  <>
                    {/* Tips */}
                    <div
                      className={classNames(
                        'tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-md tw-shadow tw-p-4 tw-mb-4 tw-transition-all tw-gap-4',
                        {
                          'tw-opacity-75 tw-bg-gray-200 tw-shadow-none': !watch('areOptionalTipsEnabled'),
                        }
                      )}
                    >
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <p className="tw-font-bold">Tips</p>
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <Link className="!tw-text-blue-500 !tw-hover:bg-none" to="../settings/tips">
                            Manage
                          </Link>
                          <Switch name="areOptionalTipsEnabled" inputRef={register} />
                        </div>
                      </div>
                      <Controller
                        name="tipsConfigurationId"
                        control={control}
                        defaultValue={sectionQuery.data.tipsConfiguration}
                        render={(props) => (
                          <TipSelector
                            areOptionalTipsEnabled={sectionQuery.data.areOptionalTipsEnabled}
                            onAreTipsEnabledChange={() => setValue('areOptionalTipsEnabled', !watch('areOptionalTipsEnabled'))}
                            store={storeQuery.data!}
                            selectedTipConfigurationId={props.value}
                            onTipSelected={(tipConfiguration) => props.onChange(tipConfiguration.id)}
                            disabled={!watch('areOptionalTipsEnabled')}
                          />
                        )}
                      />
                    </div>

                    {/* Additional Charges */}
                    <div
                      className={classNames(
                        'tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-md tw-shadow tw-p-4 tw-mb-4 tw-transition-all tw-gap-4',
                        {
                          'tw-opacity-75 tw-bg-gray-200 tw-shadow-none': !watch('areAdditionalChargesEnabled'),
                        }
                      )}
                    >
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <p className="tw-font-bold">Additional Charges</p>
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <Link className="!tw-text-blue-500 !tw-hover:bg-none" to="../settings/additional-charges">
                            Manage
                          </Link>
                          <Switch name="areAdditionalChargesEnabled" inputRef={register} />
                        </div>
                      </div>
                      <Controller
                        name="additionalCharges"
                        control={control}
                        defaultValue={sectionQuery.data.additionalCharges}
                        render={(props) => (
                          <AdditionalChargesSelect
                            store={storeQuery.data!}
                            areAdditionalChargesEnabled={watch('areAdditionalChargesEnabled')}
                            onAreAdditionalChargesEnabledChange={() =>
                              setValue('areAdditionalChargesEnabled', !watch('areAdditionalChargesEnabled'))
                            }
                            selectedAdditionalCharges={props.value}
                            onChangeAdditionalCharges={props.onChange}
                            disabled={!watch('areAdditionalChargesEnabled')}
                          />
                        )}
                      />
                    </div>

                    {/* Promotions */}
                    <div
                      className={classNames(
                        'tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-md tw-shadow tw-p-4 tw-mb-4 tw-transition-all tw-gap-4',
                        {
                          'tw-opacity-75 tw-bg-gray-200 tw-shadow-none': !watch('arePromotionsEnabled'),
                        }
                      )}
                    >
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <p className="tw-font-bold">Promotions</p>
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <Link className="!tw-text-blue-500 !tw-hover:bg-none" to="../promotions">
                            Manage
                          </Link>
                          <Switch name="arePromotionsEnabled" inputRef={register} />
                        </div>
                      </div>
                      <Controller
                        name="discounts"
                        control={control}
                        defaultValue={sectionQuery.data.discounts}
                        render={(props) => (
                          <PromotionsSelect
                            store={storeQuery.data!}
                            selectedDiscounts={props.value}
                            onChangeDiscounts={props.onChange}
                            disabled={!watch('arePromotionsEnabled')}
                          />
                        )}
                      />
                    </div>
                  </>
                )}

                {/* Integrations */}
                {isPosSection && (
                  <div className="tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-md tw-shadow tw-p-4 tw-mb-4 tw-transition-all tw-gap-4">
                    <p className="tw-font-bold">Omnivore settings</p>
                    <FormGroup label="Revenue Center" className="!tw-mb-0">
                      <Controller
                        name="omnivoreRevenueCenterId"
                        defaultValue={sectionQuery.data.omnivoreRevenueCenterId}
                        control={control}
                        render={(props) => (
                          <OmnivoreRevenueCenterSelector
                            storeId={storeId}
                            selectedItemId={props.value}
                            onItemSelected={(revenueCenter) =>
                              props.onChange(revenueCenter ? revenueCenter.revenueCenterId : null)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </div>
                )}
              </div>
            </div>
          </Container>

          <OrdersFooter />

          <TimeframeUpsertDialog
            storeId={storeId}
            sectionId={sectionQuery.data.id}
            isOpen={isTimeframeDialogOpen}
            isInputNameEnabled={false}
            timeframe={timeframeEditing}
            title={timeframeEditing ? 'Edit ordering hours' : 'Add ordering hours'}
            onResolve={() => queryClient.invalidateQueries(['section', orderingMethodId])}
            type="section"
            onClose={() => {
              setIsTimeframeDialogOpen(false);
              setTimeframeEditing(undefined);
            }}
          />
        </>
      )}
    </>
  );
}
