import {IconName} from '@blueprintjs/core';
import {first, groupBy, sortBy} from 'lodash';
import {DateTime} from 'luxon';
import naturalCompare from 'natural-compare';

import storeGenericImage from '../assets/store-generic.svg';
import {TimeframeDto} from '@kontactless/admin-api/timeframe/timeframe.dto';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';
import {COLORS, DAYS_OF_WEEK} from './constants';
import {CompareType, DayOfWeek, DiscountType, OrderStatus, OrderingFlowType, SecurityLevel, Timezone, UserStatus} from './types';

import {LocationDto} from '@kontactless/admin-api/location/location.dto';
import {CustomerDto} from '@kontactless/admin-api/customer/customer.dto';
import {OrderItemDto} from '@kontactless/admin-api/order-item/order-item.dto';
import {ProductVersionDto} from '@kontactless/admin-api/product/product.dto';
import {ModifierGroupDto} from '@kontactless/admin-api/modifier-group/modifier-group.dto';

import {UserClaims} from '../contexts/auth.context';

export const isDevelopment = (): boolean => {
  return process.env.NODE_ENV === 'development';
};

export const isSentryEnabled = (): boolean => {
  // TODO: Disable Sentry in development
  return ['development', 'pre-production', 'production'].includes(process.env.REACT_APP_NODE_ENV ?? '');
};

export const getNextDayOfWeek = (day: DayOfWeek): DayOfWeek => {
  return DAYS_OF_WEEK[(DAYS_OF_WEEK.indexOf(day) + 1) % DAYS_OF_WEEK.length];
};

export const isDateInOpenPeriod = (now: DateTime, timeframes: TimeframeDto[]) => {
  if (timeframes.length === 0) {
    return true;
  }

  if (timeframes.every((timeframe) => !timeframe.isEnabled)) {
    return true;
  }

  return timeframes.some((timeframe) => isDateInTimeframe(now, timeframe));
};

const isDateInTimeframe = (now: DateTime, timeframe: TimeframeDto): boolean => {
  if (!timeframe.isEnabled) {
    return false;
  }
  const startDate = timeframe?.startDate;
  const endDate = timeframe?.endDate;
  if (
    (!!startDate && now.toFormat('yyyy-MM-dd') < DateTime.fromISO(startDate).toFormat('yyyy-MM-dd')) ||
    (!!endDate && now.toFormat('yyyy-MM-dd') > DateTime.fromISO(endDate).toFormat('yyyy-MM-dd'))
  ) {
    return false;
  }

  const timeframeDays = timeframe.daysOfWeek ?? [...DAYS_OF_WEEK];

  let dayOfWeek = now.startOf('week').minus({days: 1});
  const endOfWeek = now.endOf('week');

  while (dayOfWeek < endOfWeek) {
    const day = dayOfWeek.weekdayLong.toLowerCase() as DayOfWeek;

    if (timeframeDays.includes(day)) {
      const timeframeStart = DateTime.fromISO(`${dayOfWeek.toFormat('yyyy-MM-dd')}T${timeframe.startTime}`, {zone: now.zone});
      const timeframeEnd =
        timeframe.startTime >= timeframe.endTime
          ? DateTime.fromISO(`${dayOfWeek.plus({days: 1}).toFormat('yyyy-MM-dd')}T${timeframe.endTime}`, {
              zone: now.zone,
            })
          : DateTime.fromISO(`${dayOfWeek.toFormat('yyyy-MM-dd')}T${timeframe.endTime}`, {
              zone: now.zone,
            });

      if (timeframeStart <= now && now <= timeframeEnd) {
        return true;
      }
    }

    dayOfWeek = dayOfWeek.plus({days: 1});
  }

  return false;
};

export const getStoreOpeningHoursString = (timezone: Timezone, openingHours: TimeframeDto[]): string => {
  const now = DateTime.local();
  const todaysDay = now.toFormat('cccc').toLowerCase() as DayOfWeek;
  const appliedTimeframes: string[] = [];
  const enabledOpeningHours = openingHours.filter(({isEnabled}) => isEnabled);

  if (!enabledOpeningHours.length) {
    return 'All day';
  }

  for (const timeframe of enabledOpeningHours) {
    if (!timeframe.daysOfWeek || timeframe.daysOfWeek.includes(todaysDay)) {
      const startDatetime = DateTime.fromFormat(timeframe.startTime, 'HH:mm:ss', {zone: timezone});
      const endDatetime = DateTime.fromFormat(timeframe.endTime, 'HH:mm:ss', {zone: timezone});

      appliedTimeframes.push(`${startDatetime.toFormat('h:mm a')} to ${endDatetime.toFormat('h:mm a')}`);
    }
  }

  return appliedTimeframes.join(' – ');
};

export const getStoreImage = (store: StoreDto): string | undefined => {
  return store.imageUrl ?? store.company?.imageUrl ?? storeGenericImage;
};

export const getTimezoneShortFormats = (timezone: Timezone): string => {
  switch (timezone) {
    case 'America/Chicago':
      return 'CST/CDT';
    case 'America/Denver':
      return 'MST/MDT';
    case 'America/Los_Angeles':
      return 'PST/PDT';
    case 'America/New_York':
      return 'EST/EDT';
    case 'America/Virgin':
      return 'AST';
    case 'Pacific/Honolulu':
      return 'HST';
    default:
      return timezone;
  }
};

export const getDayInital = (day: DayOfWeek): string => {
  switch (day) {
    case 'monday':
      return 'M';
    case 'tuesday':
      return 'Tu';
    case 'wednesday':
      return 'W';
    case 'thursday':
      return 'Th';
    case 'friday':
      return 'F';
    case 'saturday':
      return 'Sa';
    case 'sunday':
      return 'Su';
  }
};

export const sortDaysOfWeek = (days: DayOfWeek[]): DayOfWeek[] => {
  const orders: Record<DayOfWeek, number> = {monday: 0, tuesday: 1, wednesday: 2, thursday: 3, friday: 4, saturday: 5, sunday: 6};
  return days.sort((day1, day2) => (orders[day1] < orders[day2] ? -1 : 1));
};

export const getQrUrl = (qrCode: string): string => {
  return `${process.env.REACT_APP_APP_BASE_URL}/${qrCode.toLowerCase()}`;
};

export const sortLocationsNaturally = (locations: LocationDto[]): LocationDto[] => {
  return locations.sort((l1, l2) => naturalCompare(l1.attributes?.[0].value ?? '', l2.attributes?.[0].value ?? ''));
};

export const getCustomGroupString = (groups: ModifierGroupDto[]): string => {
  const str: string[] = groups.reduce((acc: string[], group: ModifierGroupDto) => {
    group.modifiers?.map(({name}) => (acc = [...acc, name]));
    return acc;
  }, []);

  return str.length > 1 ? `${str[0]}, +${str.length - 1}` : str[0];
};

export const getOrderStatusLabel = (status: OrderStatus): string => {
  switch (status) {
    case 'received':
      return 'Received';
    case 'ready':
      return 'Ready';
    case 'preparing':
      return 'Preparing';
    case 'delivered':
      return 'Delivered';
    case 'refunded':
      return 'Refunded';
    case 'partially-refunded':
      return 'Partially Refunded';
    case 'cancelled-by-customer':
      return 'Cancelled by Customer';
    case 'cancelled-by-store':
      return 'Cancelled by Store';
    default:
      return status;
  }
};

export const getOrderingFlowTypeLabel = (orderingFlowType: OrderingFlowType): string => {
  switch (orderingFlowType) {
    case 'delivery-to-location':
      return 'Delivery to location';
    case 'pickup':
      return 'Pickup';
    case 'single-location':
      return 'Single location';
    default:
      return orderingFlowType;
  }
};

export const getOrderStatusIcon = (status: OrderStatus): IconName | undefined => {
  switch (status) {
    case 'received':
      return 'th-derived';
    case 'preparing':
      return 'time';
    case 'delivered':
      return 'tick-circle';
    case 'ready':
      return 'tick';
    case 'refunded':
      return 'undo';
    case 'partially-refunded':
      return 'undo';
    case 'cancelled-by-customer':
      return 'trash';
    case 'cancelled-by-store':
      return 'trash';
    default:
      return undefined;
  }
};

export const getCustomerName = (customer?: CustomerDto): string | undefined => {
  return !customer?.firstName && !customer?.lastName ? undefined : `${customer.firstName ?? ''} ${customer.lastName ?? ''}`;
};

/**
 * This is used to compute the shortcuts for the date picker,
 * as well as to compute the current shortcut applied for use in labels on the dashboard.
 * @returns Array of date ranges with a corresponding label `[startDate, endDate, compareType]`
 */
export const getShortcutDateRanges = (): Array<[DateTime, DateTime, CompareType]> => {
  const today = DateTime.local();

  const startOfYesterday = DateTime.local().minus({days: 1}).startOf('day');
  const endOfYesterday = DateTime.local().minus({days: 1}).endOf('day');

  return [
    [today.startOf('day'), today.endOf('day'), 'today'],
    [startOfYesterday, endOfYesterday, 'yesterday'],
    [startOfYesterday.minus({days: 6}), endOfYesterday, 'last-7-days'],
    [startOfYesterday.minus({days: 29}), endOfYesterday, 'last-30-days'],
    [startOfYesterday.minus({months: 3}), endOfYesterday, 'past-3-months'],
    [startOfYesterday.minus({months: 6}), endOfYesterday, 'past-6-months'],
    [startOfYesterday.minus({years: 1}), endOfYesterday, 'past-year'],
    [startOfYesterday.minus({years: 2}), endOfYesterday, 'past-2-years'],
  ];
};

export const getCompareTypeFromDates = (start?: Date | null, end?: Date | null): CompareType => {
  const startDateTime = start ? DateTime.fromJSDate(start) : null;
  const endDateTime = end ? DateTime.fromJSDate(end) : null;

  const shortcutDateRanges = getShortcutDateRanges();

  if (startDateTime && endDateTime) {
    for (const [start, end, label] of shortcutDateRanges) {
      if (startDateTime.toLocaleString() === start.toLocaleString() && endDateTime.toLocaleString() === end.toLocaleString()) {
        return label;
      }
    }
  }

  return 'custom';
};

export const getCompareTypeLabel = (compareType: CompareType): string => {
  switch (compareType) {
    case 'today':
      return 'Today';
    case 'yesterday':
      return 'Yesterday';
    case 'last-7-days':
      return 'Last 7 days';
    case 'last-30-days':
      return 'Last 30 days';
    case 'past-3-months':
      return 'Past 3 months';
    case 'past-6-months':
      return 'Past 6 months';
    case 'past-year':
      return 'Past year';
    case 'past-2-years':
      return 'Past 2 years';
    default:
      return 'Custom';
  }
};

export const getColorForIndex = (index: number, opacity: number = 100): string => {
  return addOpacityToColor(COLORS[index % COLORS.length], opacity);
};

export const addOpacityToColor = (color: string, opacity: number): string => {
  const actualOpacity = (opacity < 0 ? 0 : opacity > 100 ? 100 : opacity) / 100;

  if (color.startsWith('rgba')) {
    const parts = color.split(',');
    return parts.length >= 4
      ? parts.slice(0, -1).join(',').concat(`,${actualOpacity})`)
      : parts.join(',').replace(')', `,${actualOpacity})`);
  } else if (color.startsWith('rgb')) {
    return color.replace('rgb', 'rgba').replace(')', `,${actualOpacity})`);
  }

  return color;
};

export const getSecurityLevelLabel = (securityLevel: SecurityLevel): string => {
  switch (securityLevel) {
    case 'superadmin':
      return 'Superadmin';
    case 'company-admin':
      return 'Company Admin';
    case 'supervisor':
      return 'Supervisor';
    case 'server':
      return 'Server';
    default:
      return securityLevel;
  }
};

export const convertBlobToBase64 = async (blob: Blob): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
};

export const sortOrderItems = (items?: OrderItemDto[]): OrderItemDto[] => {
  const groupedLines = groupBy(items, ({type}) => type);

  let result: OrderItemDto[] = [];

  result = result.concat(groupedLines['product'] ?? []);
  delete groupedLines['product'];

  result = result.concat(groupedLines['tax'] ?? []);
  delete groupedLines['tax'];

  result = result.concat(groupedLines['tip'] ?? []);
  delete groupedLines['tip'];

  result = result.concat(groupedLines['surcharge'] ?? []);
  delete groupedLines['surcharge'];

  result = result.concat(groupedLines['fee'] ?? []);
  delete groupedLines['fee'];

  result = result.concat(groupedLines['refund'] ?? []);
  delete groupedLines['refund'];

  result = result.concat(groupedLines['discount'] ?? []);
  delete groupedLines['discount'];

  result = result.concat(
    sortBy(
      Object.keys(groupedLines)
        .map((type) => groupedLines[type])
        .flat(),
      ({id}) => id
    )
  );

  return result;
};

export const isTimeOverdue = (dateTime: DateTime, overdueTimeMinutes: number): boolean => {
  return -dateTime.diffNow('minutes').minutes > overdueTimeMinutes;
};

export const getUserStatusLabel = (status: UserStatus): string => {
  switch (status) {
    case 'confirmation-required':
      return 'Invited';
    case 'confirmed':
      return 'Confirmed';
    default:
      return status;
  }
};

export const getDiscountTypeLabel = (type: DiscountType): string => {
  switch (type) {
    case 'coupon-flat':
      return 'Coupon Flat';
    case 'coupon-percentage':
      return 'Coupon Percentage';
    case 'promotion-flat-price':
      return 'Promotion Flat Price';
    default:
      return type;
  }
};

export const getPaginationData = <T>(
  records: Array<T>,
  page: number,
  pageSize: number
): {records: Array<T>; totalRecords: number; totalPages: number} => {
  return {
    records: records.slice((page - 1) * pageSize, page * pageSize),
    totalRecords: records.length,
    totalPages: Math.ceil(records.length / pageSize),
  };
};

export const getPriceText = (price: number): string => {
  return `${price < 0 ? '-' : ''} $${Math.abs(price).toFixed(2)}`;
};

export const getProductVersionTotals = (productVersion: ProductVersionDto): {subtotal: number; tax: number; total: number} => {
  let subtotal = productVersion.priceWithDiscount ?? productVersion.price;
  let tax = (productVersion.priceWithDiscount ?? productVersion.price) * (productVersion.taxPercentage / 100);

  for (const modifier of productVersion.modifierGroups.flatMap(({modifiers}) => modifiers ?? [])) {
    subtotal += modifier.priceWithDiscount ?? modifier.price;
    tax += (modifier.priceWithDiscount ?? modifier.price) * (modifier.taxPercentage / 100);
  }

  return {subtotal, tax, total: subtotal + tax};
};

export function extractLocationAttributes(location: LocationDto) {
  const {name, locationType} = location;
  const locationAttributesLines = name!.replace(`${locationType?.name} `, '').split(', ');
  const res: Array<{name: string; value: string}> = [];

  for (const locationAttributeLine of locationAttributesLines) {
    for (const attribute of locationType?.attributes!) {
      if (locationAttributeLine.indexOf(attribute.name) === 0) {
        const name = locationAttributeLine.slice(0, attribute.name.length).trim();
        const value = locationAttributeLine.slice(attribute.name.length).trim();
        if (!!name && !!value) {
          res.push({name, value});
        }
      }
    }
  }

  return res;
}

export const getFormSubmitDatetime = (
  initialDate: Date,
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  timezone: Timezone
): string => {
  return DateTime.fromJSDate(initialDate, {zone: timezone})
    .set({
      year,
      month,
      day,
      hour,
      minute,
      second: 0,
      millisecond: 0,
    })
    .toISO();
};

export const getFormReadyDate = (isoDate: string, timezone: string) => {
  const dateTime = DateTime.fromISO(isoDate).setZone(timezone);
  return new Date(dateTime.year, dateTime.month - 1, dateTime.day, dateTime.hour, dateTime.minute);
};

export const getDiffPercentageString = (diffPercentage: number | 'inf'): string => {
  switch (diffPercentage) {
    case 'inf':
      return '+100%';
    case 0:
      return '0%';
    default:
      return `${diffPercentage! > 0 ? '+' : '-'}${Math.abs(diffPercentage).toFixed(0)}%`;
  }
};

export const getDiffUnitsString = (diffUnits: number): string => {
  return `${diffUnits >= 0 ? '+' : '-'}${Math.abs(diffUnits).toFixed(0)}`;
};

/**
 * Shorten relative DateTime
 * @param relativeDate
 * @returns shortened relative DateTime. e.g. '2 min. ago' => '2m ago'
 */
export const shortenRelativeDateTime = (relativeDate?: string | null) => {
  if (relativeDate) {
    const splitDate = relativeDate.split('.')[0].split(' '); //  e.g. ['5', 'min']
    return `${splitDate[0]}${splitDate[1][0]} ago`;
  }
  return '-';
};

export const getUserCompanyAndStoreData = (user: UserClaims): any => {
  return user
    ? {
        companyId: first(user.companies)?.id,
        storeId: first(user.stores)?.id,
      }
    : {};
};

export function getScreenNumberFromOmnivoreId(omnivoreId?: string) {
  return omnivoreId?.split('-')[1];
}
