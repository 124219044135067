import classNames from 'classnames';

export function NumberWrapper({children}: {children: React.ReactNode[]}) {
  const components = children.filter(Boolean);

  return (
    <div className="tw-flex tw-flex-col">
      {components.map((component, index) => {
        return (
          <div className="tw-flex tw-gap-8" key={index}>
            <span className="tw-absolute tw-rounded-full tw-bg-white tw-w-[20px] tw-h-[20px] tw-shadow tw-flex tw-items-center tw-justify-center tw-text-xs">
              {++index}
            </span>
            <span
              className={classNames('tw-h-full tw-w-[1px] tw-ml-[0.6rem]', {
                'tw-bg-gray-300': components.length !== index,
                'tw-bg-transparent': components.length === index,
              })}
            ></span>
            {component}
          </div>
        );
      })}
    </div>
  );
}
