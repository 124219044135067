import * as React from 'react';
import {VariantProps, cva} from 'class-variance-authority';
import {cn} from '../utils';

const badgeVariants = cva('tw-inline-flex tw-gap-1 tw-items-center tw-font-semibold tw-rounded-md tw-whitespace-nowrap', {
  variants: {
    variant: {
      default: 'tw-bg-gray-100 tw-text-gray-800',
      primary: 'tw-bg-blue-100 tw-text-blue-500',
      danger: 'tw-bg-red-100 tw-text-red-800',
      warning: 'tw-bg-amber-100 tw-text-amber-800',
      success: 'tw-bg-green-100 tw-text-green-800',
    },
    size: {
      default: 'tw-px-1.5 tw-py-0.5 tw-text-xs',
      lg: 'tw-px-2 tw-py-0.5 tw-text-sm',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

export interface BadgeProps extends React.BaseHTMLAttributes<HTMLSpanElement>, VariantProps<typeof badgeVariants> {
  Icon?: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement> & {title?: string; titleId?: string}>;
  text?: string;
  children?: never;
}

const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(({className, variant, size, text, ...props}, ref) => {
  const styles = badgeVariants({variant, size});
  return (
    <span className={cn(styles, className)} ref={ref} {...props}>
      {props.Icon && <props.Icon className="tw-w-3.5 tw-h-3.5 tw-fill-current tw-brightness-150" />}
      {text}
    </span>
  );
});

Badge.displayName = 'Badge';

export {Badge, badgeVariants};
