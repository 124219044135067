import React from 'react';

import {NumericInput} from '@blueprintjs/core';
import classNames from 'classnames';

import {getPriceText} from '../../utils/helpers.utils';

import {ProductVersionDto} from '@kontactless/admin-api/product/product.dto';
import {OrderItemType} from '@kontactless/utils/types';

interface OrderRowProps {
  item: {
    type: OrderItemType | 'total';
    quantity: number;
    quantityRefunded?: number;
    title: string;
    customerNotes?: string;
    subtotal: number;
    productVersion?: ProductVersionDto | null;
  };
  isRefund?: boolean;
  refundCount?: number;
  onRefundCountChange?: (refundCount: number) => void;
  itemTotal?: string;
}

export const OrderItemRow: React.FC<OrderRowProps> = ({item, isRefund, refundCount, onRefundCountChange}) => {
  return (
    <div className="order-item-row">
      <div className={classNames('main-line', {'is-refund': isRefund})}>
        <div className="col-quantity">{item.type === 'product' ? `${item.quantity}x` : ''}</div>
        <div className={classNames('col-title', {'is-product': item.type === 'product', 'is-total': item.type === 'total'})}>
          {item.title}
        </div>
        <div className={classNames('col-amount', {'is-total': item.type === 'total'})}>{getPriceText(item.subtotal)}</div>
        {isRefund && (
          <div className="col-refund">
            {item.type === 'product' && item.quantity - (item.quantityRefunded ?? 0) > 0 ? (
              <NumericInput
                defaultValue={0}
                min={0}
                max={item.quantity - (item.quantityRefunded ?? 0)}
                value={refundCount}
                onValueChange={onRefundCountChange}
                asyncControl
                fill
              />
            ) : null}
          </div>
        )}
      </div>
      {item.productVersion &&
        item.productVersion.modifierGroups.map((group) =>
          group.modifiers.map((modifier) => (
            <div key={`${group.id}-${modifier.id}`} className="modifier-line">
              <div className="col-quantity"></div>
              <div className="col-title is-product">{modifier.name}</div>
            </div>
          ))
        )}
      {!!item.customerNotes && (
        <div className="modifier-line">
          <div className="col-quantity"></div>
          <div className="col-title is-note">{item.customerNotes}</div>
          <div className="col-amount"></div>
        </div>
      )}
      {!!item.quantityRefunded && (
        <div className="modifier-line is-refund">
          <div className="col-quantity"></div>
          <div className="col-title is-product">Refunded {item.quantityRefunded} times</div>
          <div className="col-amount"></div>
        </div>
      )}
    </div>
  );
};
