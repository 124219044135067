import React, {useEffect, useMemo, useState} from 'react';

import {Button, Checkbox, Icon, Menu, MenuItem, Switch} from '@blueprintjs/core';
import {Popover2, Tooltip2} from '@blueprintjs/popover2';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar} from '@material-ui/core';
import {sortBy} from 'lodash';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {useMutation} from 'react-query';

import {updateProductStateRequest} from '../../../api/product.api';
import {ProductUpsertDrawer} from '../../../components/product-upsert-drawer/product-upsert-drawer.component';
import {AccordionManual} from '../../../components/ui/accordion.component';
import {useAuth} from '../../../contexts/auth.context';
import {useToasts} from '../../../contexts/toasts.context';
import {PRODUCTS_LIST_DEFAULT_LENGTH} from '../../../utils/constants';
import {getCustomGroupString, getPriceText} from '../../../utils/helpers.utils';
import {MenuCategoryDto} from '@kontactless/admin-api/menu-category/menu-category.dto';
import {ProductDto, ProductStateUpdateRequestDto} from '@kontactless/admin-api/product/product.dto';
import {MenuCategoryProductDto} from '@kontactless/admin-api/menu-category-product/menu-category-product.dto';
import {Badge} from '@kontactless/admin-app/src/common/components/Badge';

export interface CategoryAccordionProps {
  menuCategory: MenuCategoryDto;
  menuUpsellingItemsCount: number;
  addedProducts: ProductDto[];
  selectedMenuCategoryProducts: MenuCategoryProductDto[];
  onAddProductClick?: () => void;
  onMenuCategoryProductSelected?: (menuCategoryProduct: MenuCategoryProductDto) => void;
  onProductStateChange?: (product: ProductDto, isEnabled: boolean) => void;
  onEditMenuCategoryClick: () => void;
  onRemoveMenuCategoryClick: () => void;
  pricesIncludeTax: boolean;
}

export const CategoryAccordion: React.FC<CategoryAccordionProps> = ({
  menuCategory,
  menuUpsellingItemsCount,
  addedProducts,
  selectedMenuCategoryProducts,
  onAddProductClick,
  onMenuCategoryProductSelected,
  onProductStateChange,
  onEditMenuCategoryClick,
  onRemoveMenuCategoryClick,
  pricesIncludeTax,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const sortedProducts = useMemo(() => sortBy(menuCategory.menuCategoryProducts ?? [], ({order}) => order), [
    menuCategory.menuCategoryProducts,
  ]);

  return (
    <>
      <AccordionManual
        className="menu-category-accordion"
        title={menuCategory.name}
        isOpen={isOpen}
        onChange={setIsOpen}
        collapseProps={{className: 'remove-transform'}}
        headerRightElement={
          isOpen ? (
            <Popover2
              content={
                <Menu>
                  <MenuItem icon="edit" text="Edit" onClick={onEditMenuCategoryClick} />
                  <MenuItem icon="trash" text="Delete" intent="danger" onClick={onRemoveMenuCategoryClick} />
                </Menu>
              }
            >
              <Button icon="more" minimal />
            </Popover2>
          ) : (
            <div className="page-info">
              <p>Total: {sortedProducts.length} items</p>
            </div>
          )
        }
      >
        <div className="accordion-body">
          <div className="products-list">
            <Droppable droppableId={`drop-product-${menuCategory.id}`} type="product">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <hr className="divider" />
                  {sortedProducts
                    .slice(0, showAllProducts ? sortedProducts.length : PRODUCTS_LIST_DEFAULT_LENGTH)
                    .map((menuCategoryProduct, index) => (
                      <Draggable
                        key={`category-${menuCategory.id}-product-${menuCategoryProduct.id}`}
                        draggableId={`drag-category-${menuCategory.id}-product-${menuCategoryProduct.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <ProductsListItem
                              menuCategoryId={menuCategoryProduct.menuCategoryId}
                              product={menuCategoryProduct.product!}
                              isSelected={selectedMenuCategoryProducts.includes(menuCategoryProduct)}
                              onSelectedChange={() => onMenuCategoryProductSelected?.(menuCategoryProduct)}
                              onProductStateChange={(product, isEnabled) => onProductStateChange?.(product, isEnabled)}
                              pricesIncludeTax={pricesIncludeTax}
                              menuUpsellingItemsCount={menuUpsellingItemsCount}
                            />
                            <hr className="divider" />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {sortedProducts.length === 0 ? (
                    <>
                      <p className="tw-text-sm tw-text-gray-500 tw-my-2">There are no items in this category</p>
                      <hr className="divider" />
                    </>
                  ) : null}
                  {!showAllProducts && PRODUCTS_LIST_DEFAULT_LENGTH < sortedProducts.length ? (
                    <div className="see-all-bar" onClick={() => setShowAllProducts(true)}>
                      {addedProducts.length > 0 ? (
                        <div className="hidden-items">
                          <FontAwesomeIcon className="icon" icon={faInfoCircle} />
                          <p>
                            {getAddedProductsLabelText(
                              sortedProducts
                                .slice(PRODUCTS_LIST_DEFAULT_LENGTH)
                                .filter(({id}) => addedProducts.map((added) => added.id === id)).length
                            )}
                          </p>
                        </div>
                      ) : null}
                      <label>See All</label>
                    </div>
                  ) : null}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <footer className="accordion-footer">
            <Button
              className="add-product-button"
              icon="add"
              text="Add Product"
              intent="primary"
              onClick={onAddProductClick}
              minimal
            />
            <div className="page-info">
              {!showAllProducts && PRODUCTS_LIST_DEFAULT_LENGTH < sortedProducts.length ? (
                <p>Viewing: {PRODUCTS_LIST_DEFAULT_LENGTH} items</p>
              ) : null}
              <p>Total: {sortedProducts.length} items</p>
            </div>
          </footer>
        </div>
      </AccordionManual>
    </>
  );
};

const getAddedProductsLabelText = (productsCount: number): string => {
  return `${productsCount} item${productsCount === 1 ? ' was' : 's were'} added to the end of the list`;
};

interface ProductsListItemProps {
  product: ProductDto;
  isSelected: boolean;
  onSelectedChange: () => void;
  onProductStateChange: (product: ProductDto, isEnabled: boolean) => void;
  menuCategoryId: number;
  menuUpsellingItemsCount: number;
  pricesIncludeTax: boolean;
}

const ProductsListItem: React.FC<ProductsListItemProps> = ({
  product,
  isSelected,
  onSelectedChange,
  onProductStateChange,
  menuCategoryId,
  menuUpsellingItemsCount,
  pricesIncludeTax,
}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const [isEnabled, setIsEnabled] = useState(product.isEnabled);
  const updateProductStateMutation = useMutation<{isEnabled: boolean}, Error, {id: number} & ProductStateUpdateRequestDto>(
    (update) => updateProductStateRequest(user.token, update.id, update)
  );
  const [isProductUpsertDrawerOpen, setisProductUpsertDrawerOpen] = useState(false);
  useEffect(() => setIsEnabled(product.isEnabled), [product.isEnabled]);

  const onSwitchClick = async (isEnabled: boolean) => {
    try {
      setIsEnabled(isEnabled);
      const {isEnabled: newState} = await updateProductStateMutation.mutateAsync({id: product.id, isEnabled});

      onProductStateChange(product, newState);

      toaster.show({intent: 'success', message: `Product "${product.name}" state was changed successfully`});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: `An error ocurred changing the product "${product.name}" state`});
      setIsEnabled(!isEnabled);
    }
  };

  let productPrice = product.price;
  let taxValue = getPriceText(product.taxValue || 0);
  let priceDescription = 'Price';
  if (pricesIncludeTax) {
    productPrice = product.price + product.taxValue;
    taxValue = `${product.taxPercentage}%`;
    priceDescription = 'Price (tax incl.)';
  }

  return (
    <>
      <div className="product-list-item">
        <div className="item-control">
          <Checkbox checked={isSelected} disabled={updateProductStateMutation.isLoading} onChange={onSelectedChange} />
        </div>
        <div className="item-switch">
          <Switch
            checked={isEnabled}
            disabled={updateProductStateMutation.isLoading}
            onChange={({currentTarget: {checked}}) => onSwitchClick(checked)}
          />
        </div>
        <div className="item-name">
          <p className="title">
            {product.name}
            {product.isLinked && (
              <Tooltip2 content="This item is used in multiple menus" position="top">
                <Icon icon="link" iconSize={10} className="linked-icon" />
              </Tooltip2>
            )}
          </p>
          <p className="description">{product.description}</p>
        </div>
        <div className="item-customs">
          <div className="description">{product.modifierGroups?.length ? 'Modifiers' : 'No modifiers'}</div>
          <div className="subtitle">{getCustomGroupString(product.modifierGroups ?? [])}</div>
        </div>
        <div className="tw-w-10">{product.isUpselling && <Badge variant="primary" text="Upselling" />}</div>
        <div className="item-images">
          {product.images?.map(({id, url}) => <Avatar key={id} alt="product_image" variant="rounded" src={url} />) ?? null}
        </div>
        <div className="item-price">
          <p className="description">{priceDescription}</p>
          <p className="subtitle">${(productPrice || 0).toFixed(2)}</p>
        </div>
        <div className="item-tax">
          <p className="description">Tax</p>
          <p className="subtitle">{taxValue}</p>
        </div>
        <div className="item-actions">
          <Button
            className="action-button"
            icon="edit"
            text="Edit"
            onClick={() => setisProductUpsertDrawerOpen(true)}
            disabled={updateProductStateMutation.isLoading}
            minimal
          />
        </div>
      </div>
      <ProductUpsertDrawer
        title="Edit product"
        isOpen={isProductUpsertDrawerOpen}
        onClose={() => setisProductUpsertDrawerOpen(false)}
        product={product}
        menuCategoryId={menuCategoryId}
        pricesIncludeTax={pricesIncludeTax}
        menuUpsellingItemsCount={menuUpsellingItemsCount}
      />
    </>
  );
};
