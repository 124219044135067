import {Button, InputGroup, NonIdealState, Spinner} from '@blueprintjs/core';
import {DiscountDto} from '@kontactless/admin-api/discount/discount.dto';
import {TimeframeDto} from '@kontactless/admin-api/timeframe/timeframe.dto';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {fetchAllDiscountsRequest} from '../../api/discount.api';
import {DiscountUpsertDialog} from '../../components/discounts/discount-upsert-dialog.component';
import {ProductSelectorDrawer} from '../../components/product-selector-drawer/product-selector-drawer.component';
import {TimeframeUpsertDialog} from '../../components/timeframes/timeframe-upsert-dialog.component';
import {useAuth} from '../../contexts/auth.context';
import {PromotionsCard} from '../components/promotions/PromotionsCard';
import {fetchStoreRequest} from '../../api/store.api';

export function PromotionsPage() {
  const {user} = useAuth();
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);

  const storeQuery = useQuery({
    queryKey: ['stores', storeId],
    queryFn: () => fetchStoreRequest(user.token, storeId),
  });

  const [discountName, setDiscountName] = useState('');
  const [selectedTimeframe, setSelectedTimeframe] = useState<TimeframeDto | undefined>();
  const [selectedDiscount, setSelectedDiscount] = useState<DiscountDto | undefined>();
  const [isTimeframeDialogOpen, setIsTimeframeDialogOpen] = useState(false);
  const [isDiscountDialogOpen, setIsDiscountDialogOpen] = useState(false);
  const [isProductSelectorOpen, setIsProductSelectorOpen] = useState(false);

  const discountsQuery = useQuery({
    queryKey: ['stores', storeId, 'discounts'],
    queryFn: () => fetchAllDiscountsRequest(user.token, storeId),
    onSuccess: (discounts) => {
      const refreshedDiscount = discounts.find(({id}) => id === selectedDiscount?.id);

      if (!refreshedDiscount) {
        setSelectedDiscount(undefined);
        setIsProductSelectorOpen(false);
      } else {
        setSelectedDiscount(refreshedDiscount);
      }
    },
  });

  const filteredPromotions =
    discountsQuery.data?.filter((discount) =>
      discountName ? discount.name.toLowerCase().includes(discountName.toLowerCase()) : discount
    ) ?? [];

  if (!storeQuery.data) {
    return null;
  }

  const isLoading = discountsQuery.isLoading;

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-4">
      {isLoading ? (
        <Spinner />
      ) : discountsQuery.data?.length === 0 ? (
        <NonIdealState
          icon="settings"
          title="There are no promotions yet"
          action={<Button intent="primary" text="+ Create promotion" onClick={() => setIsDiscountDialogOpen(true)} />}
        />
      ) : (
        <>
          <div className="tw-flex tw-items-center tw-justify-between">
            <InputGroup
              onChange={(event) => setDiscountName(event?.target.value)}
              placeholder="Search promotion..."
              value={discountName}
              leftIcon="search"
            />
            <Button
              intent="primary"
              onClick={() => {
                setSelectedDiscount(undefined);
                setIsDiscountDialogOpen(true);
              }}
            >
              + Create promotion
            </Button>
          </div>
          <ul className="tw-flex tw-flex-col tw-gap-2">
            {filteredPromotions?.map((discount) => {
              return (
                <PromotionsCard
                  key={discount.id}
                  store={storeQuery.data}
                  discount={discount}
                  onEditClick={() => {
                    setSelectedDiscount(discount);
                    setIsDiscountDialogOpen(true);
                  }}
                  onAddProductsClick={() => {
                    setSelectedDiscount(discount);
                    setIsProductSelectorOpen(true);
                  }}
                  onAddTimeframeClick={() => {
                    setSelectedDiscount(discount);
                    setIsTimeframeDialogOpen(true);
                  }}
                  onEditTimeframeClick={(timeframe) => {
                    setSelectedDiscount(discount);
                    setSelectedTimeframe(timeframe);
                    setIsTimeframeDialogOpen(true);
                  }}
                />
              );
            })}
          </ul>
        </>
      )}

      <TimeframeUpsertDialog
        storeId={storeId}
        isOpen={isTimeframeDialogOpen}
        timeframe={selectedTimeframe}
        type="discount"
        discountId={selectedDiscount?.id}
        onResolve={() => {
          discountsQuery.refetch({cancelRefetch: true});
        }}
        onClose={() => {
          setSelectedTimeframe(undefined);
          setIsTimeframeDialogOpen(false);
        }}
      />
      <DiscountUpsertDialog
        store={storeQuery.data}
        isOpen={isDiscountDialogOpen}
        discount={selectedDiscount}
        onResolve={() => {
          discountsQuery.refetch({cancelRefetch: true});
        }}
        onClose={() => {
          setSelectedDiscount(undefined);
          setIsDiscountDialogOpen(false);
        }}
      />
      <ProductSelectorDrawer
        isOpen={isProductSelectorOpen}
        store={storeQuery.data}
        discountId={selectedDiscount?.id!}
        selectedMenuCategoryProductsIds={selectedDiscount?.menuCategoryProducts?.map(({id}) => id) ?? []}
        close={() => {
          setIsProductSelectorOpen(false);
        }}
      />
    </div>
  );
}
