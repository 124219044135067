import {callApi} from './api';

import {
  LocationCheckRequestDto,
  LocationCreateRequestDto,
  LocationDto,
  LocationUpdateRequestDto,
  LocationUpdateStateRequestDto,
  LocationsExportRequestDto,
} from '@kontactless/admin-api/location/location.dto';

export const createLocationRequest = async (token: string, location: LocationCreateRequestDto): Promise<LocationDto> => {
  return callApi<LocationDto>(`/locations`, {token, method: 'POST', body: JSON.stringify(location)}).then(({data}) => data);
};

export const updateLocationRequest = async (
  token: string,
  location: LocationUpdateRequestDto,
  locationId: number
): Promise<LocationDto> => {
  return callApi<LocationDto>(`/locations/${locationId}`, {token, method: 'PATCH', body: JSON.stringify(location)}).then(
    ({data}) => data
  );
};

export const checkLocationRequest = async (token: string, check: LocationCheckRequestDto): Promise<any> => {
  return callApi<{locationWithSameQr: Location; locationWithSameAttributes: Location}>(`/locations/check`, {
    token,
    method: 'POST',
    body: JSON.stringify(check),
  }).then(({data}) => data);
};

export const updateLocationStateRequest = async (
  token: string,
  location: LocationUpdateStateRequestDto,
  locationId: number
): Promise<LocationDto> => {
  return callApi<LocationDto>(`/locations/${locationId}/state`, {token, method: 'PATCH', body: JSON.stringify(location)}).then(
    ({data}) => data
  );
};

export const fetchLocationRequest = async (token: string, locationId: number): Promise<LocationDto> => {
  return callApi<LocationDto>(`/locations/${locationId}`, {token}).then(({data}) => data);
};

export const deleteLocationRequest = async (token: string, locationId: number): Promise<void> => {
  return callApi<void>(`/locations/${locationId}`, {token, method: 'DELETE'}).then(({data}) => data);
};

export const exportLocationsRequest = async (token: string, body: LocationsExportRequestDto): Promise<{url: string}> => {
  return callApi<{url: string}>(`/locations/export`, {token, method: 'POST', body: JSON.stringify(body)}).then(({data}) => data);
};
