import {ApiKeyDto} from '@kontactless/admin-api/api-key/api-key.dto';
import {callApi} from './api';

export const getApiKeysRequest = async (token: string, companyId: number): Promise<ApiKeyDto[]> => {
  return callApi<ApiKeyDto[]>(`/api-keys`, {token, method: 'GET', params: {companyId}}).then(({data}) => data);
};

export const createApiKeyRequest = async (token: string, body: {companyId: number; name: string}): Promise<string> => {
  return callApi<string>(`/api-keys`, {token, method: 'POST', body: JSON.stringify(body)}).then(({data}) => data);
};

export const deleteApiKeyRequest = async (token: string, apiKeyId: number): Promise<{}> => {
  return callApi<{}>(`/api-keys/${apiKeyId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
