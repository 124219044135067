import React from 'react';

import {Button, MenuItem} from '@blueprintjs/core';
import {IMultiSelectProps, MultiSelect} from '@blueprintjs/select';
import classNames from 'classnames';

import {MenuDto} from '@kontactless/admin-api/menu/menu.dto';
import {OmnivoreIcon} from '../icons/omnivore.icon';

interface MenusSelectorProps extends Omit<IMultiSelectProps<MenuDto>, 'itemRenderer' | 'tagRenderer'> {
  selectedMenusIds: number[];
  disabledType?: 'pos-menus' | 'regular-menus';
}

const MenusSelectorComponent = MultiSelect.ofType<MenuDto>();

export const MenusSelector: React.FC<MenusSelectorProps> = ({selectedMenusIds, disabledType, ...props}) => {
  let inputRef: any = {current: undefined};

  return (
    <MenusSelectorComponent
      {...props}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{className: 'menus-selector-popover', minimal: true}}
      itemRenderer={(menu, {modifiers, handleClick}) => (
        <MenuItem
          active={false}
          disabled={(disabledType === 'pos-menus' && menu.isPosMenu) || (disabledType === 'regular-menus' && !menu.isPosMenu)}
          key={menu.id}
          onClick={handleClick}
          text={
            <div className="menu-selector-item">
              {menu.name} {menu.isPosMenu ? <OmnivoreIcon className="tw-w-5" /> : null}
            </div>
          }
          icon={selectedMenusIds.includes(menu.id) ? 'tick' : 'blank'}
        />
      )}
      scrollToActiveItem={false}
      selectedItems={props.items.filter(({id}) => selectedMenusIds.includes(id))}
      tagRenderer={({name}) => name}
      itemsEqual={(m1, m2) => m1.id === m2.id}
      itemPredicate={(query, menu) => menu.name.toLocaleLowerCase().includes(query.toLowerCase())}
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      tagInputProps={{
        inputRef: (input) => (inputRef = input),
        placeholder: 'Choose menus...',
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
        tagProps: {minimal: true},
      }}
    />
  );
};
