import React, {createRef, useState} from 'react';

import {Callout, HTMLSelect, HTMLTable, Switch} from '@blueprintjs/core';
import {useMutation, useQuery} from 'react-query';

import {deletePrinterRequest, fetchAllPrinters, updatePrinterRequest} from '../../api/printer.api';
import {useAuth} from '../../contexts/auth.context';
import {useCache} from '../../contexts/cache/cache.context';
import {useToasts} from '../../contexts/toasts.context';
import {ORDER_STATUSES} from '../../utils/constants';
import {getOrderStatusLabel} from '../../utils/helpers.utils';
import {OrderStatus} from '../../utils/types';
import {ErrorIcon, SuccessIcon, WarningIcon} from '../icons';
import {PrinterUpsertDialog} from './printer-upsert-dialog.component';

import {PrinterDto, PrinterUpdateRequestDto} from '@kontactless/admin-api/printer/printer.dto';

type PrinterListColumns = 'enable' | 'name' | 'trigger' | 'paperStatus' | 'coverStatus' | 'actions';

interface PrinterListProps {
  storeId: number;
  columns?: PrinterListColumns[];
  onPrinterUpdated?: (printer: PrinterDto) => void;
  onPrinterDeleted?: (printer: PrinterDto) => void;
}

const PrinterUpdateDialog = PrinterUpsertDialog;

export const PrinterList: React.FC<PrinterListProps> = ({
  storeId,
  columns = ['enable', 'name', 'trigger', 'paperStatus', 'coverStatus', 'actions'],
  ...props
}) => {
  const {user} = useAuth();
  const {data: printers} = useQuery(['printers', storeId], () => fetchAllPrinters(user.token, storeId), {
    refetchInterval: 1000 * 30,
  });

  const {
    state: {toaster},
  } = useToasts();
  {
    /* TODO: Remove the code below once we are sure the automatic creation of printers works fine */
  }
  // const { alertsDispatch } = useAlerts();

  const {dispatchCacheAction} = useCache();
  const [printerEditing, setPrinterEditing] = useState<PrinterDto | undefined>();
  const [isPrinterDialogOpen, setIsPrinterDialogOpen] = useState(false);

  const switchRef = createRef<HTMLInputElement>();
  const statusTriggerLabel = (printer: PrinterDto) => getOrderStatusLabel(printer.orderStatusTrigger);

  const updatePrinterMutation = useMutation<PrinterDto, Error, {printer: PrinterUpdateRequestDto; printerId: number}>(
    ({printer, printerId}) => updatePrinterRequest(user.token, printer, printerId)
  );

  const deletePrinterMutation = useMutation<{}, Error, number>((printerId) => deletePrinterRequest(user.token, printerId));

  {
    /* TODO: Remove the code below once we are sure the automatic creation of printers works fine */
  }
  // const onEditPrinterActionClick = (printer: Printer) => {
  //   setIsPrinterDialogOpen(true);
  //   setPrinterEditing(printer);
  // };

  {
    /* TODO: Remove the code below once we are sure the automatic creation of printers works fine */
  }
  // const onDeletePrinterActionClick = async (printer: Printer) => {
  //   alertsDispatch({
  //     type: 'set-alert',
  //     alert: {
  //       children: `Do you want to delete the printer "${printer.name}"? This action can't be undone.`,
  //       intent: 'danger',
  //       icon: 'trash',
  //       confirmButtonText: 'Yes, delete it',
  //       cancelButtonText: `No, I don't`,
  //       onConfirm: async (setAlert, removeAlert) => {
  //         try {
  //           setAlert({loading: true});
  //           await deletePrinterMutation.mutateAsync(printer.id);
  //           props.onPrinterDeleted?.(printer);
  //           dispatchCacheAction({type: 'remove-printer', printer});
  //           toaster.show({intent: 'success', message: 'Printer was deleted successfully'});
  //           removeAlert();
  //         } catch (error) {
  //           console.error(error);
  //           toaster.show({intent: 'danger', message: 'An error ocurred deleting the revenue group'});
  //           setAlert({loading: false});
  //         }
  //       },
  //     },
  //   });
  // };

  const onPrinterEnableSwitchChange = async (printer: PrinterDto, isEnabled: boolean) => {
    try {
      const updatedPrinter = await updatePrinterMutation.mutateAsync({
        printer: {
          ...printer,
          isEnabled,
        },
        printerId: printer.id,
      });
      props.onPrinterUpdated?.(updatedPrinter);
      dispatchCacheAction({type: 'upsert-printer', printer: updatedPrinter});
      toaster.show({intent: 'success', message: `Printer state was ${isEnabled ? 'enabled' : 'disabled'} successfully`});
    } catch (error) {
      console.error(error);
      if (switchRef.current) {
        switchRef.current.checked = false;
      }
      toaster.show({intent: 'danger', message: `An error ocurred while ${isEnabled ? 'enabling' : 'disabling'} the printer`});
    }
  };

  const onOrderStatusTriggerSelectChange = async (printer: PrinterDto, selectedStatus: OrderStatus) => {
    try {
      const updatedPrinter = await updatePrinterMutation.mutateAsync({
        printer: {
          ...printer,
          orderStatusTrigger: selectedStatus,
        },
        printerId: printer.id,
      });
      props.onPrinterUpdated?.(updatedPrinter);
      dispatchCacheAction({type: 'upsert-printer', printer: updatedPrinter});
      toaster.show({intent: 'success', message: `Trigger was updated successfully`});
    } catch (error) {
      console.error(error);
      if (switchRef.current) {
        switchRef.current.checked = false;
      }
      toaster.show({intent: 'danger', message: `An error ocurred while updatng the trigger`});
    }
  };

  const getPrinterPaperStatusIcon = (printer: PrinterDto) => {
    if (!printer.paperStatus.code) {
      return null;
    }

    if (printer.paperStatus?.code === 'empty') {
      return ErrorIcon;
    }

    if (printer.paperStatus?.code === 'near_end') {
      return WarningIcon;
    }

    return SuccessIcon;
  };

  const getPrinterCoverStatusIcon = (printer: PrinterDto) => {
    if (printer.coverStatus.isOpen === null) {
      return null;
    }

    if (printer.coverStatus?.isOpen) {
      return ErrorIcon;
    }
    return SuccessIcon;
  };

  const isLoading = deletePrinterMutation.isLoading || updatePrinterMutation.isLoading;

  return (
    <>
      {printers?.length ? (
        <HTMLTable className="printers-table">
          <thead>
            <tr>
              {columns.includes('enable') && <th></th>}
              {columns.includes('name') && <th title="Name">Name</th>}
              {columns.includes('trigger') && <th title="Trigger">Trigger</th>}
              {columns.includes('paperStatus') && <th title="Paper roll">Paper roll</th>}
              {columns.includes('coverStatus') && <th title="Cover">Cover status</th>}
              {/* TODO: Remove the code below once we are sure the automatic creation of printers works fine */}
              {/* {columns.includes('actions') && <th className="actions">{isFetchingPrinters && <Spinner size={15} />}</th>} */}
            </tr>
          </thead>
          <tbody>
            {printers?.map((printer) => (
              <tr key={printer.id}>
                {columns.includes('enable') && (
                  <td>
                    <Switch
                      defaultChecked={printer.isEnabled}
                      disabled={isLoading}
                      onChange={({currentTarget: {checked}}) => onPrinterEnableSwitchChange(printer, checked)}
                      inputRef={switchRef}
                    />
                  </td>
                )}
                {columns.includes('name') && <td title={printer.name}>{printer.name}</td>}
                {columns.includes('trigger') && (
                  <td title={statusTriggerLabel(printer)}>
                    <HTMLSelect
                      fill
                      disabled={isLoading}
                      options={ORDER_STATUSES.map((status) => ({label: getOrderStatusLabel(status), value: status}))}
                      onChange={(event) => onOrderStatusTriggerSelectChange(printer, event.target.value as OrderStatus)}
                      value={printer.orderStatusTrigger}
                    />
                  </td>
                )}
                {columns.includes('paperStatus') && (
                  <td title={printer.paperStatus.message}>
                    {printer.paperStatus.message} {getPrinterPaperStatusIcon(printer)}
                  </td>
                )}
                {columns.includes('coverStatus') && (
                  <td title={printer.coverStatus.message}>
                    {printer.coverStatus.message} {getPrinterCoverStatusIcon(printer)}
                  </td>
                )}
                {/* TODO: Remove the code below once we are sure the automatic creation of printers works fine */}
                {/* {columns.includes('actions') && (
                  <td className="actions">
                    <Popover className={Classes.POPOVER_CONTENT_SIZING} enforceFocus={false}>
                      <Button minimal icon="more" intent="none" title="Actions" />
                      <Menu key="menu">
                        <MenuItem onClick={() => onEditPrinterActionClick(printer)} icon="edit" text="Edit" />
                        <MenuItem
                          onClick={() => onDeletePrinterActionClick(printer)}
                          icon="trash"
                          text="Delete"
                          intent="danger"
                        />
                      </Menu>
                    </Popover>
                  </td>
                )} */}
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      ) : (
        <Callout>There are no printers configured for this store.</Callout>
      )}

      <PrinterUpdateDialog
        storeId={storeId}
        isOpen={isPrinterDialogOpen}
        printer={printerEditing}
        onSubmit={props?.onPrinterUpdated}
        onClose={() => {
          setIsPrinterDialogOpen(false);
          setPrinterEditing(undefined);
        }}
      />
    </>
  );
};
