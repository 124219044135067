import React from 'react';

import {Button} from '@blueprintjs/core';
import {DatePicker, DatePickerProps} from '@blueprintjs/datetime';
import {Popover2, Popover2Props} from '@blueprintjs/popover2';
import {DateTime} from 'luxon';

export interface DatePickerButtonProps extends Omit<DatePickerProps, 'value' | 'defaultValue'> {
  placeholder?: string;
  value?: Date;
  popoverProps?: Popover2Props;
}

export const DatePickerButton: React.FC<DatePickerButtonProps> = ({
  value,
  onChange,
  placeholder = 'Select date',
  popoverProps,
  timePrecision,
  ...props
}) => {
  const getDisplayedValue = (value?: Date) => {
    if (value) {
      return timePrecision
        ? DateTime.fromJSDate(value).toFormat('LLLL d, yyyy – hh:mm a')
        : DateTime.fromJSDate(value).toFormat('LLLL d, yyyy');
    }
    return placeholder;
  };

  return (
    <Popover2
      fill
      position="bottom-right"
      {...popoverProps}
      content={<DatePicker {...props} timePrecision={timePrecision} onChange={onChange} value={value} />}
    >
      <Button icon="calendar" outlined text={getDisplayedValue(value)} />
    </Popover2>
  );
};
