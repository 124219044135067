import {callApi} from './api';
import {StoreCreateRequestDto, StoreDto, StoreUpdateRequestDto} from '@kontactless/admin-api/store/store.dto';
import {useAuth} from '../contexts/auth.context';
import {useQuery} from 'react-query';

export const fetchAllStoresRequest = async (
  token: string,
  companyId?: number,
  includes?: Array<'sections' | 'locationTypes' | 'settings' | 'revenue-groups'>
): Promise<StoreDto[]> => {
  return callApi<StoreDto[]>('/stores', {token, params: {companyId, includes}}).then(({data}) => data);
};

export const fetchStoreRequest = async (token: string, storeId: number): Promise<StoreDto> => {
  return callApi<StoreDto>(`/stores/${storeId}`, {token}).then(({data}) => data);
};

export function useStoreQuery(storeId: number) {
  const {user} = useAuth();
  const queryKey = ['stores', storeId];
  return useQuery(queryKey, () => fetchStoreRequest(user.token, storeId));
}

export const createStoreRequest = async (token: string, store: StoreCreateRequestDto): Promise<StoreDto> => {
  return callApi<StoreDto>(`/stores`, {token, method: 'POST', body: JSON.stringify(store)}).then(({data}) => data);
};

export const updateStoreRequest = async (token: string, store: StoreUpdateRequestDto, storeId: number): Promise<StoreDto> => {
  return callApi<StoreDto>(`/stores/${storeId}`, {token, method: 'PATCH', body: JSON.stringify(store)}).then(({data}) => data);
};

export const deleteStoreRequest = async (token: string, storeId: number): Promise<{}> => {
  return callApi<{}>(`/stores/${storeId}`, {token, method: 'DELETE'}).then(({data}) => data);
};

export const fetchAllStoresByCompanyIdRequest = async (token: string, companyId: number): Promise<StoreDto[]> => {
  return callApi<StoreDto[]>('/stores', {token, params: {companyId}}).then(({data}) => data);
};
