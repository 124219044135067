import React from 'react';

import {Button, ButtonGroup} from '@blueprintjs/core';
import {useMutation, useQueryClient} from 'react-query';

import {deleteUserRequest, resendUserInviteRequest} from '../../api/user.api';
import {useAlerts} from '../../contexts/alerts.context';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {UserDto} from '@kontactless/admin-api/user/user.dto';
import {getSecurityLevelLabel, getUserStatusLabel} from '../../utils/helpers.utils';
import {Badge} from '../../common/components/Badge';

interface UserRowProps {
  companyId: number;
  user: UserDto;
  onDeleted?: (user: UserDto) => void;
  onEditClick?: (user: UserDto) => void;
  onChangePasswordClick?: (user: UserDto) => void;
}

export function UserRow({companyId, user, onEditClick}: UserRowProps) {
  const {user: authUser} = useAuth();
  const {alertsDispatch} = useAlerts();
  const {
    state: {toaster},
  } = useToasts();
  const deleteUserMutation = useMutation<{}, Error, number>((userId) => deleteUserRequest(authUser.token, userId));
  const resendUserInviteMutation = useMutation<{}, Error, number>((userId) => resendUserInviteRequest(authUser.token, userId));
  const queryClient = useQueryClient();

  const onDeleteClick = async () => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Delete user "${user.fullName}"?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Delete',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await deleteUserMutation.mutateAsync(user.id);
            queryClient.invalidateQueries(['companies', companyId, 'users']);
            toaster.show({intent: 'success', message: 'User was deleted successfully'});
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred deleting the user'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  const onResendInviteClick = async () => {
    try {
      await resendUserInviteMutation.mutateAsync(user.id);
      toaster.show({intent: 'success', message: `Invite was sent to ${user.username}`});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: `An error ocurred sending an invite to ${user.username}`});
    }
  };

  return (
    <tr>
      <td className="tw-align-middle tw-whitespace-nowrap tw-py-2 tw-pr-3 tw-text-sm">
        <div className="tw-font-medium tw-text-gray-900">{user.fullName}</div>
        <div className="tw-text-gray-500">{user.username}</div>
      </td>
      <td className="tw-align-middle tw-whitespace-nowrap tw-px-3 tw-py-2 tw-text-sm tw-text-gray-500">
        {getSecurityLevelLabel(user.securityLevel)}
      </td>
      <td className="tw-align-middle tw-whitespace-nowrap tw-px-3 tw-py-2 tw-text-sm tw-text-gray-500">
        <Badge variant={user.status === 'confirmed' ? 'success' : 'warning'} text={getUserStatusLabel(user.status)} />
      </td>
      <td className="tw-align-middle tw-whitespace-nowrap tw-py-2 tw-pl-3 tw-text-right">
        <ButtonGroup minimal>
          {user.status === 'confirmation-required' ? (
            <Button
              text="Re-send Invite"
              icon="send-message"
              loading={resendUserInviteMutation.isLoading}
              disabled={deleteUserMutation.isLoading || resendUserInviteMutation.isLoading}
              onClick={() => onResendInviteClick()}
            />
          ) : null}
          <Button
            text="Edit"
            icon="edit"
            intent="none"
            disabled={deleteUserMutation.isLoading || resendUserInviteMutation.isLoading}
            onClick={() => onEditClick?.(user)}
          />
          <Button
            text="Delete"
            icon="trash"
            intent="danger"
            loading={deleteUserMutation.isLoading}
            disabled={deleteUserMutation.isLoading || resendUserInviteMutation.isLoading}
            onClick={onDeleteClick}
          />
        </ButtonGroup>
      </td>
    </tr>
  );
}
