import React, {useState} from 'react';

import {Button, Callout, Spinner} from '@blueprintjs/core';
import {useQuery} from 'react-query';
import {fetchAllRevenueGroupsRequest} from '../../../api/revenue-group.api';
import {RevenueGroupRow} from '../../../components/revenue-groups/revenue-group-row.component';
import {RevenueGroupUpsertDialog} from '../../../components/revenue-groups/revenue-group-upsert-dialog.component';
import {useAuth} from '../../../contexts/auth.context';
import {useCache} from '../../../contexts/cache/cache.context';
import {RevenueGroupDto} from '@kontactless/admin-api/revenue-group/revenue-group.dto';
import {useParams} from 'react-router-dom';

export function RevenueGroupsSettingsPage() {
  const {user} = useAuth();
  const [isRevenueGroupDialogOpen, setIsRevenueGroupDialogOpen] = useState(false);
  const [revenueGroupEditing, setRevenueGroupEditing] = useState<RevenueGroupDto | undefined>();
  const {dispatchCacheAction} = useCache();
  const params = useParams<{storeId: string}>();
  const storeId = Number(params.storeId);

  const revenueGroupsQuery = useQuery(['revenue-groups', storeId], () => fetchAllRevenueGroupsRequest(user.token, storeId));

  const onRevenueGroupUpserted = (upsertedRevenueGroup: RevenueGroupDto) => {
    dispatchCacheAction({type: 'add-revenue-group', revenueGroup: upsertedRevenueGroup});
  };

  const onRevenueGroupDeleted = (revenueGroup: RevenueGroupDto) => {
    dispatchCacheAction({type: 'remove-revenue-group', revenueGroup});
  };

  const rendeRevenuyeGroupList = () => {
    const {isLoading, data: revenueGroups} = revenueGroupsQuery;

    if (isLoading) {
      return <Spinner />;
    }

    if (!revenueGroups?.length) {
      return <Callout icon="info-sign">There are no revenue groups set for this store</Callout>;
    }

    return revenueGroups?.map((revenueGroup) => (
      <RevenueGroupRow
        key={revenueGroup.id}
        revenueGroup={revenueGroup}
        onDelete={onRevenueGroupDeleted}
        onEdit={(revenueGroup) => {
          setRevenueGroupEditing(revenueGroup);
          setIsRevenueGroupDialogOpen(true);
        }}
      />
    ));
  };

  return (
    <div className="tw-px-6 tw-my-4">
      <h2 className="tw-text-zinc-700 tw-text-xl tw-font-bold">Revenue groups</h2>
      <p className="tw-text-gray-500 tw-text-sm">Group your products to improve your reports</p>
      <div className="tw-mt-4">
        <Button
          text="Add Revenue Group"
          intent="primary"
          icon="add"
          minimal
          onClick={() => setIsRevenueGroupDialogOpen(true)}
          className="tw-mb-2"
        />
        <div className="revenue-groups-table">{rendeRevenuyeGroupList()}</div>
      </div>
      <RevenueGroupUpsertDialog
        storeId={storeId}
        isOpen={isRevenueGroupDialogOpen}
        revenueGroup={revenueGroupEditing}
        onResolve={onRevenueGroupUpserted}
        onClose={() => {
          setIsRevenueGroupDialogOpen(false);
          setRevenueGroupEditing(undefined);
        }}
      />
    </div>
  );
}
