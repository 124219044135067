import {useEffect, useState} from 'react';

export default function useClipboard(timeout = 2000) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timeoutId: number;
    if (copied) {
      timeoutId = window.setTimeout(() => setCopied(false), timeout);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [copied, timeout]);

  const copy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  return {copied, copy};
}
