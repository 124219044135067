import {Button, InputGroup, Menu, MenuItem} from '@blueprintjs/core';
import {Popover2} from '@blueprintjs/popover2';

import {UserDto} from '@kontactless/admin-api/user/user.dto';
import {DateTime} from 'luxon';

import {Link} from 'react-router-dom';
import {useCache} from '../../contexts/cache/cache.context';
import {UserUpsertDialog} from '../../scenes/users/UserUpsertDialog';
import {getSecurityLevelLabel} from '../../utils/helpers.utils';

interface LastLoggedUsersProps {
  companyId: number;
  userNameFilter: string;
  setUserNameFilter(value: string): void;
  isLoading: boolean;
  users?: UserDto[];
  setUserToEdit(user?: UserDto): void;
  userToEdit?: UserDto;
  isUserUpsertDialogOpen: boolean;
  setIsUserUpsertDialogOpen(value: boolean): void;
}

export function LastLoggedUsersSection({
  companyId,
  userNameFilter,
  setUserNameFilter,
  isLoading,
  users,
  userToEdit,
  setUserToEdit,
  isUserUpsertDialogOpen,
  setIsUserUpsertDialogOpen,
}: LastLoggedUsersProps) {
  const {} = useCache();

  return (
    <>
      <div className="tw-border-b tw-border-solid tw-border-gray-200 tw-p-4">
        <div className="tw-flex tw-items-center tw-justify-between">
          <div>
            <h3 className="tw-text-base tw-font-bold tw-leading-6 tw-text-gray-900">Recently active users</h3>
            <Link className="tw-text-blue-600 !tw-no-underline hover:tw-text-blue-600 tw-text-center" to="users">
              View all users
            </Link>
          </div>
          <InputGroup
            leftIcon="search"
            onChange={(event) => setUserNameFilter(event?.target.value)}
            placeholder="Search users..."
            value={userNameFilter}
          />
        </div>
      </div>

      <div className="tw-px-4 sm:tw-px-6 lg:tw-px-8">
        <div className="tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
          <div className="tw-inline-block tw-min-w-full tw-align-middle">
            <table className="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-text-xs">
              <thead className="tw-border-solid">
                <tr>
                  <th scope="col" className="tw-py-2 tw-pl-4 tw-pr-3 tw-text-left tw-font-semibold tw-text-gray-900">
                    User
                  </th>
                  <th scope="col" className="tw-px-3 tw-py-2 tw-text-left tw-font-semibold tw-text-gray-900">
                    Stores assigned
                  </th>
                  <th scope="col" className="tw-px-3 tw-py-2 tw-text-left tw-font-semibold tw-text-gray-900">
                    Role
                  </th>

                  <th scope="col" className="tw-px-3 tw-py-2 tw-text-left tw-font-semibold tw-text-gray-900">
                    Last login
                  </th>

                  <th scope="col" className="tw-relative tw-py-2 tw-pl-3 tw-pr-4">
                    <span className="tw-sr-only">Manage</span>
                  </th>
                </tr>
              </thead>
              <tbody className="tw-divide-y tw-divide-gray-200  tw-border-solid">
                {users?.map((user) => {
                  return (
                    <tr className="tw-border-solid" key={user.id}>
                      <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-gray-900">
                        <p className="tw-text-xs tw-font-semibold tw-text-zinc-700">{user.fullName}</p>
                        <p className="tw-text-xs tw-text-gray-500">{user.username}</p>
                      </td>
                      <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-align-middle">
                        <p className="tw-text-xs tw-text-zinc-700">{user.stores?.map((store) => store.name).join(', ') ?? '–'}</p>
                      </td>
                      <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-align-middle">
                        <p className="tw-text-xs tw-text-zinc-700">{getSecurityLevelLabel(user.securityLevel)}</p>
                      </td>
                      <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-align-middle">
                        {user.status !== 'confirmation-required' ? (
                          user.lastLogin ? (
                            DateTime.fromISO(user.lastLogin).toRelative({style: 'short'})
                          ) : null
                        ) : (
                          <p className="tw-flex tw-items-center tw-justify-center tw-rounded tw-bg-orange-100 tw-p-1 tw-text-xs tw-text-orange-500">
                            Invited
                          </p>
                        )}
                      </td>
                      <td className="tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-6 tw-text-right tw-align-middle">
                        <Popover2
                          className="item-action"
                          content={
                            <Menu>
                              <MenuItem
                                text="Edit"
                                icon="edit"
                                onClick={() => {
                                  setUserToEdit(user);
                                  setIsUserUpsertDialogOpen(true);
                                }}
                              />
                            </Menu>
                          }
                          position="bottom-left"
                        >
                          <Button icon="more" minimal />
                        </Popover2>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <UserUpsertDialog
        companyId={companyId}
        isOpen={isUserUpsertDialogOpen}
        onClose={() => {
          setIsUserUpsertDialogOpen(false);
          setUserToEdit(undefined);
        }}
        user={userToEdit}
      />
    </>
  );
}
