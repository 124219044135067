import React from 'react';

import {Button, MenuItem} from '@blueprintjs/core';
import {IMultiSelectProps, MultiSelect} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';

import {fetchAllAttributesRequest} from '../../api/attributes.api';
import {useAuth} from '../../contexts/auth.context';
import {AttributeDto} from '@kontactless/admin-api/attribute/attribute.dto';

interface AttributesSelectorProps extends Omit<IMultiSelectProps<AttributeDto>, 'itemRenderer' | 'tagRenderer' | 'items'> {
  selectedAttributesIds: number[];
  disabled?: boolean;
}

const AttributesSelectorComponent = MultiSelect.ofType<AttributeDto>();

export const AttributesSelector: React.FC<AttributesSelectorProps> = ({selectedAttributesIds, disabled, ...props}) => {
  const {user} = useAuth();
  const {data: attributes} = useQuery(['attributes'], () => fetchAllAttributesRequest(user.token));
  let inputRef: any = {current: undefined};

  return (
    <AttributesSelectorComponent
      {...props}
      items={attributes || []}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      itemRenderer={(attribute, {modifiers, handleClick}) => (
        <MenuItem
          active={false}
          key={attribute.id}
          onClick={handleClick}
          text={attribute.name}
          icon={selectedAttributesIds.includes(attribute.id) ? 'tick' : 'blank'}
        />
      )}
      scrollToActiveItem={false}
      selectedItems={attributes?.filter(({id}) => selectedAttributesIds.includes(id))}
      tagRenderer={({name}) => name}
      itemsEqual={(m1, m2) => m1.id === m2.id}
      itemPredicate={(query, attribute) => attribute.name.toLocaleLowerCase().includes(query.toLowerCase())}
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      tagInputProps={{
        inputRef: (input) => (inputRef = input),
        placeholder: 'Choose many...',
        tagProps: {minimal: true},
        disabled,
        rightElement: <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />,
      }}
    />
  );
};
