import React, {useEffect} from 'react';

import {Button, FormGroup, InputGroup, MenuItem} from '@blueprintjs/core';
import {DateTime} from 'luxon';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {CreateEventRequestDto, EventDto, UpdateEventRequestDto} from '@kontactless/admin-api/event/event.dto';
import {Controller, useForm} from 'react-hook-form';
import {fetchAllSectionsRequest} from '../../api/section.api';

import {DatePickerButton} from '../../components/selectors/date-picker-button.component';
import {getFormReadyDate, getFormSubmitDatetime} from '../../utils/helpers.utils';

import {SectionDto} from '@kontactless/admin-api/section/section.dto';
import {Select} from '@blueprintjs/select';
import {ImageCropperInput} from '../../common/components/ImageCropperInput';
import {Timezone} from '@kontactless/utils/types';
import {deleteEventRequest} from '../../api/event.api';
import {useAlerts} from '../../contexts/alerts.context';
import {useNavigate} from 'react-router-dom';

interface EventFormFields {
  name: string;
  coverImageUrl: string;
  startDatetime: Date;
  preorderStartDatetime: Date;
  preorderEndDatetime: Date;
  preorderOrderingMethod: SectionDto;
}

const OrderingMethodSelect = Select.ofType<SectionDto>();

type EventFormProps = {
  id: string;
  storeId: number;
  timezone: Timezone;
} & (
  | {
      event: EventDto;
      onSubmit: (data: UpdateEventRequestDto) => void;
    }
  | {
      event?: undefined;
      onSubmit: (data: CreateEventRequestDto) => void;
    }
);

export function EventForm({id, event, storeId, timezone, onSubmit}: EventFormProps) {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();

  const queryClient = useQueryClient();
  const {alertsDispatch} = useAlerts();
  const navigate = useNavigate();
  const {register, handleSubmit, errors, control, reset, watch} = useForm<EventFormFields>();

  const preorderOrderingMethods = useQuery({
    queryKey: ['stores', storeId, 'sections'],
    queryFn: () => fetchAllSectionsRequest(user.token, Number(storeId)),
  });

  const eventDeleteMutation = useMutation<{}, Error, number>({
    mutationKey: ['delete-event'],
    mutationFn: (eventId) => deleteEventRequest(user.token, eventId),
  });

  const onDelete = async (eventId: number) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: (
          <div className="tw-my-2 tw-flex tw-flex-col tw-gap-2">
            <h1 className="tw-font-bold tw-text-lg">Delete event?</h1>
            <p className="tw-text-gray-500">This action cannot be undone.</p>
          </div>
        ),
        intent: 'danger',
        confirmButtonText: 'Yes, delete it',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await eventDeleteMutation.mutateAsync(eventId);
            toaster.show({intent: 'success', message: 'Event deleted successfully'});
            removeAlert();
            queryClient.invalidateQueries(['stores', storeId, 'events']);
            navigate('../');
          } catch (error) {
            toaster.show({intent: 'danger', message: 'The event could not be deleted'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  useEffect(() => {
    if (event) {
      const startDatetime = getFormReadyDate(event.startDatetime, timezone);

      reset(
        {
          name: event.name,
          startDatetime: startDatetime,
          coverImageUrl: event.coverImageUrl,
          preorderStartDatetime: getFormReadyDate(event.preorderStartDatetime, timezone),
          preorderEndDatetime: getFormReadyDate(event.preorderEndDatetime, timezone),
          preorderOrderingMethod: event.preorderOrderingMethod,
        },
        {dirtyFields: false}
      );
    }
  }, [event, reset, timezone]);

  const submitForm = async (form: EventFormFields) => {
    console.log(form);
    try {
      const startDatetime = getFormSubmitDatetime(
        form.startDatetime,
        form.startDatetime.getFullYear(),
        form.startDatetime.getMonth() + 1,
        form.startDatetime.getDate(),
        form.startDatetime.getHours(),
        form.startDatetime.getMinutes(),
        timezone
      );

      const preorderStartDatetime = getFormSubmitDatetime(
        form.preorderStartDatetime,
        form.preorderStartDatetime.getFullYear(),
        form.preorderStartDatetime.getMonth() + 1,
        form.preorderStartDatetime.getDate(),
        form.preorderStartDatetime.getHours(),
        form.preorderStartDatetime.getMinutes(),
        timezone
      );

      const preorderEndDatetime = getFormSubmitDatetime(
        new Date(),
        form.preorderEndDatetime.getFullYear(),
        form.preorderEndDatetime.getMonth() + 1,
        form.preorderEndDatetime.getDate(),
        form.preorderEndDatetime.getHours(),
        form.preorderEndDatetime.getMinutes(),
        timezone
      );

      onSubmit({
        name: form.name,
        startDatetime,
        coverImageUrl: form.coverImageUrl,
        preorderStartDatetime,
        preorderEndDatetime,
        preorderOrderingMethodId: form.preorderOrderingMethod.id,
        storeId,
      });
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: `An error ocurred ${event ? 'updating' : 'creating'} the event`});
    }
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} className="tw-flow-root" id={id}>
      <ul role="list" className="tw--mb-8">
        {/* Event name */}
        <li>
          <div className="tw-relative tw-pb-8">
            <span className="tw-absolute tw-top-5 tw-left-5 tw--ml-px tw-h-full tw-w-0.5 tw-bg-gray-200" aria-hidden="true" />
            <div className="tw-relative tw-flex tw-items-start tw-space-x-3">
              <div>
                <div className="tw-relative tw-px-1">
                  <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-shadow">
                    1
                  </div>
                </div>
              </div>
              <div className="tw-min-w-0 tw-flex-1 tw-py-1.5 tw-max-w-[600px]">
                <div className="tw-flex-col tw-bg-white tw-rounded tw-shadow tw-py-2 tw-px-4">
                  <h2 className="tw-font-bold tw-mb-2 tw-text-base">Name</h2>
                  <FormGroup intent={errors.name ? 'danger' : 'none'} helperText={errors.name?.message}>
                    <InputGroup
                      defaultValue=""
                      className="tw-w-52"
                      placeholder={event?.name}
                      name="name"
                      fill
                      intent={errors.name ? 'danger' : 'none'}
                      inputRef={register({validate: {required: (value: string) => value.trim() !== '' || 'Name is required'}})}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </li>

        {/* Cover image */}
        <li>
          <div className="tw-relative tw-pb-8">
            <span className="tw-absolute tw-top-5 tw-left-5 tw--ml-px tw-h-full tw-w-0.5 tw-bg-gray-200" aria-hidden="true" />
            <div className="tw-relative tw-flex tw-items-start tw-space-x-3">
              <div>
                <div className="tw-relative tw-px-1">
                  <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-shadow">
                    2
                  </div>
                </div>
              </div>
              <div className="tw-min-w-0 tw-flex-1 tw-py-1.5 tw-max-w-[600px]">
                <div className="tw-flex-col tw-bg-white tw-rounded tw-shadow tw-py-2 tw-px-4">
                  <h2 className="tw-font-bold tw-mb-2 tw-text-base">Cover Image</h2>
                  <FormGroup intent={errors.coverImageUrl ? 'danger' : 'none'} helperText={errors.coverImageUrl?.message}>
                    <Controller
                      name="coverImageUrl"
                      control={control}
                      defaultValue=""
                      rules={{validate: {required: (value: string) => value.trim() !== '' || 'Cover image is required'}}}
                      render={(props) => (
                        <ImageCropperInput
                          cropperAspectRatio={11 / 5}
                          cropperDialogTitle="Event cover"
                          onFileUploaded={props.onChange}
                          value={props.value}
                          roundCroppedImage
                        />
                      )}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </li>

        {/* Event date */}
        <li>
          <div className="tw-relative tw-pb-8">
            <span
              className="tw-absolute tw-top-5 tw-left-5 tw--ml-px tw-h-full tw-w-0.5 tw-bg-gray-200"
              aria-hidden="true"
            ></span>
            <div className="tw-relative tw-flex tw-items-start tw-space-x-3">
              <div>
                <div className="tw-relative tw-px-1">
                  <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-shadow">
                    3
                  </div>
                </div>
              </div>
              <div className="tw-min-w-0 tw-flex-1 tw-py-1.5 tw-max-w-[600px]">
                <div className="tw-flex-col tw-bg-white tw-rounded tw-shadow tw-py-2 tw-px-4">
                  <h2 className="tw-font-bold tw-mb-2 tw-text-base">Event Date</h2>

                  <div className="tw-flex tw-gap-2 tw-items-end">
                    <FormGroup intent={errors.startDatetime ? 'danger' : 'none'} helperText={errors.startDatetime?.message}>
                      <Controller
                        name="startDatetime"
                        control={control}
                        rules={{required: 'Event date is required'}}
                        render={(props) => (
                          <DatePickerButton
                            timePrecision="minute"
                            timePickerProps={{useAmPm: true}}
                            placeholder="Select date and time"
                            popoverProps={{position: 'bottom'}}
                            maxDate={DateTime.now().plus({years: 1}).toJSDate()}
                            highlightCurrentDay
                            value={props.value}
                            onChange={props.onChange}
                            canClearSelection={false}
                          />
                        )}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        {/* Pre-order settings */}
        <li>
          <div className="tw-relative tw-pb-8">
            <div className="tw-relative tw-flex tw-items-start tw-space-x-3">
              <div>
                <div className="tw-relative tw-px-1">
                  <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-shadow">
                    4
                  </div>
                </div>
              </div>
              <div className="tw-min-w-0 tw-flex-1 tw-py-1.5 tw-max-w-[600px]">
                <div className="tw-flex-col tw-bg-white tw-rounded tw-shadow tw-py-2 tw-px-4">
                  <h2 className="tw-font-bold tw-mb-2 tw-text-base">Pre-order</h2>

                  {/* Pre-order start date and time */}
                  <div className="tw-flex tw-gap-2 tw-items-end">
                    <FormGroup
                      label="Starts at"
                      intent={errors.preorderStartDatetime ? 'danger' : 'none'}
                      helperText={errors.preorderStartDatetime?.message}
                    >
                      <Controller
                        name="preorderStartDatetime"
                        control={control}
                        rules={{
                          required: 'Pre-order start is required',
                          validate: {
                            beforeEventStart: (value) =>
                              watch('startDatetime') && value > watch('startDatetime')
                                ? 'Pre-order must start before the event starts'
                                : true,
                          },
                        }}
                        render={(props) => (
                          <DatePickerButton
                            timePrecision="minute"
                            timePickerProps={{useAmPm: true}}
                            placeholder="Select date and time"
                            popoverProps={{position: 'bottom'}}
                            maxDate={DateTime.now().plus({years: 1}).toJSDate()}
                            highlightCurrentDay
                            value={props.value}
                            canClearSelection={false}
                            onChange={(date, isUserChange) => {
                              if (isUserChange) {
                                props.onChange(date);
                              }
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </div>

                  {/* Pre-order end date and time */}
                  <div className="tw-flex tw-gap-2 tw-items-end">
                    <FormGroup
                      label="Ends at"
                      intent={errors.preorderEndDatetime ? 'danger' : 'none'}
                      helperText={errors.preorderEndDatetime?.message}
                    >
                      <Controller
                        name="preorderEndDatetime"
                        control={control}
                        rules={{
                          required: 'Pre-order end is required',
                          validate: {
                            beforeEventStart: (value) =>
                              watch('startDatetime') && value > watch('startDatetime')
                                ? 'Pre-order must end before the event starts'
                                : true,
                            afterStartDate: (value) =>
                              watch('preorderStartDatetime') && value < watch('preorderStartDatetime')
                                ? 'Pre-order end must be after pre-order start'
                                : true,
                          },
                        }}
                        render={(props) => (
                          <DatePickerButton
                            timePrecision="minute"
                            timePickerProps={{useAmPm: true}}
                            placeholder="Select date and time"
                            popoverProps={{position: 'bottom'}}
                            maxDate={DateTime.now().plus({years: 1}).toJSDate()}
                            highlightCurrentDay
                            value={props.value}
                            onChange={(date, isUserChange) => {
                              if (isUserChange) {
                                props.onChange(date);
                              }
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </div>

                  <h2 className="tw-font-bold tw-text-base">Ordering method</h2>
                  <div className="tw-text-sm tw-mb-2">Choose which ordering method you want to use for pre-order</div>

                  <FormGroup
                    intent={errors.preorderOrderingMethod ? 'danger' : 'none'}
                    helperText={errors.preorderOrderingMethod ? 'You must select an ordering method' : ''}
                  >
                    <Controller
                      name="preorderOrderingMethod"
                      control={control}
                      rules={{required: 'You must select an ordering method'}}
                      render={(props) => (
                        <OrderingMethodSelect
                          items={preorderOrderingMethods.data ?? []}
                          onItemSelect={props.onChange}
                          itemsEqual={(a, b) => a.id === b.id}
                          popoverProps={{position: 'bottom', minimal: true}}
                          itemRenderer={(item, {handleClick, modifiers}) => (
                            <MenuItem active={modifiers.active} key={item.id} onClick={handleClick} text={item.name} />
                          )}
                        >
                          <Button
                            minimal
                            outlined
                            text={props.value?.name ?? 'Select an ordering method'}
                            rightIcon="chevron-down"
                          />
                        </OrderingMethodSelect>
                      )}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="tw-mt-6 tw-ml-12 tw-pl-1">
        {event && <Button intent="danger" text="Delete event" onClick={() => onDelete(event.id)} />}
      </div>
    </form>
  );
}
