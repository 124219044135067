import {Icon, Tag} from '@blueprintjs/core';

interface RolesChipProps {
  role: 'superadmin' | 'company-admin' | 'supervisor';
}

export const RolesChip = ({role}: RolesChipProps) => {
  return (
    <div className="tw-flex tw-gap-2">
      <Tag round className="!tw-bg-rose-300 !tw-text-zinc-700">
        <div className="tw-flex tw-items-center tw-gap-1">
          <Icon icon="lock" size={10} />
          <p>{role}</p>
        </div>
      </Tag>
    </div>
  );
};
