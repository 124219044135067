import {PropsWithChildren} from 'react';
import {useAuth} from '../../contexts/auth.context';
import {SecurityLevel} from '../../utils/types';

interface RestrictedProps {
  to: SecurityLevel | SecurityLevel[];
}

export default function Restricted({to, children}: PropsWithChildren<RestrictedProps>) {
  const {user} = useAuth();

  const permittedRoles = Array.isArray(to) ? to : [to];

  if (!permittedRoles.includes(user?.securityLevel) || !user) {
    return null;
  }

  return <>{children}</>;
}
