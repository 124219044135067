import React, {useState} from 'react';

import {Button, Callout, FormGroup, InputGroup} from '@blueprintjs/core';
import {useForm} from 'react-hook-form';
import {useMutation} from 'react-query';

import kontactlessSvg from '../../assets/kontactless.svg';
import {PasswordInput} from '../../components/ui/password-input.component';
import {useAuth} from '../../contexts/auth.context';
interface LoginForm {
  username: string;
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
  verificationCode: string;
}

type LoginStep = 'login' | 'new-password-required' | 'forgot-password' | 'verification-code-required' | 'verification-success';

export const LoginScene: React.FC = () => {
  const {signIn, forgotPassword, confirmPassword} = useAuth();
  const [step, setStep] = useState<LoginStep>('login');
  const [formError, setFormError] = useState('');
  const [formWarning] = useState('');
  const [codeDeliveryDetails, setCodeDeilveryDetails] = useState<
    {attribute: string; deliveryMedium: string; destination: string} | undefined
  >();
  const {register, errors, setError, handleSubmit} = useForm<LoginForm>();

  const onSubmit = async (form: LoginForm) => {
    try {
      setFormError('');

      if (step === 'login' || step === 'verification-success') {
        const {result} = await signIn(form.username, form.password);

        if (result === 'new-password-required') {
          setStep('new-password-required');
        }
      } else if (step === 'new-password-required') {
        if (form.newPassword === form.newPasswordConfirmation) {
          await signIn(form.username, form.password, form.newPassword);
        } else {
          setError('newPasswordConfirmation', {type: '', message: "Passwords don't match"});
        }
      } else if (step === 'forgot-password') {
        setCodeDeilveryDetails(await forgotPassword(form.username));
        setStep('verification-code-required');
      } else if (step === 'verification-code-required') {
        if (form.newPassword === form.newPasswordConfirmation) {
          await confirmPassword(form.verificationCode, form.newPassword);
          setStep('verification-success');
        } else {
          setError('newPasswordConfirmation', {type: '', message: "Passwords don't match"});
        }
      }
    } catch (error: any) {
      if (error.message === 'wrong_credentials') {
        setError('username', {type: '', message: ''});
        setError('password', {type: '', message: 'Incorrect username or password.'});
      } else if (error.message === 'invalid_password') {
        setError('newPassword', {
          type: '',
          message:
            'Invalid password.\nIt must be 8 characters long, include one uppercase letter, include one lowercase letter, and one number',
        });
      } else if (error.message === 'invalid_verification_code') {
        setError('verificationCode', {type: 'invalid', message: 'Verification code is incorrect'});
      } else if (error.message === 'reset_limit_exceeded') {
        setFormError('Attempt limit exceeded, please try again later or contact your administrator.');
      } else {
        setFormError('An unexpected error ocurred');
      }
    }
  };

  const signInMutation = useMutation<void, Error, LoginForm>(onSubmit);

  const getVerificationCodeSentMessage = ({deliveryMedium, destination}: {deliveryMedium: string; destination: string}) => {
    if (deliveryMedium === 'EMAIL') {
      return (
        <>
          We just sent a verification code to <b>{destination}</b>. Be sure to check your spam folder.
        </>
      );
    }

    if (deliveryMedium === 'SMS') {
      return (
        <>
          We just sent a verification code by SMS to <b>{destination}</b>.
        </>
      );
    }

    return (
      <>
        We just sent a verification code to <b>{destination}</b>
      </>
    );
  };

  const getCtaButtonText = (step: LoginStep): string => {
    switch (step) {
      case 'login':
        return 'Sign-In';
      case 'new-password-required':
        return 'Set password';
      case 'forgot-password':
        return 'Send verification code';
      case 'verification-code-required':
        return 'Reset password';
      case 'verification-success':
        return 'Sign-In';
      default:
        return 'Confirm';
    }
  };

  return (
    <section className="login-scene">
      <form className="login-form card" onSubmit={handleSubmit((form) => signInMutation.mutate(form))}>
        <header className="form-header">
          <figure className="form-logo">
            <img src={kontactlessSvg} alt="Kontactless" />
          </figure>
        </header>
        <div className="form-body">
          {/* Form Warning */}
          {formWarning ? <Callout intent="warning" children={formWarning} /> : null}

          {/* Forgot password message */}
          {step === 'forgot-password' ? (
            <>
              <h3 className="bp3-heading">Reset your password</h3>
              <p className="bp3-running-text">
                Tell us the username and we'll send you an email with a verification code to reset your password
              </p>
            </>
          ) : null}

          {/* Verification success text */}
          {step === 'verification-success' ? (
            <>
              <h3 className="bp3-heading">Password was reset</h3>
              <p className="bp3-running-text">Please sign-in with your new credentials</p>
            </>
          ) : null}

          {/* Username input */}
          {['login', 'forgot-password', 'verification-success'].includes(step) ? (
            <FormGroup
              label="Username"
              intent={errors.username ? 'danger' : 'none'}
              helperText={errors.username ? errors.username.message ?? 'Username is required' : ''}
            >
              <InputGroup
                name="username"
                inputRef={register({required: true})}
                intent={errors.username ? 'danger' : 'none'}
                disabled={signInMutation.isLoading}
                large
              />
            </FormGroup>
          ) : null}

          {/* Verification code input */}
          {step === 'verification-code-required' ? (
            <>
              {codeDeliveryDetails ? (
                <>
                  <h3 className="bp3-heading">Verification code sent</h3>
                  <p className="bp3-running-text">{getVerificationCodeSentMessage(codeDeliveryDetails)}</p>
                  <Callout
                    className="forgot-password-callout"
                    intent="warning"
                    children="If you don’t receive the email or run into any issue, please contact us at (857) 320-9340"
                  />
                </>
              ) : null}
              <FormGroup
                label="Verification code"
                intent={errors.verificationCode ? 'danger' : 'none'}
                helperText={errors.verificationCode ? errors.verificationCode.message || 'Verification code is required' : ''}
              >
                <InputGroup
                  name="verificationCode"
                  inputRef={register({required: true})}
                  intent={errors.verificationCode ? 'danger' : 'none'}
                  disabled={signInMutation.isLoading}
                  large
                />
              </FormGroup>
            </>
          ) : null}

          {/* Password input */}
          {['login', 'verification-success'].includes(step) ? (
            <FormGroup
              label="Password"
              intent={errors.password ? 'danger' : 'none'}
              helperText={errors.password ? errors.password.message || 'Password is required' : ''}
            >
              <PasswordInput
                name="password"
                inputRef={register({required: true})}
                intent={errors.password ? 'danger' : 'none'}
                disabled={signInMutation.isLoading}
                large
              />
            </FormGroup>
          ) : null}

          {/* New password input */}
          {['new-password-required', 'verification-code-required'].includes(step) ? (
            <>
              <FormGroup
                label="New Password"
                intent={errors.newPassword ? 'danger' : 'none'}
                helperText={
                  errors.newPassword
                    ? errors.newPassword.message || 'New Password is required'
                    : '8 characters long, One uppercase letter, One lowercase letter, One number, One special character'
                }
              >
                <PasswordInput
                  name="newPassword"
                  inputRef={register({required: true})}
                  intent={errors.newPassword ? 'danger' : 'none'}
                  disabled={signInMutation.isLoading}
                  large
                />
              </FormGroup>
              <FormGroup
                label="Password Confirmation"
                intent={errors.newPasswordConfirmation ? 'danger' : 'none'}
                helperText={
                  errors.newPasswordConfirmation
                    ? errors.newPasswordConfirmation.message || 'Password confirmation is required'
                    : ''
                }
              >
                <PasswordInput
                  name="newPasswordConfirmation"
                  inputRef={register({required: true})}
                  intent={errors.newPasswordConfirmation ? 'danger' : 'none'}
                  disabled={signInMutation.isLoading}
                  large
                />
              </FormGroup>
            </>
          ) : null}

          {/* Forgot password link */}
          {step === 'login' ? (
            <a
              href="#"
              role="button"
              onClick={(ev) => {
                ev.preventDefault();
                setStep('forgot-password');
              }}
            >
              Forgot password
            </a>
          ) : null}

          {formError ? <Callout intent="danger">{formError}</Callout> : null}
          <div className="form-actions">
            <Button
              text={getCtaButtonText(step)}
              intent="primary"
              type="submit"
              large
              disabled={signInMutation.isLoading}
              loading={signInMutation.isLoading}
            />
          </div>
        </div>
      </form>
    </section>
  );
};
