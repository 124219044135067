import {callApi} from './api';
import {OrdersReportDto} from '@kontactless/admin-api/orders-report/orders-report.dto';

export const fetchAllOrdersReports = async (token: string, storeId: number): Promise<OrdersReportDto[]> => {
  return callApi<OrdersReportDto[]>(`/orders-reports`, {
    token,
    method: 'GET',
    params: {storeId},
  }).then(({data}) => data);
};

export const deleteOrdersReportRequest = async (token: string, ordersReportId: number): Promise<OrdersReportDto> => {
  return callApi<OrdersReportDto>(`/orders-reports/${ordersReportId}`, {token, method: 'DELETE'}).then(({data}) => data);
};

export const downloadOrderReport = async (token: string, orderReportId: number): Promise<File> => {
  return callApi<File>(`/orders-reports/download/${orderReportId}`, {
    token,
    method: 'GET',
  }).then(({data}) => data);
};
