import React, {Dispatch, createContext, useContext, useReducer} from 'react';

import {IToaster, Toaster} from '@blueprintjs/core';

export interface ToastsState {
  toaster: IToaster;
}

export interface ToastsContextState {
  state: ToastsState;
  dispatch: Dispatch<ToastsAction>;
}

export type ToastsAction = {type: 'create-toaster'};

const initialState: ToastsState = {toaster: Toaster.create({position: 'bottom-left'})};

const ToastsContext = createContext<ToastsContextState>({} as any);
ToastsContext.displayName = 'ToastsContext';

export const ToastsProvider: React.FC = ({children, ...props}) => {
  const [state, dispatch] = useReducer((state: ToastsState, action: ToastsAction) => {
    switch (action.type) {
      case 'create-toaster':
        return {...state, toaster: Toaster.create({position: 'bottom-left'})};
      default:
        return state;
    }
  }, initialState);

  return (
    <ToastsContext.Provider value={{state, dispatch}} {...props}>
      {children}
    </ToastsContext.Provider>
  );
};

export const useToasts = () => {
  const context = useContext(ToastsContext);
  if (context === undefined) {
    throw new Error(`useToasts must be used within an ToastsProvider`);
  }
  return context;
};
