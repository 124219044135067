import React, {useState} from 'react';
import {Button, ButtonGroup} from '@blueprintjs/core';
import {DateTime} from 'luxon';
import {useMutation, useQueryClient} from 'react-query';
import {cancelOrderRequest} from '../../api/order.api';
import {OrderStatusBadge} from '../../components/ui/OrderStatusBadge';
import {RatingStars} from '../../components/ui/rating.component';
import {useAlerts} from '../../contexts/alerts.context';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {OrderPaymentDto} from '@kontactless/admin-api/order-payment/order-payment.dto';
import {OrderStatus, Timezone} from '../../utils/types';
import {OrderDto} from '@kontactless/admin-api/order/order.dto';
import {getPriceText} from '../../utils/helpers.utils';
import {OrderDetailsDialog} from './OrderDetailsDialog';
import {OrderRefundDialog} from './OrderRefundDialog';
import {PaymentMethodItem} from './PaymentMethodItem';

interface OrderRowProps {
  timezone: Timezone;
  order: OrderDto;
  eventsFeatureEnabled: boolean;
}

export function OrderRow({order, timezone, eventsFeatureEnabled}: OrderRowProps) {
  const {user} = useAuth();
  const [isOrderDetailsDialogOpen, setIsOrderDetailsDialogOpen] = useState(false);
  const [isOrderRefundDialogOpen, setIsOrderRefundDialogOpen] = useState(false);
  const {alertsDispatch} = useAlerts();
  const queryClient = useQueryClient();
  const {
    state: {toaster},
  } = useToasts();
  const cancelOrderMutation = useMutation<OrderDto, Error, number>((orderId) => cancelOrderRequest(user.token, orderId));

  const onOrderCancelClick = async (order: OrderDto) => {
    alertsDispatch({
      type: 'set-alert',
      alert: {
        children: `Cancel Order #${order.number} by $${order.total}?`,
        intent: 'danger',
        icon: 'trash',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Close',
        onConfirm: async (setAlert, removeAlert) => {
          try {
            setAlert({loading: true});
            await cancelOrderMutation.mutateAsync(order.id);
            queryClient.invalidateQueries(['stores', order.storeId, 'orders']);
            toaster.show({intent: 'success', message: 'Order was cancelled successfully'});
            removeAlert();
          } catch (error) {
            console.error(error);
            toaster.show({intent: 'danger', message: 'An error ocurred cancelling the order'});
            setAlert({loading: false});
          }
        },
      },
    });
  };

  return (
    <>
      <tr className="odd:tw-bg-white even:tw-bg-gray-50">
        <td className="tw-pr-2 tw-align-middle tw-whitespace-nowrap tw-py-2 tw-text-sm tw-text-gray-900">
          <div className="tw-text-gray-900">
            <span className="tw-font-semibold tw-mr-2 tw-text-xs">#{order.number}</span>

            {/* TODO: Uncomment when order.isPreorder exists */}
            {/* {order.isPreorder && <Badge text="Pre-order" />} */}
          </div>
          <div className="tw-mt-1 tw-text-gray-500 tw-text-xs">
            {DateTime.fromISO(order.createdAt).setZone(timezone).toFormat('LLL d y, h:mm a')}
          </div>
        </td>
        <td className="tw-px-2 tw-align-middle tw-text-xs">
          {order.location ? order.location?.name : order.locationName || '–'}
        </td>
        {eventsFeatureEnabled && (
          <td className="tw-px-2 tw-align-middle tw-text-xs">
            <span className="tw-mr-2">{!!order.event ? order.event.name : '-'}</span>
          </td>
        )}
        <td className="tw-px-2 tw-align-middle tw-text-xs">{order.items?.filter(({type}) => type === 'product').length}</td>
        {/* <td className="tw-px-2 tw-align-middle tw-text-xs">{getPriceText(order.subtotal)}</td> */}
        <td className="tw-px-2 tw-align-middle tw-text-xs">{getPriceText(order.total)}</td>
        <td className="tw-px-2 tw-text-xs tw-align-middle">
          {/* Online payment (card/wallets) */}
          {!!order.payment && (
            <PaymentMethodItem
              cardBrand={order.payment.cardBrand ?? ''}
              lastFourDigits={order.payment.cardDigits ?? ''}
              cardHolderName={order.payment?.cardName!}
            />
          )}
          {/* Room charge */}
          {!!order.roomNumber && 'Room charge'}
          {!order.payment && !order.roomNumber && 'Manual payment'}
        </td>
        <td className="tw-px-2 tw-align-middle tw-text-xs">
          <OrderStatusBadge status={order.status} />
        </td>
        {/* <td className="tw-px-2 tw-align-middle tw-text-xs">{order.roomNumber}</td>
      <td className="tw-px-2 tw-align-middle tw-text-xs">{order.roomGuestLastName}</td> */}
        <td className="tw-px-2 tw-align-middle tw-text-xs">
          {!!order.feedback ? <RatingStars rating={order.feedback.rating} /> : '–'}
        </td>
        <td className="tw-px-2 tw-align-middle tw-text-xs tw-text-right">
          <ButtonGroup>
            <Button minimal intent="primary" small text="View details" onClick={() => setIsOrderDetailsDialogOpen(true)} />
            {canOrderBeCancelled(order.status) && (
              <Button minimal text="Cancel" small intent="danger" onClick={() => onOrderCancelClick?.(order)} />
            )}
            {canOrderBeRefunded(order.status, order.payment, order.total) && (
              <Button minimal text="Refund" small intent="danger" onClick={() => setIsOrderRefundDialogOpen(true)} />
            )}
          </ButtonGroup>
        </td>
      </tr>

      <OrderDetailsDialog
        isOpen={isOrderDetailsDialogOpen}
        orderId={order.id}
        timezone={timezone}
        onClose={() => setIsOrderDetailsDialogOpen(false)}
      />

      <OrderRefundDialog
        isOpen={isOrderRefundDialogOpen}
        order={order}
        timezone={timezone}
        onClose={() => setIsOrderRefundDialogOpen(false)}
      />
    </>
  );
}

const canOrderBeCancelled = (status: OrderStatus): boolean => {
  return ['received', 'preparing', 'ready'].includes(status);
};

const canOrderBeRefunded = (status: OrderStatus, orderPayment: OrderPaymentDto | undefined, orderTotal: number): boolean => {
  return ['delivered', 'partially-refunded'].includes(status) && orderPayment != null && orderTotal > 0;
};
