import {Button, Dialog, DialogProps, FormGroup, InputGroup} from '@blueprintjs/core';

import {useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import {ApiError} from '../../api/api';
import {createApiKeyRequest} from '../../api/api-key.api';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';

interface CreateApiKeyDialogProps extends DialogProps {
  isOpen: boolean;
  companyId: number;
  onApiKeyCreated: (apiKey: string) => void;
}

interface CreateApiKeyForm {
  name: string;
}

export function CreateApiKeyDialog({isOpen, companyId, onApiKeyCreated, ...props}: CreateApiKeyDialogProps) {
  const {register, errors, handleSubmit} = useForm<CreateApiKeyForm>();
  const {user} = useAuth();
  const queryClient = useQueryClient();
  const {
    state: {toaster},
  } = useToasts();

  const createApiKeyMutation = useMutation<string, ApiError, {name: string}>({
    mutationFn: (body) => createApiKeyRequest(user.token, {companyId, ...body}),
    onSuccess: (apiKey) => {
      queryClient.invalidateQueries(['api-keys', companyId]);
      onApiKeyCreated(apiKey);
    },
    onError: () => {
      toaster.show({
        intent: 'danger',
        message: 'An error ocurred creating the API key',
      });
    },
  });

  const submit = (data: CreateApiKeyForm) => {
    createApiKeyMutation.mutate({name: data.name});
  };

  return (
    <Dialog title="Create API Key" isOpen={isOpen} {...props}>
      <div className="tw-px-6 tw-pt-6 tw-flex tw-flex-col tw-gap-2 tw-text-sm">
        <form onSubmit={handleSubmit(submit)}>
          <FormGroup
            label="API key name"
            intent={errors.name ? 'danger' : 'none'}
            helperText={
              errors.name ? errors.name.message || 'Name is required' : 'Give your API key a name to help you identify it later.'
            }
          >
            <InputGroup name="name" inputRef={register({required: true})} intent={errors.name ? 'danger' : 'none'} />
          </FormGroup>
        </form>
        <div className="tw-flex tw-justify-end tw-gap-2">
          <Button text="Cancel" intent="none" outlined onClick={(ev) => props.onClose?.(ev)} />
          <Button
            text="Confirm"
            intent="primary"
            loading={createApiKeyMutation.isLoading}
            disabled={createApiKeyMutation.isLoading}
            onClick={handleSubmit((form) => createApiKeyMutation.mutate(form))}
          />
        </div>
      </div>
    </Dialog>
  );
}
