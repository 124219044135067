import React from 'react';

import {Button, FormGroup, Icon, InputGroup, Tag} from '@blueprintjs/core';
import classNames from 'classnames';
import {DragDropContext, Draggable, Droppable, OnDragEndResponder} from 'react-beautiful-dnd';
import {Controller, useFieldArray} from 'react-hook-form';

import {TaxSelector} from '../selectors/tax-selector.component';
import {ModifierFieldArray} from './modifier-field-array';
import {FieldArrayProps, ModifierGroupFieldset} from './product-upsert-drawer.model';

export const ModifierGroupFieldArray: React.FC<FieldArrayProps> = ({
  control,
  register,
  isFormLoading,
  clearErrors,
  setError,
  errors,
  storeId,
  isOmnivoreProduct,
}) => {
  const {fields, append, remove, move} = useFieldArray<ModifierGroupFieldset>({control, name: 'modifierGroups'});
  const fieldPath = (index: number, fieldName: string) => `modifierGroups[${index}].${fieldName}`;
  const addModifierGroup = () => append({}, false);

  const hasModifiersMinLengthError = (index: number): boolean => !!errors?.modifierGroups?.[index]?.modifiersCount;
  const hasError = (index: number, fieldName: string) => !!(errors as any)?.modifierGroups?.[index]?.[fieldName];

  const onDragEnd: OnDragEndResponder = async (result) => {
    if (result && result.destination) {
      move(result.source.index, result.destination.index);
    }
  };

  return (
    <>
      {fields.length === 0 && <p className="no-modifiers-message">This product doesn't have modifiers.</p>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="drop-modifier">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, index) => (
                <React.Fragment key={`modifier-${field.id}`}>
                  <Draggable draggableId={`drag-modifier-${field.id}`} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <fieldset key={field.id} className="modifier-group">
                          {typeof field.id === 'number' ? (
                            <input
                              type="hidden"
                              name={fieldPath(index, 'id')}
                              defaultValue={field.id}
                              ref={register({valueAsNumber: true})}
                            />
                          ) : null}
                          <header>
                            <Icon
                              {...provided.dragHandleProps}
                              title="Drag to reorder the modifiers"
                              icon="drag-handle-vertical"
                              className="handle"
                            />
                            <InputGroup
                              className={classNames('modifier-group-title editable-text', {empty: !field.name})}
                              autoFocus={!field.name}
                              fill
                              placeholder="Enter the group title"
                              disabled={isFormLoading}
                              name={fieldPath(index, 'name')}
                              defaultValue={field.name}
                              intent={errors?.modifierGroups?.[index]?.name ? 'danger' : 'none'}
                              inputRef={register({required: true})}
                            />
                            {!isOmnivoreProduct && (
                              <Controller
                                name={fieldPath(index, 'taxId')}
                                control={control}
                                defaultValue={field.taxId}
                                render={(props) => (
                                  <TaxSelector
                                    fill
                                    storeId={+storeId!}
                                    disabled={isFormLoading}
                                    selectedItemId={props.value}
                                    popoverProps={{usePortal: false}}
                                    onItemSelected={(tax) => props.onChange(tax?.id ?? 0)}
                                    inputProps={{intent: errors?.modifierGroups?.[index]?.taxId ? 'danger' : 'none'}}
                                  />
                                )}
                              />
                            )}

                            {!isOmnivoreProduct && (
                              <Button
                                className="delete-modifier-group"
                                minimal
                                title="Delete modifiers group"
                                icon="trash"
                                disabled={isFormLoading}
                                onClick={() => remove(index)}
                              />
                            )}
                          </header>

                          <ModifierFieldArray
                            isOmnivoreProduct={isOmnivoreProduct}
                            clearErrors={clearErrors}
                            setError={setError}
                            control={control}
                            parentIndex={index}
                            isFormLoading={isFormLoading}
                            register={register}
                            errors={errors}
                          />

                          <FormGroup label="Modifiers allowed to select:">
                            <div className="modifiers-selection-limits">
                              <InputGroup
                                type="number"
                                fill
                                step={1}
                                disabled={isFormLoading}
                                defaultValue={field.minimumRequired?.toString() || '0'}
                                name={fieldPath(index, 'minimumRequired')}
                                intent={hasError(index, 'minimumRequired') ? 'danger' : 'none'}
                                leftElement={<Tag minimal>Min.</Tag>}
                                inputRef={register({required: true, valueAsNumber: true})}
                              />

                              <InputGroup
                                type="number"
                                fill
                                step={1}
                                disabled={isFormLoading}
                                defaultValue={field.maximumAllowed?.toString()}
                                name={fieldPath(index, 'maximumAllowed')}
                                intent={hasError(index, 'maximumAllowed') ? 'danger' : 'none'}
                                leftElement={<Tag minimal>Max.</Tag>}
                                inputRef={register({valueAsNumber: true})}
                              />
                            </div>
                          </FormGroup>
                          <label className="modifiers-min-count-error">
                            {hasModifiersMinLengthError(index) && 'You must add at least one modifier'}
                          </label>
                        </fieldset>
                      </div>
                    )}
                  </Draggable>
                </React.Fragment>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!isOmnivoreProduct && (
        <Button disabled={isFormLoading} intent="primary" minimal icon="add" onClick={addModifierGroup}>
          Add modifier group
        </Button>
      )}
    </>
  );
};
