import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from '@blueprintjs/core';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {createEventRequest} from '../../api/event.api';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {CreateEventRequestDto, EventDto} from '@kontactless/admin-api/event/event.dto';
import {fetchStoreRequest} from '../../api/store.api';
import {Header} from '../../common/components/layout/Header';
import {Container} from '../../common/components/layout/Container';
import {OrdersFooter} from '../../common/components/layout/OrdersFooter';
import {EventForm} from '../components/EventForm';

export function CreateEventPage() {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const params = useParams<{companyId: string; eventId?: string; storeId: string; page: string}>();
  const storeId = Number(params.storeId);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const createEventMutation = useMutation<EventDto, Error, CreateEventRequestDto>({
    mutationKey: 'create-event',
    mutationFn: (payload) => createEventRequest(user.token, payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId, 'events']);
      toaster.show({message: 'Event created successfully', intent: 'success'});
      navigate('..');
    },
  });

  const storeQuery = useQuery(['stores', storeId], () => fetchStoreRequest(user.token, storeId));

  return (
    <>
      <Header>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-gap-4 tw-items-center">
            <Button
              text="Back"
              icon="chevron-left"
              minimal
              small
              className="!tw-bg-blue-100 !tw-rounded-lg"
              onClick={() => navigate('..')}
            />
            <h1 className="tw-text-base tw-font-bold">Create event</h1>
          </div>

          <div className="tw-flex tw-gap-4 tw-items-center">
            <Button text="Cancel" outlined disabled={createEventMutation.isLoading} onClick={() => navigate('..')} />
            <Button
              text="Create"
              type="submit"
              intent="primary"
              disabled={createEventMutation.isLoading}
              loading={createEventMutation.isLoading}
              form="create-event-form"
            />
          </div>
        </div>
      </Header>
      <Container>
        <EventForm
          id="create-event-form"
          storeId={storeId}
          timezone={storeQuery.data?.timezone || 'America/Los_Angeles'}
          onSubmit={createEventMutation.mutate}
        />
      </Container>
      <OrdersFooter />
    </>
  );
}
