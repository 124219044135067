import React from 'react';

import {Button, ButtonGroup} from '@blueprintjs/core';

interface PaginationProps {
  page: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  disabled?: boolean;
  hideInSinglePage?: boolean;
  onPageSelected: (page: number) => void;
}

export function Pagination({
  page,
  pageSize,
  totalPages,
  totalRecords,
  disabled,
  hideInSinglePage,
  onPageSelected,
}: PaginationProps) {
  if (hideInSinglePage && totalPages === 1) {
    return null;
  }

  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
      <ButtonGroup>
        {page - 3 >= 1 ? (
          <Button outlined icon="double-chevron-left" title="First" onClick={() => onPageSelected(1)} disabled={disabled} />
        ) : null}
        {page > 1 ? (
          <Button outlined icon="chevron-left" title="Previous" onClick={() => onPageSelected(page - 1)} disabled={disabled} />
        ) : null}
        {page - 2 >= 1 ? <Button outlined text={page - 2} onClick={() => onPageSelected(page - 2)} disabled={disabled} /> : null}
        {page - 1 >= 1 ? <Button outlined text={page - 1} onClick={() => onPageSelected(page - 1)} disabled={disabled} /> : null}
        <Button intent="primary" text={page} disabled={disabled} />
        {page + 1 <= totalPages ? (
          <Button outlined text={page + 1} onClick={() => onPageSelected(page + 1)} disabled={disabled} />
        ) : null}
        {page + 2 <= totalPages ? (
          <Button outlined text={page + 2} onClick={() => onPageSelected(page + 2)} disabled={disabled} />
        ) : null}
        {page + 1 <= totalPages ? (
          <Button outlined icon="chevron-right" title="Next" onClick={() => onPageSelected(page + 1)} disabled={disabled} />
        ) : null}
        {page + 4 <= totalPages ? (
          <Button
            outlined
            icon="double-chevron-right"
            title="Last"
            onClick={() => onPageSelected(totalPages)}
            disabled={disabled}
          />
        ) : null}
      </ButtonGroup>
      <div className="tw-text-sm tw-text-gray-500">
        Showing {(page - 1) * pageSize + 1} to {page * pageSize} of {totalRecords} results
      </div>
    </div>
  );
}
