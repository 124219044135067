import React from 'react';

import {Button, Classes, Dialog, FormGroup, IDialogProps, InputGroup} from '@blueprintjs/core';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import {useMutation} from 'react-query';

import {createRevenueGroupRequest, updateRevenueGroupRequest} from '../../api/revenue-group.api';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {RevenueGroupDto, RevenueGroupUpsertRequestDto} from '@kontactless/admin-api/revenue-group/revenue-group.dto';

export interface RevenueGroupUpsertDialogProps extends IDialogProps {
  storeId: number;
  revenueGroup?: RevenueGroupDto;
  onResolve?: (revenueGroup: RevenueGroupDto) => void;
}

interface RevenueGroupForm {
  name: string;
}

export const RevenueGroupUpsertDialog: React.FC<RevenueGroupUpsertDialogProps> = ({
  storeId,
  revenueGroup,
  onResolve,
  ...props
}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {register, handleSubmit, errors} = useForm<RevenueGroupForm>();
  const upsertRevenueGroupMutation = useMutation<RevenueGroupDto, Error, RevenueGroupUpsertRequestDto>((body) => {
    if (revenueGroup?.id) {
      return updateRevenueGroupRequest(user.token, body, revenueGroup.id);
    } else {
      return createRevenueGroupRequest(user.token, body);
    }
  });

  const submitForm = async (form: RevenueGroupForm) => {
    try {
      const upsertedRevenueGroup = await upsertRevenueGroupMutation.mutateAsync({
        ...form,
        storeId,
        name: form.name.trim(),
      });

      props.onClose?.(undefined as any);
      onResolve?.(upsertedRevenueGroup);
      toaster.show({intent: 'success', message: 'Revenue Group was created successfully'});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred creating the revenue group'});
    }
  };

  return (
    <Dialog
      title={revenueGroup ? 'Edit Revenue Group' : 'New Revenue Group'}
      className="kl-dialog revenue-group-dialog"
      {...props}
    >
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          <FormGroup
            label="Revenue Group name"
            intent={errors.name ? 'danger' : 'none'}
            helperText={errors.name ? 'Name is required' : ''}
          >
            <InputGroup
              className="control"
              name="name"
              intent={errors.name ? 'danger' : 'none'}
              defaultValue={revenueGroup?.name ?? ''}
              inputRef={register({
                validate: {
                  required: (value: string) => value.trim() !== '',
                },
              })}
            />
          </FormGroup>
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={upsertRevenueGroupMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text={revenueGroup ? 'Edit' : 'Create'}
          intent="primary"
          loading={upsertRevenueGroupMutation.isLoading}
          disabled={upsertRevenueGroupMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
};
