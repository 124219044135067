import React from 'react';

import {Button, Callout, Classes, Dialog, FormGroup, IDialogProps, InputGroup, Tag} from '@blueprintjs/core';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import {useMutation} from 'react-query';

import {createTaxRequest, updateTaxRequest} from '../../api/tax.api';
import {useAuth} from '../../contexts/auth.context';
import {useToasts} from '../../contexts/toasts.context';
import {TaxCreateRequestDto, TaxDto, TaxUpdateRequestDto} from '@kontactless/admin-api/tax/tax.dto';

export interface TaxUpsertDialogProps extends IDialogProps {
  storeId: number;
  tax?: TaxDto;
  onResolve?: (tax: TaxDto) => void;
}

interface TaxForm {
  name: string;
  percentage: number;
}

export const TaxUpsertDialog: React.FC<TaxUpsertDialogProps> = ({storeId, tax, onResolve, ...props}) => {
  const {user} = useAuth();
  const {
    state: {toaster},
  } = useToasts();
  const {register, handleSubmit, errors} = useForm<TaxForm>();

  const upsertTaxMutation = useMutation<TaxDto, Error, TaxCreateRequestDto | TaxUpdateRequestDto>((body) => {
    if (tax) {
      return updateTaxRequest(user.token, tax?.id, body as TaxUpdateRequestDto);
    } else {
      return createTaxRequest(user.token, body as TaxCreateRequestDto);
    }
  });

  const submitForm = async (form: TaxForm) => {
    try {
      const upsertedTax = await upsertTaxMutation.mutateAsync({
        ...form,
        storeId,
        name: form.name.trim(),
      });

      props.onClose?.(undefined as any);
      onResolve?.(upsertedTax);
      toaster.show({intent: 'success', message: 'Tax was created successfully'});
    } catch (error) {
      console.error(error);
      toaster.show({intent: 'danger', message: 'An error ocurred creating the tax'});
    }
  };

  return (
    <Dialog title={tax ? 'Edit Tax' : 'New Tax'} className="kl-dialog tax-dialog" {...props}>
      <div className={classNames(Classes.DIALOG_BODY, '')}>
        <div className="dialog-form">
          <FormGroup label="Tax name" intent={errors.name ? 'danger' : 'none'} helperText={errors.name ? 'Name is required' : ''}>
            <InputGroup
              className="control"
              name="name"
              intent={errors.name ? 'danger' : 'none'}
              defaultValue={tax?.name ?? ''}
              inputRef={register({
                validate: {
                  required: (value: string) => value.trim() !== '',
                },
              })}
            />
          </FormGroup>
          <FormGroup
            label="Tax percentage"
            intent={errors.percentage ? 'danger' : 'none'}
            helperText={errors.percentage ? 'Percentage is required' : ''}
          >
            <InputGroup
              className="control"
              name="percentage"
              type="number"
              min={0}
              defaultValue={(tax?.percentage ?? 0).toString()}
              rightElement={<Tag minimal children="%" />}
              intent={errors.percentage ? 'danger' : 'none'}
              inputRef={register({
                validate: {
                  required: (value: string) => value !== '' && +value >= 0,
                },
              })}
              fill
            />
          </FormGroup>

          {tax?.id ? <Callout intent="warning">All products associated with this tax will be updated</Callout> : null}
        </div>
      </div>
      <footer className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          intent="danger"
          outlined
          disabled={upsertTaxMutation.isLoading}
          onClick={(ev) => props.onClose?.(ev)}
        />
        <Button
          text={tax ? 'Edit' : '+ Create'}
          intent="primary"
          loading={upsertTaxMutation.isLoading}
          disabled={upsertTaxMutation.isLoading}
          onClick={handleSubmit(submitForm)}
        />
      </footer>
    </Dialog>
  );
};
