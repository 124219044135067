import React, {useEffect, useRef} from 'react';

import {Button, ButtonProps, MenuItem} from '@blueprintjs/core';
import {Suggest, SuggestProps} from '@blueprintjs/select';
import classNames from 'classnames';
import {useQuery} from 'react-query';

import {fetchAllStoresRequest} from '../../api/store.api';
import {useAuth} from '../../contexts/auth.context';
import {StoreDto} from '@kontactless/admin-api/store/store.dto';

import './selectors.styles.scss';

interface StoreSelectorProps
  extends Omit<SuggestProps<StoreDto>, 'items' | 'itemRenderer' | 'onItemSelect' | 'inputValueRenderer'> {
  selectFirstByDefault?: boolean;
  fill?: boolean;
  selectedItemId?: number;
  buttonProps?: ButtonProps;
  companyId?: number;
  includes?: Array<'sections' | 'locationTypes' | 'settings' | 'revenue-groups'>;
  onItemSelected: (item: StoreDto | undefined) => void;
}

const StoreSelectorComponent = Suggest.ofType<StoreDto>();

function getSelectedStore(stores: StoreDto[], selectFirstByDefault: boolean, selectedStoredId?: number) {
  if (selectedStoredId) {
    return stores.find((store) => store.id === selectedStoredId) ?? null;
  }

  if (selectFirstByDefault && stores.length) {
    return stores[0];
  }

  return null;
}

export function StoreSelector({
  selectedItemId,
  onItemSelected,
  buttonProps,
  companyId,
  includes,
  selectFirstByDefault = true,
  ...props
}: StoreSelectorProps) {
  const {user} = useAuth();
  const {data: stores, isLoading: isLoadingStores} = useQuery({
    queryKey: includes?.length ? ['stores', `${companyId ?? companyId}`, includes] : ['stores'],
    queryFn: () => fetchAllStoresRequest(user.token, companyId ?? companyId, includes),
  });
  const inputRef = useRef<any>();
  const selectedItem = getSelectedStore(stores ?? [], selectFirstByDefault, selectedItemId);

  useEffect(() => {
    if (selectedItem?.id && selectedItem.id !== selectedItemId) {
      onItemSelected(selectedItem);
    }
  }, [selectedItem, selectedItemId, onItemSelected]);

  return (
    <StoreSelectorComponent
      {...props}
      inputValueRenderer={(store) => store.name}
      className={classNames(props.className, {'bp3-fill': props.fill})}
      popoverProps={{minimal: true}}
      items={stores ?? []}
      itemRenderer={(store, {modifiers, handleClick}) => (
        <MenuItem
          active={store.id === selectedItemId}
          disabled={modifiers.disabled}
          key={store.id}
          onClick={handleClick}
          text={store.name}
        />
      )}
      onItemSelect={onItemSelected}
      itemsEqual={(store1, store2) => store1.id === store2.id}
      selectedItem={selectedItem}
      itemPredicate={(query, store) => store.name.toLocaleLowerCase().includes(query.toLowerCase())}
      noResults={<MenuItem disabled={true} text="No results." />}
      ref={inputRef}
      inputProps={{
        ...(props.inputProps ?? {}),
        leftElement: isLoadingStores ? <Button minimal loading /> : undefined,
        placeholder: 'Choose one...',
        rightElement: selectedItem ? (
          <Button icon="cross" minimal onClick={() => onItemSelected(undefined)} />
        ) : (
          <Button icon="chevron-down" minimal onClick={() => inputRef.current?.inputElement?.focus()} />
        ),
      }}
    />
  );
}
