import {Tag} from '@blueprintjs/core';
import {TipsConfigurationDto} from '@kontactless/admin-api/tips-configuration/tips-configuration.dto';

export function TipConfigurationSummary({tipConfiguration}: {tipConfiguration: TipsConfigurationDto}) {
  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      {tipConfiguration.isDefaultTipOptionFixed ? (
        <>
          <span className="tw-text-gray-500">Fixed</span>
          <Tag minimal intent="primary">
            {tipConfiguration.defaultTipValue! * 100}%
          </Tag>
        </>
      ) : (
        <>
          <Tag minimal intent={tipConfiguration.defaultTipOption === 1 ? 'primary' : 'none'}>
            {tipConfiguration.tip1! * 100}%
          </Tag>
          <Tag minimal intent={tipConfiguration.defaultTipOption === 2 ? 'primary' : 'none'}>
            {tipConfiguration.tip2! * 100}%
          </Tag>
          <Tag minimal intent={tipConfiguration.defaultTipOption === 3 ? 'primary' : 'none'}>
            {tipConfiguration.tip3! * 100}%
          </Tag>
        </>
      )}
    </div>
  );
}
