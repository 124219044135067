import React from 'react';

import {StarIcon} from '@heroicons/react/20/solid';
import {cn} from '../../common/utils';

interface RatingStarsProps {
  starCount?: number;
  rating: number;
}

export const RatingStars: React.FC<RatingStarsProps> = ({starCount, rating}) => {
  return (
    <div className="tw-flex tw-items-center tw-gap-0.5">
      {Array(starCount ?? 5)
        .fill(null)
        .map((_, idx) => (
          <StarIcon key={idx} className={cn('tw-w-4 tw-h-4 tw-text-blue-400', idx >= rating && 'tw-text-blue-200')} />
        ))}
    </div>
  );
};
