import React, {ReactElement} from 'react';

import {CircularProgress, Tooltip} from '@material-ui/core';
import classNames from 'classnames';
import {isNil, round, sumBy} from 'lodash';

import {RevenueGroupReport} from '../../../api/report.api';
import {DashboardWidget, DashboardWidgetProps} from './dashboard-widget.component';

export interface DashboardWidgetRevenueGroupsProps extends DashboardWidgetProps {
  title: string;
  listItems: RevenueGroupReport;
  errorMessage?: string;
  noItemsComponent?: ReactElement;
}

export const DashboardWidgetRevenueGroups: React.FC<DashboardWidgetRevenueGroupsProps> = ({
  title,
  listItems,
  isLoading,
  errorMessage,
  noItemsComponent,
  ...itemProps
}) => {
  let component: ReactElement | null = null;

  const totalCurrentAmount = sumBy(listItems, ({totalAmount}) => totalAmount);
  const totalCurrentTax = sumBy(listItems, ({totalTax}) => totalTax);

  if (isLoading) {
    component = <CircularProgress className="widget-spinner" />;
  } else if (!isNil(errorMessage)) {
    component = <p className="widget-error">{errorMessage}</p>;
  } else if (listItems.length === 0) {
    component = noItemsComponent ?? null;
  } else if (!component) {
    component = (
      <div className="list-items">
        {listItems.map(({id, name, totalAmount, totalTax}) => (
          <div key={id} className="list-item">
            <Tooltip title={name} arrow>
              <h4 className="item-name">{name}</h4>
            </Tooltip>
            <p className="item-total-amount">
              <small>${round(totalAmount, 2).toFixed(2)}</small>
              <span>Sales</span>
            </p>
            <p className="item-total-amount">
              <small>${round(totalTax, 2).toFixed(2)}</small>
              <span>Tax</span>
            </p>
          </div>
        ))}
        <div className="list-item">
          <h4 className="item-name">Totals</h4>
          <p className="item-total-amount">
            <small>${round(totalCurrentAmount, 2).toFixed(2)}</small>
            <span>Total Sales</span>
          </p>
          <p className="item-total-amount">
            <small>${round(totalCurrentTax, 2).toFixed(2)}</small>
            <span>Total Tax</span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <DashboardWidget {...itemProps} className={classNames('widget-list widget-revenue-groups', itemProps.className)}>
      <h4 className="widget-title">{title}</h4>
      {component}
    </DashboardWidget>
  );
};
