import {callApi} from './api';
import {MenuCreateRequestDto, MenuDto, MenuDuplicateRequestDto, MenuUpdateRequestDto} from '@kontactless/admin-api/menu/menu.dto';

export const fetchMenusRequest = async (token: string, storeId: number): Promise<MenuDto[]> => {
  return callApi<MenuDto[]>(`/menus`, {token, params: {storeId}}).then(({data}) => data);
};

export const fetchMenuRequest = async (token: string, menuId: number): Promise<MenuDto> => {
  return callApi<MenuDto>(`/menus/${menuId}`, {token}).then(({data}) => data);
};

export const fetchMenuSimpleRequest = async (token: string, menuId: number): Promise<MenuDto> => {
  return callApi<MenuDto>(`/menus/${menuId}/simple`, {token}).then(({data}) => data);
};

export const createMenuRequest = async (token: string, menu: MenuCreateRequestDto): Promise<MenuDto> => {
  return callApi<MenuDto>(`/menus`, {token, method: 'POST', body: JSON.stringify(menu)}).then(({data}) => data);
};

export const updateMenuRequest = async (token: string, menuId: number, menu: MenuUpdateRequestDto): Promise<MenuDto> => {
  return callApi<MenuDto>(`/menus/${menuId}`, {token, method: 'PATCH', body: JSON.stringify(menu)}).then(({data}) => data);
};

export const duplicateMenuRequest = async (token: string, menuId: number, menu: MenuDuplicateRequestDto): Promise<MenuDto> => {
  return callApi<MenuDto>(`/menus/${menuId}/duplicate`, {token, method: 'POST', body: JSON.stringify(menu)}).then(
    ({data}) => data
  );
};

export const deleteMenuRequest = async (token: string, menuId: number): Promise<void> => {
  return callApi<void>(`/menus/${menuId}`, {token, method: 'DELETE'}).then(({data}) => data);
};
