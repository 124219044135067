import {QueryClient} from 'react-query';

import {OrderDto} from '@kontactless/admin-api/order/order.dto';

export type CacheOrderAction =
  | {type: 'add-order'; order: OrderDto}
  | {type: 'remove-order'; order: {id: number; storeId?: number}}
  | {type: 'update-order'; order: OrderDto};

export const dispatchOrderAction = (queryClient: QueryClient, action: CacheOrderAction) => {
  switch (action.type) {
    case 'add-order':
      addOrder(queryClient, action.order);
      break;
    case 'remove-order':
      removeOrder(queryClient, action.order.id, action.order.storeId);
      break;
    case 'update-order':
      updateOrder(queryClient, action.order);
      break;
  }
};

const addOrder = (queryClient: QueryClient, order: OrderDto) => {
  queryClient.setQueryData<OrderDto[] | undefined>(['orders'], () => {
    const orders = queryClient.getQueryData<OrderDto[] | undefined>(['orders']);

    if (orders) {
      const updatedOrders = orders.concat([order]);
      return updatedOrders;
    }
  });
};

const removeOrder = (queryClient: QueryClient, orderId: number, storeId?: number) => {
  queryClient.setQueryData<OrderDto[] | undefined>(['orders'], () => {
    const orders = queryClient.getQueryData<OrderDto[] | undefined>(['orders']);

    if (orders) {
      const updatedOrders = orders.filter(({id}) => id !== orderId);
      return updatedOrders;
    }
  });

  queryClient.setQueryData<OrderDto[] | undefined>(['orders', storeId], () => {
    const orders = queryClient.getQueryData<OrderDto[] | undefined>(['orders', storeId]);

    if (orders) {
      const updatedOrders = orders.filter(({id}) => id !== orderId);
      return updatedOrders;
    }
  });
};

const updateOrder = (queryClient: QueryClient, order: OrderDto) => {
  queryClient.setQueryData<OrderDto[] | undefined>(['orders'], () => {
    const orders = queryClient.getQueryData<OrderDto[] | undefined>(['orders']);

    if (orders) {
      const cachedOrderIdx = orders.findIndex(({id}) => id === order.id);

      if (cachedOrderIdx != null) {
        orders[cachedOrderIdx] = {...orders[cachedOrderIdx], ...order};

        return [...orders];
      }
    }
  });

  queryClient.setQueryData<OrderDto[] | undefined>(['orders', order.storeId], () => {
    const orders = queryClient.getQueryData<OrderDto[] | undefined>(['orders', order.storeId]);

    if (orders) {
      const cachedOrderIdx = orders.findIndex(({id}) => id === order.id);

      if (cachedOrderIdx != null) {
        orders[cachedOrderIdx] = {...orders[cachedOrderIdx], ...order};

        return [...orders];
      }
    }
  });

  if (['cancelled-by-store', 'cancelled-by-customer'].includes(order.status)) {
    queryClient.setQueryData<OrderDto[] | undefined>(['orders', order.storeId, 'cancelled'], () => {
      const orders = queryClient.getQueryData<OrderDto[] | undefined>(['orders', order.storeId, 'cancelled']);

      if (orders) {
        const cachedOrderIdx = orders.findIndex(({id}) => id === order.id);

        if (cachedOrderIdx != null) {
          orders[cachedOrderIdx] = {...orders[cachedOrderIdx], ...order};

          return [...orders];
        }
      }
    });
  }
};
