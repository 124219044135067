import {Spinner} from '@blueprintjs/core';
import {PercentageChip} from './PercentageChip';

interface ResumeCardProps {
  title: string;
  amount: string;
  diffPercentage: string;
  isError: boolean;
  isLoading: boolean;
  showPercentage: boolean;
}

export default ({title, amount, diffPercentage, isError, isLoading, showPercentage}: ResumeCardProps) => {
  if (isError) {
    return (
      <div className="tw-flex tw-h-[110px] tw-flex-col tw-gap-1 tw-rounded tw-bg-white tw-p-3 tw-shadow">
        <p className="tw-mt-3 tw-text-red-700">Error</p>
      </div>
    );
  }

  return (
    <>
      <div className="tw-p-3 tw-border tw-shadow tw-space-y-1 tw-bg-white tw-rounded-lg tw-overflow-hidden">
        <p className="tw-truncate tw-text-xs tw-font-medium tw-text-gray-500">{title}</p>

        {isLoading ? (
          <div className="tw-flex tw-justify-center">
            <Spinner size={30} />
          </div>
        ) : (
          <dd>
            <p className="tw-text-xl tw-mb-1 tw-font-bold tw-tracking-tight tw-text-gray-900">{amount}</p>
            {showPercentage && <PercentageChip size="lg" diffPercentage={diffPercentage} />}
          </dd>
        )}
      </div>
    </>
  );
};
