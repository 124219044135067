import React, {ReactElement} from 'react';

import {CircularProgress, Tooltip} from '@material-ui/core';
import classNames from 'classnames';
import {isNil} from 'lodash';

import {DashboardWidget, DashboardWidgetProps} from './dashboard-widget.component';

export interface DashboardWidgetListProps extends DashboardWidgetProps {
  title: string;
  listItems: Array<{
    id: string | number;
    title: string;
    subtitle?: string;
    sideInfo?: string;
    imageUrl?: string;
  }>;
  errorMessage?: string;
  noItemsComponent?: ReactElement;
}

export const DashboardWidgetList: React.FC<DashboardWidgetListProps> = ({
  title,
  listItems,
  isLoading,
  errorMessage,
  noItemsComponent,
  ...itemProps
}) => {
  let component: ReactElement | null = null;

  if (isLoading) {
    component = <CircularProgress className="widget-spinner" />;
  } else if (!isNil(errorMessage)) {
    component = <p className="widget-error">{errorMessage}</p>;
  } else if (listItems.length === 0) {
    component = noItemsComponent ?? null;
  } else if (!component) {
    component = (
      <div className="list-items">
        {listItems.map(({id, title, subtitle, sideInfo, imageUrl}) => (
          <div key={id} className="list-item">
            <figure className="item-figure">{imageUrl ? <img src={imageUrl} alt="product" /> : null}</figure>
            <header className="item-header">
              <Tooltip title={title} arrow>
                <h4 className="item-title">{title}</h4>
              </Tooltip>
              <p className="item-subtitle">{subtitle}</p>
            </header>
            <small className="item-side-info">{sideInfo}</small>
          </div>
        ))}
      </div>
    );
  }

  return (
    <DashboardWidget {...itemProps} className={classNames('widget-list', itemProps.className)}>
      <h4 className="widget-title">{title}</h4>
      {component}
    </DashboardWidget>
  );
};
