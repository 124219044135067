import React from 'react';

import {Button, ButtonGroup, MenuItem} from '@blueprintjs/core';
import {ISelectProps, Select} from '@blueprintjs/select';

interface ScreenNumberSelectorProps extends Omit<ISelectProps<number>, 'items' | 'itemRenderer' | 'onItemSelect'> {
  items: number[];
  itemSelected: number | undefined;
  fill?: boolean;
  onItemSelected: (item: number | undefined) => void;
}

const ScreenNumberSelectorComponent = Select.ofType<number>();

export const ScreenNumberSelector: React.FC<ScreenNumberSelectorProps> = ({items, itemSelected, onItemSelected, ...props}) => {
  return (
    <ScreenNumberSelectorComponent
      filterable={false}
      popoverProps={{className: 'screen-number-selector'}}
      items={items}
      itemRenderer={(screenNumber, {modifiers, handleClick}) => (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={screenNumber}
          onClick={handleClick}
          text={screenNumber ? screenNumber : ''}
        />
      )}
      onItemSelect={(value) => onItemSelected(value)}
    >
      <ButtonGroup fill={props.fill}>
        <Button
          text={`Screen number${itemSelected ? `: ${itemSelected}` : ''}`}
          rightIcon="caret-down"
          icon="filter"
          outlined
          fill={props.fill}
        />
        {itemSelected ? <Button icon="cross" onClick={() => onItemSelected(undefined)} outlined /> : null}
      </ButtonGroup>
    </ScreenNumberSelectorComponent>
  );
};
